import React, { useEffect, useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { connect, useSelector } from "react-redux";
import Pagination from "../../../extra/Pagination";
import Table from "../../../extra/Table";
import moment from "moment";
import { getUserHistory } from "../../../store/users/user.action";
import { useNavigate } from "react-router-dom";

const UserCallHistory = (props) => {
  const { userHistory, totalData, totalCoin, totalCallCharge } = useSelector(
    (state) => state.user
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  useEffect(() => {
    setData(userHistory);
  }, [userHistory]);

  const navigate = useNavigate();

  useEffect(() => {
    props.getUserHistory(
      props?.props?.historyId,
      "call",
      currentPage,
      size,
      startDate,
      endDate
    );
  }, [currentPage, size, startDate, endDate ,props?.props?.data]);

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      body: "detalis",
      Cell: ({ row }) => {
        var str = row?.hostName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span className="fw-semibold ">
              {row?.callType === "MissedCall" && (
                <i class="fa fa-phone m-1 text-danger"> </i>
              )}
              {row?.callType === "Outgoing" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
              {row?.callType === "Incoming" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
            </span>

            <span
              className="text-primary fw-bold fs-6"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/admin/host/profile", { state: row?.hostId })
              }
            >
              @{str}
            </span>

            <span className="me-2 fs-6 ">[{row?.callType}]</span>
          </>
        );
      },
    },
    {
      Header: "Type",
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold">
          {row?.callType === "MissedCall" && (
            <span className="text-danger">MissedCall</span>
          )}
          {row?.callType === "Outgoing" && (
            <span class="text-success"> Outgoing</span>
          )}
          {row?.callType === "Incoming" && (
            <span class="  text-success">Incoming</span>
          )}
        </span>
      ),
    },

    {
      Header: "Duration",
      Cell: ({ row }) => (
        <span className="fw-bold fs-6">
          {row.callConnect
            ? row.callEndTime
              ? moment
                  .utc(
                    moment.duration(row?.duration, "seconds").as("milliseconds")
                  )
                  .format("HH:mm:ss")
              : " - "
            : "00:00:00"}
        </span>
      ),
    },
    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.hCoin}</span>
        ),
    },
    { Header: "Description", body: "description", class: "fw-semibold fs-6" },
    {
      Header: "End By",
      body: "callEndReason",
      class: "fw-semibold  fs-6",
      Cell: ({ row }) => (
        <span className="fw-semibold fs-6">
          {row?.callEndReason == null ? "-" : row?.callEndReason}
        </span>
      ),
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
        </div>
        <Pagination
          component="div"
          count={userHistory?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={totalData}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default connect(null, { getUserHistory })(UserCallHistory);
