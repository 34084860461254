import { data } from "jquery";
import * as ActionType from "./agency.type";
const initialState = {
  agency: [],
  dialogOpen: false,
  dialogData: null,
  agencyHistory: [],
  agencySettlement: [],
  agencyPay: [],
  agencyWiseHost: [],
  totalHost: 0,
};

export const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_AGENCY:
      return {
        ...state,
        agency: action.payload,
      };
    case ActionType.OPEN_DIALOGUE:
      return {
        ...state,
        dialogOpen: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_DIALOGUE:
      return {
        ...state,
        dialogOpen: false,
        dialogData: null,
      };
    case ActionType.ADD_AGENCY:
      let data = [...state.agency];
      data?.unshift(action.payload);

      return {
        ...state,
        agency: data,
      };

    case ActionType.EDIT_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agencyId) =>
          agencyId._id === action.payload.editAgency._id
            ? action.payload.editAgency
            : agencyId
        ),
      };
    case ActionType.SWITCH:
      return {
        ...state,
        agency: state.agency.map((agencyId) =>
          agencyId._id === action.payload.data._id
            ? action.payload.data
            : agencyId
        ),
      };
    case ActionType.AGENCY_HISTORY:
      return {
        ...state,
        agencyHistory: action.payload,
      };
    case ActionType.AGENCY_SETTLEMENT:
      return {
        ...state,
        agencySettlement: action.payload,
      };

    // agencyPayout

    case ActionType.AGENCY_PAYOUT_LIST:
      return {
        ...state,
        agencyPay: action.payload,
      };

    case ActionType.DISABLE_AGENCY:
      return {
        ...state,
        agency: state.agency.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.AGENCY_WISE_HOST:
      return {
        ...state,
        agencyWiseHost: action.payload.host,
        totalHost: action.payload.total,
      };
    case ActionType.AGENCY_WISE_BLOCK:
      return {
        ...state,
        agencyWiseHost: state.agencyWiseHost.map((hostBlock) => {
          if (hostBlock._id === action.payload.id) return action.payload.data;
          else return hostBlock;
        }),
        hostProfile: action.payload.data,
      };
    default:
      return state;
  }
};
