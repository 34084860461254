// get banner

export const GET_BANNER = "GET_BANNER";

// open Dialog

export const OPEN_BANNER_DIALOG = "OPEN_DIALOG";

// close Dialog

export const CLOSE_BANNER_DIALOG = "CLOSE_DIALOG";

// CREATE_BANNER

export const CREATE_BANNER = "CREATE_BANNER";

// UPDATE_BANNER
export const UPDATE_BANNER = "UPDATE_BANNER";

//  DELETE BANNER
export const DELETE_BANNER = "DELETE_BANNER";

//  WEB PURCHASE BANNER
export const  WEB_PURCHASE_BANNER = "WEB_PURCHASE_BANNER";
