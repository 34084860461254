import * as ActionType from "./type";
const initialState = {
  dashBoard: [],
  appData: {},
};

export const dashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_DASHBOARD:
      return {
        ...state,
        dashBoard: action.payload,
      };
    case ActionType.APP_DATA:
      return {
        ...state,
        appData: action.payload,
      };
    default:
      return state;
  }
};
