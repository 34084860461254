import React, { useState } from "react";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { SendMessageByAdmin } from "../../store/message/message.action";

import { updateAlertMessage ,hostApproved } from "../../store/host/host.action";
import { Typography } from "@material-ui/core";
import { VERIFICATION_VIDEO_CLOSE_DIALOGUE } from "../../store/host/host.type";

const RejectionVideoDialogue = (props) => {
    const { verificationDialogue, verificationDialogueData } = useSelector(
        (state) => state.host
      );

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [reason, setReason] = useState("");

  const handleSubmit = () => {
    if (!message || reason === "") {
      return setError({
        message: "Message Is Required",
        reason: "Reason is Required",
      });
    } else {
      props.updateAlertMessage(message, verificationDialogueData._id);

      let messageDiaogeData = {
        title: "Verification rejection message",
        link: "https://qwee.app/",
        message,
      };

      props.SendMessageByAdmin(
        {
          type: "host",
          id: verificationDialogueData._id,
        },
        messageDiaogeData
      );
      const data = {
        _id: verificationDialogueData._id,
        name: verificationDialogueData.name,
        message,
        reason,
      };

      props.hostApproved(data, "Rejected");
      dispatch({ type: VERIFICATION_VIDEO_CLOSE_DIALOGUE });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
          Rejection Video
          </div>
          <div
            className="closeBtn boxCenter"
            onClick={() => {
              dispatch({ type: VERIFICATION_VIDEO_CLOSE_DIALOGUE });
            }}
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 my-2">
              <Input
                label={`Message`}
                id={`message`}
                type={`text`}
                value={message}
                errorMessage={error.message && error.message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      message: `Message Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      message: "",
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 my-2 ">
            <label class="float-left dialog__input__title">
              Delete Reason?
            </label>
            <select
              name="type"
              class="form-control form-control-line mt-2 "
              id="type"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);

                if (e.target.value === "") {
                  return setError({
                    ...error,
                    reason: "Platform type is required !",
                  });
                } else {
                  return setError({
                    ...error,
                    reason: "",
                  });
                }
              }}
              onKeyPress={handleKeyPress}
            >
              <option value="Select Package">---Select Package---</option>

              <option value="">----Select Reason----</option>
              <option value="2">Bio</option>
              <option value="1">Video/Photos</option>
            </select>
            {error.reason && (
              <div class="pl-1 text-left">
                <Typography
                  variant="caption"
                  color="error"
                  style={{ fontFamily: "Circular-Loom" }}
                >
                  {error.reason}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={`Submit`}
              btnColor={`btnBlackPrime`}
              style={{ borderRadius: "5px", width: "80px" }}
              newClass={`me-2`}
              onClick={handleSubmit}
            />

            <Button
              btnName={`Close`}
              btnColor={`bg-danger text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { SendMessageByAdmin, updateAlertMessage ,hostApproved})(
  RejectionVideoDialogue
);
