import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { getHostReport } from "../store/Report/action";
import Pagination from "../extra/Pagination";
import Title from "../extra/Title";
import $ from "jquery";
import male from "../../assets/images/maleImage.png";
//Pagination

const HostReport = (props) => {
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);

  useEffect(() => {
    props.getHostReport();
  }, []);

  const { hostReport } = useSelector((state) => state.report);

  useEffect(() => {
    setData(hostReport);
  }, [hostReport]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  return (
    <>
      <div className="primeHeader">
        <div className="row ">
          <div className="col-6 title-head title-head ">
            <Title name={`Host Report`} />
          </div>
          <div className="col-6 d-flex justify-content-end mt-3">
            <ol class="breadcrumb1 pb-0 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Host Report </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="d-flex mb-3"></div>

      <div className="table-responsive bg-white shadow-sm">
        <table
          className="table table-hover card-table table-vcenter text-nowrap"
          style={{ borderSpacing: "0px" }}
        >
          <thead className="reportBorder">
            <tr className="text-center reportTable">
              <th width="50px" className="fw-bold fs-18 ">
                No
              </th>
              <th width="270px" className="fw-bold fs-18">
                Avatar
              </th>
              <th width="220px" className="fw-bold fs-18">
                Name
              </th>
              <th width="210px" className="fw-bold fs-18">
                Country
              </th>
              <th width="180px" className="fw-bold fs-18">
                Count
              </th>
              <th width="120px" className="fw-bold fs-18">
                Coin
              </th>
              <th width="100px" className="fw-bold fs-18">
                Info
              </th>
            </tr>
          </thead>
        </table>

        {data?.length > 0 ? (
          (rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          )?.map((repo, ids) => {
            return (
              <>
                <div class="accordion" id={`accordionExample`}>
                  <div class="accordion-item border-bottom pb-4">
                    <h2 class="accordion-header" id={`heading${ids}`}>
                      <button
                        class="accordion-button justify-content-between p-0 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${ids}`}
                        aria-expanded="true"
                        aria-controls={`collapse${ids}`}
                      >
                        <table
                          className="w-100 table table-hover card-table table-vcenter text-nowrap border-bottom"
                          style={{ borderSpacing: "0px" }}
                        >
                          <tr
                            className="text-center py-0"
                            style={{ fontSize: "13px" }}
                          >
                            <td width="41px" style={{ fontSize: "18px" }}>
                              {page * rowsPerPage + parseInt(ids) + 1}
                            </td>

                            <td className="p-0" width="221px">
                              <img
                                src={repo._id.image[0]}
                                alt="Profile"
                                className="m-auto table_image "
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "25px",
                                }}
                              />
                            </td>
                            <td width="179px" style={{ fontSize: "18px" }}>
                              {repo._id.name}
                            </td>
                            <td
                              width="170px"
                              className="text-warning fw-bolder"
                              style={{ fontSize: "18px" }}
                            >
                              {repo._id.country}
                            </td>
                            <td
                              width="150px"
                              className="text-info fw-bolder"
                              style={{ fontSize: "18px" }}
                            >
                              {repo.count}
                            </td>
                            <td
                              width="105px"
                              className="text-danger fw-bolder"
                              style={{ fontSize: "18px" }}
                            >
                              {repo._id.coin}
                            </td>
                            <td width="75px" style={{ fontSize: "18px" }}>
                              <button
                                type="button"
                                className="btn btn-primary bg-primary btn-icon p-0 py-2"
                                style={{ width: "50px" }}
                              >
                                <i
                                  class="fa-solid fa-circle-info text-white"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </button>
                    </h2>
                    <div
                      id={`collapse${ids}`}
                      class="accordion-collapse collapse"
                      aria-labelledby={`heading${ids}`}
                      data-bs-parent={`#accordionExample`}
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      <div class="accordion-body py-4">
                        <h5 className="fw-bold text-primary ">
                          Report Host Details
                        </h5>
                        <table width="100%">
                          <tr className="text-center border-bottom">
                            <th width="50px" className="fw-bold fs-16">
                              No
                            </th>
                            <th width="200px" className="py-3 fs-16">
                              Avatar
                            </th>
                            <th width="150px" className="py-3 fs-16">
                              Name
                            </th>
                            <th width="100px" className="py-3 fs-16">
                              Country
                            </th>
                            <th width="150px" className="py-3 fs-16">
                              Coin
                            </th>
                            <th className="py-3">Decription</th>
                          </tr>
                          {repo.report.map((com, index) => {
                            return (
                              <>
                                <tr className="text-center border-bottom py-2">
                                  <td className="py-2 fs-16">{index + 1}</td>
                                  <td className="py-2 fs-16">
                                    <img
                                      src={com?.user?.image}
                                      alt="Profile"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      className="table_image m-auto"
                                    />
                                  </td>
                                  <td className="py-2 fs-16">
                                    {com?.user?.name}
                                  </td>
                                  <td className="py-2 fs-16 text-danger fw-bolder">
                                    {com?.user?.country}
                                  </td>
                                  <td className="py-2 fs-16 text-success fw-bolder">
                                    {com?.user?.coin}
                                  </td>
                                  <td className="py-2 fs-16">
                                    {com?.description}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <table className="w-100 table table-hover card-table table-vcenter text-nowrap border-bottom">
            <tr>
              <td colSpan="7" className="text-center">
                No Data Found !
              </td>
            </tr>
          </table>
        )}

        <table width="100%">
          <tr className="text-end">
            <td colspan="7">
              <Pagination
                component="div"
                count={hostReport?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={hostReport?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default connect(null, { getHostReport })(HostReport);
