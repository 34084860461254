import * as ActionType from "./feedBack.type";

const initialState = {
  feedBack: [],
  dialog: false,
  dialogData: null,
};

export const feedBackReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Feedback
    case ActionType.GET_FEEDBACK:
      return {
        ...state,
        feedBack: action.payload,
      };

    //Feedback Solved Switch
    case ActionType.FEEDBACK_SOLVED:
      return {
        ...state,
        feedBack: state.feedBack.filter(
          (solvedFeedBack) =>
            solvedFeedBack._id !== action.payload && solvedFeedBack
        ),
      };
    //Feedback Delete
    case ActionType.FEEDBACK_DELETE:
      return {
        ...state,
        feedBack: state.feedBack.filter((feedBack) => feedBack._id !== action.payload),
      };

    //Open Feedback Dialog
    case ActionType.OPEN_FEEDBACK_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Feedback Dialog
    case ActionType.CLOSE_FEEDBACK_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};
