import React, { useEffect, useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { connect, useSelector } from "react-redux";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import { getUserHistory } from "../../../store/users/user.action";

const UserAdminHistory = (props) => {
  const { userHistory, totalData, totalCoin, totalCallCharge } = useSelector(
    (state) => state.user
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  useEffect(() => {
    setData(userHistory);
  }, [userHistory]);

  useEffect(() => {
    props.getUserHistory(
      props?.props,
      "message",
      currentPage,
      size,
      startDate,
      endDate
    );
  }, [currentPage, size, startDate, endDate]);

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span className="fw-semibold ,fs-6">{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark">{row.hCoin}</span>
        ),
    },

    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="fw-semibold fs-6">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="fw-semibold fs-6">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="tableMain mt-5">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
          <Pagination
            component="div"
            count={userHistory?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalData}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUserHistory })(UserAdminHistory);
