import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { redeemPending, redeemPay } from "../../store/redeem/redeem.action";

import { OPEN_REDEEM_DIALOG } from "../../store/redeem/redeem.type";
import RedeemNoteDilaoge from "./RedeemNoteDilaoge";
import Pagination from "../../extra/Pagination";
import Title from "../../extra/Title";
import Searching from "../../extra/Searching";

const RedeemPending = (props) => {
  const { redeemPending } = useSelector((state) => state.redeem);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [sorting, setSorting] = useState({
    hostSalary: false,
    revenue: false,
    total: false,
    penaltyOrBonus: false,
    commissionRate: false,
    dollar: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    props.redeemPending();
  }, []);

  useEffect(() => {
    setData(redeemPending);
  }, [redeemPending]);

  const handleRedeem = (id) => 
  {
    props.redeemPay(id);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  //handle Search Function
  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = redeemPending.filter((data) => {
        return (
          data?.agencyName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.uniqueId?.toString()?.indexOf(value) > -1 ||
          data?.settlementCoin?.toString()?.indexOf(value) > -1 ||
          data?.hostSalary?.toString()?.indexOf(value) > -1 ||
          data?.total?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(redeemPending);
    }
  };

  //Handle Redirect User Settlement Page
  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
    };
    navigate("/admin/agency/agencySettlement", { state: state });
  };

  const openAction = (id) => {
    dispatch({ type: OPEN_REDEEM_DIALOG, payload: id });
  };

  // const handleShort = () => {
  //   setSorting(!sorting);
  //   if (!sorting) {
  //     let arraySort = data.sort((data, array) => {
  //       return data.hostSalary - array.hostSalary;
  //     });
  //     setData(arraySort);
  //   } else {
  //     let arraySort = data.sort((data, array) => {
  //       return array.hostSalary- data.hostSalary;
  //     });
  //     setData(arraySort);
  //   }
  // };

  const handleSort = (field) => {
    setSorting((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    let arraySort;
    if (!sorting[field]) {
      arraySort = data.sort((a, b) => a[field] - b[field]);
    } else {
      arraySort = data.sort((a, b) => b[field] - a[field]);
    }
    setData([...arraySort]);
  };

  return (
    <>
      <div className=" primeHeader">
        <div className="row ">
          <div className="col-6 title-head title-head ">
            <Title name={`Redeem Pending`} />
          </div>
          <div className="col-6 d-flex justify-content-end mt-3">
            <ol class="breadcrumb1 pb-0 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Redeem Pending</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="d-flex my-3 "></div>
      <div className="card border-0 m-3">
        <div className="card-body p-0">
          <div className="row my-4">
            <div className="col-8 d-flex align-items-center">
              <div></div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
              <form action="">
                <div className="input-group  border rounded-pill">
                  <input
                    type="search"
                    placeholder="Searching for..."
                    aria-describedby="button-addon4"
                    className="form-control bg-none border-0 rounded-pill searchBar"
                    onChange={handleSearch}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="table-responsive">
            <table
              className="table table-hover card-table table-vcenter text-nowrap settlementTable"
              style={{ backgroundColor: "#fbfafa" }}
            >
              <thead>
                <tr className="text-center">
                  <th className="fw-bold border-top">ID</th>
                  <th className="fw-bold border-top">Agency Code</th>
                  <th className="fw-bold border-top">Agency Name</th>
                  <th className="fw-bold border-top">Note</th>
                  <th className="fw-bold border-top">Start Date </th>
                  <th className="fw-bold border-top">End Date</th>
                  <th className="fw-bold border-top bg-dark4">
                    Host Salary
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("hostSalary")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark4 border-top">
                    Revenue
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("revenue")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark2 border-top">Total Revenue</th>
                  <th className="fw-bold bg-dark3 border-top">
                    Commission{" "}
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("commissionRate")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark3 border-top">
                    penalty/Bonus
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("penaltyOrBonus")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark2 border-top">
                    Total{" "}
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("total")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark2 border-top">
                    Total Amount{" "}
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("dollar")}
                    ></i>
                  </th>
                  <th className="fw-bold border-top">Pay</th>
                  <th className="fw-bold border-top">Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((redeemData, index) => {
                      // var string = redeemData?.penaltyOrBonus.toString();
                      var data = String(redeemData?.penaltyOrBonus).charAt(0);

                      return (
                        <>
                          <tr className="text-center">
                            <td className="border-bottom">
                              {page * rowsPerPage + parseInt(index) + 1}
                            </td>
                            <td className="border-bottom">
                              {redeemData?.agencyCode?.code}{" "}
                            </td>
                            <td className="border-bottom">
                              {redeemData?.agencyName}{" "}
                            </td>
                            <td className="text-danger fw-semibold border-bottom">
                              {redeemData?.note ? redeemData?.note : "-"}
                            </td>
                            <td className="fw-bold border-bottom">
                              {redeemData?.startDate}
                            </td>
                            <td className="fw-bold border-bottom">
                              {redeemData?.endDate}
                            </td>
                            <td className="fw-bold bg-dark4 border-bottom">
                              {redeemData?.hostSalary}
                            </td>
                            <td
                              className="fw-bold bg-dark4 cursor-pointer border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {redeemData?.settlementCoin}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-info bg-dark2 cursor-pointer border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {parseInt(redeemData?.settlementCoin) +
                                parseInt(redeemData?.hostSalary)}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold bg-dark3 border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {redeemData.agencyCommission}{" "}
                              <span className="text-danger">
                                {redeemData.commissionRate
                                  ? redeemData.commissionRate !== 0 &&
                                    `(${redeemData.commissionRate}%)`
                                  : ""}
                              </span>
                            </td>

                            <td className="fw-bold bg-dark3 border-bottom">
                              {redeemData?.penaltyOrBonus}
                            </td>

                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-danger bg-dark2 cursor-pointer border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {redeemData?.total}
                            </td>
                            <td className="fw-bold text-info bg-dark2 cursor-pointer border-bottom">
                              {redeemData?.dollar}$
                            </td>
                            <td className="border-bottom">
                              <div
                                className="btn btn-success px-5"
                                onClick={() => handleRedeem(redeemData?._id)}
                              >
                                Pay
                              </div>
                            </td>
                            <td className="border-bottom">
                              <button
                                className="btn btn-warning text-white "
                                onClick={() => openAction(redeemData)}
                              >
                                Action
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan="50" className="text-center">
                      No Data Found !
                    </td>
                  </tr>
                )}

                <td colSpan="50" className="bg-white">
                  <Pagination
                    component="div"
                    count={redeemPending?.length}
                    serverPage={page}
                    type={"client"}
                    onPageChange={handleChangePage}
                    serverPerPage={rowsPerPage}
                    totalData={redeemPending?.length}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <RedeemNoteDilaoge />
    </>
  );
};

export default connect(null, { redeemPending, redeemPay })(RedeemPending);
