import React, { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";

import { getAgencySettlement } from "../../store/settlement/settlement.action";
import dayjs from "dayjs";
import Title from "../../extra/Title";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Button from "../../extra/Button";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

const AgencyTransaction = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState("agency");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { settlement } = useSelector((state) => state.settlement);

  useEffect(() => {
    props.getAgencySettlement("ALL");
  }, []);

  const handelShowHostSettlement = (row) => {
    const state = {
      id: row,
      dayType: "ALL",
      startDate: startDate,
      endDate: endDate,
    };
    navigate("/admin/settlement/agencyHostSettlement", {
      state: state,
    });
  };

  useEffect(() => {
    setData(settlement);
  }, [settlement]);

  const navigate = useNavigate();

  const agencyTransactionTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Agency Name", body: "name", class: "fw-semibold fs-6 " },
    { Header: "Agency Code", body: "uniqueId", class: "fw-semibold fs-6 " },
    {
      Header: "Total Host",
      body: "totalHost",
      class: "fw-semibold fs-6 text-dark cursorPointer",
      sorting: { type: "client" },
    },
    {
      Header: "Host Salary",
      body: "hostBasicSalary",
      class: "fw-semibold fs-6 ",
      sorting: { type: "client" },
    },

    {
      Header: "Agency Commission",
      body: "agencyCommission",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span
          className="fs-6 fw-semibold "
       
      
        >
          {row?.agencyCommission?.toFixed(2)}
          <span className="text-danger">
            {row?.commissionRate !== 0 && `(${row?.commissionRate}%)`}
          </span>
        </span>
      ),
    },
    {
      Header: "Revenue",
      body: "Revenue",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <Tooltip title={`${row.name} Settlement`} placement="bottom">
          <span
            className="fs-6 fw-bold text-success"
            style={{ cursor: "pointer" }}
            onClick={() => handelShowHostSettlement(row._id)}
          >
            {row?.settlementCoin?.toFixed(2)}
          </span>
        </Tooltip>
      ),
    },
    {
      Header: "Total",
      body: "totalPayable",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span
          className="fs-6 fw-bold text-primary"
          style={{ cursor: "pointer" }}
          onClick={() => handelShowHostSettlement(row._id)}
        >
          {row?.totalPayable?.toFixed(2)}
        </span>
      ),
    },

    {
      Header: "Settlement",
      body: "settlement",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-info`}
          btnIcon={`fas fa-folder px-2 text-white fs-6 `}
          onClick={() => handelShowHostSettlement(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");

    setStartDate(start);
    setEndDate(end);
    props.getAgencySettlement("custom", start, end);
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker?.element.val("");
    setStartDate("");
    setEndDate("");
    props.getAgencySettlement("week");
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className=" primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head mt-3">
                <Title name={`Agency Transaction`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem text-capitalize">
                    Agency Transaction
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="coin-plan">
            <div className="planMain">
              <div className="coin-plan-btn d-flex align-items-center pt-4 ps-3">
                <div>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        cancelLabel: "Clear",
                      },
                      maxDate: new Date(),

                      buttonClasses: ["btn btn-dark"],
                    }}
                    onApply={handleApply}
                    onCancel={handleCancel}
                  >
                    <input
                      readOnly
                      type="text"
                      class="daterange form-control float-left bg-primary text-white"
                      value="Select Date"
                      style={{ width: 120, fontWeight: 700 }}
                    />
                  </DateRangePicker>
                </div>
                {startDate === "" || endDate === "" ? (
                  <div className="ms-3 fs-5 text-primary fw-bold mb-2">
                    All Settlement
                  </div>
                ) : (
                  <div className="dateShow ms-3 fs-5 text-primary fw-bold mb-2">
                    <span className="me-3">{startDate}</span>
                    <span className="me-3">To</span>
                    <span>{endDate}</span>
                  </div>
                )}
              </div>
              <div className="primeMain border-top">

              <Table
                data={data}
                mapData={agencyTransactionTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
              </div>

              <Pagination
                component="div"
                count={settlement?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={settlement?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencySettlement })(AgencyTransaction);
