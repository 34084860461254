import * as ActionType from "./user.type";

const initialState = {
  user: [],
  userDetails: {},
  total: 0,
  userHistory: [],
  totalCoin: 0,
  totalCallCharge: 0,
  allCountry: [],
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get All User
    case ActionType.GET_USERS:
      return {
        ...state,
        user: action.payload.user,
        total: action.payload.total,
      };
    //Get Plan Purchase User
    case ActionType.GET_PLAN_PURCHASE_USERS:
      return {
        ...state,
        user: action.payload.user,
        total: action.payload.total,
      };

    //Get single user by id
    case ActionType.GET_SINGLE_USERS:
      return {
        ...state,
        userDetails: action.payload,
      };

    //Update User Coin
    case ActionType.UPDATE_USER_COIN:
      return {
        ...state,
        user: action.payload.user,
      };

    // blocked user
    case ActionType.BLOCKED_USER:
      return {
        ...state,
        user: state.user.map((users) => {
          if (users._id === action.payload.id) return action.payload.data;
          else return users;
        }),

        userDetails: action.payload.data,
      };

    //Get User History
    case ActionType.GET_USER_HISTORY:
      
      return {
        ...state,
        userHistory: action.payload.history,
        totalCoin: action.payload.totalCoin ? action.payload.totalCoin : 0,
        totalData: action.payload.totalData ? action.payload.totalData: 0,
        totalCallCharge: action.payload.totalCallCharge
          ? action.payload.totalCallCharge
          : 0,
      };

    default:
      return state;
  }
};
