import React, { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCoinSeller,
  deleteCoinSeller,
  showCoinSeller,
} from "../../store/coinSeller/action";
import Pagination from "../../extra/Pagination";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Searching from "../../extra/Searching";
import CoinSellerCreateDialogue from "./CoinSellerCreateDialogue";
import Table from "../../extra/Table";
import dayjs from "dayjs";
import ToggleSwitch from "../../extra/ToggleSwitch";
import CoinSellerAddCoin from "./CoinSellerAddCoin";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import male from "../../../assets/images/maleImage.png";

const CoinSellerTable = (props) => {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { coinSeller } = useSelector((state) => state.coinSeller);

  useEffect(() => {
    props.getCoinSeller();
  }, []);

  useEffect(() => {
    setData(coinSeller);
  }, [coinSeller]);

  const dispatch = useDispatch();

  const handleClick = (value) => {
    props.showCoinSeller(value);
  };

  const handleDelete = (value) => {
    props.deleteCoinSeller(value);
  };

  const navigate = useNavigate();

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const coinSellerTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Name", body: "name" },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Email", body: "email" },
    { Header: "Mobile No", body: "mobileNo" },
    {
      Header: "Receive Coin",
      body: "receiveCoin",
      sorting: { type: "client" },
    },
    { Header: "Spend Coin", body: "spendCoin", sorting: { type: "client" } },
    { Header: "Coin", body: "coin", sorting: { type: "client" } },
    {
      Header: "Give Coin",
      body: "coin",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-info`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row._id, type: "coinSellerAddCoin" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "Created At",
      body: "created At",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Is Show",
      body: "isShow",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isShow} onClick={() => handleClick(row._id)} />
      ),
    },
    {
      Header: "Is Accepted",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isDisable}
          onClick={() => handleDelete(row._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "coinSellerCerate" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "History",
      body: "history",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-success`}
            btnIcon={`fas fa-history px-2 fs-6`}
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
            onClick={() =>
              navigate("/admin/coinSeller/history", { state: row })
            }
          />
        </>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Coin Seller`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Coin Seller</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="planMain">
          <div className="coin-plan">
            <div className="coin-plan-btn">
              <div className="row ps-4 py-3">
                <div className="col-6 ">
                  <div>
                    <Button
                      newClass={`themeFont boxCenter userBtn`}
                      btnColor={`btnBlackPrime`}
                      btnName={`New`}
                      btnIcon={`fa-regular fa-plus me-2`}
                      onClick={() => {
                        dispatch({
                          type: OPEN_DIALOGUE,
                          payload: { type: "coinSellerCerate" },
                        });
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "80px",
                      }}
                    />

                    {dialogue && dialogueType === "coinSellerCerate" && (
                      <CoinSellerCreateDialogue />
                    )}
                    {dialogue && dialogueType === "coinSellerAddCoin" && (
                      <CoinSellerAddCoin />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <Searching
                    button={true}
                    type={`client`}
                    data={coinSeller}
                    setData={setData}
                    column={coinSellerTable}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={coinSellerTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <Pagination
              component="div"
              count={coinSeller?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={coinSeller?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getCoinSeller,
  deleteCoinSeller,
  showCoinSeller,
})(CoinSellerTable);
