import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Input from "../../extra/Input";
import { connect, useSelector } from "react-redux";
import SettingBox from "../../Table/setting/SettingBox";
import {
  getSetting,
  updateSetting,
  handleToggleSwitch,
} from "../../store/setting/setting.action";
//Multi Select Dropdown
import Multiselect from "multiselect-react-dropdown";

const PaymentSetting = (props) => {
  const { setting } = useSelector((state) => state.setting);

  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [googlePlaySwitch, setGooglePlaySwitch] = useState(false);
  const [stripeSwitch, setStripeSwitch] = useState(false);
  const [googlePlayKey, setGooglePlayKey] = useState("");
  const [webPaymentLink, setWebPaymentLink] = useState("");
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [googlePlayEmail, setGooglePlayEmail] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [maxPurchaseCoin, setMaxPurchaseCoin] = useState(0);
  const [maxPurchaseAmount, setMaxPurchaseAmount] = useState(0);
  const [selectedValue, setSelectedValue] = useState([]);

  const [error, setError] = useState({
    googlePlayKey: "",
    googlePlayEmail: "",
    stripePublishableKey: "",
    stripeSecretKey: "",
    webPaymentLink: "",
    maxPurchaseAmount: "",
    maxPurchaseCoin: "",
  });

  useEffect(() => {
    props.getSetting();
  }, []);

  useEffect(() => {
    const paymentGateway = setting?.paymentGateway?.map((data) => {
      return {
        name:
          (data == "UPI" && "UPI") ||
          (data == "Paytm" && "Paytm") ||
          (data == "Banking" && "Banking"),
      };
    });
    setStripePublishableKey(setting?.stripePublishableKey);
    setStripeSwitch(setting?.stripeSwitch);
    setGooglePlaySwitch(setting?.googlePlaySwitch);
    setGooglePlayKey(setting?.googlePlayKey);
    setStripeSecretKey(setting?.stripeSecretKey);
    setGooglePlayEmail(setting?.googlePlayEmail);
    setPaymentGateway(setting?.paymentGateway);
    setSelectedValue(paymentGateway);
    setWebPaymentLink(setting?.webPaymentLink);
    setMaxPurchaseCoin(setting?.maxPurchaseCoin);
    setMaxPurchaseAmount(setting?.maxPurchaseAmount);
    setError({
      googlePlayKey: "",
      googlePlayEmail: "",
      stripePublishableKey: "",
      stripeSecretKey: "",
      paymentGateway: "",
      webPaymentLink: "",
      maxPurchaseAmount: "",
      maxPurchaseCoin: "",
    });
  }, [setting]);

  const paymentGateWayOption = [
    { name: "UPI", id: "UPi" },
    { name: "Paytm", id: "Paytm" },
    { name: "Banking", id: "Banking" },
  ];

  //onselect function of selecting multiple values
  function onSelect(selectedList, selectedItem) {
    paymentGateway.push(selectedItem.name);
  }

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setPaymentGateway(selectedList.map((data) => data.name));
  }

  const handleSubmit = () => {
    if (
      !googlePlayKey ||
      !stripeSecretKey ||
      !googlePlayEmail ||
      !stripePublishableKey ||
      !webPaymentLink ||
      paymentGateway.length === 0 ||
      maxPurchaseAmount < 0 ||
      maxPurchaseCoin < 0
    ) {
      let error = {};

      if (!googlePlayKey) error.googlePlayKey = "Key is required!";
      if (!googlePlayEmail) error.googlePlayEmail = "Email is required!";
      if (!webPaymentLink)
        error.webPaymentLink = "webPayment Link is required!";
      if (!stripePublishableKey)
        error.stripePublishableKey = "Key is required!";
      if (!stripeSecretKey) error.stripeSecretKey = "SecretKey is required!";
      if (paymentGateway.length === 0)
        error.paymentGateway = "PaymentGateway is Required !";
      if (maxPurchaseCoin < 0)
        error.maxPurchaseCoin = "Please enter valid MaxPurchaseCoin!";
      if (maxPurchaseAmount < 0)
        error.maxPurchaseAmount = "Please enter valid maxPurchaseAmount!";
      return setError({
        ...error,
      });
    } else {
      const data = {
        googlePlayKey,
        googlePlayEmail,
        stripePublishableKey,
        stripeSecretKey,
        paymentGateway,
        webPaymentLink,
        maxPurchaseAmount,
        maxPurchaseCoin,
      };

      props.updateSetting(setting?._id, data);
    }
  };

  const handleClick = (type) => {
    props.handleToggleSwitch(setting?._id ,type);
  };

  return (
    <>
      <div className="maniSettingBar">
        <div className="settingBar">
          <div className="settingHeader primeHeader py-0">
            <div className="row align-items-center justify-content-center px-3">
              <div className="col-6">
                <Title name={`Setting`} />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1 pb-0 mb-0">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem"> setting </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="settingMain py-0">
            <div className="row">
              <div className="col-6">
                <Title name={`Payment Setting`} />
                {/*-------------- Box 1 --------------*/}
                <SettingBox
                  submit={handleSubmit}
                  title={`GOOGLE PLAY`}
                  toggleSwitch={{
                    switchName: "",
                    switchValue: googlePlaySwitch,
                    handleClick: () => {
                      handleClick("googlePlay");
                    },
                  }}
                >
                  <Input
                    type={`text`}
                    label={`googlePlay Email`}
                    value={googlePlayEmail}
                    newClass={`col-12`}
                    placeholder={`Enter googlePlay Email....`}
                    onChange={(e) => {
                      setGooglePlayEmail(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`google Play Key`}
                    value={googlePlayKey}
                    newClass={`col-12`}
                    placeholder={`Enter google Play Key....`}
                    onChange={(e) => {
                      setGooglePlayKey(e.target.value);
                    }}
                  />
                </SettingBox>
              </div>
              <div className="col-6">
                <Title name={`Stripe Setting`} />
                {/*-------------- Box 2 --------------*/}

                <SettingBox
                  submit={handleSubmit}
                  title={`STRIPE`}
                  toggleSwitch={{
                    switchName: "",
                    switchValue: stripeSwitch,
                    handleClick: () => {
                      handleClick("stripe");
                    },
                  }}
                >
                  <Input
                    type={`text`}
                    label={`stripe Publishable Key`}
                    value={stripePublishableKey}
                    newClass={`col-12`}
                    placeholder={`Coin Per Dollar`}
                    onChange={(e) => {
                      setStripePublishableKey(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`stripe Secret Key`}
                    value={stripeSecretKey}
                    newClass={`col-12`}
                    placeholder={`callTax`}
                    onChange={(e) => {
                      setStripeSecretKey(e.target.value);
                    }}
                  />
                </SettingBox>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Title name={`Redeem Setting`} />
                {/*-------------- Box 1 --------------*/}
                <SettingBox submit={handleSubmit} title={`payment get Way`}>
                  <Multiselect
                    className="mb-3"
                    options={paymentGateWayOption}
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  />
                </SettingBox>
              </div>
              <div className="col-6">
                <Title name={`Purchase Limit`} />
                {/*-------------- Box 2 --------------*/}

                <SettingBox submit={handleSubmit} title={`STRIPE`}>
                  <Input
                    type={`text`}
                    label={`Max Purchase Coin (per day)`}
                    value={maxPurchaseCoin}
                    newClass={`col-6`}
                    placeholder={`Coin Per Dollar`}
                    onChange={(e) => {
                      setMaxPurchaseCoin(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Max Purchase Amount (per day in ₹)`}
                    value={maxPurchaseAmount}
                    newClass={`col-6`}
                    placeholder={`callTax`}
                    onChange={(e) => {
                      setMaxPurchaseAmount(e.target.value);
                    }}
                  />
                </SettingBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSetting, updateSetting, handleToggleSwitch })(
  PaymentSetting
);
