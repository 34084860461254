import { Link, useLocation, useNavigate } from "react-router-dom";

import "../../assets/js/custom";
import Navigator from "../extra/Navigator";
import { warning } from "../util/Alert";
import { useDispatch } from "react-redux";
import MobileLogo from "../../assets/images/logo.png";
import WebLogo from "../../assets/images/logo.png";
import { LOGOUT_ADMIN } from "../store/admin/admin.type";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";

const Sidebar = () => {
  //   const location = useLocation();
  //   console.log(location);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    const data = warning();
    data
      .then((logout) => {
        if (logout) {
          dispatch({ type: LOGOUT_ADMIN });
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleGift = () => {
    localStorage.setItem("GiftClick", true);
  };

  const handleRemove = () => {
    dispatch({ type: CLOSE_DIALOGUE });
    localStorage.removeItem("dialogueData");
  };

  const navBarArray = [
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      navIcon: "fa-solid fa-house",
    },

    {
      name: "Agency",
      path: "/admin/agency",
      navIcon: "fa-solid fa-user-tie",
      onClick: handleRemove,
    },
    {
      name: "Agency Request",
      path: "/admin/agencyRequest",
      navIcon: "fa-solid fa-circle-user",
    },
    {
      name: "Host ",
      path: "/admin/host",
      navIcon: "fa-solid fa-users-gear",
      onClick: handleRemove,
    },
    {
      name: "Host Request",
      path: "/admin/hostRequest",
      navIcon: "fa-solid fa-circle-user",
    },
    {
      name: "User",
      path: "/admin/user",
      navIcon: "fa-solid fa-users",
      onClick: handleRemove,
    },

    {
      name: "task",
      path: "/admin/task",
      navIcon: "fa-solid fa-bars-progress",
    },
    {
      name: "Level",
      path: "/admin/level",
      navIcon: "fa-solid fa-circle-user",
    },
    {
      name: "Coin Seller",
      path: "/admin/coinSeller",
      navIcon: "fa-solid fa-coins",
    },

    {
      name: "Plan",
      navIcon: "bi bi-currency-exchange",
      subMenu: [
        {
          subName: "Coin Plan",
          subPath: "/admin/CoinPlan",
        },
        {
          subName: "PurchasedHistory",
          subPath: "/admin/purchasedHistory",
        },
      ],
    },
    {
      name: "Banner",
      path: "/admin/banner",
      navIcon: "fa-solid fa-image",
    },
    {
      name: "Report",

      navIcon: "fa-solid fa-flag",
      subMenu: [
        {
          subName: "User Report",
          subPath: "/admin/userReport",
        },
        {
          subName: "Host Report",
          subPath: "/admin/hostReport",
        },
      ],
    },

    {
      name: "Gift",

      navIcon: "fa-solid fa-gift",
      subMenu: [
        {
          subName: "category",
          subPath: "/admin/giftCategory",
          onClick: handleGift,
        },
        {
          subName: "Gift",
          subPath: "/admin/gift",
          onClick: handleGift,
        },
      ],
    },
    {
      name: "FeedBack",

      navIcon: "fa-solid fa-message",
      subMenu: [
        {
          subName: "FeedBack Solved",
          subPath: "/admin/feedbackSolved",
        },
        {
          subName: "Feedback Pending",
          subPath: "/admin/feedBackPending",
        },
      ],
    },
    {
      name: "Call Review",
      path: "/admin/callReview",
      navIcon: "fa-solid fa-comments",
    },
    {
      name: "Settlement",
      navIcon: "fa-solid fa-credit-card",
      subMenu: [
        {
          subName: "Settlement All",
          subPath: "/admin/settlementAll",
        },

        {
          subName: "Settlement Pending",
          subPath: "/admin/settlementPending",
        },
        {
          subName: "Settlement Solved",
          subPath: "/admin/settlementSolved",
        },
      ],
    },

    {
      name: "Transaction",
      navIcon: "fa-solid fa-coins",
      subMenu: [
        {
          subName: "Agency Transaction",
          subPath: "/admin/agencyTransaction",
          onClick: handleRemove,
        },
        {
          subName: "Host Transaction",
          subPath: "/admin/hostTransaction",
          onClick: handleRemove,
        },
      ],
    },
    {
      name: "Commission",
      path: "/admin/commission",
      navIcon: "fa-solid fa-hand-holding-dollar",
    },
    {
      name: "Management",
      path: "/admin/management",
      navIcon: "fa-solid fa-people-roof",
    },
    {
      name: "Most Used",
      path: "/admin/mostUsed",
      navIcon: "fa-solid fa-money-bill-transfer",
    },

    {
      name: "Guide Function",
      path: "/admin/guideFunction",
      navIcon: "fa-solid fa-note-sticky",
    },
    {
      name: "Profile",
      path: "/admin/profile",
      navIcon: "fa-solid fa-user-tie",
    },

    {
      name: "Setting",

      navIcon: "fa-solid fa-gear",
      subMenu: [
        {
          subName: "App Setting",
          subPath: "/admin/appSetting",
        },
        {
          subName: "Coin Setting",
          subPath: "/admin/coinSetting",
        },
        {
          subName: "payment Setting",
          subPath: "/admin/paymentSetting",
        },
        {
          subName: "Developer Pending",
          subPath: "/admin/developerSetting",
        },
      ],
    },

    {
      name: "LogOut",
      navIcon: "fa-solid fa-right-from-bracket",
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <div className="mainSidebar">
        <div className="sideBar webSidebar">
          <div className="sideBarLogo boxCenter">
            <Link to="/admin/dashboard">
              {/* <img
                src={WebLogo}
                alt=""
                width={"40px"}
                className="me-2 webLogo"
              /> */}
              {/* <img src={MobileLogo} alt="" className="mobileLogo" /> */}
              <span className="text-center h2 " style={{color:"#664dc9"}}>QWEE</span>
            </Link>
          </div>
          {/* ======= Navigation ======= */}
          <div className="navigation">
            <nav>
              {/* About */}
              {navBarArray.map((res) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu &&
                        res?.subMenu?.map((subMenu) => {
                          return (
                            <Navigator
                              subName={subMenu.subName}
                              subPath={subMenu.subPath}
                              onClick={subMenu.onClick}
                            />
                          );
                        })}
                    </Navigator>
                  </>
                );
              })}
              
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
