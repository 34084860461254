import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { redeemAllList, redeemPay } from "../../store/redeem/redeem.action";

import "../../../assets/js/bootstrap.min";

//datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { warning } from "../../util/Alert";
import { OPEN_REDEEM_DIALOG } from "../../store/redeem/redeem.type";
import Pagination from "../../extra/Pagination";
import Title from "../../extra/Title";
import RedeemNoteDilaoge from "./RedeemNoteDilaoge";

const RedeemAll = (props) => {
  const { redeemAll } = useSelector((state) => state.redeem);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState({
    count: false,
    settlementCoin: false,
    agencyCommission: false,
    penaltyOrBonus: false,
    hostSalary: false,
    total: false,
    dollar: false,
    commissionRate: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    props.redeemAllList();
  }, []);

  useEffect(() => {
    setData(redeemAll);
  }, [redeemAll]);

  const handleRedeem = (id) => {

    props.redeemPay(id);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleSearch = (e, i) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const sData = redeemAll[i].agency.filter((data) => {
        return (
          data?.agencyName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.uniqueId?.toString()?.indexOf(value) > -1
        );
      });
      const updatedRedeemAll = [...redeemAll];
      updatedRedeemAll[i] = {
        ...updatedRedeemAll[i],
        agency: sData,
      };
      setData(updatedRedeemAll);
    } else {
      setData(redeemAll);
    }
  };

  const handleSort = (field) => {
    setSorting((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    let arraySort;
    if (!sorting[field]) {
      arraySort = data.map((item) => ({
        ...item,
        agency: item.agency.sort((a, b) => a[field] - b[field]),
      }));
    } else {
      arraySort = data.map((item) => ({
        ...item,
        agency: item.agency.sort((a, b) => b[field] - a[field]),
      }));
    }
    setData([...arraySort]);
  };

  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
    };
    navigate("/admin/agency/agencySettlement", { state: state });
  };

  const openTarget = (attr) => {
    console.log(attr);
    // $(attr).toggleClass("show");
  };

  const openAction = (id) => {
    console.log("id", id);
    dispatch({ type: OPEN_REDEEM_DIALOG, payload: id });
  };

  return (
    <>
      <div className=" primeHeader mt-1">
        <div className="row align-items-center mt-3">
          <div className="col-6 title-head title-head ">
            <Title name={`Redeem All`} />
          </div>
          <div className="col-6 d-flex justify-content-end ">
            <ol class="breadcrumb1 pb-0 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Redeem All</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="card border-0 m-3">
        <div className="card-body pt-3">
          <div class="table-responsive">
            <table class="table table-condensed table-striped">
              <thead className="text-center">
                <tr>
                  <th className="fw-bold fs-18 ">No</th>
                  <th className="fw-bold fs-18">Start Date</th>
                  <th className="fw-bold fs-18">End Date</th>
                  <th className="fw-bold fs-18">
                    Total Agency
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("count")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    Total Revenue
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("settlementCoin")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    Total Commission
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("agencyCommission")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    penalty/Bonus
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("penaltyOrBonus")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    Total Salary
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("hostSalary")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    Total
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("total")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">
                    Total Amount
                    {/* <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("dollar")}
                    ></i> */}
                  </th>
                  <th className="fw-bold fs-18">Info</th>
                </tr>
              </thead>

              {data?.length > 0 ? (
                (rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                )?.map((res, ids) => {
                  return (
                    <>
                      <tbody>
                        <tr
                          className="text-center accordion-toggle"
                          style={{ fontSize: "13px" }}
                        >
                          <td className="fs-14  fw-bolder">
                            {page * rowsPerPage + parseInt(ids) + 1}
                          </td>

                          <td className="fs-14 fw-bolder">{res.startDate}</td>
                          <td className="fs-14 fw-bolder">{res.endDate}</td>
                          <td className=" fw-bolder fs-14">{res.count}</td>
                          <td className="fw-bolder fs-14">
                            {res.settlementCoin}
                          </td>
                          <td className="fw-bolder fs-14">
                            {res.agencyCommission.toFixed(2)}
                          </td>
                          <td className="fw-bolder fs-14">
                            {res.penaltyOrBonus.toFixed()}
                          </td>
                          <td className=" fw-bolder fs-14">
                            {res.hostSalary.toFixed()}
                          </td>
                          <td className="text-danger fw-bolder fs-14">
                            {res.total.toFixed()}
                          </td>
                          <td className="text-success fw-bolder fs-14">
                            {res.dollar.toFixed(2)}$
                          </td>
                          <td className="fs-14">
                            <button
                              type="button"
                              className="btn btn-primary bg-primary btn-icon p-0 py-2"
                              style={{ width: "50px" }}
                              data-toggle="collapse"
                              data-target={`#demo${ids}`}
                            >
                              <i
                                class="fa-solid fa-circle-info text-white"
                                style={{ fontSize: "15px" }}
                              ></i>
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="12" class="hiddenRow">
                            <div
                              class="mh-500 collapse subReport"
                              id={`demo${ids}`}
                              style={{ overflow: "auto" }}
                            >
                              <table class="table table-striped settlementTable">
                                <thead
                                  className="sticky-top"
                                  style={{
                                    top: "-1px",
                                    backgroundColor: "#F4F4F6",
                                  }}
                                >
                                  <tr>
                                    <th
                                      colSpan="3"
                                      style={{ backgroundColor: "#f1f1f380" }}
                                    >
                                      <div className="input-group">
                                        <input
                                          type="search"
                                          placeholder="Searching for..."
                                          aria-describedby="button-addon4"
                                          className="form-control bg-none border-0 rounded-pill searchBar"
                                          onChange={(e) => {
                                            handleSearch(e, ids);
                                          }}
                                        />
                                      </div>
                                    </th>
                                    <th colSpan="12"></th>
                                  </tr>
                                  <tr className="text-center info text-dark fs-15 border-bottom border-white ">
                                    <th className="fw-bold">ID</th>
                                    <th className="fw-bold">Agency Code</th>
                                    <th className="fw-bold">Agency Name</th>
                                    <th className="fw-bold">Note</th>
                                    <th className="fw-bold bg-dark4">
                                      Host Salary
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() => handleSort("hostSalary")}
                                      ></i>
                                    </th>
                                    <th className="fw-bold bg-dark4">
                                      Revenue
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() =>
                                          handleSort("settlementCoin")
                                        }
                                      ></i>
                                    </th>
                                    <th className="fw-bold bg-dark2">
                                      Total Revenue
                                    </th>
                                    <th className="fw-bold bg-dark3">
                                      Commission
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() =>
                                          handleSort("commissionRate")
                                        }
                                      ></i>
                                    </th>

                                    <th className="fw-bold bg-dark3">
                                      penalty/Bonus
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() =>
                                          handleSort("penaltyOrBonus")
                                        }
                                      ></i>
                                    </th>
                                    <th className="fw-bold bg-dark2">
                                      Total
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() => handleSort("total")}
                                      ></i>
                                    </th>
                                    <th className="fw-bold bg-dark2">
                                      Total Amount
                                      <i
                                        className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                                        onClick={() => handleSort("dollar")}
                                      ></i>
                                    </th>
                                    <th className="fw-bold">Pay</th>
                                    <th className="fw-bold">Action</th>
                                  </tr>
                                </thead>

                                <tbody className="text-capitalize">
                                  {res.agency.map((com, index) => {
                                    return (
                                      <>
                                        <tr className="text-center fs-12 py-2">
                                          <td className="p-0">
                                            {page * rowsPerPage +
                                              parseInt(index) +
                                              1}
                                          </td>

                                          <td className="py-2">
                                            {com?.uniqueId}
                                          </td>
                                          <td className="py-2 ">
                                            {com?.agencyName}
                                          </td>

                                          <td className="py-2 text-danger fw-semibold">
                                            {com?.note ? com?.note : "-"}
                                          </td>
                                          <td className="fw-bold bg-dark4">
                                            {com?.hostSalary}
                                          </td>
                                          <td className="fw-bold bg-dark4">
                                            {com?.settlementCoin}
                                          </td>
                                          <td
                                            style={{ cursor: "pointer" }}
                                            className="fw-bold text-info bg-dark2 cursor-pointer"
                                            onClick={() =>
                                              handleLoadSettlementPage(com)
                                            }
                                          >
                                            {parseInt(com?.settlementCoin) +
                                              parseInt(com?.hostSalary)}
                                          </td>
                                          <td className="fw-bold bg-dark3">
                                            {com?.agencyCommission}
                                            <span className="text-danger">
                                              {com?.commissionRate
                                                ? com?.commissionRate !== 0 &&
                                                  `(${com?.commissionRate}%)`
                                                : ""}
                                            </span>
                                          </td>

                                          <td className="fw-bold bg-dark3">
                                            {com?.penaltyOrBonus}
                                          </td>
                                          <td
                                            style={{ cursor: "pointer" }}
                                            className="fw-bold text-danger bg-dark2 cursor-pointer"
                                            onClick={() =>
                                              handleLoadSettlementPage(com)
                                            }
                                          >
                                            {com?.total.toFixed(2)}
                                          </td>
                                          <td
                                            style={{ cursor: "pointer" }}
                                            className="fw-bold text-primary bg-dark2 cursor-pointer"
                                            onClick={() =>
                                              handleLoadSettlementPage(com)
                                            }
                                          >
                                            {com?.dollar.toFixed(2)}$
                                          </td>
                                          <td>
                                            {com?.isPay ? (
                                              <i class="fa-solid fa-circle-check fs-4 text-success p-2"></i>
                                            ) : (
                                              <div
                                                className="btn btn-success px-4 "
                                                onClick={() =>
                                                  handleRedeem(com?._id)
                                                }
                                              >
                                                Pay
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {com?.isPay ? (
                                              <i class="fa-solid fa-circle-check fs-4 p-2 text-warning "></i>
                                            ) : (
                                              <button
                                                className="btn btn-warning text-white"
                                                onClick={() => openAction(com)}
                                              >
                                                Action
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    No Data Found !
                  </td>
                </tr>
              )}
            </table>
            <div className="p-0 ">
              <Pagination
                component="div"
                count={redeemAll?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={redeemAll?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
      <RedeemNoteDilaoge />
    </>
  );
};

export default connect(null, { redeemAllList, redeemPay })(RedeemAll);
