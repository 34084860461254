import * as actionType from "./notification.type";

//Initial State
const initialState = {
  notificationDialog: false,
  agencyNotificationDialog: false,
  notificationDialogData: null,
  agencyNotificationDialogData: null,
  dialogType: null,
};

//Notification Reducer
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.OPEN_NOTIFICATION_DIALOG:
      return {
        ...state,
        notificationDialog: true,
        notificationDialogData: action.payload || null,
      };

    case actionType.CLOSE_NOTIFICATION_DIALOG:
      return {
        ...state,
        notificationDialog: false,
        notificationDialogData: null,
      };

    case actionType.OPEN_AGENCY_NOTIFICATION_DIALOG:
      return {
        ...state,
        agencyNotificationDialog: true,
        agencyNotificationDialogData: action.payload.id || null,
        dialogType: action.payload.type || null,
      };

    case actionType.CLOSE_AGENCY_NOTIFICATION_DIALOG:
      return {
        ...state,
        agencyNotificationDialog: false,
        agencyNotificationDialogData: null,
        dialogType: null,
      };

    default:
      return state;
  }
};
