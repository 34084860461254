import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCallReview } from "../store/CallReview/action";
import Pagination from "../extra/Pagination";
import male from "../../assets/images/maleImage.png";

import $ from "jquery";
import { baseURL } from "../util/config";
import Title from "../extra/Title";

const CallReview = (props) => {
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.getCallReview();
  }, []);

  const { callReview } = useSelector((state) => state.callReview);

  useEffect(() => {
    setData(callReview);
  }, [callReview]);

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 0));
    setPage(0);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  return (
    <>
      <div className="primeHeader mt-1 ">
        <div className="row mt-3 align-items-center">
          <div className="col-6 title-head title-head ">
            <Title name={`Call Review`} />
          </div>
          <div className="col-6 d-flex justify-content-end mt-3">
            <ol class="breadcrumb1 pb-0 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Call Review </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="d-flex mb-3"></div>

      <div
        className="table-responsive bg-white shadow-sm primeMain "
        style={{ maxHeight: "72vh" }}
      >
        <table
          className="table table-hover card-table table-vcenter text-nowrap callReviewTable"
          style={{ borderSpacing: "0px" }}
        >
          <thead  className="reportBorder">
            <tr className="text-center py-3 reportTable">
              <th width="50px" className="fw-bold fs-18">
                No
              </th>
              <th width="270px" className="fw-bold fs-18">
                Image
              </th>
              <th width="220px" className="fw-bold fs-18">
                Name
              </th>
              <th width="210px" className="fw-bold fs-18">
                Country
              </th>
              <th width="240px" className="fw-bold fs-18">
                Count
              </th>
              <th width="120px" className="fw-bold fs-18">
                Coin
              </th>
              <th width="100px" className="fw-bold fs-18">
                Info
              </th>
            </tr>
          </thead>
        </table>

        {data?.length > 0 ? (
          data
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((callrev, ids) => {
              return (
                <>
                  <div class="accordion" id={`accordionExample`}>
                    <div class="accordion-item border-bottom pb-4">
                      <h2 class="accordion-header" id={`heading${ids}`}>
                        <button
                          class="accordion-button justify-content-between p-0 collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${ids}`}
                          aria-expanded="true"
                          aria-controls={`collapse${ids}`}
                        >
                          <table
                            className="w-100 table table-hover card-table table-vcenter text-nowrap border-bottom pt-3 primeMain"
                            style={{ borderSpacing: "0px" }}
                          >
                            <tr
                              className="text-center py-0"
                              style={{ fontSize: "13px" }}
                            >
                              <td width="50px" style={{ fontSize: "18px" }}>
                                {ids + 1}
                              </td>

                              <td className="p-0" width="250px">
                                <img
                                  src={callrev.profileImage}
                                  alt="Profile"
                                  className="m-auto table_image "
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "25px",
                                  }}
                                />
                              </td>
                              <td width="200px" style={{ fontSize: "18px" }}>
                                {callrev.name}
                              </td>
                              <td
                                width="200px"
                                className="text-warning fw-bolder"
                                style={{ fontSize: "18px" }}
                              >
                                {callrev.country}
                              </td>
                              <td
                                width="200px"
                                className="text-info fw-bolder"
                                style={{ fontSize: "18px" }}
                              >
                                {callrev.count}
                              </td>
                              <td
                                width="120px"
                                className="text-danger fw-bolder"
                                style={{ fontSize: "18px" }}
                              >
                                {callrev.coin}
                              </td>
                              <td width="50px" style={{ fontSize: "18px" }}>
                                <button
                                  type="button"
                                  className="btn btn-primary bg-primary btn-icon p-0 py-2"
                                  style={{ width: "50px" }}
                                >
                                  <i
                                    class="fa-solid fa-circle-info text-white"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </button>
                      </h2>
                      <div
                        id={`collapse${ids}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`heading${ids}`}
                        data-bs-parent={`#accordionExample`}
                        style={{
                          backgroundColor: "#f6f6f6",
                          maxHeight: "42vh",
                          overflowY: "auto",
                        }}
                      >
                        <div class="accordion-body py-4">
                          <h5 className="fw-bold text-primary ">
                            Call Review Details
                          </h5>
                          <table width="100%">
                            <tr className="text-center border-bottom callReviewTableShow">
                              <th width="50px" className="fw-bold fs-16">
                                No
                              </th>
                              <th width="200px" className="py-3 fs-16">
                                Image
                              </th>
                              <th width="150px" className="py-3 fs-16">
                                Name
                              </th>
                              <th width="100px" className="py-3 fs-16">
                                Country
                              </th>
                              <th width="100px" className="py-3 fs-16">
                                Title
                              </th>
                              <th width="100px" className="py-3 fs-16">
                                Rate
                              </th>
                              <th width="150px" className="py-3 fs-16">
                                Coin
                              </th>
                              <th className="py-3">Description</th>
                            </tr>
                            {callrev.user.map((com, index) => {
                              var str = com?.description;
                              if (str?.length > 30)
                                str = str.substring(0, 30) + "...";
                              return (
                                <>
                                  <tr className="text-center border-bottom py-2 ">
                                    <td className="py-2 fs-16">{index + 1}</td>
                                    <td className="py-2 fs-16">
                                      <img
                                        src={com?.image}
                                        alt="Profile"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        className="table_image m-auto"
                                      />
                                    </td>
                                    <td className="py-2 fs-16">{com?.name}</td>
                                    <td className="py-2 fs-16 text-danger fw-bolder">
                                      {com?.country}
                                    </td>
                                    <td className="py-2 fs-16 text-danger fw-bolder">
                                      {com?.title}
                                    </td>
                                    <td className="py-2 fs-16 text-danger fw-bolder">
                                      {com?.rate}
                                    </td>
                                    <td className="py-2 fs-16 text-success fw-bolder">
                                      {com?.coin}
                                    </td>
                                    <td className="py-2 fs-16">{str}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        ) : (
          <table className="w-100 table table-hover card-table table-vcenter text-nowrap border-bottom">
            <tr>
              <td colSpan="7" className="text-center">
                No Data Found !
              </td>
            </tr>
          </table>
        )}
      </div>
      <table width="100%">
        <tr className="text-end">
          <td colspan="7">
            <Pagination
              component="div"
              count={callReview?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={callReview?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </td>
        </tr>
      </table>
    </>
  );
};

export default connect(null, { getCallReview })(CallReview);
