import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import { Typography } from "@material-ui/core";
import Input from "../../extra/Input";
import {
  updateHostLevel,
  crateHostLevel,
} from "../../store/hostLevel/hostLevel.action";

const LevelCerateEditDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [name, setName] = useState("");
  const [rupee, setRupee] = useState(0);
  const [privateCallPrice, setPrivateCallPrice] = useState(0);
  const [image, setImage] = useState([]);
  const [type, setType] = useState("");
  const [coin, setCoin] = useState(0);
  const [count, setCount] = useState(0);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
 
    setName(dialogueData?.data?.name);
    setCoin(dialogueData?.data?.coin);
    setRupee(dialogueData?.data?.rupee);
    setCount(dialogueData?.data?.count);
    setType(dialogueData?.type);
    setImagePath(dialogueData?.data?.image);
    setPrivateCallPrice(dialogueData?.data?.privateCallPrice);
  }, [dialogue]);

  useEffect(
    () => () => {
      setName("");
      setCoin("");
      setCount("");
      setRupee("");
      setImage([]);
      setImagePath("");
      setPrivateCallPrice("");
      setError({
        type: "",
        coin: "",
        count: "",
        name: "",
        image: "",
        imagePath: "",
        privateCallPrice: "",
      });
    },
    [dialogue]
  );

  const dispatch = useDispatch();
  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  //  handleSubmit
  const handleSubmit = () => {
    if (
      !name ||
      rupee < 0 ||
      type === "Select Type" ||
      (type === "host" && !privateCallPrice) ||
      count < 0 ||
      !type
    ) {
      let error = {};
      if (!name) error.name = "Name Is Required !";
      if (!coin) error.coin = "Coin Is Required !";
      if (rupee < 0) error.rupee = "Invalid Rupee !";
      if (count < 0) error.count = "Invalid Count !";
      if (!imagePath) error.image = "Image Is Required !";
      if (!privateCallPrice && type === "host")
        error.privateCallPrice = "Private Call Price Is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("rupee", rupee);
      formData.append("type", type);
      formData.append("count", count);
      formData.append("image", image);
      formData.append(
        "privateCallPrice",
        privateCallPrice ? privateCallPrice : 0
      );

      if (dialogueData.data) {
        props.updateHostLevel(dialogueData?.data?._id, formData);
      } else {
        props.crateHostLevel(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData.data ? "Edit Level" : "Create Level"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Name`}
                  id={`name`}
                  placeholder={`Name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Count`}
                  id={`count`}
                  placeholder={`1000`}
                  type={`number`}
                  value={count}
                  errorMessage={error.count && error.count}
                  onChange={(e) => {
                    setCount(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        count: `count Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        count: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`rupee`}
                  id={`rupee`}
                  placeholder={`1000`}
                  type={`number`}
                  value={rupee}
                  errorMessage={error.rupee && error.rupee}
                  onChange={(e) => {
                    setRupee(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        rupee: `Rupee Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        rupee: "",
                      });
                    }
                  }}
                />
              </div>
              {type === "host" && (
                <div className="col-12 col-sm-12 col-md-12 my-2">
                  <Input
                    label={`privateCallPrice`}
                    id={`privateCallPrice`}
                    placeholder={`1000`}
                    type={`number`}
                    value={privateCallPrice}
                    errorMessage={
                      error.privateCallPrice && error.privateCallPrice
                    }
                    onChange={(e) => {
                      setPrivateCallPrice(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          privateCallPrice: `PrivateCallPrice Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          privateCallPrice: "",
                        });
                      }
                    }}
                  />
                </div>
              )}

              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData?.data ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { crateHostLevel, updateHostLevel })(
  LevelCerateEditDialogue
);
