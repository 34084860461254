import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { AddMoneyByAdmin } from "../../store/coinSeller/action";

const CoinSellerAddCoin = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
 

  const [money, setMoney] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setMoney("");
    setError("");
  }, [dialogue]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!money) {
      let error = {};
      if (!money) error.money = "Coin Is Required!";
      return setError({ ...error });
    } else {
      let data = {
        coin: money,
      };
 
      props.AddMoneyByAdmin(data, dialogueData);
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Add Coin</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  placeholder={`100`}
                  type={`number`}
                  value={money}
                  errorMessage={error.money && error.money}
                  onChange={(e) => {
                    setMoney(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        money: `Money Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        money: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { AddMoneyByAdmin })(CoinSellerAddCoin);
