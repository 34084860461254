import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./message.type";

// send message Host User particular

export const SendMessageByAdmin = (data, message) => (dispatch) => {

  axios
    .post(`adminMessage/single?id=${data.id}&type=${data.type}`, message)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: ActionType.ADMIN_SEND_MESSAGE, payload: res.data });
        setToast("success", "message Sent Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//   send message All
export const SendMessageByAdminAll = (messageType, message) => (dispatch) => {
  
  axios
    .post(`adminMessage/all?type=${messageType}`, message)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: ActionType.ADMIN_SEND_MESSAGE, payload: res.data });
        setToast("success", "message Sent Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
