import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./banner.type";

export const getBanner = () => (dispatch) => {
  axios
    .get(`banner`)
    .then((res) => {
      ;
      dispatch({
        type: ActionType.GET_BANNER,
        payload: res.data.banner,
      });
    })
    .catch((error) => console.log(error));
};

export const createBanner = (formData) => (dispatch) => {
  axios
    .post(`banner`, formData)
    .then((res) => {
      if (res.data.status === true) {
        console.log(res.data.banner);
        dispatch({ type: ActionType.CREATE_BANNER, payload: res.data.banner });
        setToast("success", "Banner Create Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// update banner

export const updateBanner = (formData, id) => (dispatch) => {
  axios
    .patch(`banner/${id}`, formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.UPDATE_BANNER,
          payload: { data: res.data.banner, id },
        });
        setToast("success", "banner Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

// delete banner

export const deleteBanner = (id) => (dispatch) => {
  axios
    .delete(`banner/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.DELETE_BANNER, payload: id });
      }
      setToast("success", "Delete Banner Successfully");
    })
    .catch((error) => setToast("error", error));
};

// web purchase banner

export const webPurchaseBanner = (id) => (dispatch) => {
  axios
    .put(`banner/${id}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.WEB_PURCHASE_BANNER,
          payload: { data: res.data.banner, id },
        });
        setToast("success", "banner Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
