import React, { useEffect, useState } from "react";
import Button from "../../../extra/Button";
import { useNavigate } from "react-router-dom";
import Title from "../../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAllHostHistory } from "../../../store/host/host.action";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import moment from "moment";
import { OPEN_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import HostInfoPage from "./HostInfoPage";
import UserDetails from "../../user/UserDetails";

const AllHostHistory = (props) => {
  const { hostHistory, totalData } = useSelector((state) => state.host);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [field, setField] = useState("coin");
  const [sorting, setSorting] = useState("0");

  useEffect(() => {
    setData(hostHistory);
  }, [hostHistory]);

  useEffect(() => {
    props.getAllHostHistory(currentPage, size, field, sorting);
  }, [currentPage, size, refresh, field, sorting]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const hostAllHistory = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Host",
      body: "host",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold text-primary fs-6 cursorPointer"
          onClick={() => handleProfile(row?.host, "hostProfile")}
        >
          {row?.host?.name}
        </span>
      ),
    },
    {
      Header: "User",
      body: "user",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold text-info fs-6 cursorPointer"
          onClick={() => handleProfile(row?.user, "userProfile")}
        >
          {row?.user?.name}
        </span>
      ),
    },
    {
      Header: "Call EndReason",
      body: "callEndReason",
      class: "fw-bold fs-6 text-dark",
      Cell: ({ row }) => (
        <span class="fw-bold fs-6 text-dark">
          {row?.callEndReason == null
            ? "No Match for Call end reason"
            : row?.callEndReason}
        </span>
      ),
    },
    {
      Header: "Host Coin",
      body: "coin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          className={`${
            row?.coin !== 0 ? "text-success" : "text-dark"
          } fs-6 fw-bold`}
        >
          {row?.coin}
        </span>
      ),
    },
    {
      Header: "type",
      body: "type",
      Cell: ({ row }) => (
        <span
          className={
            row?.callType === "MissedCall"
              ? "text-danger fw-bold fs-6"
              : "text-success fw-bold fs-6"
          }
        >
          {row?.callType}
        </span>
      ),
    },
    {
      Header: "Status",
      body: "status",

      Cell: ({ row }) => (
        <span className="fw-bold fs-6 text-warning">
          {row?.callConnect
            ? row?.callEndTime
              ? moment
                  .utc(
                    moment.duration(row?.duration, "seconds").as("milliseconds")
                  )
                  .format("HH:mm:ss")
              : " - "
            : "00:00:00"}
        </span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      class: "fw-bold fs-6 text-dark",
    },
  ];

  const handleChildValue = (value) => {
    setSorting(sorting == 0 ? 1 : 0);
    setField(value);
  };

  const handelPreviousPage = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="mainPlanTable position-relative">
        {dialogue && dialogueType === "hostProfile" && (
          <>
            <div className="mainHostHistory">
              <HostInfoPage />
            </div>
          </>
        )}
        {dialogue && dialogueType === "userProfile" && (
          <>
            <div className="mainHostHistory" style={{ height: "100vh" }}>
              <UserDetails />
            </div>
          </>
        )}
        <div
          className="planTable"
          style={{
            display: `${
              dialogueType === "userProfile"
                ? "none"
                : dialogueType === "hostProfile"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Host Call History`} />
              </div>
              <div className="col-6 d-flex justify-content-end align-items-center">
                <Button
                  btnColor={`btn-primary`}
                  newClass={`themeFont boxCent text-white`}
                  onClick={() => handelPreviousPage()}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "50px",
                  }}
                  btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                />
              </div>
            </div>
          </div>
          <div className="primeHeader py-0 pb-3">
            <div className="row ">
              <div className="col-6 title-head title-head ps-0">
                <Button
                  newClass={`themeFont  userBtn text-white mx-3`}
                  btnColor={`btn-danger`}
                  btnIcon={`fa-solid fa-rotate-right me-2`}
                  btnName={`Refresh`}
                  onClick={() => setRefresh(refresh === true ? false : true)}
                  style={{
                    borderRadius: "5px",
                    marginRight: "auto",
                    width: "95px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="primeMain border-top">
                  <Table
                    data={data}
                    mapData={hostAllHistory}
                    serverPerPage={rowsPerPage}
                    serverPage={page}
                    type={"server"}
                    onChildValue={handleChildValue}
                  />
                </div>
                <Pagination
                  component="div"
                  count={hostHistory?.length}
                  type={"server"}
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={totalData}
                  serverPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAllHostHistory })(AllHostHistory);
