import { combineReducers } from "redux";
import { dialogueReducer } from "./dialogue/dialogue.reducer";
import { adminReducer } from "./admin/admin.reducer";
import { settingReducer } from "./setting/setting.reducer";
import { dashBoardReducer } from "./Dashboard/reducer";
import { hostReducer } from "./host/host.reducer";
import { agencyReducer } from "./agencyTable/agency.reducer";
import { settlementReducer } from "./settlement/settlement.reducer";
import { giftCategoryReducer } from "./giftCategory/reducer";
import { giftReducer } from "./gift/reducer";
import { taskReducer } from "./TaskTable/task.reducer";
import { hostRequestReducer } from "./hostRequest/hostRequest.reducer";
import { hsotLevelReducer } from "./hostLevel/hostLevel.reducer";
import { agencyRequestReducer } from "./agencyRequest/agencyRequest.reducer";
import { coinSellerReducer } from "./coinSeller/reducer";
import { coinPlanReducer } from "./coinPlan/coinPlan.reducer";
import { userReducer } from "./users/user.reducer";
import { bannerReducer } from "./banner/banner.reducer";
import { permissionReducer } from "./permission/permission.reducer";
import { commissionReducer } from "./Commission/commission.reducer";
import { transactionReducer } from "./transaction/transaction.reducer";
import { feedBackReducer } from "./feedBack/feedBack.Reducer";
import { notificationReducer } from "./notification/notification.reducer";
import { reportReducer } from "./Report/reducer";
import { callReviewReducer } from "./CallReview/reducer";
import { redeemReducer } from "./redeem/redeem.reducer";
import { PlanListReducer } from "./webPayment/webPayment.reducer";
import { messageReducer } from "./message/message.reducer";

export default combineReducers({
  dialogue: dialogueReducer,
  dashBoard: dashBoardReducer,
  admin: adminReducer,
  agency: agencyReducer,
  agencyRequest: agencyRequestReducer,
  host: hostReducer,
  hostRequest: hostRequestReducer,
  user: userReducer,
  coinPlan: coinPlanReducer,
  task: taskReducer,
  coinSeller: coinSellerReducer,
  banner: bannerReducer,
  hostLevel: hsotLevelReducer,
  permission: permissionReducer,
  commission: commissionReducer,
  giftCategory: giftCategoryReducer,
  gift: giftReducer,
  feedBack: feedBackReducer,
  mostUsePackage: transactionReducer,
  redeem: redeemReducer,
  settlement: settlementReducer,
  report: reportReducer,
  callReview: callReviewReducer,
  setting: settingReducer,
  PlanList: PlanListReducer,
  notification: notificationReducer,
  messageData :messageReducer,
});
