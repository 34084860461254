import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../extra/Table";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import TransactionDialogue from "./TransactionDialogue";
import Searching from "../../extra/Searching";
import Pagination from "../../extra/Pagination";
import {
  getMostUSeCoinPlan,
  getMostUSeGift,
} from "../../store/transaction/transaction.action";
import { baseURL } from "../../util/config";
import $ from "jquery";
import noImage from "../../../assets/images/noImage.png";

const MostUsed = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState("gift");

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { mostUsePackage } = useSelector((state) => state.mostUsePackage);

  useEffect(() => {
    type === "gift" ? props.getMostUSeGift() : props.getMostUSeCoinPlan();
  }, [type]);

  console.log("mostUsePackage", mostUsePackage);

  useEffect(() => {
    setData(mostUsePackage);
  }, [mostUsePackage]);

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const transactionTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span className="text-dark fs-6">
          {page * rowsPerPage + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: type === "gift" ? "Image" : "",
      body: "image",
      Cell: ({ row }) =>
        type === "gift" && (
          <img
          draggable={false}
            src={baseURL + row.image}
            className="table-image mx-auto d-block"
            height={60}
            width={60}
            alt=""
          />
        ),
    },

    {
      Header: "Count",
      body: "count",
      sorting: { type: "client" },
      class: "fw-bold text-success fs-6",
    },

    type === "gift"
      ? {
          Header: "gift Coin",
          body: "giftCoin",
          sorting: { type: "client" },
          class: "fw-bold text-warning fs-6",
        }
      : {
          Header: "Coin",
          body: "coin",
          sorting: { type: "client" },
          class: "fw-bold text-warning fs-6",
        },

    type === "gift"
      ? {
          Header: "Platform Type",
          body: "platFormType",
          Cell: ({ row }) => (
            <span className="text-dark fs-6">
              {row.platFormType == 0 ? "Android" : "Ios"}
            </span>
          ),
        }
      : {
          Header: "Plan Level",
          body: "planLevel",
          sorting: { type: "client" },
          class: "text-dark fs-6",
        },
  ];

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Transaction`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Transaction </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="coin-plan">
            <div className="coin-plan-btn"></div>

            <div className="planMain">
              <div className="row ps-4 py-3">
                <div className="col-6 ">
                  <Title name={`Most Used ` + type} />
                </div>
                <div className="col-6 mt-3">
                  <div class="d-flex justify-content-end">
                    <Button
                      newClass={`themeFont boxCenter userBtn text-white me-3`}
                      btnColor={`bg-primary`}
                      btnName={`Most Gift`}
                      onClick={() => setType("gift")}
                      style={{
                        borderRadius: "5px",

                        width: "90px",
                      }}
                    />
                    <Button
                      newClass={`themeFont boxCenter userBtn text-white`}
                      btnColor={`bg-info`}
                      btnName={`Most Purchase Coin`}
                      onClick={() => setType("purchase")}
                      style={{
                        borderRadius: "5px",

                        width: "200px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={transactionTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                />
              </div>
              <Pagination
                component="div"
                count={mostUsePackage?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={mostUsePackage?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getMostUSeGift, getMostUSeCoinPlan })(MostUsed);
