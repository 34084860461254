import * as ActionType from "./settlement.type";

const initialState = {
  settlement: [],
  agencyHostSettlement: {},
  total: 0,
  totalData: 0,
  totalAgentPayable: null,
};

export const settlementReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Entire Settlement
    case ActionType.GET_ENTIRE_SETTLEMENT:

      return {
        ...state,
        settlement: action.payload.settlement,
        total: action.payload.total,
        totalData: action.payload.totalData,
      };

    //Get Agency Settlement
    case ActionType.GET_AGENCY_SETTLEMENT:
      return {
        ...state,
        settlement: action.payload,
      };

    //Get Agency Host Settlement
    case ActionType.GET_AGENCY_HOST_SETTLEMENT:
      return {
        ...state,
        agencyHostSettlement: action.payload.data,
        totalAgentPayable: action.payload.totalAgentPayable,
      };

    default:
      return state;
  }
};
