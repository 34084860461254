import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getCategory,
  deleteCategory,
  categoryActive,
} from "../../store/giftCategory/action";

//routing
import {  useNavigate } from "react-router-dom";
// type
import {
  OPEN_CATEGORY_DIALOG,
  GET_CATEGORY,
} from "../../store/giftCategory/types";

//sweet alert
import { alert, warning } from "../../util/Alert";
import $ from "jquery";
import noGift from "../../../assets/images/noGift.jpg";

//image
// import noImage from "../../assets/images/noImage.png";
import { baseURL } from "../../util/config";
import GiftCategory from "../GiftCategory/GiftCategoryDialogue/GiftCategory";

import IOSSwitch from "@material-ui/core/Switch";
import Button from "../../extra/Button";
import Title from "../../extra/Title";

const GiftCategoryTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [active, setActive] = useState();

  useEffect(() => {
    props.getCategory(); // eslint-disable-next-line
  }, []);

  const { giftCategory } = useSelector((state) => state.giftCategory);

  useEffect(() => {
    setData(giftCategory);
  }, [giftCategory]);

  const handleSearch = (e) => {
    const value = e.target.value.trim().toUpperCase();
    if (value) {
      const data = giftCategory.filter((data) => {
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.giftCount?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(giftCategory);
    }
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_CATEGORY_DIALOG });
  };

  const handleDelete = (categoryId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCategory(categoryId);
          alert("Deleted!", `Category has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_CATEGORY_DIALOG, payload: data });
  };

  const openGifts = (data) => {
    localStorage.removeItem("GiftClick");
    localStorage.setItem("Category", JSON.stringify(data));
    navigate("/admin/giftCategory/gift");
  };

  const categoryHandleActive = (data) => {
    props.categoryActive(data, data?.isActive === true ? false : true);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noGift);
    });
  });

  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Gift Category`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Gift Category </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="d-flex my-3 "></div>

          <div class="main-wrapper px-3">
            <div className="row mb-5 justify-content-between">
              <div className="col-2 col-lg-8">
                <Button
                  newClass={`themeFont userBtn`}
                  btnColor={`btnBlackPrime`}
                  btnName={`New`}
                  btnIcon={`fa-regular fa-plus me-2 me-2`}
                  onClick={handleOpen}
                  style={{
                    borderRadius: "5px",
                    marginRight: "auto",
                    width: "80px",
                  }}
                />
              </div>
            </div>
            <div class="row">
              {data.length > 0 ? (
                data.map((data, index) => {
                  return (
                    <div class="col-12 col-sm-6 col-lg-4" key={index}>
                      <div
                        class={`card gift-card`}
                        style={{
                          opacity: data?.isActive === false ? "0.4" : "1",
                        }}
                      >
                        <div className="blockSwich p-3 align-items-center d-flex justify-content-end">
                          <span className="fw-bold">
                            {data?.isActive === false ? "Deactive" : "Active"}
                          </span>
                          <IOSSwitch
                            checked={data?.isActive}
                            color="primary"
                            onClick={() => categoryHandleActive(data)}
                          />
                        </div>
                        <div class="card-body text-center">
                          <img
                            src={baseURL + data?.image}
                            className="categoryImage"
                            alt="Gift"
                            draggable="false"
                            onClick={
                              data?.isActive === false
                                ? false
                                : () => openGifts(data)
                            }
                          />

                          <p className="h4 mb-0 my-2 fw-bold text-dark">
                            {data?.name}
                          </p>
                          <p className="text-dark fw-bold fs-6 my-2">
                            {data?.giftCount} Gifts
                          </p>
                          <div className="d-flex justify-content-center">
                            <div className="mx-4">
                              <button
                                className="btn btn-primary float-right btn-icon"
                                onClick={
                                  data?.isActive === false
                                    ? false
                                    : () => handleEdit(data)
                                }
                              >
                                <i
                                  class="fas fa-edit px-2 fs-5"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </button>
                            </div>
                            <div className="mx-4">
                              <button
                                type="button"
                                className="btn btn-danger btn-icon"
                                onClick={
                                  data?.isActive === false
                                    ? false
                                    : () => handleDelete(data._id)
                                }
                              >
                                <i
                                  class="fa fa-trash px-2 fs-5"
                                  style={{ fontSize: "18px " }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    Nothing to show!!
                  </td>
                </tr>
              )}
            </div>
          </div>
          <GiftCategory />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCategory, deleteCategory, categoryActive })(
  GiftCategoryTable
);
