import { useEffect, useState } from "react";
import Input from "../../extra/Input";
import Title from "../../extra/Title";
import SettingBox from "./SettingBox";
import { connect, useSelector } from "react-redux";
import {
  getSetting,
  updateSetting,
  
} from "../../store/setting/setting.action";

const Setting = (props) => {
  const { setting } = useSelector((state) => state.setting);

  // box 1
  const [agoraKey, setAgoraKey] = useState("");
  const [agoraCertificate, setAgoraCertificate] = useState("");
  // box 2
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [termAndCondition, setTermAndCondition] = useState("");
  const [isAppActive, setAppIsAppActive] = useState(false);

  useEffect(() => {
    props.getSetting(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // box 1
    setAgoraKey(setting?.agoraKey);
    setAgoraCertificate(setting?.agoraCertificate);
    // box 2
    setPrivacyPolicyLink(setting?.privacyPolicyLink);
    setPrivacyPolicyText(setting?.privacyPolicyText);
    setTermAndCondition(setting?.termAndCondition);
    setAppIsAppActive(setting?.isAppActive);
  }, [setting]);
  const handleSubmit = () => {
    let settingData = {
      agoraKey,
      agoraCertificate,
      privacyPolicyLink,
      privacyPolicyText,
      termAndCondition,
      isAppActive,
    };

    props.updateSetting(settingData);
  };
  const handleClick = (type) => {
    props.handleSwitch(type);
  };

  return (
    <>
      <div className="mainSettingBar">
        <div className="settingBar ">
          <div className="settingHeader primeHeader">
            <div className="row align-items-center">
              <div className="col-6">
                <Title name={`Setting`} />
              </div>
            </div>
          </div>
          <div className="settingMain">
            <div className="row">
              {/*-------------- Box 1 --------------*/}
              <SettingBox submit={handleSubmit} title={`Agora Setting`}>
                <Input
                  type={`text`}
                  label={`Agora Key`}
                  value={agoraKey}
                  newClass={`col-12`}
                  placeholder={`Enter You Agora Key....`}
                  onChange={(e) => {
                    setAgoraKey(e.target.value);
                  }}
                />
                <Input
                  type={`text`}
                  label={`Agora Certificate`}
                  value={agoraCertificate}
                  newClass={`col-12`}
                  onChange={(e) => {
                    setAgoraCertificate(e.target.value);
                  }}
                />
              </SettingBox>

              {/*-------------- Box 2 --------------*/}
              <SettingBox
                submit={handleSubmit}
                title={`App Setting`}
                toggleSwitch={{
                  switchName: "Is App Active",
                  switchValue: isAppActive,
                  handleClick: () => {
                    handleClick("app");
                  },
                }}
              >
                <Input
                  type={`text`}
                  label={`Privacy Policy Link`}
                  value={privacyPolicyLink}
                  newClass={`col-6`}
                  placeholder={`Enter You Privacy Policy Link....`}
                  onChange={(e) => {
                    setPrivacyPolicyLink(e.target.value);
                  }}
                />
                <Input
                  type={`text`}
                  label={`Term And Condition Link`}
                  value={termAndCondition}
                  newClass={`col-6`}
                  placeholder={`Enter You Term And Condition Link....`}
                  onChange={(e) => {
                    setTermAndCondition(e.target.value);
                  }}
                />
                <Input
                  type={`text`}
                  label={`Privacy Policy Text`}
                  value={privacyPolicyText}
                  newClass={`col-12`}
                  placeholder={`Enter You Privacy Policy Text....`}
                  onChange={(e) => {
                    setPrivacyPolicyText(e.target.value);
                  }}
                />
              </SettingBox>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSetting, updateSetting })(
  Setting
);
