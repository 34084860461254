import * as ActionType from "./commission.type";

const initialState = {
  commission: [],
  dialoge: false,
  dialogeData: null,
};

export const commissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_COMMISSION:
      return {
        ...state,
        commission: action.payload,
      };
    case ActionType.OPEN_COMMISSION_DIALOGE:
      return {
        ...state,
        dialoge: true,
        dialogeData: action.payload || null,
      };
    case ActionType.CLOSE_COMMISSION_DIALOGE:
      return {
        ...state,
        dialoge: false,
        dialogeData: null,
      };
    case ActionType.CREATE_COMMISSION:
      let data = [...state.commission];
      data.unshift(action.payload)
      return {
        ...state,
        commission: data,
      };

    case ActionType.UPDATE_COMMISSION:
      return {
        ...state,
        commission: state.commission.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_COMMISSION:
      return {
        ...state,
        commission: state.commission.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    default:
      return state;
  }
};
