import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProfile } from "../../store/admin/admin.action";
import {
  resetData,
  getDashBoard,
  getDashBoardChart,
  getAppData,
} from "../../store/Dashboard/action";

// import { getProfile } from "../store/admin/admin.action";

// import io from "socket.io-client";

//js
// import "../assets/js/chart/canvasjs.min.js";
// import { baseURL } from "../util/config";
import dayjs from "dayjs";

import Analytics from "../../extra/Analytics";
import { baseURL } from "../../util/config";
import { io } from "socket.io-client";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";

const DashBoard = (props) => {
  const { dashBoard, chart, appData } = useSelector((state) => state.dashBoard);
  const [appDatas, setAppDatas] = useState({});

  useEffect(() => {
    props.resetData();
  }, []);

  useEffect(() => {
    const socket = io(baseURL, {
      transports: ["websocket", "polling", "flashsocket"],
      query: {
        adminRoom: "superAdmin123",
      },
    });

    socket.on("connect", () => {
      console.log("connection successful");
    });

    socket.on("appData", (appData) => {
      setAppDatas(appData);
    });
    props.getAppData();
  }, []);

  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setData(dashBoard);
  }, [dashBoard]);
  // const { dashBoard, chart, appData } = useSelector((state) => state.dashBoard);

  useEffect(() => {
    props.getDashBoard(startDate, endDate);
    props.getProfile();
  }, []);

  useEffect(() => {
    props.getDashBoard(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setAppDatas(appData);
  }, [appData]);

  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch({ type: CLOSE_DIALOGUE });
    localStorage.removeItem("dialogueData");
  };

  const handleClickGift = () => {
    localStorage.setItem("GiftClick", true);
  };

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  return (
    <>
      <div className="dashboard-page pt-3">
        <div
          class="d-flex justify-content-between page-header pt-3"
          style={{ margin: "0" }}
        >
          <div class="">
            <h4 class="page-title mb-0 carn1 breadcrumb-item1 pb-4">
              DashBoard
            </h4>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12 col-xl-12 d-flex align-items-center mb-5">
          <div></div>
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div class="row position-relative z-1">
          {/* total user */}

          <div className={`col-lg-6 col-md-12`}>
            <div
              class="card overflow-hidden dash1-card border-0 text-primary dashMain"
              onClick={handleRemove}
            >
              <div class="card-body card-body-primary">
                <Link to="/admin/user">
                  <div class="row allTxt">
                    <div class="col-9 m-auto">
                      <div className="row carn1">
                        <div className="col-4">
                          <div class="cntTxt">
                            <h2 class="mb-2 number-font    total_text fw-bold">
                              {data?.totalUser ? data?.totalUser : 0}
                            </h2>
                            <span
                              class=" font-weight-normal my-3 fw-semibold"
                              style={{ fontSize: "18px" }}
                            >
                              Total User
                            </span>
                          </div>
                        </div>
                        <div className="col-8">
                          <div class="row allTxt  py-2 px-5">
                            <div className="col-6  border-end">
                              <div class="cntTxt">
                                <h2
                                  class="m-0 number-font  text-start fw-bold"
                                  style={{ fontSize: "20px" }}
                                >
                                  {data?.maleUser ? data?.maleUser : 0}
                                </h2>
                                <span
                                  class="fs-14 font-weight-normal my-3  text-start fw-semibold"
                                  style={{ fontSize: "18px" }}
                                >
                                  Male
                                </span>
                              </div>
                            </div>
                            <div className="col-6  border-start">
                              <div class="cntTxt">
                                <h2
                                  class="m-0 number-font  fw-bold text-start fw-bold"
                                  style={{ fontSize: "20px" }}
                                >
                                  {data?.femaleUser ? data?.femaleUser : 0}
                                </h2>
                                <span
                                  class="fs-14 font-weight-normal my-3  text-start fw-semibold"
                                  style={{ fontSize: "18px" }}
                                >
                                  Female
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 m-auto">
                      <div class="mx-auto text-right dashIcon dashIcon-prime">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                  </div>
                </Link>

                <div class="row allTxt mt-1 py-3 px-5">
                  <div className="col-4  border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font  fw-bold text-start fw-bold"
                        style={{ fontSize: "20px" }}
                      >
                        {appDatas?.onlineUser}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start fw-semibold"
                        style={{ fontSize: "18px" }}
                      >
                        Online
                      </span>
                    </div>
                  </div>
                  <div className="col-4 border-start border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font  fw-bold text-start fw-bold"
                        style={{ fontSize: "20px" }}
                      >
                        {appDatas?.busyUser}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start fw-semibold"
                        style={{ fontSize: "18px" }}
                      >
                        Busy User
                      </span>
                    </div>
                  </div>
                  <div className="col-4 border-start  cursor-pointer">
                    <div class="carn1">
                      <Link
                        to="/admin/purchasePlanUser"
                        className="text-primary"
                      >
                        <h2
                          class="m-0 number-font  fw-bold text-start carn1 fw-bold"
                          style={{ fontSize: "20px" }}
                        >
                          {parseInt(appDatas?.onlineUser) -
                            parseInt(appDatas?.busyUser) >=
                          0
                            ? parseInt(appDatas?.onlineUser) -
                              parseInt(appDatas?.busyUser)
                            : NaN}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start carn1 fw-semibold"
                          style={{ fontSize: "18px" }}
                        >
                          Free User
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="row allTxt mt-1 py-3 px-5 ">
                  <div className="col-6 border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font  fw-bold text-start fw-bold"
                        style={{ fontSize: "20px" }}
                      >
                        {data?.weeklyUser ? data?.weeklyUser : 0}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start fw-semibold"
                        style={{ fontSize: "18px" }}
                      >
                        This Week
                      </span>
                    </div>
                  </div>
                  <div className="col-6 border-start  cursor-pointer ">
                    <div class="cntTxt">
                      <Link
                        to="/admin/purchasePlanUser"
                        className="text-primary"
                      >
                        <h2
                          class="m-0 number-font  fw-bold text-start carn1 fw-bold"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.purchasedPlanCount
                            ? data?.purchasedPlanCount
                            : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start carn1 fw-semibold"
                          style={{ fontSize: "18px" }}
                        >
                          Purchase Plan
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* host  */}
          <div className={`col-lg-6 col-md-12`} onClick={handleRemove}>
            <div class="card overflow-hidden dash1-card border-0 text-primary dashMain">
              <div class="card-body card-body-primary">
                <Link to="/admin/host">
                  <div class="row allTxt text-primary">
                    <div class="col-9 m-auto">
                      <div class="cntTxt">
                        <h2
                          class="mb-2 number-font carn1 fw-bold  
                        fw-bold total_tex t"
                        >
                          {data?.totalHost ? data?.totalHost : 0}
                        </h2>
                        <span
                          class=" font-weight-normal my-3  fw-semibold  carn1"
                          style={{ fontSize: "18px" }}
                        >
                          Total Host
                        </span>
                      </div>
                    </div>
                    <div class="col-3 m-auto">
                      <div class="mx-auto text-right dashIcon dashIcon-prime">
                        <i class="fa-solid fa-users-gear"></i>
                      </div>
                    </div>
                  </div>
                </Link>

                <div class="row allTxt mt-1 py-3 px-5">
                  <div className="col-4 border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font carn1 fw-bold text-start"
                        style={{ fontSize: "20px" }}
                      >
                        {appDatas?.onlineHost}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start"
                        style={{ fontSize: "18px" }}
                      >
                        Online
                      </span>
                    </div>
                  </div>
                  <div className="col-4  border-start border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font carn1 fw-bold text-start"
                        style={{ fontSize: "20px" }}
                      >
                        {appDatas?.busyHost}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start"
                        style={{ fontSize: "18px" }}
                      >
                        Busy Host
                      </span>
                    </div>
                  </div>

                  <div className="col-4  border-start">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font carn1 fw-bold text-start"
                        style={{ fontSize: "20px" }}
                      >
                        {appDatas?.liveHost}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start"
                        style={{ fontSize: "18px" }}
                      >
                        Live
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row allTxt mt-1 py-3 px-5">
                  <div className="col-6 border-end">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font carn1 fw-bold text-start"
                        style={{ fontSize: "20px" }}
                      >
                        {parseInt(appDatas?.onlineHost) -
                          (parseInt(appDatas?.busyHost) +
                            parseInt(appDatas?.liveHost)) >=
                        0
                          ? parseInt(appDatas?.onlineHost) -
                            (parseInt(appDatas?.busyHost) +
                              parseInt(appDatas?.liveHost))
                          : NaN}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start"
                        style={{ fontSize: "18px" }}
                      >
                        Free Host
                      </span>
                    </div>
                  </div>
                  <div className="col-6 border-start">
                    <div class="cntTxt">
                      <h2
                        class="m-0 number-font carn1 fw-bold text-start"
                        style={{ fontSize: "20px" }}
                      >
                        {data?.weeklyHost ? data?.weeklyHost : 0}
                      </h2>
                      <span
                        class="fs-14 font-weight-normal my-3  text-start"
                        style={{ fontSize: "18px" }}
                      >
                        This Week
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* purchase */}
        <div
          class="d-flex justify-content-between page-header pt-3"
          style={{ margin: "0" }}
        >
          <div class="">
            <h4 class="page-title mb-0 text-warning breadcrumb-item1 py-4">
              Purchase
            </h4>
          </div>
        </div>
        {/* purchase history  */}
        <div class="row position-relative z-1">
          <div className={`col-xl-6 col-lg-4 col-md-6`} onClick={handleRemove}>
            <Link to="/admin/purchasedHistory">
              <div class="card overflow-hidden dash1-card border-0 text-warning dashMain">
                <div class="card-body card-body-warning">
                  <div class="row allTxt">
                    <div class="col-9 m-auto">
                      <div class="cntTxt">
                        <h2 class="mb-2 number-font  fw-bold  total_text fw-bold">
                          {data?.androidRevenue || data?.appleRevenue
                            ? parseInt(data?.androidRevenue) +
                              parseInt(data?.appleRevenue) +
                              parseInt(data?.webRevenue)
                            : 0}
                        </h2>
                        <span
                          class=" font-weight-normal my-3  fw-semibold "
                          style={{ fontSize: "18px" }}
                        >
                          Total Revenue
                        </span>
                      </div>
                    </div>
                    <div class="col-3 m-auto">
                      <div class="mx-auto text-right dashIcon dashIcon-warning">
                        <i class="fa-solid fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>

                  <div class="row allTxt mt-1 py-3 px-5">
                    <div className="col-4  border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font  fw-bold text-start"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.androidRevenue ? data?.androidRevenue : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          Android
                        </span>
                      </div>
                    </div>
                    <div className="col-4 border-start border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font fw-bold text-start"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.appleRevenue ? data?.appleRevenue : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          IOS
                        </span>
                      </div>
                    </div>
                    <div className="col-4  border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font  fw-bold text-start "
                          style={{ fontSize: "20px" }}
                        >
                          {data?.webRevenue ? data?.webRevenue : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          web
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* coinPlan */}
          <div className={`col-xl-6 col-lg-4 col-md-6`} onClick={handleRemove}>
            <Link to="/admin/coinPlan">
              <div class="card overflow-hidden dash1-card border-0 text-warning dashMain">
                <div class="card-body card-body-warning">
                  <div class="row allTxt">
                    <div class="col-9 m-auto">
                      <div class="cntTxt">
                        <h2 class="mb-2 number-font  fw-bold  total_text fw-bold">
                          {data?.coinPlanAndroid ||
                          data?.coinPlanIOS ||
                          data?.coinPlanWeb
                            ? parseInt(data?.coinPlanAndroid) +
                              parseInt(data?.coinPlanIOS) +
                              parseInt(data?.coinPlanWeb)
                            : 0}
                        </h2>
                        <span
                          class=" font-weight-normal my-3   fw-semibold"
                          style={{ fontSize: "18px" }}
                        >
                          Total Coin Plan
                        </span>
                      </div>
                    </div>
                    <div class="col-3 m-auto">
                      <div class="mx-auto text-right dashIcon dashIcon-warning">
                        <i class="fa-solid fa-coins"></i>
                      </div>
                    </div>
                  </div>

                  <div class="row allTxt mt-1 py-3 px-5">
                    <div className="col-4  border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font  fw-bold text-start"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.coinPlanAndroid ? data?.coinPlanAndroid : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          Android
                        </span>
                      </div>
                    </div>
                    <div className="col-4  border-start  border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font  fw-bold text-start"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.coinPlanIOS ? data?.coinPlanIOS : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          IOS
                        </span>
                      </div>
                    </div>
                    <div className="col-4  border-end">
                      <div class="cntTxt">
                        <h2
                          class="m-0 number-font  fw-bold text-start"
                          style={{ fontSize: "20px" }}
                        >
                          {data?.coinPlanWeb ? data?.coinPlanWeb : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3  text-start"
                          style={{ fontSize: "18px" }}
                        >
                          web
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div
          class="d-flex justify-content-between page-header pt-3"
          style={{ margin: "0" }}
        >
          <div class="">
            <h4 class="page-title mb-0  breadcrumb-item1 py-4 text-danger">
              Gift
            </h4>
          </div>
        </div>
        <div className="row position-relative z-1">
          {/* total Gift */}
          <div
            className={`col-xl-6 col-lg-6 col-md-6`}
            onClick={handleClickGift}
          >
            <Link to="/admin/gift">
              <div
                class="card overflow-hidden dash1-card border-0 bg-White text-danger  dashMain"
                style={{ color: "#15854e" }}
              >
                <div class="card-body card-body-danger">
                  <div class="row allTxt">
                    <div class="col-9">
                      <div class="cntTxt">
                        <h2 class="mb-2 number-font carn2 fw-bold fw-bold">
                          {data.totalGift ? data.totalGift : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3 fw-semibold"
                          style={{ fontSize: "20px" }}
                        >
                          Total Gift
                        </span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mx-auto text-right dashIcon dashIcon-danger">
                        <i class="fa-solid fa-gift"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div
          class="d-flex justify-content-between page-header pt-3"
          style={{ margin: "0" }}
        >
          <div class="">
            <h4
              class="page-title mb-0  breadcrumb-item1 py-4"
              style={{ color: "#15854e" }}
            >
              Today
            </h4>
          </div>
        </div>
        <div class="row  position-relative z-n1" onClick={handleRemove}>
          {/* New User */}

          <div className={`col-xl-6 col-lg-4 col-md-6`}>
            <Link to="/admin/user">
              <div
                class="card overflow-hidden dash1-card border-0 bg-White dashMain"
                style={{ color: "#15854e" }}
              >
                <div class="card-body card-body-success">
                  <div class="row allTxt">
                    <div class="col-9">
                      <div class="cntTxt">
                        <h2 class="mb-2 number-font  fw-bold fw-bold">
                          {data.newUser ? data.newUser : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3 fw-semibold"
                          style={{ fontSize: "20px" }}
                        >
                          New User
                        </span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mx-auto text-right dashIcon dashIcon-success">
                        <i class="fa-solid fa-users"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* New host*/}
          <div className={`col-xl-6 col-lg-6 col-md-6`}>
            <Link to="/admin/host">
              <div
                class="card overflow-hidden dash1-card border-0 bg-White  dashMain"
                style={{ color: "#15854e" }}
              >
                <div class="card-body card-body-success">
                  <div class="row allTxt">
                    <div class="col-9">
                      <div class="cntTxt">
                        <h2 class="mb-2 number-font  fw-bold fw-bold">
                          {data.newHost ? data.newHost : 0}
                        </h2>
                        <span
                          class="fs-14 font-weight-normal my-3 fw-semibold"
                          style={{ fontSize: "20px" }}
                        >
                          New Host
                        </span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mx-auto text-right dashIcon dashIcon-success">
                        <i class="fa-solid fa-user-tie"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div class="row mt-5">
          {/* <CanvasJSChart options={options} /> */}
          {/* <Line data={option} /> */}
          {/* <CChart type="bar" data={option} labels="months" /> */}
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDashBoard,
  getDashBoardChart,
  getProfile,
  getAppData,
  resetData,
})(DashBoard);
