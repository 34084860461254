//Get Coin Plan
export const GET_COIN_PLAN = "GET_COIN_PLAN";

//Insert Coin Plan
export const CREATE_COIN_PLAN = "CREATE_COIN_PLAN";

//Update Coin Plan
export const UPDATE_COIN_PLAN = "UPDATE_COIN_PLAN";

//Delete Coin Plan
export const DELETE_COIN_PLAN = "DELETE_COIN_PLAN";

//Active Inactive Switch
export const ACTIVE_INACTIVE = "ACTIVE_INACTIVE";

//Active Inactive Switch
export const POPULAR_SWITCH = "POPULAR_SWITCH";

//Handle Open Close Dialog
export const OPEN_COIN_PLAN_DIALOG = "OPEN_COIN_PLAN_DIALOG";

// handle close Dialog
export const CLOSE_COIN_PLAN_DIALOG = "CLOSE_COIN_PLAN_DIALOG";

// handle get purchased History 
export const PURCHASED_HISTORY = " PURCHASED_HISTORY";
