import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// react dropzone
import ReactDropzone from "react-dropzone";
import { Typography } from "@material-ui/core";

import { DebounceInput } from "react-debounce-input";

import Select from "react-select";

import { getAgencyList } from "../../store/agencyTable/agency.action";

import {
  createHost,
  getUniqueId,
  checkEmail,
} from "../../store/host/host.action";

// jquery
import $ from "jquery";
import Input from "../../extra/Input";

const HostInsertPage = (props) => {
  const { allCountry } = useSelector((state) => state.admin);

  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("female");
  const [profileImage, setProfileImage] = useState([]);
  const [profileImagePath, setProfileImagePath] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [uniqueId, setUniqueId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [age, setAge] = useState("");
  const [search, setSearch] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const { dialog, id, validationEmail } = useSelector((state) => state.host);

  const { agency } = useSelector((state) => state.agency);

  useEffect(() => {
    props.getAgencyList();
    setName("");
    setPassword("");
    setGender("female");
    setProfileImagePath("");
    setImage([]);
    setCountry("");
    setAge("");
    setUniqueId("");
    setAgencyId("");
  }, []);

  useEffect(() => {
    email !== "" && props.checkEmail(email);
  }, [email]);

  useEffect(() => {
    setData(agency);
  }, [agency]);

  useEffect(() => {
    setUniqueId(id);
  }, [id]);

  // mobile number validation
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d{10}$/.test(val);
    return validNumber;
  };

  const options = allCountry.map((countryData) => {
    return {
      value: countryData.name,
      label: countryData.name,
    };
  });
  const mobileRegex = /^[0-9]{10,12}$/;

  const handleProfileImage = (e) => {
    setProfileImage(e.target.files[0]);
    setProfileImagePath(URL.createObjectURL(e.target.files[0]));
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (selectedOption?.value) {
      setCountry(selectedOption?.value);
    }
    if (
      !email ||
      !mobileNo ||
      !name ||
      age <= 17 ||
      !age ||
      !password ||
      !gender ||
      !uniqueId ||
      !country ||
      country === "select country" ||
      !agencyId ||
      agencyId === "select agency"
    ) {
      let error = {};
      if (!email) error.email = "Email is required";
      if (!name) error.name = "Name Is Required !";
      if (!mobileNo) error.mobileNo = "Mobile No Is Required !";
      if (age <= 17) error.age = "Age not under 18 allowed !!";
      if (!age) error.age = "Age Is Required !";
      if (!uniqueId) error.uniqueId = "Unique Id is Required!";
      if (!country || country === "select country")
        error.country = "Country Is Required !";
      if (!password) error.password = "Password Is Required !";
      if (!agencyId || agencyId === "select agency")
        error.agencyId = "Agency Is Required!";
      if (!profileImagePath) error.profileImage = "ProfileImage is required !";
      if (image.length === 0) error.image = "Image is required !";

      return setError({ ...error });
    } else {
   
    
      const mobileNoValid = isNumeric(mobileNo);

      if (!mobileNoValid) {
        return setError({
          ...error,
          mobileNo: "Mobile Number must be ten digit!",
        });
      }

      const formData = new FormData();

      formData.append("profileImage", profileImage);

      for (let i = 0; i < image.length; i++) {
        formData.append("image", image[i]);
      }
      formData.append("age", age);
      formData.append("password", password);
      formData.append("name", name.trim());
      formData.append("email", email ? email : "");
      formData.append("agencyId", agencyId);
      formData.append("gender", gender);
      formData.append("uniqueId", uniqueId);
      formData.append("mobileNumber", mobileNo ? mobileNo : "");
      formData.append("country", country);

      props.createHost(formData);
      setTimeout(() => {
        navigate("/admin/host");
      }, 3800);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  // CREATE UNIQUE ID
  const handleUniqueId = () => {
    props.getUniqueId();
  };

  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });

    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImage(image.concat(files));
  };

  const removeImage = (file) => {
    if (file.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file.preview;
      });
      setImage(images);
    }
  };

  return (
    <>
      <div className="primeHeader mt-1">
        <div className="row align-items-center my-3 ">
          <div className="col-12 col-md-6 title-head title-head ps-3">
            <Title name={`Host Create`} />
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end ">
            <ol class="breadcrumb1  pb-0 mb-0 ">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1">Host </li>

              <li class="breadcrumb-item1 activeItem">Dialog </li>
            </ol>
          </div>
        </div>
        <div className="card">
          <form autoComplete="new-password">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 my-2 ">
                  <div>
                    <Input
                      type={`text`}
                      label={`Name`}
                      value={name}
                      placeholder={`Name`}
                      errorMessage={error.name}
                      onChange={(e) => {
                        setName(e.target.value.toString().slice(0, 20));
                        if (!e.target.value)
                          return setError({
                            ...error,
                            name: "Name Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            name: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <div>
                      <div className="form-group mb-0">
                        <p className="form-label fw-bold mt-2"> Agency</p>
                      </div>
                      <select
                        name="type"
                        class="form-control form-control-line"
                        id="type"
                        value={agencyId}
                        onChange={(e) => {
                          setAgencyId(e.target.value);
                          if (e.target.value === "select agency") {
                            return setError({
                              ...error,
                              agencyId: "Agency is required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              agencyId: "",
                            });
                          }
                        }}
                        onKeyPress={handleKeyPress}
                      >
                        <option value="select agency">
                          ----Select Agency----
                        </option>
                        {data?.map((name) => {
                          return <option value={name?._id}>{name.name}</option>;
                        })}
                      </select>
                      {error.agencyId && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.agencyId}
                          </Typography>
                        </div>
                      )}
                    </div>
                    {error.bio && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.bio}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold mt-3"> Email </p>
                  </div>

                  <DebounceInput
                    minLength={2}
                    placeholder="Email"
                    className="form-control mb-0"
                    debounceTimeout={100}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: "email is Required",
                        });
                      } else {
                        return setError({ ...error, email: "" });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  {error.email && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.email}
                      </Typography>
                    </div>
                  )}
                  {email && validationEmail ? (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        className={
                          validationEmail === "Success!"
                            ? "text-success"
                            : "text-danger"
                        }
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {validationEmail}
                      </Typography>
                    </div>
                  ) : (
                    email && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          Email already taken !
                        </Typography>
                      </div>
                    )
                  )}
                </div>

                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <div className="form-group mb-0">
                      <p className="form-label fw-bold mt-3">Password</p>
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group" id="Password-toggle">
                        <a
                          href={() => false}
                          class="input-group-text"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            id="showPassword"
                            class="fa fa-eye"
                            aria-hidden="true"
                            onClick={showPassword}
                          ></i>
                        </a>
                        <input
                          type="password"
                          id="password"
                          placeholder="Password"
                          className="form-control"
                          autoComplete="new-password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (!e.target.value)
                              return setError({
                                ...error,
                                password: "password Is Required !",
                              });
                            else {
                              return setError({
                                ...error,
                                password: "",
                              });
                            }
                          }}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      {error.password && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.password}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <Input
                      label={`Mobile Number`}
                      type={"number"}
                      value={mobileNo}
                      onChange={(e) => {
                        setMobileNo(
                          (e.target.value = Math.max(
                            0,
                            parseInt(e.target.value)
                          )
                            .toString()
                            .slice(0, 10))
                        );
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            mobileNo: "mobile No is Required!",
                          });
                        } else {
                          return setError({ ...error, mobileNo: "" });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />
                    {error.mobileNo && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.mobileNo}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <div>
                      <div className="form-group mb-0">
                        <p className="form-label fw-bold "> Unique Id</p>
                      </div>
                      <div class="input-group">
                        <div class="input-group">
                          <a
                            href={() => false}
                            class="input-group-text"
                            style={{ cursor: "pointer" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Generate Unique Id"
                          >
                            <i
                              class="fas fa-info-circle "
                              aria-hidden="true"
                              onClick={handleUniqueId}
                            ></i>
                          </a>
                          <input
                            type="text"
                            placeholder="Unique Id"
                            autocomplete="off"
                            value={uniqueId}
                            className="form-control "
                          />
                        </div>
                      </div>
                      {error.uniqueId && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.uniqueId}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <div>
                      <div className="form-group mb-0">
                        <p className="form-label fw-bold "> Country</p>
                      </div>

                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      />
                      {!selectedOption?.value && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.country}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <Input
                      type={`number`}
                      label={`Age`}
                      placeholder={`18`}
                      value={age}
                      style={{ height: "40px" }}
                      onChange={(e) => {
                        setAge(
                          (e.target.value = Math.max(
                            0,
                            parseInt(e.target.value)
                          )
                            .toString()
                            .slice(0, 2))
                        );
                        if (!e.target.value)
                          return setError({
                            ...error,
                            age: "Age Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            age: "",
                          });
                        }
                      }}
                    />
                  </div>
                  {error.age && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.age}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 my-2">
                  <div>
                    <div className="form-group mb-0">
                      <p className="form-label fw-bold "> Host Image</p>
                    </div>
                    <input
                      type="file"
                    accept="image/png, image/jpeg ,image/jpg "
                      className="form-control "
                      autocomplete="off"
                      onChange={(e) => handleProfileImage(e)}
                    />
                    {profileImagePath && (
                      <img
                        src={profileImagePath}
                        alt="hostImage"
                        draggable="false"
                        className="p-3 "
                        width={100}
                      />
                    )}
                    {error.profileImage && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.profileImage}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 mt-1">
                  <Input
                    type={`text`}
                    label={`Gender`}
                    value={gender}
                    readonly={`readonly`}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 my-2">
                  <div>
                    <div className="form-group mb-0">
                      <p className="form-label fw-bold mt-3"> Host Image</p>
                    </div>

                    <div className="hostAlbum d-flex">
                      <ReactDropzone
                        onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                      accept="image/png, image/jpeg ,image/jpg "
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="me-4">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  height: 130,
                                  width: 130,
                                  border: "2px dashed gray",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <i
                                  className="fas fa-plus"
                                  style={{ paddingTop: 30, fontSize: 70 }}
                                ></i>
                              </div>
                            </div>
                          </section>
                        )}
                      </ReactDropzone>
                      <div className="hostImagePre ms-5">
                        {image?.length > 0 &&
                          image?.map((file, index) => {
                            return (
                              file?.type?.split("image")[0] === "" && (
                                <>
                                  <img
                             
                                    height="60px"
                                    width="60px"
                                    alt="app"
                                    src={file.preview}
                                    style={{
                                      boxShadow:
                                        "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                      border: "2px solid #fff",
                                      borderRadius: 10,
                                      marginTop: 10,
                                      float: "left",
                                      objectFit: "contain",
                                      marginRight: 15,
                                    }}
                                    draggable="false"
                                  />
                                  <div
                                    class="img-container"
                                    style={{
                                      display: "inline",
                                      position: "relative",
                                      float: "left",
                                    }}
                                  >
                                    <i
                                      class="fas fa-times-circle text-danger"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => removeImage(file)}
                                    ></i>
                                  </div>
                                </>
                              )
                            );
                          })}
                      </div>
                    </div>

                    {error.image && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.image}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div> 
              </div>

              <div className="mt-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn  btn-success my-3 mx-2"
                  onClick={handleSubmit}
                >
                  Insert
                </button>
                <button
                  type="button"
                  className="btn btn-danger my-3 "
                  onClick={() => {
                    navigate("/admin/host");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getAgencyList,
  createHost,
  getUniqueId,
  checkEmail,
})(HostInsertPage);
