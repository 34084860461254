export const GET_COMMISSION = "GET_COMMISSION";

export const OPEN_COMMISSION_DIALOGE = "OPEN_COMMISSION_DIALOGE";

export const CLOSE_COMMISSION_DIALOGE = "CLOSE_COMMISSION_DIALOGE";

export const CREATE_COMMISSION = "CREATE_COMMISSION";

export const UPDATE_COMMISSION = "UPDATE_COMMISSION";

export const DELETE_COMMISSION = "DELETE_COMMISSION";
