import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./feedBack.type";

export const feedBack = (type, startDate, endDate, userType) => (dispatch) => {
  
  axios
    .get(
      `feedback?type=${type}&startDate=${startDate}&endDate=${endDate}&userType=${userType}`
    )
    .then((res) => {
      
      console.log(res.data.feedback);
      dispatch({ type: ActionType.GET_FEEDBACK, payload: res.data.feedback });
    })
    .catch((error) => setToast("error", error.message));
};

export const feedBackSolved = (id) => (dispatch) => {
  axios
    .patch(`feedback?feedbackId=${id}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ActionType.FEEDBACK_SOLVED, payload: id });
        if (res.data.status && res.data.feedback.isSolved === true) {
          setToast("success", "Feedback Solve");
        }
      } else {
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const deleteFeedBack = (feddBackId) => (dispatch) => {
  console.log(feddBackId);
  axios
    .delete(`feedback/${feddBackId}`)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Feedback deleted successfully");
        dispatch({ type: ActionType.FEEDBACK_DELETE, payload: feddBackId });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
