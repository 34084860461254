import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./hostLevel.type";

// get host level
export const getHostLevel = (type) => (dispatch) => {
  axios
    .get(`level?type=${type}`)
    .then((res) => {
      dispatch({ type: ActionType.GET_HOST_LEVEL, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

// cerate host level

export const crateHostLevel = (formData) => (dispatch) => {
  
  axios
    .post(`level`, formData)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_HOST_LEVEL,
          payload: res.data.data,
        });
      }
      setToast("success", `${res.data.data.type} Level created successfully!`);
    })
    .catch((error) => {
      ;
      console.log("error", error);
    });
};

//  update host level

export const updateHostLevel = (id, formData) => (dispatch) => {

  axios
    .patch(`level/${id}`, formData)
    .then((res) => {
      if (res.data.status) {
   
        dispatch({
          type: ActionType.UPDATE_HOST_LEVEL,
          payload: { edit: res.data.data, id },
        });
        setToast("success", `${res.data.data.type} Level update successfully!`);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      ;
      console.log("error", error);
    });
};

// delete host level
export const deleteHostLevel = (id, formData) => (dispatch) => {
 
  axios
    .delete(`level/${id}`, formData)
    .then((res) => {
  
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_HOST_LEVEL,
          payload: id,
        });
        setToast("success", "Level Delete Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
