import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";
import Button from "../extra/Button";
import { Typography } from "@material-ui/core";
import Input from "../extra/Input";
import {
  SendMessageByAdmin,
  SendMessageByAdminAll,
} from "../store/message/message.action";
import { ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE } from "../store/message/message.type";

const MessageDialogue = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { messageDialogueData } = useSelector((state) => state.messageData);

  const [messageType, setMessageType] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (
      !title ||
      !message ||
      (dialogueData === null && (!messageType || !messageType === "SelectType"))
    ) {
      let error = {};
      if (!title) error.title = "Title Is Required";
      if (!message) error.message = "Message Is Required !";
      if (
        dialogueData === null &&
        (!messageType || !messageType === "SelectType")
      )
        error.messageType = "MessageType Is Required !";
      return setError({ ...error });
    } else {
      if (dialogueData === null) {
        let data = {
          message,
          title,
          link,
        };
        props.SendMessageByAdminAll(messageType, data);
      } else {
        let data = {
          message,
          title,
          link,
        };
        props.SendMessageByAdmin(
          messageDialogueData ? messageDialogueData : dialogueData,
          data
        );
      }
    }
      dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE });
   
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handelCloseDialogue = () => {
    dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Message </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE });
              }}
            >
              <i class="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`title`}
                  id={`Title`}
                  placeholder={`Title `}
                  type={`text`}
                  value={title}
                  errorMessage={error.title && error.title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        title: `Title Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        title: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Link`}
                  id={`link`}
                  type={`text`}
                  placeholder={`link`}
                  value={link}
                  errorMessage={error.link && error.link}
                  onChange={(e) => {
                    setLink(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        link: `link Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        link: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {dialogueData === null && (
                <div className="col-12 mt-2">
                  <label class="float-left dialog__input__title mb-2">
                    message Type
                  </label>
                  <select
                    name="type"
                    class="form-control form-control-line"
                    id="type"
                    value={messageType}
                    onChange={(e) => {
                      setMessageType(e.target.value);

                      if (e.target.value === "SelectType") {
                        return setError({
                          ...error,
                          messageType: "messageType Is Required!",
                        });
                      } else {
                        return setError({
                          ...error,
                          messageType: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="SelectType">---Select Type---</option>

                    <option value="ALL">All</option>
                    <option value="user">User</option>
                    <option value="host">Host</option>
                  </select>
                  {error.messageType && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.messageType}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              <div className="col-12 mt-2">
                <label class="float-left dialog__input__title mb-2">
                  message
                </label>
                <textarea
                  rows="3"
                  style={{
                    cursor: "auto",
                  }}
                  placeholder="message"
                  class="form-control form-control-line  px-2"
                  required
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        message: "Message Is Required !",
                      });
                    } else {
                      return setError({
                        ...error,
                        message: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />

                {error.message && (
                  <div class="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.message}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Send`}
                btnColor={`btn-info text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={handelCloseDialogue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { SendMessageByAdmin, SendMessageByAdminAll })(
  MessageDialogue
);
