import * as ActionType from "./task.type";
const initialState = {
  task: [],
  dialogue: false,
  dialogueData: null,
};
export const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_TASK:
      return {
        ...state,
        task: action.payload,
      };
    case ActionType.CREATE_TASK:
      let taskArray = [...state.task];
      taskArray.unshift(action.payload);
      return {
        ...state,
        task: taskArray,
      };
    case ActionType.UPDATE_TASK:
      return {
        ...state,
        task: state.task.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.OPEN_DIALOGUE_TASK:
      return {
        ...state,
        dialogue: true,
        dialogueData: action.payload || null,
      };
    case ActionType.CLOSE_DIALOGUE_TASK:
      return {
        ...state,
        dialogue: false,
        dialogueData: null,
      };
    default:
      return state;
  }
};
