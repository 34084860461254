import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import BannerDialogue from "./BannerDialogue";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import ToggleSwitch from "../../extra/ToggleSwitch";
import {
  getBanner,
  webPurchaseBanner,
  deleteBanner,
} from "../../store/banner/banner.action";
import { warning } from "../../util/Alert";
import dayjs from "dayjs";
import { baseURL } from "../../util/config";
import $ from "jquery";
import noImage from "../../../assets/images/noImage.png";

const Banner = (props) => {
  const { banner } = useSelector((state) => state.banner);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getBanner();
  }, []);

  useEffect(() => {
    setData(banner);
  }, [banner]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  const dispatch = useDispatch();

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteBanner(id);
        }
      })
      .catch((err) => console.log(err));
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  const handleClick = (id) => {
    props.webPurchaseBanner(id);
  };
  const bannerTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Banner",
      body: "banner",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={baseURL + row.image}
          className={` mx-auto d-block`}
          height={60}
          width={60}
          style={{
            width: "250px",
            height: "130px",
            objectFit: "cover",
            display: "block",
            borderRadius: "20px",
          }}
          onClick={() => handleOpenUrl(baseURL + row.image)}
          alt=""
        />
      ),
    },
    { Header: "URL", body: "url" },
    { Header: "Number", body: "number" },
    {
      Header: "Web Purchase ",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isWebPurchase}
          onClick={() => handleClick(row._id)}
        />
      ),
    },
    {
      Header: "Create Date",
      body: "date",
      Cell: ({ row }) => (
        <span> {dayjs(data?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-pen-to-square`}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "banner" },
            })
          }
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className=" primeHeader">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Banner`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Banner</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-6 ">
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn`}
                        btnColor={`btnBlackPrime`}
                        btnName={`New`}
                        btnIcon={`fa-regular fa-plus me-2`}
                        onClick={() => {
                          dispatch({
                            type: OPEN_DIALOGUE,
                            payload: { type: "banner" },
                          });
                        }}
                        style={{
                          borderRadius: "5px",
                          marginRight: "auto",
                          width: "80px",
                        }}
                      />

                      {dialogue && dialogueType === "banner" && (
                        <BannerDialogue />
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <Searching
                      button={true}
                      type={`client`}
                      data={banner}
                      setData={setData}
                      column={bannerTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
              </div>

              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={bannerTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                />
              </div>
              <Pagination
                component="div"
                count={banner?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={banner?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getBanner, webPurchaseBanner, deleteBanner })(
  Banner
);
