import { setToast } from "../../util/toast";
import * as ActionType from "./task.type";
import axios from "axios";

export const getTask = () => (dispatch) => {
  axios
    .get(`task`)
    .then((res) => {
      if (res.data.message) {
        dispatch({ type: ActionType.GET_TASK, payload: res.data.task });
      } else {
        setToast(res.data.message);
      }
    })
    .catch((error) => console.log("error", error));
};

export const createTask = (data) => (dispatch) => {
  axios
    .post(`task`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Task Create Successfully!");
        dispatch({ type: ActionType.CREATE_TASK, payload: res.data.task });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error));
};

export const updateTask = (data, taskId) => (dispatch) => {
  axios
    .patch(`task/update`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Task Update Successfully!");
        dispatch({
          type: ActionType.UPDATE_TASK,
          payload: { data: res.data.task, id: taskId },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error));
};
