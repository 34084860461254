// get host level 
export const GET_HOST_LEVEL = "GET_HOST_LEVEL"

// create host level 
export const CREATE_HOST_LEVEL = "CREATE_HOST_LEVEL"

// update host level 
export const UPDATE_HOST_LEVEL = "UPDATE_HOST_LEVEL"

// delete host level 
export const DELETE_HOST_LEVEL = "DELETE_HOST_LEVEL"

// open close dialogue 

export const OPEN_HOST_LEVEL_DIALOGUE ="OPEN_HOST_LEVEL_DIALOGUE"
export const CLOSE_HOST_LEVEL_DIALOGUE ="CLOSE_HOST_LEVEL_DIALOGUE"
