
//  OPEN_STRIPE_DIALOG
export const OPEN_STRIPE_DIALOG = "OPEN_STRIPE_DIALOG";

// CLOSE_STRIPE_DIALOG
export const CLOSE_STRIPE_DIALOG = "CLOSE_STRIPE_DIALOG";


// get plan List 
export const GET_PLAN_LIST = "GET_PLAN_LIST";
// get plan 
export const GET_PLAN = "GET_PLAN";
