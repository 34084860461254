import React, { createRef } from "react";
import { useSelector } from "react-redux";
// MUI
import Dialog from "@material-ui/core/Dialog";

//Loader
import { BounceLoader } from "react-spinners";

const Loader = () => {
  const ref = createRef();
  const { isLoading } = useSelector((state) => state.dialogue);

  return (
    <>
     
      <Dialog
        open={isLoading}
        disableBackdropClick
        disableEscapeKeyDown
        PaperComponent="div"
        ref={ref}
        style={{
          // background: "#242731",
          background: "transparent",
          boxShadow: "none",
          zIndex: 9999999,
        }}
      >
        <BounceLoader size={60} color="#664dc9" loading={isLoading} />
      </Dialog>
    </>
  );
};

export default Loader;
