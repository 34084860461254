import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import { purchasedHistory } from "../../store/coinPlan/coinPlan.action";
import { connect, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import Analytics from "../../extra/Analytics";
import Searching from "../../extra/Searching";
import dayjs from "dayjs";

const PurchaseHistory = (props) => {
  const { purchasedHistory, total, totalCoin } = useSelector(
    (state) => state.coinPlan
  );

  const [type, setType] = useState("0");
  const [field, setField] = useState("coin");

  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    props.purchasedHistory(type, field); // eslint-disable-next-line
  }, [type, field]);

  useEffect(() => {
    props.purchasedHistory(type, field); // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    setData(purchasedHistory);
  }, [purchasedHistory]);

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };

  const userPurchase = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span className="fw-bold text-dark fs-6">
          {page * rowsPerPage + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "User Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="fw-bold text-danger fs-6">{row?.userId?.name}</span>
      ),
    },
    {
      Header: "Unique Id",
      body: "Unique Id",
      Cell: ({ row }) => (
        <span className="fw-bold text-primary fs-6">
          {row?.userId?.uniqueId}
        </span>
      ),
    },
    {
      Header: "Country",
      body: "name",
      Cell: ({ row }) => (
        <span className="fw-bold text-warning fs-6">
          {row?.userId?.country}
        </span>
      ),
    },
    { Header: "Order Id", body: "orderId", class: "fw-bold text-dark fs-6" },
    {
      Header: "Coin",
      body: "coin",
      Cell: ({ row }) => (
        <span className="fw-bold text-success fs-6">{row?.uCoin}</span>
      ),
      sorting: { type: "server" },
    },
    {
      Header: "INR",
      body: "dollar",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fw-bold text-info fs-6">{row?.planId?.dollar}</span>
      ),
    },

    {
      Header: "Payment Gateway",
      body: "paymentGateway",
      class: "fw-bold text-dark fs-6",
    },
    {
      Header: "Platform Type",
      body: "Platform Type",
      Cell: ({ row }) => (
        <span class="fw-bold text-warning fs-6">
          {" "}
          {row?.userId?.platformType === 0 ? "Android" : "IOS"}
        </span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      Cell: ({ row }) => (
        <span class="fw-bold text-dark fs-6">
          {dayjs(row?.date).format("DD/MM/YYYY")}
        </span>
      ),
    },
  ];
  // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event), 10);
    setSize(parseInt(event), 10);
    setCurrentPage(1);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Purchase History`} />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Purchase History</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-md-6">
                    <div className="purchase-top-btn">
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={() =>
                          setRefresh(refresh === true ? false : true)
                        }
                      >
                        <i class="fa-solid fa-rotate-right"></i> Refresh
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Searching
                      button={true}
                      type={`client`}
                      data={purchasedHistory}
                      setData={setData}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={userPurchase}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
                onChildValue={handleChildValue}
              />
            </div>

            <Pagination
              component="div"
              count={purchasedHistory?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={purchasedHistory?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { purchasedHistory })(PurchaseHistory);
