import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Dashboard from "../Table/dashboard/Dashboard";
import User from "../Table/user/User";
import Setting from "../Table/setting/Setting";
import $ from "jquery";
import { useEffect } from "react";
import PrivateRoute from "../util/PrivateRoute";
import "../../assets/js/custom";
import CoinPlan from "../Table/coinPlan/CoinPlan";
import PurchaseHistory from "../Table/coinPlan/PurchaseHistory";
import AppSetting from "./Setting/AppSetting";
import DeveloperSetting from "./Setting/DeveloperSetting";
import CoinSetting from "./Setting/CoinSetting";
import Host from "../Table/Host/Host";
import Profile from "./Profile";
import PaymentSetting from "./Setting/PaymentSetting";
import HostInsertPage from "../Table/Host/HostInsertPage";
import HostHistory from "../Table/Host/HostHistory";
import HostInfoPage from "../Table/Host/History/HostInfoPage";
import Agency from "../Table/Agency/Agency";
import AllHostHistory from "../Table/Host/History/AllHostHistory";
import AgencyWiseHost from "../Table/Agency/AgencyWiseHost";
import AgencyHistory from "../Table/Agency/AgencyHistory";
import AgencySettlement from "../Table/Agency/AgencySettlement";
import { connect } from "react-redux";
import { allCountrys } from "../store/admin/admin.action";
import Gift from "./Gift/Gift";
import AllGift from "./Gift/AllGift";
import GiftCategoryTable from "./GiftCategory/GiftCategory";
import GiftPage from "./Gift/GiftDialogaue/Add";
import Task from "../Table/Task/Task";
import HostRequest from "../Table/HostRequest/HostRequest";
import LevelTable from "../Table/Level/LevelTable";
import AgencyRequest from "../Table/AgencyRequest/AgencyRequest";
import CoinSellerTable from "../Table/CoinSeller/CoinSellerTable";
import CoinSellerHistory from "../Table/CoinSeller/CoinSellerHistory";
import UserDetails from "../Table/user/UserDetails";
import Banner from "../Table/Banner/Banner";
import UserHistory from "../Table/user/UserHistory/UserHistory";
import GuideFunction from "./GuideFunction/GuideFunction";
import Management from "../Table/Management/Management";
import Commission from "../Table/Commission/Commission";
import MostUsed from "../Table/MostUsed/MostUsed";
import FeedBackSolved from "../Table/FeedBack/FeedBackSolved";
import FeedBackPending from "../Table/FeedBack/FeedBackPending";
import Report from "../Table/Report";
import HostReport from "../Table/HostReport";
import CallReview from "../Table/CallReview";
import AgencyTransaction from "../Table/Transaction/AgencyTransaction";
import HostTransaction from "../Table/Transaction/HostTransaction";
import RedeemAll from "../Table/Settlement/RedeemAll";
import RedeemPending from "../Table/Settlement/RedeemPending";
import RedeemSoled from "../Table/Settlement/RedeemSolved";
import AgencyHostSettlement from "../Table/Transaction/AgencyHostSettlement";
import PlanPurchaseUserTable from "../Table/MostUsed/PlanPurchaseUserTable";
import Error404 from "./Error404";
import CustomScript from "../../assets/js/CustomScript";

const Admin = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    props.allCountrys();
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == ""
    ) {
      // window.location.href = "/admin/dashboard";
      // // window.history.pushState(null, null, "/admin/dashboard");
      // // window.location.reload();
      navigate("/admin/dashboard");
    }
  }, []);

  return (
    <>
      <CustomScript />
      <div className="mainContainer d-flex w-100">
        <div className="containerLeft">
          <Sidebar />
        </div>
        <div className="containerRight w-100">
          <Navbar />
          <div className="mainAdmin position-relative">
            <Routes>
              
              <Route path="/*" element={<Error404 />} />
              {/* dashBoard */}
              <Route path="/dashboard" element={<Dashboard />} />
              {/* user Table*/}
              <Route path="/user" element={<User />} />
              <Route path="/user/profile" element={<UserDetails />} />
              <Route path="/user/history" element={<UserHistory />} />
              {/* admin profile */}
              <Route path="/profile" element={<Profile />} />
              {/* settings */}
              <Route path="/appSetting" element={<AppSetting />} />
              <Route path="/coinSetting" element={<CoinSetting />} />
              <Route path="/paymentSetting" element={<PaymentSetting />} />
              <Route path="/developerSetting" element={<DeveloperSetting />} />

              {/* host Table */}
              <Route path="/host" element={<Host />} />
              <Route path="/allHostHistory" element={<AllHostHistory />} />
              <Route path="/host/hostInsertPage" element={<HostInsertPage />} />
              <Route path="/host/history" element={<HostHistory />} />
              <Route path="/host/profile" element={<HostInfoPage />} />

              {/* request  */}

              <Route path="/hostRequest" element={<HostRequest />} />

              {/* Coin Plan  */}
              <Route path="/CoinPlan" element={<CoinPlan />} />
              <Route path="/purchasedHistory" element={<PurchaseHistory />} />

              {/* agency */}
              <Route path="/agency" element={<Agency />} />
              <Route path="/agency/agencyHost" element={<AgencyWiseHost />} />
              <Route path="/agency/agencyHistory" element={<AgencyHistory />} />
              <Route
                path="/agency/agencySettlement"
                element={<AgencySettlement />}
              />
              <Route path="/agencyRequest" element={<AgencyRequest />} />

              {/* banner */}
              <Route path="/banner" element={<Banner />} />

              {/* gift */}
              <Route path="/gift" element={<Gift />} />
              <Route path="/giftCategory/gift" element={<Gift />} />
              <Route path="/allGift" element={<AllGift />} />
              <Route path={`/gift/dialog`} element={<GiftPage />} />

              {/* gift Category */}
              <Route path="/giftCategory" element={<GiftCategoryTable />} />

              {/* task */}

              <Route path={`/task`} element={<Task />} />

              {/* level */}
              <Route path={`/level`} element={<LevelTable />} />

              {/* coinSeller */}

              <Route path={`/coinSeller`} element={<CoinSellerTable />} />
              <Route
                path={`/coinSeller/history`}
                element={<CoinSellerHistory />}
              />

              {/* GuideFunction */}

              <Route path={`/guideFunction`} element={<GuideFunction />} />

              {/* permission */}

              <Route path={`/management`} element={<Management />} />

              {/* Commission */}

              <Route path={`/commission`} element={<Commission />} />

              {/* Transaction  */}

              <Route path={`/mostUsed`} element={<MostUsed />} />
              <Route
                path={`/purchasePlanUser`}
                element={<PlanPurchaseUserTable />}
              />

              {/* Feedback */}

              <Route path={`/feedbackPending`} element={<FeedBackPending />} />

              <Route path={`/feedbackSolved`} element={<FeedBackSolved />} />

              {/* report  */}

              <Route path={`/userReport`} element={<Report />} />
              <Route path={`/hostReport`} element={<HostReport />} />

              {/* Call Review */}
              <Route path={`/callReview`} element={<CallReview />} />

              {/* Transaction */}

              <Route
                path={`/agencyTransaction`}
                element={<AgencyTransaction />}
              />
              <Route path={`/hostTransaction`} element={<HostTransaction />} />

              <Route
                path={`settlement/agencyHostSettlement`}
                element={<AgencyHostSettlement />}
              />

              {/* settlement */}

              <Route path={`/settlementAll`} element={<RedeemAll />} />
              <Route path={`/settlementPending`} element={<RedeemPending />} />
              <Route path={`/settlementSolved`} element={<RedeemSoled />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { allCountrys })(Admin);
