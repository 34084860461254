import axios from "axios";
import { setToast } from "../../util/toast";
import { GET_USER_REPORT, GET_HOST_REPORT } from "./type";

export const getReport = () => (dispatch) => {
  axios
    .get(`report/userReport`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_USER_REPORT, payload: res.data.report });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const getHostReport = () => (dispatch) => {
  axios
    .get(`report/hostReport`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HOST_REPORT, payload: res.data.report });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
