import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getGift, deleteGift } from "../../store/gift/action";

//routing
import { Link, useNavigate } from "react-router-dom";
// type
import { OPEN_GIFT_DIALOG } from "../../store/gift/types";
// dialog
import GiftDialog from "./GiftDialogaue/Edit";
//sweet alert
import { warning } from "../../util/Alert";

//all gift
import AllGift from "./AllGift";
import { baseURL } from "../../util/config";
import Title from "../../extra/Title";
import $ from "jquery";
import noGift from "../../../assets/images/noGift.jpg";

const GiftTable = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const category = JSON.parse(localStorage.getItem("Category"));

  const GiftClick = localStorage.getItem("GiftClick");

  useEffect(() => {
    GiftClick === null ? props.getGift(category?._id) : props.getGift("ALL");
  }, [GiftClick]);

  useEffect(() => {
    GiftClick === null ? props.getGift(category?._id) : props.getGift("ALL");
  }, [refresh]);

  const { gift } = useSelector((state) => state.gift);

  useEffect(() => {
    setData(gift);
  }, [gift]);

  const handleDelete = (giftId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteGift(giftId);
          alert("Deleted!", `Gift has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_GIFT_DIALOG, payload: data });
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noGift);
    });
  });

  return (
    <>
      <div className="mainPlan">
        <div className="planTable">
          <div className="primeHeader">
            
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title
                  name={GiftClick === null ? `${category?.name} Gifts` : "Gift"}
                />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Gift </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="d-flex my-3 "></div>

          <div class="main-wrapper">
            <div className="row mb-5 justify-content-between ms-2">
              <div className="col-2 col-lg-8">
                <button
                  type="button"
                  className="btn btn-primary text-white bg-primary px-2"
                  // onClick={handleOpen}
                  onClick={() => {
                    GiftClick === null
                      ? navigate("/admin/giftCategory/gift/dialog")
                      : navigate("/admin/gift/dialog");
                  }}
                  id="bannerDialog"
                >
                  <i class="fas fa-plus fa-md"></i>&nbsp; New
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-2 ms-3"
                  onClick={() => setRefresh(refresh === true ? false : true)}
                >
                  <i class="fa-solid fa-rotate-right"></i>&nbsp; Refresh
                </button>
              </div>
            </div>
            <div class="row mt-3">
              {data?.length > 0 ? (
                data.map((data, index) => {
              
                  return (
                    <>
                      {GiftClick === null ? (
                        <div
                          class="col-12 col-sm-4 col-md-4 col-lg-4"
                          key={index}
                          onClick={() => {
                            localStorage.setItem("CategoryId", data._id);
                            navigate("/admin/giftCategory/gift");
                          }}
                        >
                          <div class="card gift-card contact-card card-bg ">
                            <div class="card-body p-1">
                              <div className="row  py-4">
                                <div className="col-12 d-flex justify-content-center">
                                  <img
                                    src={data.image && baseURL + data.image}
                                    alt=""
                                    className="rounded-circle"
                                    draggable="false"
                                    height={80}
                                    
                                  />
                                </div>
                                <div
                                  className="col-12"
                                  style={{
                                    padding: 0,
                                    paddingLeft: 5,
                                  }}
                                >
                                  <div class="d-flex contact-card-info mt-2 mb-3 px-3 justify-content-center">
                                    <h5 className="dialog__input__title">
                                      Coin :
                                    </h5>
                                    <h5 className="dialog__input__title mx-1">
                                      {data.coin}
                                    </h5>
                                  </div>
                                  {data.platFormType === 1 ? (
                                    <div class="d-flex contact-card-info mt-2 mb-3 px-3 justify-content-center">
                                      <h5 className="dialog__input__title">
                                        IOS
                                      </h5>
                                    </div>
                                  ) : (
                                    <div class="d-flex contact-card-info mt-2 mb-3 px-3 justify-content-center">
                                      <h5 className="dialog__input__title">
                                        Android
                                      </h5>
                                    </div>
                                  )}

                                  <div className="d-flex justify-content-center">
                                    <div className="mx-4">
                                      <button
                                        className="btn btn-primary float-right btn-icon"
                                        onClick={() => handleEdit(data)}
                                      >
                                        <i
                                          class="fas fa-edit px-2 fs-5"
                                          style={{ fontSize: "15px" }}
                                        ></i>
                                      </button>
                                    </div>
                                    <div className="mx-4">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-icon fs-5"
                                        onClick={() => handleDelete(data._id)}
                                      >
                                        <i
                                          class="fa fa-trash px-2"
                                          style={{ fontSize: "18px " }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row  order-md-1 order-last">
                            <h3 className="mt-2 mb-4 gift_category__title ms-3">
                              {data.name} Gifts
                            </h3>
                            <AllGift data={data} />
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <tr className="d-flex justify-content-center">
                  <td colSpan="6" align="center">
                    Nothing to show!!
                  </td>
                </tr>
              )}
            </div>
          </div>
          <GiftDialog />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getGift, deleteGift })(GiftTable);
