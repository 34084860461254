import React from "react";

//react-redux
import { connect, useDispatch, useSelector } from "react-redux";

//Type
import { CLOSE_REDEEM_DIALOG } from "../../store/redeem/redeem.type";
import { noteRedeem } from "../../store/redeem/redeem.action";

// material-ui
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  DialogActions,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import { useState } from "react";
import { useEffect } from "react";

const RedeemNoteDilaoge = (porps) => {
  const [note, setNote] = useState("");
  const [coin, setCoin] = useState("0");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const { dialog: open, dialogData } = useSelector((state) => state.redeem);

  useEffect(() => {
    setCoin("");
    setError({ coin: "", note: "" });
    setNote("");
  }, [open]);

  useEffect(() => {
    setCoin(dialogData?.penaltyOrBonus);
    setNote(dialogData?.note);
  }, [open]);

  //Handle Close Dialog
  const handleClose = () => {
    dispatch({ type: CLOSE_REDEEM_DIALOG });
  };

  const handleSubmit = () => {
    if (!coin || -Math.abs(dialogData?.total) > coin || !note) {
      let error = {};
      if (!coin) error.coin = "Coin iS Requrid!";
      if (-Math.abs(dialogData?.total) > coin)
        error.coin = "No penalty can be imposed more than the Total Coin";
      if (!note) error.note = "Note iS Requrid!";
      return setError({ ...error });
    } else {
      let data = {
        coin,
        note,
      };
      porps.noteRedeem(data, dialogData._id);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        style={{
          zIndex: 9999999,
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-primary fw-bold h4"> Note </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#664dc9",
          }}
        >
          <Tooltip title="Close" placement="right">
            <Cancel className="cancelButton" onClick={handleClose} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column">
              <form>
                <div class="form-group">
                  <div className="row">
                    <div className="col-md-12 my-2">
                      <label class="float-left dialog__input__title">
                        Coin
                      </label>
                      <input
                        type="number"
                        placeholder="Coin"
                        class="form-control form-control-line text-uppercase"
                        required
                        value={coin}
                        onChange={(e) => {
                          setCoin(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              coin: "coin is required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              coin: "",
                            });
                          }
                        }}
                      />

                      {error.coin && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.coin}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div classNAme="col-md-12 my-2">
                      <textarea
                        rows="3"
                        placeholder="Note"
                        style={{
                          cursor: "auto",
                        }}
                        className="form-control mb-2 pe-1"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              note: "Note Is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              note: "",
                            });
                          }
                        }}
                      ></textarea>
                      {error.note && (
                        <div class="pl-1 text-left">
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontFamily: "Circular-Loom" }}
                          >
                            {error.note}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="btn btn-success  px-3"
                    onClick={handleSubmit}
                  >
                    Insert
                  </button>

                  <button
                    type="button"
                    class="btn btn-danger  px-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { noteRedeem })(RedeemNoteDilaoge);
