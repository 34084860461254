import {
  GET_USER_REPORT,
  GET_HOST_REPORT
} from "./type";

const initialState = {
  report: [],
  hostReport: []
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REPORT:
      return {
        ...state,
        report: action.payload,
      };
      case GET_HOST_REPORT:
      return {
        ...state,
        hostReport: action.payload,
      };

    default:
      return state;
  }
};