import axios from "axios";
import * as ActionType from "./settlement.type";

//Get Entire settlement Report
export const getEntireSettlement =
(dayType, startDate, endDate, start, limit, filed, sortType) =>
(dispatch) => {
    
    axios
      .get(
        `settlement?type=${dayType}&startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}&field=${filed}&sortType=${sortType}`
      )
      .then((res) => {
        
        dispatch({
          type: ActionType.GET_ENTIRE_SETTLEMENT,
          payload: res.data,
        });
      })
      .catch((error) => console.log(error));
  };

//Get Entire settlement Report
export const getAgencySettlement =
  (dayType, startDate, endDate) => (dispatch) => {
    axios
      .get(
        // `hostSettlement/agencySettlement?type=${dayType}&startDate=${startDate}&endDate=${endDate}`
        `agencySettlement?type=${dayType}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_AGENCY_SETTLEMENT,
          payload: res.data.settlement,
        });
      })
      .catch((error) => console.log(error));
  };

//Get Entire settlement Report
export const getAgencyHostSettlement =
  (id, dayType, startDate, endDate, filed, sortType) => (dispatch) => {
    axios
      .get(
        `payableHostByAgencyForAdmin/${id}?type=${dayType}&startDate=${startDate}&endDate=${endDate}&field=${filed}&sortType=${sortType}`
      )
      .then((res) => {
        console.log("myy history", res.data);
        dispatch({
          type: ActionType.GET_AGENCY_HOST_SETTLEMENT,
          payload: {
            data: res.data.settlementHistory,
            totalAgentPayable: res.data.totalAgentPayable,
          },
        });
      })
      .catch((error) => console.log(error));
  };
