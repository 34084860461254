import * as ActionType from "./transaction.type";
const initialState = {
  mostUsePackage: {},
};
export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_MOST_USE_GIFT:
      return {
        ...state,
        mostUsePackage: action.payload,
      };
    case ActionType.GET_MOST_USE_COINPLAN:
      return {
        ...state,
        mostUsePackage: action.payload,
      };
    default:
      return state;
  }
};
