import React, { useEffect, useState } from "react";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { connect, useDispatch, useSelector } from "react-redux";
import { warning } from "../../util/Alert";
import LevelCerateEditDialogue from "./LevelCerateEditDialogue";
import Button from "../../extra/Button";
import Searching from "../../extra/Searching";
import {
  getHostLevel,
  deleteHostLevel,
} from "../.././store/hostLevel/hostLevel.action";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import dayjs from "dayjs";
import $ from "jquery";
import male from "../../../assets/images/noImage.png";

const LevelTable = (props) => {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState("host");
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { hostLevel } = useSelector((state) => state.hostLevel);

  useEffect(() => {
    props.getHostLevel(type);
  }, []);

  useEffect(() => {
    props.getHostLevel(type);
  }, [type]);
  useEffect(() => {
    setData(hostLevel);
  }, [hostLevel]);

  const dispatch = useDispatch();

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteHostLevel(id);
        }
      })
      .catch((err) => console.log(err));
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };
  // Active Plan

  const levelTabel = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Name", body: "name" },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Count", body: "count", sorting: { type: "client" } },
    { Header: "Ruppe", body: "rupee", sorting: { type: "client" } },
    {
      Header: "Private Call Charage",
      body: "privateCallPrice",
      sorting: { type: "client" },
    },
    {
      Header: "Created At",
      body: "created At",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: { data: row, type }, type: "level" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className=" primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head mt-3">
                <Title name={type + ` Level`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem text-capitalize">
                    {type} Level
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="coin-plan">
            <div className="coin-plan-btn">
             
                    {dialogue && dialogueType === "level" && (
                      <LevelCerateEditDialogue />
                    )}
            </div>
            <div className="planMain">
              <div className="row align-items-center mt-3 mx-3">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6  d-flex py-2">
                  <Button
                    newClass={`themeFont boxCenter userBtn my-2 `}
                    btnColor={`btnBlackPrime `}
                    btnName={`New`}
                    btnIcon={`fa-regular fa-plus me-2`}
                    onClick={() => {
                      dispatch({
                        type: OPEN_DIALOGUE,
                        payload: { data: { type }, type: "level" },
                      });
                    }}
                    style={{
                      borderRadius: "5px",
                      width: "80px",
                    }}
                  />
                  <Button
                    newClass={`themeFont boxCenter userBtn`}
                    btnColor={`btn-success m-2`}
                    btnName={`host`}
                    onClick={() => setType("host")}
                    style={{
                      borderRadius: "5px",
                      width: "80px",
                      color: "#fff",
                    }}
                  />
                  <Button
                    newClass={`themeFont boxCenter userBtn`}
                    btnColor={`btn-danger m-2`}
                    btnName={`User`}
                    onClick={() => setType("user")}
                    style={{
                      borderRadius: "5px",
                      width: "80px",
                      color: "#fff",
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6  d-flex justify-content-end">
                  <Searching
                    type={`client`}
                    data={hostLevel}
                    setData={setData}
                    column={levelTabel}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    button={true}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
              </div>
              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={levelTabel}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                />
              </div>
              <Pagination
                component="div"
                count={hostLevel?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={hostLevel?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getHostLevel, deleteHostLevel })(LevelTable);
