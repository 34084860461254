import { GET_HOST_CALL_REVIEW } from "./type";

const initialState = {
  callReview: [],
};

export const callReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_CALL_REVIEW:
      return {
        ...state,
        callReview: action.payload,
      };

    default:
      return state;
  }
};
