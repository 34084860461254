import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { Typography } from "@material-ui/core";
import {
  SendMessageByAdmin,
  SendMessageByAdminAll,
} from "../../store/message/message.action";
import { ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE } from "../../store/message/message.type";

const HostMessageDialogue = (props) => {
  const { dialogueData: messageDiaogeData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const { messageDialogueData } = useSelector((state) => state.messageData);

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (
      !title ||
      !message ||
      (messageDiaogeData === null &&
        (!messageType || !messageType === "SelectType"))
    ) {
      let error = {};
      if (!title) error.title = "Title Is Required";
      if (!message) error.message = "Message Is Required !";
      if (
        messageDiaogeData === null &&
        (!messageType || !messageType === "SelectType")
      )
        error.messageType = "MessageType Is Required !";
      return setError({ ...error });
    } else {
      if (messageDiaogeData === null) {
        let data = {
          message,
          title,
          link,
        };
        props.SendMessageByAdminAll(messageType, data);
      } else {
        let data = {
          message,
          title,
          link,
        };
        props.SendMessageByAdmin(
          messageDialogueData ? messageDialogueData : messageDiaogeData,
          data
        );
      }
    }
    dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Message </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12">
                <Input
                  label={`Title`}
                  id={`title`}
                  type={`title`}
                  value={title}
                  errorMessage={error.title && error.title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        title: `Title Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        title: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Link`}
                  id={`link`}
                  type={`url`}
                  placeholder={`www.demo.link.in`}
                  value={link}
                  errorMessage={error.link && error.link}
                  onChange={(e) => {
                    setLink(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        link: `Link Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        link: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <textarea
                  rows="3"
                  style={{
                    cursor: "auto",
                  }}
                  placeholder="message"
                  class="form-control form-control-line  px-2"
                  required
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        message: "Message Is Required !",
                      });
                    } else {
                      return setError({
                        ...error,
                        message: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
                {error.message && (
                  <div class="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.message}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Send`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={dispatch({ type: ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { SendMessageByAdmin, SendMessageByAdminAll })(
  HostMessageDialogue
);
