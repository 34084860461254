import React from "react";
import IOSSwitch from "@material-ui/core/Switch";

const ToggleSwitch = (props) => {

  return (
    <>
      <label class="switch">
        {/* <input
          type="checkbox"
          checked={props.value}
          onClick={props.onClick}
          className="checkbox"
        /> */}
        <IOSSwitch
          checked={props.value}
          color="primary"
          onClick={props.onClick}
        />
        {/* <div class="slider"></div> */}
      </label>
    </>
  );
};

export default ToggleSwitch;
