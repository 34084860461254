import * as ActionType from "./host.type";

const initialState = {
  host: [],
  totalHost: 0,

  dialog: false,
  dialogData: null,
  id: "",
  validationEmail: false,
  hostProfile: {},
  hostHistory: [],
  totalData: 0,
  totalCoin: 0,
  hostLiveHistory: [],
  hostTaskHistory: [],
  totalTaskCoin: 0,
  totalLiveDuration: 0,
  totalLiveCoin: 0,
  countryName: [],
  verificationDialogue: false,
  verificationDialogueData: null,
};

export const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.HOST_GET:
      return {
        ...state,
        host: action.payload.host,
        totalHost: action.payload.total,
      };
    case ActionType.OPEN_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case ActionType.HOST_UPDATE:
      return {
        ...state,
        host: state.host.map((updateHost) =>
          updateHost._id === action.payload.editHost._id
            ? action.payload.editHost
            : updateHost
        ),
      };
    case ActionType.BLOCKED_HOST:
      return {
        ...state,
        host: state.host.map((hostBlock) => {
          if (hostBlock._id === action.payload.id) return action.payload.data;
          else return hostBlock;
        }),
        hostProfile: action.payload.data,
      };
    case ActionType.APPROVED_HOST:
      return {
        ...state,
        host: state.host.map((hostApproved) => {
          if (hostApproved._id === action.payload.id)
            return action.payload.data;
          else return hostApproved;
        }),
        hostProfile: action.payload.data,
      };
    case ActionType.ENABLE_CALL_CUT_HOST:
      return {
        ...state,
        host: state.host.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
        hostProfile: action.payload.data,
      };
    case ActionType.GET_UNIQUE:
      return {
        ...state,
        id: action.payload,
      };
    case ActionType.HOST_CREATE:
      let data = [...state.host];
      data.unshift(action.payload);
      return {
        ...state,
        host: data,
      };
    case ActionType.CHECK_EMAIL:
      console.log(action.payload);
      return {
        ...state,
        validationEmail: action.payload,
      };

    // host_profile get

    case ActionType.GET_HOST_PROFILE:
      return {
        ...state,
        hostProfile: action.payload,
      };
    case ActionType.UPADTE_HOST_COIN:
      return {
        ...state,
        host: action.payload.host,
      };
    case ActionType.UPDATE_HOST_ALERT_MESSAGE:
      return {
        ...state,
        host: action.payload.host,
      };

    // get history
    case ActionType.GET_HOST_HISTORY:
      return {
        ...state,
        hostHistory: action.payload.history,
        totalCoin: action.payload.totalCoin ? action.payload.totalCoin : 0,
        totalData: action.payload.totalData ? action.payload.totalData : 0,
      };
    case ActionType.GET_HOST_LIVE_HISTORY:
      return {
        ...state,
        hostLiveHistory: action.payload.history,
        totalLiveCoin: action.payload.totalCoin,
        totalData: action.payload.totalData,
        totalLiveDuration: action.payload.totalDuration,
      };
    case ActionType.GET_HOST_TASK_HISTORY:
      return {
        ...state,
        hostTaskHistory: action.payload.history,
        totalTaskCoin: action.payload.totalCoin,
        totalData: action.payload.totalData,
      };
    // get history
    case ActionType.GET_ALL_HOST_HISTORY:
      return {
        ...state,
        hostHistory: action.payload.history,
        totalData: action.payload.totalData,
      };

    case ActionType.OFFLINE_HOST:
      return {
        ...state,
        // hostProfile: action.payload.data,
        host: state.host.map((hostOffline) => {
          if (hostOffline._id === action.payload.id._id) {
            return action.payload.data;
          } else {
            return hostOffline;
          }
        }),
      };
    case ActionType.FREE_HOST:
      return {
        ...state,
        // hostProfile: action.payload.data,
        host: state.host.map((hostFree) => {
          if (hostFree._id === action.payload.id) {
            return action.payload.data;
          } else {
            return hostFree;
          }
        }),
      };
    case ActionType.COUNTRY_FLAG_NAME:
      return {
        ...state,
        countryName: action.payload,
      };

    case ActionType.VERIFICATION_VIDEO_OPEN_DIALOGUE:
      return {
        ...state,
        verificationDialogue: true,
        verificationDialogueData: action.payload || null,
      };
    //   close dialogue
    case ActionType.VERIFICATION_VIDEO_CLOSE_DIALOGUE:
      return {
        ...state,
        verificationDialogue: false,
        verificationDialogueData: null,
      };

    default:
      return state;
  }
};
