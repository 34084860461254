import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import Analytics from "../../extra/Analytics";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import male from "../../../assets/images/download.png";
import { getAgencyHitory } from "../../store/agencyTable/agency.action";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Searching from "../../extra/Searching";

const AgencyHistory = (props) => {
  const {
    agencyRevenueHistory,
    totalAgencyRevenue,
    agencyName,
    total,
    agencyImage,
  } = useSelector((state) => state.agency.agencyHistory);

  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState("0");
  const [filed, setField] = useState("");
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handelPreviousPage = () => {
    state ? navigate(-1) : dispatch({ type: CLOSE_DIALOGUE });
    localStorage.removeItem("dialogueData");
  };
  useEffect(() => {
    props.getAgencyHitory(
      state?._id ? state?._id : dialogueData._id,
      startDate,
      endDate,
      filed,
      type
    );
  }, [startDate, endDate, filed, type]);

  useEffect(() => {
    setData(agencyRevenueHistory);
  }, [agencyRevenueHistory]);

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const historyTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    // { Header: "Code", body: "uniqueId", class: "fw-bold fs-6" },
    { Header: "Name", body: "name", class: "fw-bold fs-6" },

    {
      Header: "Country",
      body: "country",

      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.country == "" ? "india" : row?.country}
        </span>
      ),
    },
    {
      Header: "Host Coin",
      body: "coin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-warning">{row?.coin}</span>
      ),
    },
    {
      Header: "Gift Coin",
      body: "giftHistory",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-danger">
          {!row?.giftHistory ? 0 : row?.giftHistory}
        </span>
      ),
    },
    {
      Header: "Video Call Coin",
      body: "videoCallCoinHistory",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-info">
          {!row?.videoCallCoinHistory ? 0 : row?.videoCallCoinHistory}
        </span>
      ),
    },
    {
      Header: "Admin Coin",
      body: "adminCoinHistory",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-dark">
          {!row?.adminCoinHistory ? 0 : row?.adminCoinHistory}
        </span>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable ">
        <div className="planTable ">
          <div className="primeHeader ">
            <div className="row align-items-center ">
              <div className="col-6 title-head title-head ">
                <Title name={"Agency History"} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <div className="profileBtn d-flex align-items-center">
                  <Button
                    btnColor={`btn-primary`}
                    newClass={`themeFont boxCent text-white`}
                    onClick={handelPreviousPage}
                    style={{
                      borderRadius: "5px",
                      height: "36px",
                      width: "50px",
                    }}
                    btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan ">
              <div className="coin-plan-btn">
                <div className="row m-3 border-bottom ">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div>
                      <Analytics
                        analyticsStartDate={start}
                        analyticsStartEnd={end}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end align-items-center fs-5 fw-bold text-primary">
                    Total Revenue : {totalAgencyRevenue}
                  </div>
                </div>
                <div className="row px-3 pt-2">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                    <div className="text-primary d-flex align-items-center">
                      <span>
                        <img
                          src={agencyImage}
                          alt="agency"
                          width="40px"
                          height="40px"
                          className="rounded-circle me-3"
                        />
                      </span>
                      <h4 className="fw-bold m-0">{agencyName}</h4>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      type={`client`}
                      data={agencyRevenueHistory}
                      setData={setData}
                      column={historyTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>

                <div className="userMain mt-3 border-top">
                  <div className="primeMainHistory border-top">
                    <Table
                      data={data}
                      mapData={historyTable}
                      PerPage={rowsPerPage}
                      Page={page}
                      type={"client"}
                      onChildValue={handleChildValue}
                    />
                  </div>
                  <Pagination
                    component="div"
                    count={agencyRevenueHistory?.length}
                    serverPage={page}
                    type={"client"}
                    onPageChange={handleChangePage}
                    serverPerPage={rowsPerPage}
                    totalData={agencyRevenueHistory?.length}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyHitory })(AgencyHistory);
