import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Searching from "../../extra/Searching";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Pagination from "../../extra/Pagination";
import male from "../../../assets/images/maleImage.png";
import { getHost, hostBlocked } from "../../store/agencyTable/agency.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { offlineHost } from "../../store/host/host.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import HostInfoPage from "../Host/History/HostInfoPage";

const AgencyWiseHost = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { agencyWiseHost, totalHost } = useSelector((state) => state.agency);

  const { allCountry } = useSelector((state) => state.host);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const rowParPageValue = sessionStorage.getItem("rowParPageHostValue");
    return rowParPageValue !== null ? rowParPageValue : 10;
  });

  const [search, setSearch] = useState(() => {
    const searchStoredValue = sessionStorage.getItem("searchAgencyHost");
    return searchStoredValue !== null ? searchStoredValue : "";
  });

  const [genderType, setGenderType] = useState("");
  const [statusType, setStatusType] = useState(() => {
    const statusStoredValue = sessionStorage.getItem("statusTypeAgencyHost");
    return statusStoredValue !== null ? statusStoredValue : "";
  });
  const [blockType, setBlockType] = useState(() => {
    const blockStoredValue = sessionStorage.getItem("blockTypeAgencyHost");
    return blockStoredValue !== null ? blockStoredValue : "";
  });
  const [countryType, setCountryType] = useState(() => {
    const countryStoredValue = sessionStorage.getItem("countryAgencyHost");
    return countryStoredValue !== null ? countryStoredValue : "";
  });
  const [type, setType] = useState(() => {
    const typeStoredValue = sessionStorage.getItem("typeHost");
    return typeStoredValue !== null ? typeStoredValue : "0";
  });
  const [field, setField] = useState(() => {
    const fieldStoredValue = sessionStorage.getItem("filedTypeHost");
    return fieldStoredValue !== null ? fieldStoredValue : "createdAt";
  });
  const { state } = useLocation();

  console.log("state", state);

  useEffect(() => {
    props.getHost(
      state?._id,
      search,
      genderType,
      statusType,
      blockType,
      countryType,
      field,
      type,
      currentPage,
      size
    );
  }, [
    search,
    genderType,
    statusType,
    blockType,
    countryType,
    field,
    type,
    currentPage,
    size,
  ]);

  useEffect(() => {
    sessionStorage.setItem("searchAgencyHost", search);
    sessionStorage.setItem("genderTypeAgencyHost", genderType);
    sessionStorage.setItem("blockTypeAgencyHost", blockType);
    sessionStorage.setItem("statusTypeAgencyHost", statusType);
    sessionStorage.setItem("countryAgencyHost", countryType);
  }, [search, genderType, countryType, blockType, statusType]);

  useEffect(() => {
    props.getHost(
      state?._id,
      search,
      genderType,
      statusType,
      blockType,
      countryType,
      field,
      type,
      currentPage,
      size
    );
  }, [refresh]);

  useEffect(() => {
    setData(agencyWiseHost);
  }, [agencyWiseHost]);

  const allReset = () => {
    $("#search").val("");
    sessionStorage.removeItem("searchAgencyHost");
    sessionStorage.removeItem("statusTypeAgencyHost");
    sessionStorage.removeItem("countryAgencyHost");
    sessionStorage.removeItem("blockTypeAgencyHost");
    props.getHost(state?._id, "", "", "", "", "", field, type, 1, 10);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  const handleClick = (id) => {
    props.hostBlocked(id, id.isBlock === true ? false : true);
  };

  const navigate = useNavigate();

  const handleSwitchOffline = (id) => {
    id.isOnline && props.offlineHost(id);
  };

  const dispatch = useDispatch();
  const hostTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Unique Id", body: "uniqueId" },
    {
      Header: "Name",
      body: "name",
      class: "text-capitalize",
      shorting: true,
    },

    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
          src={row.profileImage}
          className={`mx-auto d-block table-image`}
          height={60}
          width={60}
          alt=""
          // onClick={() => navigate("/admin/host/profile", { state: row })}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: {
                type: "hostProfile",
                data: row,
              },
            })
          }
        />
      ),
    },

    // { Header: "Email", body: "email" } \\ AS
    {
      Header: "Coin",
      body: "coin",
      Cell: ({ row }) => <span>{row.coin}</span>,
      sorting: { type: "server" },
    },
    { Header: "Call Charge", body: "callCharge", sorting: true },
    {
      Header: "Level",
      body: "level",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.level ? row.level.name : "Level 0"}
        </span>
      ),
      sorting: true,
    },

    {
      Header: "Country",
      body: "country",

      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.country == "" ? "india" : row?.country}
        </span>
      ),
    },
    {
      Header: "Online host",
      body: "online",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={
            row?.isOnline
              ? "badge badge-success mt-2"
              : "badge badge-danger disabled"
          }
          btnName={row?.isOnline ? "Online" : "Offline"}
          onClick={() => handleSwitchOffline(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "80px" }}
        />
      ),
    },
    {
      Header: "Disable Host",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock} onClick={() => handleClick(row)} />
      ),
    },
  ];

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  return (
    <>
      <div className="mainPlanTable">
        {dialogue && dialogueType === "hostProfile" && (
          <>
            <div className="mainHostHistory">
              <HostInfoPage />
            </div>
          </>
        )}
        <div className="planTable">
          <div className="primeHeader">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={state?.name + ` Host`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Host </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain ">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-info btn-pill dropdown-toggle text-white border-0"
                        data-bs-toggle="dropdown"
                      >
                        {statusType ? (
                          <span class="caret">{statusType}</span>
                        ) : (
                          <span class="caret text-capitalize ">Status</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("online");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "online"
                              );
                            }}
                          >
                            Online
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("offline");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "offline"
                              );
                            }}
                          >
                            Offline
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("live");
                              localStorage.setItem("statusTypeHost", "live");
                            }}
                          >
                            isLive
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("busy");
                              sessionStorage.setItem("statusTypeHost", "busy");
                            }}
                          >
                            isBusy
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("pending");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "pending"
                              );
                            }}
                          >
                            Pending
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("rejected");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "rejected"
                              );
                            }}
                          >
                            Rejected
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("approved");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "approved"
                              );
                            }}
                          >
                            Approved
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("");
                              sessionStorage.setItem("statusTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-warning btn-pill dropdown-toggle text-capitalize text-white"
                        data-bs-toggle="dropdown"
                      >
                        {blockType ? (
                          <span class="caret text-capitalize">{blockType}</span>
                        ) : (
                          <span class="caret ">Blocked</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("block");
                              sessionStorage.setItem("blockTypeHost", "block");
                            }}
                          >
                            Block Host
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("unblock");
                              sessionStorage.setItem(
                                "blockTypeHost",
                                "unblock"
                              );
                            }}
                          >
                            Unblock Host
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("");
                              sessionStorage.setItem("blockTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-success btn-pill dropdown-toggle text-white"
                        data-bs-toggle="dropdown"
                      >
                        {countryType ? (
                          <span class="caret">{countryType}</span>
                        ) : (
                          <span class="caret text-capitalize">Country</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        {allCountry?.map((res) => {
                          return (
                            <>
                              <li>
                                <a
                                  className="dropdown-option-text text-capitalize"
                                  href={() => false}
                                  onClick={() => {
                                    setCountryType(res.name.toLowerCase());
                                    sessionStorage.setItem(
                                      "countryTypeHost",
                                      res.name.toLowerCase()
                                    );
                                  }}
                                >
                                  {res.name.toLowerCase()}
                                </a>
                              </li>
                            </>
                          );
                        })}

                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setCountryType();
                              sessionStorage.setItem("countryTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-dark text-white"
                        onClick={allReset}
                      >
                        <span class="caret">Reset All</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      button={true}
                      type={`server`}
                      data={agencyWiseHost}
                      setData={setData}
                      column={hostTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      setSearchValue={setSearch}
                      searchValue={search}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={hostTable}
                serverPerPage={rowsPerPage}
                serverPage={page}
                type={"server"}
              />
            </div>
            <Pagination
              component="div"
              count={agencyWiseHost?.length}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={totalHost}
              serverPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHost,
  hostBlocked,
  offlineHost,
})(AgencyWiseHost);
