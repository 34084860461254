import { Link } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import {
  getCoinPlan,
  deleteCoinPlan,
  activeInactiveSwitch,
  popularSwitch,
} from "../../store/coinPlan/coinPlan.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import CoinPlanDialogue from "./CoinPlanDialogue";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Searching from "../../extra/Searching";
import Pagination from "../../extra/Pagination";

const CoinPlan = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [field, setField] = useState("date");
  const [type, setType] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const { coinPlan } = useSelector((state) => state.coinPlan);

  useEffect(() => {
    props.getCoinPlan(field, type);
  }, [field, type]);

  
  useEffect(() => {
    props.getCoinPlan(field, type);
  }, [refresh]);

  useEffect(() => {
    setData(coinPlan);
  }, [coinPlan]);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  //   console.log(coinPlan);

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCoinPlan(id);
        }
      })
      .catch((err) => console.log(err));
  };
  // Active Plan
  const handleClick = (id) => {
    props.activeInactiveSwitch(id);
  };

  const handlePopular = (id) => {
    dispatch(popularSwitch(id));
  }

  // Coin Plan Table
  const planTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Coin", body: "coin", sorting: { type: "server" } },
    { Header: "Extra Coin", body: "extraCoin", sorting: { type: "server" } },
    { Header: "INR", body: "dollar", sorting: { type: "server" } },
    { Header: "Plan Level", body: "planLevel",  },
    {
      Header: "Platform Type",
      body: "platFormType",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>
          {row?.platFormType === 0 && "Android"}
          {row?.platFormType === 1 && "IOS"}
          {row?.platFormType === 2 && "Web"}
        </span>
      ),
    },
    {
      Header: "Is Popular",
      body: "isPopular",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isPopular}
          onClick={() => handlePopular(row._id)}
        />
      ),
    },

    { Header: "Tag", body: "tag" },
    {
      Header: "Created Date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isActive}
          onClick={() => handleClick(row._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-pen-to-square`}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "plan" },
            })
          }
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },

    // add more columns as needed
  ];
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="planHeader primeHeader">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Coin Plan`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Coin Plan </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                    <Button
                      newClass={`themeFont  userBtn`}
                      btnColor={`btnBlackPrime`}
                      btnName={`New`}
                      btnIcon={`fa-regular fa-plus me-2 me-2`}
                      onClick={() => {
                        dispatch({
                          type: OPEN_DIALOGUE,
                          payload: { type: "plan" },
                        });
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "80px",
                      }}
                    />
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />
                  </div>
                  {dialogue && dialogueType === "plan" && <CoinPlanDialogue />}
                  <div className="col-6">
                    <Searching
                      button={true}
                      type={`client`}
                      data={coinPlan}
                      setData={setData}
                      column={planTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={planTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                  onChildValue={handleChildValue}
                />
              </div>
              <Pagination
                component="div"
                count={coinPlan?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={coinPlan?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getCoinPlan,
  deleteCoinPlan,
  activeInactiveSwitch,
})(CoinPlan);
