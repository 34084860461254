import axios from "axios";
import * as ActionType from "./admin.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { key } from "../../util/config";
export const loginAdmin = (login) => (dispatch) => {
  axios
    .post("admin/login", login)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        dispatch({ type: ActionType.LOGIN_ADMIN, payload: res.data.token });
        setToast("success", "Login Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
      console.log(error);
    });
};

//get admin profile
export const getProfile = () => (dispatch) => {
  axios
    .get("admin/profile")
    .then((res) => {
      dispatch({ type: ActionType.GET_PROFILE, payload: res.data.admin });
    })
    .catch((error) => console.log(error));
};

// update profile
export const updateProfile = (edit) => (dispatch) => {
  axios
    .patch("admin", edit)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ActionType.UPDATE_PROFILE, payload: res.data.admin });
        setToast("success", "Update Your Profile!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//  update Password

export const ChangePassword = (password) => (dispatch) => {
  axios
    .put("admin", password)
    .then((res) => {
      if (res.data.status === true) {
        setToast("success", "Change Your Password!");
        setTimeout(() => {
          dispatch({ type: ActionType.LOGOUT_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast(error.message));
};

// update image

export const UpdateImage = (formData) => (dispatch) => {
  axios
    .patch("admin/updateImage", formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.UPDATE_IMAGE_PROFILE,
          payload: res.data.admin,
        });
        setToast("success", "Update Your Image ");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const sendEmail = (data) => () => {
  axios
    .post("admin/sendEmail")
    .then((res) => {
      if (res.data.status) {
        setToast(
          "success",
          "Mail has been sent successfully. Sometimes mail has been landed on your spam!"
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
// user Country
export const allCountrys = (key) => (dispatch) => {
 
  axios
    .get(`flag`)
    .then((res) => {
   
      console.log(res.data);
      if (res.data.status) {
        dispatch({ type: ActionType.ALL_COUNTRY, payload: res.data.flag });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};
