import React from "react";

//redux
import { connect, useDispatch } from "react-redux";

//serverPath

//sweet alert

//action
import { deleteGift } from "../../store/gift/action";

// type
import { OPEN_GIFT_DIALOG } from "../../store/gift/types";
import $ from "jquery";
import noGift from "../../../assets/images/noGift.jpg";

//routing

import { baseURL } from "../../util/config";
import { warning } from "../../util/Alert";
import { useNavigate } from "react-router-dom";

const AllGift = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (giftId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteGift(giftId);
          // alert("Deleted!", `Gift has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_GIFT_DIALOG, payload: data });
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noGift);
    });
  });

  return (
    <>
      {props.data?.gift?.length > 0 ? (
        props.data?.gift?.map((data, index) => {
          return (
            <>
              <div
                className="col-12 col-sm-6 col-lg-4"
                key={index}
                onClick={() => {
                  localStorage.setItem("CategoryId", data._id);
                  // history.push("/admin/giftCategory/gift");
                }}
              >
                <div class="card gift-card contact-card card-bg">
                  <div class="card-body p-1">
                    <div className="row  py-4">
                      <div className="col-12 d-flex justify-content-center">
                        <img
                          src={data.image && baseURL + data.image}
                          alt=""
                          className="categoryImage"
                          style={{}}
                          draggable="false"
                        />
                      </div>
                      <div
                        className="col-12 d-flex flex-column"
                        style={{
                          padding: 0,
                          paddingLeft: 5,
                        }}
                      >
                        <div class="d-flex contact-card-info justify-content-center mt-2 pt-2">
                          <h5 className="dialog__input__title">Coin : </h5>
                          <h5 className="dialog__input__title mx-1">
                            {data.coin}
                          </h5>
                        </div>
                        {data.platFormType === 1 ? (
                          <div class="d-flex contact-card-info my-2  px-2 justify-content-center">
                            <h5 className="dialog__input__title">IOS</h5>
                          </div>
                        ) : (
                          <div class="d-flex contact-card-info my-2  px-2 justify-content-center">
                            <h5 className="dialog__input__title">Android</h5>
                          </div>
                        )}

                        <div className="d-flex justify-content-center">
                          <div className="mx-4">
                            <button
                              className="btn btn-primary float-right btn-icon"
                              onClick={() =>
                                handleEdit(data)
                              }
                            >
                              <i
                                class="fas fa-edit px-2 fs-5"
                                style={{ fontSize: "15px" }}
                              ></i>
                            </button>
                          </div>
                          <div className="mx-4">
                            <button
                              type="button"
                              className="btn btn-danger btn-icon"
                              onClick={() => handleDelete(data._id)}
                            >
                              <i
                                class="fa fa-trash px-2 fs-5"
                                style={{ fontSize: "18px " }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <tr>
          <td colSpan="4" align="center">
            Nothing to show!!
          </td>
        </tr>
      )}
    </>
  );
};

export default connect(null, { deleteGift })(AllGift);
