import React, { useState } from "react";
import "animate.css";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import {
  createCoinPlan,
  updateCoinPlan,
} from "../../store/coinPlan/coinPlan.action";
import { Typography } from "@material-ui/core";

const CoinPlanDialogue = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [mongoId, setMongoId] = useState(0);
  const [coin, setCoin] = useState(0);
  const [extraCoin, setExtraCoin] = useState(0);
  const [amount, setAmount] = useState(0);
  const [platFormType, setPlatFormType] = useState("");
  const [tag, setTag] = useState("");
  const [productKey, setProductKey] = useState("");
  const [planLevel, setPlanLevel] = useState("");
  const [error, setError] = useState({
    coin: "",
    extraCoin: "",
    amount: "",
    platFormType: "",
    tag: "",
    productKey: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setPlanLevel(dialogueData?.planLevel);
    setMongoId(dialogueData?._id);
    setCoin(dialogueData?.coin);
    setExtraCoin(dialogueData?.extraCoin);
    setAmount(dialogueData?.dollar);
    setPlatFormType(dialogueData?.platFormType);
    setTag(dialogueData?.tag);
    setProductKey(dialogueData?.productKey);
  }, [dialogueData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !planLevel ||
      !coin ||
      !amount ||
      !productKey ||
      planLevel < 0 ||
      coin < 0 ||
      amount < 0 ||
      platFormType < 0 ||
      platFormType === "Select Package"
    ) {
      const error = {};
      if (!coin) error.coin = "Coin is required!";
      if (coin < 0) error.coin = "Invalid Coin!";
      if (!amount) error.amount = "Dollar is required!";
      if (amount < 0) error.amount = "Invalid dollar!";
      if (planLevel < 0) error.planLevel = "Invalid Plan Level!";
      if (platFormType < 0 || platFormType === "Select Package") {
        error.platFormType = "PlatFormType is Required!";
      }
      if (!productKey) error.productKey = "Product Key is required!";

      return setError({ ...error });
    }

    const data = {
      planLevel,
      coin,
      dollar: amount,
      extraCoin: extraCoin ? extraCoin : 0,
      tag,
      productKey,
      platFormType,
    };

    if (mongoId) {
      props.updateCoinPlan(mongoId, data);
    } else {
      props.createCoinPlan(data);
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Plan</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  type={`number`}
                  value={coin}
                  errorMessage={error.coin && error.coin}
                  onChange={(e) => {
                    setCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        coin: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        coin: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Extra Coin`}
                  id={`extraCoin`}
                  type={`number`}
                  value={extraCoin}
                  errorMessage={error.extraCoin && error.extraCoin}
                  onChange={(e) => {
                    setExtraCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        extraCoin: `Extra Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        extraCoin: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Amount($)`}
                  id={`amount`}
                  type={`number`}
                  value={amount}
                  errorMessage={error.amount && error.amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        amount: `Amount Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        amount: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-md-6 ">
                <label class="float-left ">Platform Type</label>
                <select
                  name="type"
                  class="form-control form-control-line mt-2 "
                  id="type"
                  value={platFormType}
                  onChange={(e) => {
                    setPlatFormType(e.target.value);

                    if (e.target.value === "Select Package") {
                      return setError({
                        ...error,
                        platFormType: "Platform type is required !",
                      });
                    } else {
                      return setError({
                        ...error,
                        platFormType: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                >
                  <option value="Select Package">---Select Package---</option>

                  <option value="0">Android</option>
                  <option value="1">IOS</option>
                  <option value="2">Web</option>
                </select>
                {error.platFormType && (
                  <div class="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.platFormType}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="col-6">
                <Input
                  label={`Tag`}
                  id={`tag`}
                  type={`text`}
                  value={tag}
                  placeholder={`Enter Tag`}
                  errorMessage={error.tag && error.tag}
                  onChange={(e) => {
                    setTag(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        tag: `Tag Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        tag: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Plan level`}
                  id={`planLevel`}
                  type={`number`}
                  value={planLevel}
                  placeholder={`Enter Plan Level`}
                  errorMessage={error.planLevel && error.planLevel}
                  onChange={(e) => {
                    setPlanLevel(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        planLevel: `Plan Level Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        planLevel: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>

              <div className="col-12">
                <Input
                  label={`Product Key`}
                  id={`productKey`}
                  type={`text`}
                  value={productKey}
                  placeholder={`Enter Product Key`}
                  errorMessage={error.productKey && error.productKey}
                  onChange={(e) => {
                    setProductKey(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        productKey: `Product Key Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        productKey: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createCoinPlan, updateCoinPlan })(
  CoinPlanDialogue
);
