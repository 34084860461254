// agency request get
export const AGENCY_REQUEST_GET = "AGENCY_REQUEST_GET";

// agency Request accept 

export const AGENCY_REQUEST_ACCEPT = "AGENCY_REQUEST_ACCEPT";


 
// update agent_REQUEST
export const UPDATE_AGENCY_REQUEST = "UPDATE_AGENCY_REQUEST";