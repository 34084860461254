import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Searching from "../../extra/Searching";
import { connect, useDispatch, useSelector } from "react-redux";
import Admin from "../../Pages/Admin";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import {
  getMostPlanPurchaseUser,
  userBlocked,
} from "../../store/users/user.action";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { Tooltip } from "@material-ui/core";
import NotificationDialogue from "../../Pages/NotificationDialogue";

function PlanPurchaseUserTable(props) {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { user, total } = useSelector((state) => state.user);
  const { allCountry } = useSelector((state) => state.admin);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    props.getMostPlanPurchaseUser(search, page, size);
  }, [search, page, size]);

  useEffect(() => {
    props.getMostPlanPurchaseUser("ALL", page, size);
  }, []);

  useEffect(() => {
    setData(user);
  }, [user]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (row) => {
    props.userBlocked(row, row.isBlock === true ? false : true);
  };

  const purchaseUserTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
          draggable={false}
          src={row.image}
          className={`table-image d-block mx-auto`}
          height={60}
          width={60}
          alt=""
          onClick={() => navigate("/admin/user/profile", { state: row })}
        />
      ),
    },
    { Header: "Name", body: "name", sorting: { type: "server" } },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <span>{row.email ? row.email : "qwee@gmail.com"}</span>
      ),
    },
    { Header: "Unique Id", body: "uniqueId" },
    { Header: "Country", body: "country" },
    { Header: "Coin", body: "coin", sorting: { type: "server" } },
    {
      Header: "Block",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Created Date",
      body: "createdAt",
      // sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <Tooltip title="Action" placeholder="bottom">
          <div class="btn-group mt-2 mb-2 mx-2 ">
            <i
              class="fa-solid fa-ellipsis-vertical fs-4 p-3"
              data-bs-toggle="dropdown"
            ></i>

            <ul class="dropdown-menu my-1" role="menu">
              <li style={{ cursor: "pointer" }}>
                <a
                  onClick={() =>
                    navigate("/admin/user/profile", { state: row })
                  }
                >
                  <i class="fas fa-info-circle pe-2 fs-6"></i>
                  <span> Profile</span>
                </a>
              </li>
              <li className="text-muted " style={{ cursor: "pointer" }}>
                <a
                  onClick={() =>
                    navigate("/admin/user/history", { state: row })
                  }
                >
                  <i class="fas fa-history pe-2 fs-6"></i>
                  <span> History</span>
                </a>
              </li>
              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  onClick={() =>
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: {
                        type: "userMessage",
                        data: { id: row._id, type: "user" },
                        type: "MessageAdminAll",
                      },
                    })
                  }
                >
                  <i class="fa-solid fa-envelope pe-2  fs-6"></i>
                  <span> Message</span>
                </a>
              </li>
              <li className="text-muted cursor-pointer">
                <a
                  onClick={() =>
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: {
                        type: "NotificationUser",
                        data: row,
                      },
                    })
                  }
                >
                  <i class="fa-solid fa-bell fs-6"></i>
                  <span> Notification</span>
                </a>
              </li>
            </ul>
          </div>
        </Tooltip>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="mainPlanTable">
        <div className="plantable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head mt-3">
                <Title name={` Most Purchase Plan`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem text-capitalize">
                    Most Purchase Plan
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="coin-plan">
            <div className="coin-plan-btn">
              <div className="row ps-4 py-3">
                <div className="col-6 "></div>
                <div className="col-6">
                  <Searching
                    type={`client`}
                    data={user}
                    setData={setData}
                    column={purchaseUserTable}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
                {dialogue && dialogueType === "NotificationUser" && (
                  <NotificationDialogue />
                )}
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={purchaseUserTable}
                serverPerPage={rowsPerPage}
                serverPage={page}
                type={"server"}
              />
            </div>
            <Pagination
              component="div"
              count={user?.length}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={total}
              serverPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { getMostPlanPurchaseUser, userBlocked })(
  PlanPurchaseUserTable
);
