import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import SettingBox from "../../Table/setting/SettingBox";
import { connect, useSelector } from "react-redux";
import Input from "../../extra/Input";
import {
  getSetting,
  updateSetting,
  handleToggleSwitch,
} from "../../store/setting/setting.action";

function DeveloperSetting(props) {
  const { setting } = useSelector((state) => state.setting);

  const [liveTimeApiCallTime, setLiveTimeApiCallTime] = useState("");
  const [isAppActive, setIsAppActive] = useState(false);
  const [allHostOnlineEnable, setAllHostOnlineEnable] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    props.getSetting();
  }, []);

  useEffect(() => {
    setLiveTimeApiCallTime(setting?.liveTimeApiCallTime);
    setIsAppActive(setting?.isAppActive);
    setAllHostOnlineEnable(setting?.allHostOnlineEnable);
  }, [setting]);

  const handleSubmit = () => {
    if (!liveTimeApiCallTime || liveTimeApiCallTime < 0) {
      return setError({
        liveTimeApiCallTime:
          "live TimeApi Call Time is required positive  value",
      });
    } else {
      let data = { liveTimeApiCallTime };
      props.updateSetting(setting?._id, data);
    }
  };
  
  const handleClick = (type) => {
    props.handleToggleSwitch(setting?._id, type);
  };

  return (
    <>
      <div>
        <div className="mainSettingBar">
          <div className="settingBar ">
            <div className="settingHeader primeHeader">
              <div className="row align-items-center justify-content-center px-3">
                <div className="col-6">
                  <Title name={`Setting`} />
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <ol class="breadcrumb1">
                    <li class="breadcrumb-item1 pb-0 mb-0">
                      <p to="/admin/dashboard">Dashboard</p>
                    </li>
                    <li class="breadcrumb-item1 activeItem"> setting </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="settingMain">
              <div className="row">
                <div className="col-6">
                  <Title name={`Live Api Setting`} />
                  {/*-------------- Box 1 --------------*/}
                  <SettingBox
                    submit={handleSubmit}
                    title={`Is App Active`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: isAppActive,
                      handleClick: () => {
                        handleClick("app");
                      },
                    }}
                  >
                    <Input
                      type={`number`}
                      label={`Live Api Time`}
                      value={liveTimeApiCallTime}
                      newClass={`col-12`}
                      placeholder={`Enter You Live Api Time....`}
                      onChange={(e) => {
                        setLiveTimeApiCallTime(e.target.value);
                      }}
                    />
                  </SettingBox>
                </div>
                <div className="col-6">
                  <Title name={`Host Setting`} />
                  {/*-------------- Box 2 --------------*/}

                  <SettingBox
                    submit={handleSubmit}
                    title={`ALL HOST ONLINE ENABLE`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: allHostOnlineEnable,
                      handleClick: () => {
                        handleClick("allHostOnlineEnable");
                      },
                    }}
                  ></SettingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, { getSetting, updateSetting, handleToggleSwitch })(
  DeveloperSetting
);
