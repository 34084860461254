import axios from "axios";
import { setToast } from "../../util/toast";
import { GET_HOST_CALL_REVIEW } from "./type";

export const getCallReview = () => (dispatch) => {
  
  axios
    .get(`callReview`)
    .then((res) => {
      
      console.log("res.data", res.data);
      if (res.data.status) {
        dispatch({ type: GET_HOST_CALL_REVIEW, payload: res.data.callReview });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
