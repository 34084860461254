import axios from "axios";
import * as ActionType from "./transaction.type";

// get most use gift
export const getMostUSeGift = () => (dispatch) => {
  axios
    .get("mostUsedGift")
    .then((res) => {
      dispatch({ type: ActionType.GET_MOST_USE_GIFT, payload: res.data.history });
    })
    .catch((error) => console.log(error));
};

// get most use coinPlan

export const getMostUSeCoinPlan = () => (dispatch) => {
  axios
    .get("mostUsedCoinPlan")
    .then((res) => {
      dispatch({ type: ActionType.GET_MOST_USE_COINPLAN, payload: res.data.history });
    })
    .catch((error) => console.log(error));
};
