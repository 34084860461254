import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import {
  createCommission,
  updateCommission,
} from "../../store/Commission/commission.action";

const CommissionDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const [money, setMoney] = useState("");
  const [rate, setRate] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setRate(dialogueData?.rate);
    setMoney(dialogueData?.coin);
  }, [dialogue]);

  useEffect(() =>  () =>{
    setMoney("");
    setRate("");
    setError({ money: "", rate: "" });
  }, [dialogue]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!money || !rate) {
      let error = {};
      if (!money) error.money = "Coin Is Required!";
      if (!rate) error.rate = "Rate Is Required!";
      return setError({ ...error });
    } else {
      let data = {
        coin: money,
        rate,
      };
      if (dialogueData) {
        console.log("id", dialogueData?._id);
        props.updateCommission(data, dialogueData?._id);
      } else {
        props.createCommission(data);
      }
    }
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Commission" : "Create Commission"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Money`}
                  id={`Money`}
                  placeholder={`1000`}
                  type={`number`}
                  value={money}
                  errorMessage={error.money && error.money}
                  onChange={(e) => {
                    setMoney(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        money: `Money Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        money: "",
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Rate`}
                  id={`rate`}
                  type={`number`}
                  placeholder={`10%`}
                  value={rate}
                  errorMessage={error.rate && error.rate}
                  onChange={(e) => {
                    setRate(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        rate: `Rate Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        rate: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { updateCommission, createCommission })(
  CommissionDialogue
);
