import React from "react";

//react-redux
import { useDispatch, useSelector } from "react-redux";

//Type
import { CLOSE_FEEDBACK_DIALOG } from "../../store/feedBack/feedBack.type";

import { baseURL } from "../../util/config";

import $ from "jquery";

// image
import male from "../../../assets/images/maleImage.png";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";

const FeedbackDetailsDialog = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  //Handle Close Dialog
  const handleClose = () => {
    dispatch({ type: CLOSE_FEEDBACK_DIALOG });
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  //Handle Open URL in new page
  const handleOpenUrl = (url) => {
    handleClose();
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">FeedBack Details</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <table>
                    {dialogueData?.screenshot && (
                      <tr>
                        <td className="py-2 mb-2 feedback__title">
                          Feedback Image
                        </td>
                        <td className="py-2 mb-2 feedback__title">
                          &nbsp;:&nbsp;&nbsp;
                        </td>
                        <td className="py-2 mb-2 feedback__details">
                          <img
                                  draggable={false}
                            height="100px"
                            width="100px"
                            alt="app"
                            src={baseURL + dialogueData?.screenshot}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleOpenUrl(baseURL + dialogueData?.screenshot)
                            }
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="py-2 mb-2 feedback__title">User Name</td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {dialogueData?.userId?.name.charAt(0).toUpperCase() +
                          dialogueData?.userId?.name.slice(1)}
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 mb-2 feedback__title">
                        User's Country
                      </td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {dialogueData?.userId.country}
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 mb-2 feedback__title">Contact </td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {dialogueData?.contact}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 mb-2 feedback__title">
                        Feedback Type
                      </td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {(dialogueData?.type === 1 && "Recharge") ||
                          (dialogueData?.type === 2 && "Gifting") ||
                          (dialogueData?.type === 3 && "Stream") ||
                          (dialogueData?.type === 4 && "Events") ||
                          (dialogueData?.type === 5 && "Suggestions") ||
                          (dialogueData?.type === 6 && "Others")}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 mb-2 feedback__title">
                        Feedback Message
                      </td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {dialogueData?.description}
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 mb-2 feedback__title">
                        Feedback Status
                      </td>
                      <td className="py-2 mb-2 feedback__title">
                        &nbsp;:&nbsp;&nbsp;
                      </td>
                      <td className="py-2 mb-2 feedback__details">
                        {dialogueData?.isSolved ? (
                          <div className="badge bg-success mt-2 p-1">
                            Solved
                          </div>
                        ) : (
                          <div className="badge bg-success mt-2 p-1">
                            Pending
                          </div>
                        )}
                      </td>
                    </tr>
                  </table>
                </form>
              </div>
            </div>
          </div>
          <div className="dialogueFooter"></div>
        </div>
      </div>
    </>
  );
};

export default FeedbackDetailsDialog;
