import axios from "axios";
import * as ACTION_TYPE from "./coinPlan.type";
import { setToast } from "../../util/toast";
import { baseURL, key } from "../../util/config";

//Get Coin Plan
export const getCoinPlan = (field, type) => (dispatch) => {
  axios
    .get(`${baseURL}coinPlan?field=${field}&type=${type}`)
    .then((res) => {
      dispatch({ type: ACTION_TYPE.GET_COIN_PLAN, payload: res.data.coinPlan });
    })
    .catch((error) => console.log(error));
};

//Create Coin Plan
export const createCoinPlan = (data) => (dispatch) => {
  console.log("data", data);
  axios
    .post(`coinPlan/create`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ACTION_TYPE.CREATE_COIN_PLAN,
          payload: res.data.coinPlan,
        });

        setToast("success", "Coin Plan Created Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Update Coin Plan
export const updateCoinPlan = (id, data) => (dispatch) => {
  axios
    .patch(`coinPlan/${id}`, data)
    .then((res) => {
      if (res.data.status === true) {
        setToast("success", "Coin plan Updated Successfully!");
      } else {
        setToast("error", res.config.message);
      }
      dispatch({
        type: ACTION_TYPE.UPDATE_COIN_PLAN,
        payload: { data: res.data.coinPlan, id },
      });
    })
    .catch((error) => console.log(error));
};

//Delete Coin Plan
export const deleteCoinPlan = (id) => (dispatch) => {
  axios
    .delete(`coinPlan/${id}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ACTION_TYPE.DELETE_COIN_PLAN, payload: id });
        setToast("success", "Coin Plan Delete  successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Active Inactive Switch
export const activeInactiveSwitch = (id) => (dispatch) => {
  axios
    .put(`coinPlan/${id}`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPE.ACTIVE_INACTIVE,
        payload: { data: res.data.coinPlan, id },
      });
      setToast("success", res?.data?.coinPlan?.isActive === true ?  "Coin Plan Active Succesfully" : "Coin Plan InActive Succesfully");

    })
    .catch((error) => console.log(error));
};

//Popular Switch
export const popularSwitch = (id) => (dispatch) => {
  axios
    .patch(`/coinPlan/${id}/isPopularOrNot`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPE.POPULAR_SWITCH,
        payload: { data: res.data.coinPlan, id },
      });
      setToast("success", res.data.coinPlan?.isPopular === true ? "Popular Plan Active Succesfully" : "Popular Plan InActive Succesfully");
    })
    .catch((error) => console.log(error));
};

// purchased history

export const purchasedHistory = (type, filed) => (dispatch) => {
  axios
    .get(`coinPlan/purchasedHistory?type=${type}&field=${filed}`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPE.PURCHASED_HISTORY,
        payload: res.data.history,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
