import axios from "axios";
import * as ActionType from "./setting.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";

export const getSetting = () => (dispatch) => {
  axios
    .get(`setting`)
    .then((res) =>
      dispatch({ type: ActionType.GET_SETTING, payload: res.data.setting })
    )
    .catch((error) => {
      console.log(error);
    });
};

//Update Setting
export const updateSetting = (id, data) => (dispatch) => {
 
  axios
    .patch(`setting/${id}`, data)
    .then((res) => {
     
      if (res.data.status === true) {
        dispatch({
          type: ActionType.UPDATE_SETTING,
          payload: res.data.setting,
        });
        setToast("success", "Setting Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Update Setting
export const updateHtml = (data) => (dispatch) => {
  axios
    .post(`setting/updateGuid`, data)
    .then((res) => {
      console.log(res.data.setting);
      if (res.data.status === true) {
        dispatch({
          type: ActionType.UPDATE_SETTING,
          payload: res.data.setting,
        });
        setToast("success", "Setting Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Handle Toggle Switch
export const handleToggleSwitch = (id, type) => (dispatch) => {
  axios
    .put(`setting/${id}?type=${type}`)
    .then((res) => {
      dispatch({
        type: ActionType.HANDLE_TOGGLE_SWITCH,
        payload: { setting: res.data.setting, id },
      });
    })
    .catch((error) => console.log(error));
};
