//Get Permission Data
export const GET_PERMISSION = "GET_PERMISSION";

//Create Permission
export const CREATE_PERMISSION = "CREATE_PERMISSION";

//Update Permission
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

//Delete Permission
export const DELETE_PERMISSION = "DELETE_PERMISSION";

//Active Permission
export const ACTIVE_PERMISSION = "ACTIVE_PERMISSION";

//Open Permission Dialog
export const OPEN_PERMISSION_DIALOG = "OPEN_PERMISSION_DIALOG";
export const CLOSE_PERMISSION_DIALOG = "CLOSE_PERMISSION_DIALOG";
