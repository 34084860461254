import * as ActionType from "./webPayment.type";
const initialState = {
  PlanList: [],
  dialog: false,
  dialogData: null,
  plan:{}
};
export const PlanListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_PLAN_LIST:
      return {
        ...state,
        PlanList: action.payload,
      };
    case ActionType.GET_PLAN:
      return {
        ...state,
        plan : action.payload,
      };
    case ActionType.OPEN_STRIPE_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_STRIPE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    default:
      return state;
  }
};
