// message dialogue open

export const ADMIN_SEND_MESSAGE_DIALOGUE_OPEN =
  "ADMIN_SEND_MESSAGE_DIALOGUE_OPEN";

// message dialogue Close
export const ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE =
  "ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE";

// message send by admin
export const ADMIN_SEND_MESSAGE = "ADMIN_SEND_MESSAGE";
