import axios from "axios";
import { baseURL, key } from "../../util/config";
import { setToast } from "../../util/toast";
import * as ActionType from "./agency.type";

// GET AGENCY
export const getAgency = () => (dispatch) => {
  axios
    .get(`agency?type=admin`)
    .then((res) => {
      dispatch({ type: ActionType.GET_AGENCY, payload: res.data.agency });
    })
    .catch((error) => console.log(error));
};
export const getAgencyList = () => (dispatch) => {
  axios
    .get(`agency/list`)
    .then((res) => {
      console.log(res.data.agency);
      dispatch({ type: ActionType.GET_AGENCY, payload: res.data.agency });
    })
    .catch((error) => console.log(error));
};

// CREATE AGENCY
export const addAgency = (formData) => (dispatch) => {
  axios
    .post("agency", formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ActionType.ADD_AGENCY, payload: res.data.agency });
        setToast("success", "Agency Add Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// UPDATE AGENCY
export const editAgency = (id, fromData) => (dispatch) => {
  axios
    .patch(`agency/${id}`, fromData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.EDIT_AGENCY,
          payload: { editAgency: res.data.agency, id },
        });
        setToast("success", "Agency Update Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// DELETE AGANCY
export const deleteAgency = (id, disable) => (dispatch) => {
  axios
    .patch(`agency/disable/${id?._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DISABLE_AGENCY,
          payload: { data: res.data.data, id: id?._id },
        });
        setToast(
          "success",
          `${id.name} Is ${
            disable === true ? "Disable" : "available"
          } Successfully`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// GET AGENCY HISTORY
export const getAgencyHitory =
  (id, startDate, endDate, filed, sortType) => (dispatch) => {
 
    axios
      .get(
        `agency/agencyTotalRevenue/${id}?startDate=${startDate}&endDate=${endDate}&field=${filed}&sortType=${sortType}`
      )
      .then((res) => {
        
        dispatch({
          type: ActionType.AGENCY_HISTORY,
          payload: {
            agencyRevenueHistory: res.data.agencyRevenueHistory,
            totalAgencyRevenue: res.data.totalAgencyRevenue,
            agencyName: res.data.agencyName,
            total: res.data.total,
            agencyImage: res.data.agencyImage,
          },
        });
      })
      .catch((error) => console.log(error));
  };

// GET AGENCY SETTLEMENT
export const getAgencySettlement = (id, startDate, endDate) => (dispatch) => {
  axios
    .get(
      `agency/agencyWiseHostSettlement/${id}?startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.AGENCY_SETTLEMENT,
        payload: res.data.settlementReport,
      });
    })
    .catch((error) => console.log(error));
};

// agency_payout_list

export const agencyPayout = () => (dispatch) => {
  axios
    .get(`adminPayoutList`)
    .then((res) => {
      dispatch({
        type: ActionType.AGENCY_PAYOUT_LIST,
        payload: res.data.adminPayout,
      });
    })
    .catch((error) => console.Console(error));
};

// export const getHost = (id) => (dispatch) => {
//
//   axios
//     .get(`host/agencyWiseHost?agencyId=${id}`)

//     .then((res) => {
//
//       console.log(res.data.host);
//       dispatch({ type: ActionType.AGENCY_WISE_HOST, payload: res.data.host });
//     })
//     .catch((error) => console.log("error", error));
// };

export const getHost =
  (
    id,
    searchAgencyHost,
    genderType,
    statusType,
    blockType,
    countryType,
    field,
    type,
    start,
    limit
  ) =>
  (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", key: key },
    };

    fetch(
      `${baseURL}host/agencyWiseHost?search=${searchAgencyHost}&agencyId=${id}&gender=${genderType}&status=${statusType}&blockType=${blockType}&country=${countryType}&field=${field}&sortType=${type}&start=${start}&limit=${limit} `,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({ type: ActionType.AGENCY_WISE_HOST, payload: res });
      })
      .catch((error) => console.error(error));
  };

// Host Blocked

export const hostBlocked = (host, block) => (dispatch) => {
  axios
    .put(`host/${host._id}`)
    .then((res) => {
      console.log(res.data.host);
      if (res.data.status) {
        dispatch({
          type: ActionType.AGENCY_WISE_BLOCK,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// DELETE AGANCY
export const agencySwitch = (id, type, available) => (dispatch) => {
  axios
    .put(`agency/switch?agencyId=${id?._id}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.SWITCH,
          payload: { data: res.data.agency, id: id?._id },
        });
        setToast(
          "success",
          `${id.name} Is ${available === false ? "Disable" : "available"} for ${
            type === "live" ? "LiveStreaming" : "Random Match"
          }`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};
