import * as ActionType from "./message.type";

const initialState = {
  messageDialogue: false,
  messageDialogueData: null,
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADMIN_SEND_MESSAGE_DIALOGUE_OPEN:
     
      return {
        ...state,
        messageDialogue: true,
        messageDialogueData: action.payload || null,
      };
    case ActionType.ADMIN_SEND_MESSAGE_DIALOGUE_CLOSE:
      return {
        ...state,
        messageDialogue: false,
        messageDialogueData: null,
      };

    default:
      return state;
  }
};
