
//Get Redeem All
export const GET_REDEEM_ALL = "GET_REDEEM_ALL";

//Get Redeem Pending
export const GET_REDEEM_PENDING = "GET_REDEEM_PENDING";

//Get Redeem Solved
export const GET_REDEEM_SOLVED = "GET_REDEEM_SOLVED";

//isSolved Redeem Switch
export const REDEEM_SOLVED = "REDEEM_SOLVED";

// Open and Close Redeem Dialog
export const OPEN_REDEEM_DIALOG = "OPEN_REDEEM_DIALOG";

export const CLOSE_REDEEM_DIALOG = "CLOSE_REDEEM_DIALOG";

// REDEEM_UPDATE
export const REDEEM_UPDATE = "REDEEM_UPDATE";

