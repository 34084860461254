import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCoinSellerHistory } from "../../store/coinSeller/action";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";

const CoinSellerHistory = (props) => {
  const { state } = useLocation();

  const { coinSellerHistory } = useSelector((state) => state.coinSeller);

  const [data, setData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);
  const [agnId, setAgnId] = useState("");

  useEffect(() => {
    setData(coinSellerHistory);
  }, [coinSellerHistory]);

  useEffect(() => {
    props.getCoinSellerHistory(state._id);
  }, []);

  const navigate = useNavigate();

  const handelPreviousPage = () => {
    navigate(-1);
  };

  let date;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const coinSellerHistoryTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{(page) * rowsPerPage + parseInt(index)+1}</span>,
    },
    {
      Header: "Name",
      body: "name",

      Cell: ({ row }) => (
        <span className="fw-bold text-success text-capitalize fs-6">
          {row?.userId ? row?.userId?.name : "Admin"}
        </span>
      ),
    },
    { Header: "Coin", body: "coin", class: "fw-bold text-warning fs-6" },
    {
      Header: "Purchase Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold fs-6">{date[0]}</span>;
      },
    },
    {
      Header: "Purchase Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[1]}</span>;
      },
    },
  ];

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row mt-5">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Coin Seller History`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Coin Seller History </li>
                </ol>
              </div>
            </div>
   
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan overflow-hidden">
            <div className="row border-bottom">
              <div className="col-6 title-head title-head px-5">
                <Title name={`History`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3 px-5">
              <Title name={`Total Coin : ` + state?.coin} />
                
              </div>
            </div>
              <div className="coin-plan-btn">
              <div className="primeMain border-top">

                <Table
                  data={data}
                  mapData={coinSellerHistoryTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                />
                </div>

                <Pagination
                  component="div"
                  count={data?.length}
                  serverPage={page}
                  type={"client"}
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={data?.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCoinSellerHistory })(CoinSellerHistory);
