import * as ActionType from "./agencyRequest.type";

const initialState = {
  agencyRequest: [],
};

export const agencyRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    // get agency Request
    case ActionType.AGENCY_REQUEST_GET:
      return {
        ...state,
        agencyRequest: action.payload,
      };

    // accept agency request
    case ActionType.AGENCY_REQUEST_ACCEPT:
      return {
        ...state,
        agencyRequest: state.agencyRequest.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    case ActionType.UPDATE_AGENCY_REQUEST:
      return {
        ...state,
        agencyRequest: state.agencyRequest.map((data) =>
          data._id === action.payload.updateRequest._id
            ? action.payload.updateRequest
            : data
        ),
      };

    default:
      return state;
  }
};
