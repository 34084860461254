const Button = (props) => {
  const { newClass, btnColor, btnName, onClick, style, btnIcon, disabled } =
    props;

  return (
    <>
      <button
      type="button"
        className={`themeBtn text-center ${newClass} ${btnColor}`}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {btnIcon ? (
          <div>
            <i className={btnIcon}></i>
            <span>{btnName}</span>  
          </div>
        ) : (
          btnName
        )}
      </button>
    </>
  );
};

export default Button;
