import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getPlanList } from "../store/webPayment/webPayment.action";

import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { key } from "../util/config";
import coin from "../../assets/images/coin.png";
import coin1 from "../../assets/images/coin1.png";
import coin2 from "../..//assets/images/coin2.png";
import coin3 from "../../assets/images/coin3.png";

const PlanList = (props) => {
  const [data, setData] = useState([]);
  const [coinData, setCoinData] = useState([]);
  const { PlanList } = useSelector((state) => state.PlanList);

  const dispatch = useDispatch();

  console.log("props", props);

  // useEffect(() => {
  //   axios.defaults.headers.common["key"] = key;
  //   axios
  //     .get(
  //       `coinPlan/get?platFormType=2&currency=${props.match.params.currency}`
  //     )
  //     .then((response) => {
  //       setData(response.data.coinPlan);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  let params = useParams();

  const navigate = useNavigate();

  const handleOpenPurchase = (data) => {
    navigate("webPayment", { state: { data } });
  };
  console.log("coinPlan", data);

  // border :rgb(209,179 ,232)

  // button

  // useEffect(() => {
  //   axios
  //     .get(`user/userById/${props.match.params.userId}`)
  //     .then((response) => {
  //       setCoinData(response.data.coin);
  //       if (response.data.status) {
  //       } else {
  //         navigate("/webPayment");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       navigate("/webPayment");
  //     });
  // }, []);

  const handleStripe = (coinPlanId, props) => {
    navigate(`/webPayment/${coinPlanId}/${props.match.params.userId}`, {
      state: {
        data: coinPlanId,
        currency: props.match.params.currency,
        symbol: props.match.params.symbol,
      },
    });
  };
  return (
    <>
      <div class="account-pages">
        <div
          style={{ width: "auto", height: "20%", backgroundColor: "#ead8ff" }}
        >
          <div className="text-center">
            <h5 className=" pt-5 font-weight-bold font-weight-2000 ">
              Buy Coins
            </h5>
            <p className="mb-0 fw-200">My coins </p>
            {/* <div classNmae="d-flex justify-content-center align-items-center"> */}

            <img
              src={coin}
              alt="coinImage"
              className="mx-auto mb-2"
              draggable="false"
              style={{
                width: "30px",
                height: "30px",
                // display: "block",
                objectFit: "cover",
              }}
            />
            <span className="fs-3 ps-2 font-weight-bold">{coinData}</span>
          </div>
          {/* </div> */}
        </div>

        <div className="p-4">
          <div className="row  align-items-center">
            {data?.map((data) => {
              return (
                <>
                  <div
                    className="col-6"
                    onClick={() => handleStripe(data?._id)}
                  >
                    <div
                      className="card"
                      style={{
                        border: "5px solid rgb(209,179,232)",
                        borderRadius: "30px",
                        cursor: "pointer",
                        maxHeight: "240px",
                        overflow: "hidden",
                        paddingTop: "30px",
                      }}
                    >
                      <div>
                        <div
                          className="text-center  my-2 planListTag "
                          style={{ fontSize: "13px" }}
                        >
                          <p className="fw-bold planTag">
                            {data?.tag ? data?.tag : "-"}
                          </p>
                        </div>
                        {data?.coin <= 500 && data?.coin <= 1000 && (
                          <img
                            src={coin}
                            alt="coinImage"
                            className=" mx-auto"
                            draggable="false"
                            style={{
                              width: "50px",
                              height: "50px",
                              display: "block",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        {data?.coin >= 1000 && data?.coin <= 10000 && (
                          <img
                            src={coin2}
                            alt="coinImage"
                            className=" mx-auto "
                            style={{
                              width: "56px",
                              height: "51px",
                              display: "block",
                              objectFit: "cover",
                            }}
                            draggable="false"
                          />
                        )}
                        {data?.coin >= 10001 && data?.coin <= 50000 && (
                          <img
                            src={coin3}
                            alt="coinImage"
                            className=" mx-auto "
                            style={{
                              width: "70px",
                              height: "51px",
                              display: "block",
                              objectFit: "cover",
                            }}
                            draggable="false"
                          />
                        )}

                        {data?.coin >= 50001 && (
                          <img
                            src={coin1}
                            alt="coinImage"
                            className=" mx-auto "
                            style={{
                              width: "66px",
                              height: "55px",
                              display: "block",
                              objectFit: "cover",
                            }}
                            draggable="false"
                          />
                        )}

                        <div className="text-center text-primary ">
                          <h2 className="fw-bold mb-0 mt-2 fs-3">
                            {parseInt(data?.coin) + parseInt(data?.extraCoin)}
                          </h2>
                        </div>

                        <div
                          className="text-center text-muted "
                          style={{ fontSize: "10px" }}
                        >
                          {data.extraCoin === 0 ? (
                            <span className="text-dark  mb-1 fs-6">-</span>
                          ) : (
                            <span className="text-dark text-decoration-line-through mb-1 fs-6">
                              {data?.coin}
                            </span>
                          )}
                        </div>
                        <button type="button" className="pt-4 stripe_button">
                          <h4 className="text-center fw-bold text-white ">
                            {props.match.params.symbol}
                            {data?.rates[0].rate.toFixed(2)}
                          </h4>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getPlanList })(PlanList);

