import React, { useEffect, useState } from "react";
import Title from "../../../extra/Title";
import Button from "../../../extra/Button";
import { useLocation, useNavigate } from "react-router-dom";
import UserCallHistory from "./UserCallHistory";
import UserGiftHistory from "./UserGiftHistory";
import UserAdminHistory from "./UserAdminHistory";
import UserCoinPlanHistory from "./UserCoinPlanHistory";
import UserCoinSellerHistory from "./UserCoinSellerHistory";
import UserMassageHistory from "./UserMassageHistory";
import $ from "jquery";
import male from "../../../../assets/images/maleImage.png";
import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../../store/dialogue/dialogue.type";

const UserHistory = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [type, setType] = useState("Call");

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handelPreviousPage = () => {
    localStorage.removeItem("dialogueData");
    if (state) {
      navigate(-1);
      dispatch({ type: CLOSE_DIALOGUE });
    } else {
      const userProfile = localStorage.getItem("userProfile");
      const profile = JSON.parse(userProfile);
      profile
        ? dispatch({
            type: OPEN_DIALOGUE,
            payload: {
              dialogue: true,
              type: "userProfile",
              data: profile.dialogueData,
            },
          })
        : dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh(!refresh)
  };

  return (
    <>
      <div className={`mainPlanTable  hostHistory ${state && "mt-4"}`}>
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title
                  name={state ? state?.name : dialogueData?.name + `'s History`}
                />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <Button
                  btnColor={`btn-primary`}
                  newClass={`themeFont boxCent text-white`}
                  onClick={handelPreviousPage}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "50px",
                  }}
                  btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                />
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan overflow-hidden">
              <div className="coin-plan-button">
                <div className="row p-3 d-flex align-items-center border-bottom">
                  <div className="col-sm-12 col-md-6 ">
                    <Title className="mt-0" name={type + " History"} />
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                    <div className="me-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-danger`}
                        btnIcon={`fa-solid fa-rotate-right pe-2`}
                        btnName={`Refresh`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "95px",
                        }}
                        onClick={handleRefresh}
                      />
                    </div>
                    <div className="">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-primary`}
                        btnName={`Call`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Call")}
                      />
                    </div>

                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-secondary`}
                        btnName={`Message`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("message")}
                      />
                    </div>
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-warning`}
                        btnName={`Gift`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Gift")}
                      />
                    </div>

                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-danger`}
                        btnName={`Admin`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "70px",
                        }}
                        onClick={() => setType("Admin")}
                      />
                    </div>
                    <div className="">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-info`}
                        btnName={`Coin Plan`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("coinPlan")}
                      />
                    </div>
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-success`}
                        btnName={`Coin Seller`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("coinSeller")}
                      />
                    </div>
                  </div>
                </div>
                {type === "message" ? (
                  <UserMassageHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "Call" ? (
                  <UserCallHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "Gift" ? (
                  <UserGiftHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "Admin" ? (
                  <UserAdminHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "coinPlan" ? (
                  <UserCoinPlanHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : (
                  type === "coinSeller" && (
                    <UserCoinSellerHistory
                    props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHistory;
