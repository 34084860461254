import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getTask } from "../../store/TaskTable/task.action";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import TaskDialogue from "./Dialogue/TaskDialogue";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import male from "../../../assets/images/noImage.png";
import $ from "jquery";

const Task = (props) => {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const { task } = useSelector((state) => state.task);

  useEffect(() => {
    props.getTask();
  }, []);

  useEffect(() => {
    props.getTask();
  }, [refresh]);

  useEffect(() => {
    setData(task);
  }, [task]);

  const taskTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Name", body: "name" },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <img
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Coin", body: "coin", sorting: { type: "client" } },
    {
      Header: "Description",
      body: "description",
      Cell: ({ row }) => {
        var str = row?.description;

        if (str?.length > 35) str = str?.substring(0, 35) + "...";
        return <span>{str}</span>;
      },
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "task" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
  ];

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const dispatch = useDispatch();

  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Task`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Task</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="planMain">
          <div className="coin-plan">
            <div className="coin-plan-btn">
              <div className="row ps-4 py-3">
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                  <Button
                    newClass={`themeFont  userBtn`}
                    btnColor={`btnBlackPrime`}
                    btnName={`New`}
                    btnIcon={`fa-regular fa-plus me-2 me-2`}
                    onClick={() => {
                      dispatch({
                        type: OPEN_DIALOGUE,
                        payload: { type: "Agency" },
                      });
                    }}
                    style={{
                      borderRadius: "5px",
                      marginRight: "auto",
                      width: "80px",
                    }}
                  />
                  <Button
                    newClass={`themeFont  userBtn text-white mx-3`}
                    btnColor={`btn-danger`}
                    btnIcon={`fa-solid fa-rotate-right me-2`}
                    btnName={`Refresh`}
                    onClick={() => setRefresh(refresh === true ? false : true)}
                    style={{
                      borderRadius: "5px",
                      marginRight: "auto",
                      width: "95px",
                    }}
                  />
                </div>
                {dialogue && dialogueType === "task" && <TaskDialogue />}
                <div className="col-6">
                  <Searching
                    type={`client`}
                    data={task}
                    setData={setData}
                    column={taskTable}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    button={true}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={taskTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <Pagination
              component="div"
              count={task?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={task?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getTask })(Task);
