import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import DateRangePicker from "react-bootstrap-daterangepicker";
import dayjs from "dayjs";
import Title from "../../extra/Title";
import { getAgencyHostSettlement } from "../../store/settlement/settlement.action";
import Button from "../../extra/Button";
import { Tooltip } from "@material-ui/core";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import HostInfoPage from "../Host/History/HostInfoPage";

const AgencyHostSettlement = (props) => {
  const { agencyHostSettlement, totalAgentPayable } = useSelector(
    (state) => state.settlement
  );
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { state } = useLocation();

 

  useEffect(() => {
    props.getAgencyHostSettlement(
      state?.id,
      state?.dayType,
      state?.startDate,
      state?.endDate
    );
  }, []);

  useEffect(() => {
    setData(agencyHostSettlement);
  }, [agencyHostSettlement]);

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");

    setStartDate(start);
    setEndDate(end);
    props.getAgencyHostSettlement("custom", start, end);
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker?.element.val("");
    setStartDate("");
    setEndDate("");
    props.getAgencyHostSettlement("week", "", "");
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const dispatch = useDispatch();
  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const HostAgencySettlement = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{(page) * rowsPerPage + parseInt(index)+1}</span>,
    },
    {
      Header: "Host Name",
      body: "name",
      class: "text-capitalize fs-6 fw-semibold text-info",
      Cell: ({ row }) => (
        <Tooltip title={`${row.name} Profile`} placement="bottom">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleProfile(row, "hostProfile")}
          >
            {row?.name}
          </span>
        </Tooltip>
      ),
    },
    {
      Header: "Host Coin",
      body: "hostCoin",
      class: "fs-6 fw-semibold text-success",
    },
    {
      Header: "Host Salary",
      body: "hostBasicSalary",
      class: "fs-6 fw-semibold text-primary",
    },
    {
      Header: "Revenue",
      body: "settlementCoin",
      class: "fs-6 fw-semibold text-warning",
    },
    { Header: "Total", body: "total", class: "fs-6 fw-bold text-dark " },
  ];

  const navigate = useNavigate();
  const handelPreviousPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="mainPlanTable position-relative">
      {dialogue && dialogueType === "hostProfile" && (
          <>
            <div className="mainHostHistory">
              <HostInfoPage />
            </div>
          </>
        )}
        <div className="planTable">
          <div className=" primeHeader">
            <div className="row mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Settlement Transaction`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <Button
                  btnColor={`btn-primary`}
                  newClass={`themeFont boxCent text-white`}
                  onClick={handelPreviousPage}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "50px",
                  }}
                  btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                />
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="row coin-plan-btn d-flex align-items-center px-3">
                <div className="col-6  d-flex align-items-center">
                  <div>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: "Clear",
                        },
                        maxDate: new Date(),

                        buttonClasses: ["btn btn-dark"],
                      }}
                      onApply={handleApply}
                      onCancel={handleCancel}
                    >
                      <input
                        readOnly
                        type="text"
                        class="daterange form-control float-left bg-primary text-white"
                        value="Select Date"
                        style={{ width: 120, fontWeight: 700 }}
                      />
                    </DateRangePicker>
                  </div>

                  {startDate === "" || endDate === "" ? (
                    <div className="ms-3 fs-5 text-primary fw-bold mb-2">
                      All Settlement
                    </div>
                  ) : (
                    <div className="dateShow ms-3 fs-5 text-primary fw-bold mb-2">
                      <span className="me-3">{startDate}</span>
                      <span className="me-3">To</span>
                      <span>{endDate}</span>
                    </div>
                  )}
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Title name={`Total Revenue : ` + totalAgentPayable} />
                </div>
              </div>
              <div className="primeMain border-top">

              <Table
                data={data}
                mapData={HostAgencySettlement}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
              </div>

              <Pagination
                component="div"
                count={agencyHostSettlement?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={agencyHostSettlement?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyHostSettlement })(AgencyHostSettlement);
