import axios from "axios";
import { baseURL, key } from "../../util/config";
import { setToast } from "../../util/toast";
import * as ActionType from "./host.type";
import { CLOSE_LOADER, LOADER_OPEN } from "../dialogue/dialogue.type";

// GET HOST
export const getHost =
  (
    searchHost,
    genderType,
    statusType,
    blockType,
    countryType,
    field,
    type,
    start,
    limit
  ) =>
  (dispatch) => {
    dispatch({ type: LOADER_OPEN });

 
    axios
      .get(
        `host/hostList?search=${searchHost}&gender=${genderType}&status=${statusType}&blockType=${blockType}&country=${countryType}&field=${field}&sortType=${type}&start=${start}&limit=${limit}`, 
      )

      .then((res) => {
        if (res.status) {
          dispatch({ type: ActionType.HOST_GET, payload: res.data });
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        dispatch({ type: CLOSE_LOADER });
      });
  };

// export const getHost =
//   (
//     searchHost,
//     genderType,
//     statusType,
//     blockType,
//     countryType,
//     field,
//     type,
//     start,
//     limit
//   ) =>
//   (dispatch) => {
//
//     axios
//       .get(
//         `host/hostList?search=${searchHost}&gender=${genderType}&status=${statusType}&blockType=${blockType}&country=${countryType}&field=${field}&sortType=${type}&start=${start}&limit=${limit}`
//       )
//       .then((res) => {
//
//         dispatch({ type: ActionType.HOST_GET, payload: res.data });
//       })
//       .catch((error) => console.error(error));
//   };

// CREATE UNIQUE ID
export const getUniqueId = () => (dispatch) => {
  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json", key: key },
  };

  fetch(`${baseURL}host/generateUniqueId`, request)
    .then((response) => response.json())
    .then((res) => {
      dispatch({ type: ActionType.GET_UNIQUE, payload: res.uniqueId });
    })
    .catch((error) => setToast("error", error.message));
};

// CHECK EMAIL

export const checkEmail = (email) => (dispatch) => {
  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json", key: key },
  };
  fetch(`${baseURL}host/checkHostEmail?email=${email}`, request)
    .then((response) => response.json())
    .then((res) => {
      dispatch({ type: ActionType.CHECK_EMAIL, payload: res.message });
    })
    .catch((error) => setToast("error", error.message));
};

// CREATE NEW HOST
export const createHost = (formData) => (dispatch) => {
  axios
    .post("host/createHostByAdmin", formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({ type: ActionType.HOST_CREATE, payload: res.data.host });
        setToast("success", "Host created Successfully !");
      }
    })
    .catch((error) => console.error(error));
};

// UPDATE HOST
export const updateHost = (formData, id) => (dispatch) => {
  axios
    .patch(`host/hostUpdate?position=0`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.HOST_UPDATE,
          payload: { editHost: res.data.host_, id },
        });

        setToast("success", "Host Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.message);
      console.log(error);
    });
};

// GET PROFILE
export const getHostProfile = (id) => (dispatch) => {
  axios
    .get(`host/profile?hostId=${id}`)
    .then((res) => {
      dispatch({ type: ActionType.GET_HOST_PROFILE, payload: res.data.host });
    })
    .catch((error) => setToast("error", error));
};

// UPDATE HOST COIN
export const upadteHostCoin = (val, ids) => (dispatch) => {
  axios
    .post(`user/addLessCoin`, { hostId: ids, coin: val })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPADTE_HOST_COIN,
          payload: { editHostCoin: res.data.user },
        });
        setToast("success", "Host  Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// UPDATE HOST COIN

export const updateAlertMessage = (val, id) => (dispatch) => {
  axios
    .post(`host/alertMessage?hostId=${id}&message=${val}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_HOST_ALERT_MESSAGE,
          payload: res.data.host,
        });
        setToast("success", "Host  Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

//Get Host History
export const getHostHistory =
  (hId, type, page, size, startDate, endDate, field, sortType) =>
  (dispatch) => {
    axios
      .get(
        `historyForUser?hostId=${hId}&type=${type}&start=${page}&limit=${size}&startDate=${startDate}&endDate=${endDate}&field=${field}&sortType=${sortType}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_HOST_HISTORY,
          payload: {
            history: res.data.history,
            totalCoin: res.data.totalCallCharge
              ? res.data.totalCallCharge
              : res.data.totalCoin,

            totalData: res.data.total,
          },
        });
      })
      .catch((error) => setToast("error", error));
  };

//Get Host History
export const getAllHostHistory = (start, limit, filed, sort) => (dispatch) => {
  axios
    .get(
      `hostHistory?start=${start}&limit=${limit}&filed=${filed}&sort=${sort}`
    )
    .then((res) => {
      console.log(res.data, "history>>>>>>>>>>>>>");
      dispatch({
        type: ActionType.GET_ALL_HOST_HISTORY,
        payload: {
          history: res.data.host,
          totalData: res.data.historyCount,
        },
      });
    })
    .catch((error) => setToast("error", error));
};
//Get Host Live History
export const getHostLiveHistory =
  (hId, type, page, size, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `liveStreaming?hostId=${hId}&type=${type}&start=${page}&limit=${size}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_HOST_LIVE_HISTORY,
          payload: {
            history: res.data.history,
            totalCoin: res.data.totalCoin,
            totalDuration: res.data.totalDuration,
            totalData: res.data.total,
          },
        });
      })
      .catch((error) => setToast("error", error));
  };

//Get Host Live History
export const getHostTaskHistory =
  (hId, page, size, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `task/history?hostId=${hId}&start=${page}&limit=${size}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_HOST_TASK_HISTORY,
          payload: {
            history: res.data.history,
            totalCoin: res.data.totalCoin,
            totalData: res.data.totalHistory,
          },
        });
      })
      .catch((error) => setToast("error", error));
  };

// Host Blocked

export const hostBlocked = (host, block) => (dispatch) => {
  axios
    .put(`host/${host._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.BLOCKED_HOST,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
export const hostApproved = (host, type) => (dispatch) => {
  axios
    .put(
      `host/approved/?hostId=${
        host._id
      }&type=${type}&verifiedBy=${3}&rejectionCase=${host.reason}`
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.APPROVED_HOST,
          payload: { data: res.data.host, id: host._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            type === "Approved" ? "Approved" : "Rejected"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

// host offline
export const offlineHost = (id) => (dispatch) => {
  axios
    .post(`host/offline?hostId=${id._id}`)
    .then((res) => {
      console.log(res.data.host);
      if (res.data.status) {
        dispatch({
          type: ActionType.OFFLINE_HOST,
          payload: { data: res.data.host, id },
        });
        setToast("success", res.data.host.name + "Offline");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// host free
export const freeHost = (id) => (dispatch) => {
  axios
    .post(`host/hostCallFree?hostId=${id}`)
    .then((res) => {
      console.log(res.data.host);
      if (res.data.status) {
        dispatch({
          type: ActionType.FREE_HOST,
          payload: { data: res.data.host, id },
        });
        setToast("success", res.data.host.name + "Free");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const enableCallCutBYAdmin = (host) => (dispatch) => {
  axios
    .put(`host/cutCall/${host?._id}`)
    .then((res) => {
      console.log(res.data.host);
      if (res.data.status) {
        dispatch({
          type: ActionType.ENABLE_CALL_CUT_HOST,
          payload: { data: res.data.host, id: host?._id },
        });
        setToast(
          "success",
          `${host.name} Is ${
            host.enableCallCut === true ? "Enable Call Cut " : "Call Cut"
          } Successfully!`
        );
      }
    })
    .catch((error) => console.log(error));
};
