import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./commission.type";

// get commission

export const getCommission = () => (dispatch) => {
  axios
    .get(`commission`)
    .then((res) => {
      console.log(res.data.commission);
      dispatch({
        type: ActionType.GET_COMMISSION,
        payload: res.data.commission,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

// create commission

export const createCommission = (data) => (dispatch) => {
  axios
    .post(`commission`, data)
    .then((res) => {
      console.log(res.data.commission);
      if (res.data.status) {
        dispatch({
          type: ActionType.CREATE_COMMISSION,
          payload: res.data.commission,
        });
        setToast("success", "commission Create successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// update commission

export const updateCommission = (data, id) => (dispatch) => {
  axios
    .patch(`commission?commissionId=${id}`, data)
    .then((res) => {
      console.log(res.data.commission);
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_COMMISSION,
          payload: { data: res.data.commission, id },
        });
        setToast("success", "commission Update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
};

// delete commission

export const deleteCommission = (id) => (dispatch) => {
  axios
    .delete(`commission?commissionId=${id}`)
    .then((res) => {
      ;
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_COMMISSION,
          payload: id,
        });
        setToast("success", "commission Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
};
