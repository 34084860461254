import React, { useEffect, useState } from "react";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { Typography } from "@material-ui/core";
import $ from "jquery";
import Button from "../../extra/Button";
import { editAgency, addAgency } from "../../store/agencyTable/agency.action";
import male from "../../../assets/images/maleImage.png";

const AgencyDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    name: "",
    imagePath: "",
    code: "",
    mobileNo: "",
    password: "",
  });


  
  useEffect(() => {
    if (dialogueData) {
      setPassword(dialogueData.password);
      setName(dialogueData.name);
      setCode(dialogueData.code);
      setMobileNo(dialogueData.mobileNo);
      setImagePath(dialogueData.image);
    }
  }, [dialogue]);

  useEffect(
    () => () => {
      setName("");
      setPassword("");
      setCode(0);
      setMobileNo(0);
      setImagePath("");
      setError({ name: "", code: "", mobileNo: "", imagePath: "", image: [] });
    },
    [dialogue]
  );

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (
      !name ||
      !code ||
      code < 0 ||
      !password ||
      !mobileNo ||
      mobileNo < 0 ||
      !imagePath
    ) {
      const error = {};
      if (!name) error.name = "Name Is Required !";
      if (!password) error.password = "password Is Required !";
      if (!code) error.code = "Code Is Required !";
      if (code < 0) error.code = "Invalid Code!";
      if (!mobileNo) error.mobileNo = "Mobile No Is Required !";
      if (mobileNo < 0) error.mobileNo = "Invalid Mobile No!";
      if (!imagePath) error.image = "Image is required !";
      return setError({ ...error });
    } else {
      const mobileNoValid = isNumeric(mobileNo);

      if (!mobileNoValid) {
        return setError({
          ...error,
          mobileNo: "Mobile Number must be ten digit!",
        });
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("password", password);
        formData.append("code", code);
        formData.append("image", image);
        formData.append("mobileNo", mobileNo);

        if (dialogueData) {
          props.editAgency(dialogueData._id, formData);
        } else {
          props.addAgency(formData);
        }
        dispatch({ type: CLOSE_DIALOGUE });
      }
    }
  };

  // mobile number validation
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d{10}$/.test(val);
    return validNumber;
  };
  $("input[type=number]").on("mousewheel", function (e) {
    $(e.target).blur();
  });

  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const dispatch = useDispatch();

  const handleCreateCode = () => {
    let generateRandomCode = Math.floor(Math.random() * 900000) + 100000;
    setCode(generateRandomCode);
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Agency" : "Create Agency"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  autocomplete="off"
                  label={`Agency Name`}
                  id={`agencyName`}
                  placeholder={`Agency Name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Agency Mobile No`}
                  id={`mobileNo`}
                  placeholder={`96512-xxxxx`}
                  type={`number`}
                  value={mobileNo}
                  errorMessage={error.mobileNo && error.mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        mobileNo: `mobileNo Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        mobileNo: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div className="form-group mb-0">
                  <p className="form-label fw-bold">Password</p>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group" id="Password-toggle">
                    <a
                      href={() => false}
                      class="input-group-text"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        id="showPassword"
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={showPassword}
                      ></i>
                    </a>
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      className="form-control"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (!e.target.value)
                          return setError({
                            ...error,
                            password: "password Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            password: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  {error.password && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.password}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div className="form-group mb-0">
                  <p className="form-label fw-bold">Code</p>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group" id="code-toggle">
                    {!dialogueData && (
                      <a
                        href={() => false}
                        class="input-group-text"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          id="showPassword"
                          class="fas fa-info-circle "
                          aria-hidden="true"
                          onClick={handleCreateCode}
                        ></i>
                      </a>
                    )}
                    <input
                      type="number"
                      id="code"
                      readOnly="readOnly"
                      placeholder="code"
                      className="form-control"
                      autoComplete="new-password"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                        if (!e.target.value)
                          return setError({
                            ...error,
                            code: "Code Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            code: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  {error.code && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.code}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Code`}
                  id={`Code`}
                  type={`number`}
                  placeholder={`123432`}
                  value={code}
                  readOnly={dialogueData && `readOnly`}
                  errorMessage={error.code && error.code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        code: `Code Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        code: "",
                      });
                    }
                  }}
                />
              </div> */}
              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Agency Image</p>
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { editAgency, addAgency })(AgencyDialogue);
