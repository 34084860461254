import axios from "axios";
import * as ActionType from "./type";

export const getDashBoard = (sDate, eDate) => (dispatch) => {
  let startDate = sDate == "" ? "ALL" : sDate;
  let endDate = eDate == "" ? "ALL" : eDate;
 
  axios
    .get(`dashboard/admin?startDate=${startDate}&endDate=${endDate}`)
    .then((res) => {
      dispatch({ type: ActionType.GET_DASHBOARD, payload: res.data.dashboard });
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getAppData = () => (dispatch) => {
  axios
    .get("data")
    .then((res) => {
      console.log("=========", res.data.data);
      dispatch({ type: ActionType.APP_DATA, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};
export const getDashBoardChart = (startDate, endDate) => (dispatch) => {
  axios
    .get(`dashboard/chart?startDate=${startDate}&endDate${endDate}`)
    .then((res) => {
      // console.log("+++++",res.data);
      dispatch({
        type: ActionType.GET_DASHBOARD_CHART,
        payload: res.data.dashboard,
      });
    })
    .catch((error) => console.log(error));
};
export const resetData = () => (dispatch) => {
  axios
    .get(`dashboard/reset`)
    .then((res) => {
      // console.log("+++++",res.data);
      dispatch({ type: ActionType.APP_DATA, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};
