// GET AGENCY
export const GET_AGENCY = "GET_AGENCY";

// ADD AGENCY
export const ADD_AGENCY = "ADD_AGENCY";

// EDIT AGENCY
export const EDIT_AGENCY = "EDIT_AGENCY";

// DELETE AGENCY
export const DISABLE_AGENCY = "DISABLE_AGENCY";

// SWITCH
export const SWITCH = "SWITCH";

// OPEN DIALOG

export const OPEN_DIALOGUE = "OPEN_DIALOGUE";

// CLOSE DIALOG
export const CLOSE_DIALOGUE = "CLOSE_DIALOGUE";

// AGENCY_HISTORY
export const AGENCY_HISTORY = "AGENCY_HISTORY";

// AGENCY_SETTLEMENT
export const AGENCY_SETTLEMENT = "AGENCY_SETTLEMENT";

// agencyPayoutList

export const AGENCY_PAYOUT_LIST = "AGENCY_PAYOUT_LIST";

// agency Disable

export const AGENCY_DISABLE = "AGENCY_DISABLE";

// get agency wise host
export const AGENCY_WISE_HOST = "AGENCY_WISE_HOST";

// agency wise host block

export const AGENCY_WISE_BLOCK = "AGENCY_WISE_BLOCK";
