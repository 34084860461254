import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { getUserHistory } from "../../../store/users/user.action";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import male from "../../../../assets/images/maleImage.png";

const UserGiftHistory = (props) => {
  const { userHistory, totalData, totalCoin } = useSelector(
    (state) => state.user
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getUserHistory(
      props?.props?.historyId,
      "gift",
      currentPage,
      size,
      startDate,
      endDate
    );
  }, [currentPage, size, startDate, endDate, , props?.props?.data]);

  useEffect(() => {
    setData(userHistory);
  }, [userHistory]);

  const navigate = useNavigate();

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      body: "detalis",
      Cell: ({ row }) => {
        var str = row?.hostName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span className="fs-6 text-dark">Gift send to </span>

            <span
              className="text-info fw-bold fs-6"
              onClick={() => navigate("/admin/host/profile", { state: row })}
            >
              @{str}
            </span>
          </>
        );
      },
    },

    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.uCoin > 0 ? (
          <span className="text-danger fs-6 fw-bold">-{row.uCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.uCoin}</span>
        ),
    },

    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
        </div>
        <Pagination
          component="div"
          count={userHistory?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={totalData}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default connect(null, { getUserHistory })(UserGiftHistory);
