import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
  getSetting,
  updateSetting,
  updateHtml,
  handleToggleSwitch,
} from "../../store/setting/setting.action";

//Base URL
// import { baseURL } from "../../util/ServerPath";
//React
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Title from "../../extra/Title";

const GuideFunction = (props) => {
  const editor = useRef(null);
  const [guideFunctionText, setGuideFunctionText] = useState("");
  const [error, setError] = useState({
    guideFunctionText: "",
  });
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    props.getSetting();
  }, []);

  useEffect(() => {
    setGuideFunctionText(setting?.guideFunctionText);
  }, [setting]);

  const handleSubmit = () => {
    const data = {
      guideFunctionText: ` <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Guide</title>
      </head>
      <style>
        pre {
            white-space: pre-wrap;
        }
    </style>
      <body>
        ${guideFunctionText} 
        </body></html>`,
    };

    props.updateHtml(data);
  };
  console.log("--------", guideFunctionText);
  return (
    <>
      <div className=" settingHeader primeHeader mt-2">
        <div className="row ">
          <div className="col-6">
            <Title name={`Guid Function`} />
          </div>
          <div className="col-6 d-flex justify-content-end">
            <ol class="breadcrumb1 pb-1 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Guid Function </li>
            </ol>
          </div>
        </div>
      </div>
      <div
        className="mt-2"
        style={{ boxShadow: "0px 0px 15px rgba(0,0,0,.05)" }}
      >
        <SunEditor
          value={guideFunctionText}
          setContents={guideFunctionText}
          ref={editor}
          height={600}
          onChange={(e) => {
            setGuideFunctionText(e);

            if (!e) {
              return setError({
                ...error,
                guideFunctionText: "Guide Function Text is Required !",
              });
            } else {
              return setError({
                ...error,
                guideFunctionText: "",
              });
            }
          }}
          placeholder="Guide Function Text"
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],

              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              ["bold", "underline", "italic", "subscript", "superscript"],

              ["align", "list", "lineHeight"],
              ["link"],
              ["fullScreen"],
            ],
          }}
        />
      </div>
      <div className="mb-2 mt-2 text-end">
        <button
          class="btn btn-primary mt-1"
          href={() => false}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default connect(null, {
  getSetting,
  updateSetting,
  updateHtml,
  handleToggleSwitch,
})(GuideFunction);
