// import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import VIPPlanStripPayment from "./VIPPlanStripPayment";
import { useEffect } from "react";

import React, { useState } from "react";
import axios from "axios";

import logo from "../../assets/images/logo.png";
import { getPlan } from "../store/webPayment/webPayment.action";
import { key } from "../util/config";

const WebPayment = (props) => {
  const [keyValue, setKeyValue] = useState("");
  const [data, setData] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    axios.defaults.headers.common["key"] = key;
    axios
      .get("setting")
      .then((res) => {
        setKeyValue(res.data.setting);
      })
      .catch((error) => console.log(error));
    axios
      .get(`user/userById/${props.match.params.userId}`)
      .then((response) => {
        if (response.data.status) {
        } else {
          navigate("/webPayment");
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/webPayment");
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `coinPlan/byId?planId=${props.match.params.planId}&currency=${location.state.currency}`
      )
      .then((response) => {
        if (response.data.status) {
          setData({
            ...response.data.coinPlan,
            userId: props.match.params.userId,
            rate: response.data.coinPlan.rate,
            currency: location.state.currency,
            symbol: location.state.symbol,
          });
        } else {
         navigate("/webPayment");
        }
      })
      .catch((error) => {
        console.log(error);
       navigate("/webPayment");
      });
  }, []);

  console.log("data wab payment", data);

  const PUBLIC_KEY = keyValue.stripePublishableKey;

  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  return (
    <>
      <div
        className="modal-body pt-1 px-1 "
        style={{
          background:
            "linear-gradient(164deg, rgba(228,238,255,1) 0%, rgba(235,229,255,1) 100%)",
          // background:"#e4eeff",
          height: "100vh",
        }}
      >
        <div class="ml-auto  bd-highlight  "></div>
        <div style={{ maxWidth: "270vh" }}>
          {/* <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary btn-icon px-4 me-4 m-3"
              onClick={goPrevious}
            >
              <i class="fa-solid fa-angles-left text-white fs-6"></i>
            </button>
          </div> */}
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="d-none d-sm-none d-md-none d-lg-block d-xxl-block d-xs-none d-md-none col-lg-6 col-xxl-6 ">
              <div
                className="d-flex justify-content-around"
                style={{ height: "99vh" }}
              >
                <div className="text-primary fs-1 fw-bold alight-items-start pt-4">
                  Stripe
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={logo}
                    alt="QWEE"
                    style={{ width: "17vh" }}
                    draggable="false"
                  />
                  <div className=" text-primary fw-bold text-center stripe_app_name">
                    Qwee
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
              <div className="d-flex justify-content-center stripe_card_height">
                <div
                  classNMae="card"
                  style={{
                    background:
                      "linear-gradient(160deg, rgba(230,223,255,1) 0%, rgba(227,245,255,1) 100%)",
                    borderRadius: "20px",
                    boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                    width: "470px",
                  }}
                >
                  <div cklassame="card-body">
                    <div className="row justify-content-end  m-4">
                      <div className="text-dark">
                        <div className="col-12 my-2">
                          <div className="my-2">
                            <label
                              className="stripeLabel fw-bold  "
                              style={{ fontSize: "20px" }}
                            >
                              Coin
                            </label>

                            <input
                              type="text"
                              placeholder="Coin"
                              class="StripeElement ms-0 fw-bold "
                              style={{ width: "100%", fontSize: "17px" }}
                              readOnly
                              value={data?.coin}
                            />
                          </div>
                        </div>
                        <div className="col-12 my-2">
                          <div className="my-2">
                            <label
                              className="stripeLabel fw-bold  "
                              style={{ fontSize: "20px" }}
                            >
                              Extra Coin
                            </label>

                            <input
                              type="text"
                              placeholder="Coin"
                              class="StripeElement ms-0 fw-bold "
                              style={{ width: "100%", fontSize: "17px" }}
                              readOnly
                              value={data?.extraCoin}
                            />
                          </div>
                        </div>
                        <div className="col-12 my-2">
                          <div>
                            <label
                              className="stripeLabel fw-bold  "
                              style={{ fontSize: "20px" }}
                            >
                              Amount
                            </label>

                            <input
                              type="text"
                              placeholder="Dollar"
                              class="StripeElement ms-0 fw-bold "
                              style={{ width: "100%", fontSize: "17px" }}
                              readOnly
                              value={`${data?.rate} ${data?.symbol}`}
                            />
                          </div>
                        </div>
                        
                      </div>

                      <div className="mt-4">
                        <Elements stripe={stripeTestPromise}>
                          <VIPPlanStripPayment data={data} />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {  getPlan })(WebPayment);
