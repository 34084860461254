import { Link } from "react-router-dom";

const Title = (props) => {
  return (
    <>
      <div className="title-head title-head mt-3">
        <div className="title">
          <h3 className=" fw-bold">{props.name}</h3>
        </div>
        {/* <div className="titlePath mb-0">
          <span>
            <Link className="primeColor" to="/admin/dashboard">
              DashBoard
            </Link>
          </span>
          <span> / {props.name} </span>
        </div> */}
      </div>
    </>
  );
};

export default Title;
