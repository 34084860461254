import { Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

const Navigator = (props) => {
  const location = useLocation();

  const { name, path, navIcon, onClick } = props;

  if (!Array.isArray(props.children) && props.children) {
    var { subName, subPath  } = props.children.props;
  }



  return (
    <ul className="mainMenu">
      <li onClick={onClick}>
        <Tooltip title={name} placement="right">
          <Link
            to={{ pathname: path }}
            className={`${location.pathname === path && "activeMenu"}`}
          >
            <div>
              <i className={navIcon}></i>
              <span className="text-capitalize ">{name}</span>
            </div>
            {props?.children && <i class="fa-solid fa-angle-right"></i>}
          </Link>
        </Tooltip>
        {/* If Submenu */}
        {props.children && (
          <>
            {Array.isArray(props.children) ? (
              <ul className={`subMenu transform0`}>
                {props.children?.map((res) => {
                  const { subName, subPath, onClick } = res?.props;
                  return (
                    <>
                      <Tooltip title={subName} placement="right">
                        <li onClick={onClick}>
                          <Link
                            to={{ pathname: subPath }}
                            className={`${
                              location.pathname === subPath && "activeMenu"
                            }`}
                          >
                            <i class="fa-solid fa-circle"></i>
                            <span>{subName}</span>
                          </Link>
                        </li>
                      </Tooltip>
                    </>
                  );
                })}
              </ul>
            ) : (
              <ul className={`subMenu transform0`}>
                <li>
                  <Link
                    to={{ pathname: subPath }}
                    className={`${
                      location.pathname === subPath && "activeMenu"
                    }`}
                  >
                    <i class="fa-solid fa-circle"></i>
                    <span className="text-capitalize">{subName}</span>
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
      </li>
    </ul>
  );
};

export default Navigator;
