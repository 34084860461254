import React, { createContext, useEffect, useState, useContext } from "react";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import {
  getHost,
  freeHost,
  offlineHost,
  hostBlocked,
  enableCallCutBYAdmin,
} from "../../store/host/host.action";
import dayjs from "dayjs";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { baseURL } from "../../util/config";
import io from "socket.io-client";
import $ from "jquery";
import male from "../../../assets/images/download.png";
import { useNavigate } from "react-router-dom";
import HostUpdateDialogue from "./HostUpdateDialogue";
import HostMessageDialogue from "./HostMessageDialogue";
import { Tooltip } from "@material-ui/core";
import HostHistory from "./HostHistory";
import HostInfoPage from "./History/HostInfoPage";
import useLocalStorageValue from "../../extra/useLocalStorageValue";
import axios from "axios";
import { ADMIN_SEND_MESSAGE_DIALOGUE_OPEN } from "../../store/message/message.type";

const Host = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const { messageData } = useSelector((state) => state.messageData);

  const { allCountry } = useSelector((state) => state.admin);
  const { host, totalHost } = useSelector((state) => state.host);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [genderType, setGenderType] = useState("");

  const [page, setPage] = useLocalStorageValue("pageHostTable", 1);
  const [size, setSize] = useLocalStorageValue("sizePageHostValue", 10);
  const [currentPage, setCurrentPage] = useLocalStorageValue(
    "currentPageHostTable",
    1
  );
  const [rowsPerPage, setRowsPerPage] = useLocalStorageValue(
    "rowParPageHostValue",
    10
  );
  const [search, setSearch] = useLocalStorageValue("searchHost", "");
  const [statusType, setStatusType] = useLocalStorageValue(
    "statusTypeHost",
    ""
  );
  const [blockType, setBlockType] = useLocalStorageValue("blockTypeHost", "");
  const [countryType, setCountryType] = useLocalStorageValue(
    "countryTypeHost",
    ""
  );
  const [type, setType] = useLocalStorageValue("typeHost", "0");
  const [field, setField] = useLocalStorageValue("filedTypeHost", "createdAt");

  const handleChildValue = (value) => {
    setType(type == 0 ? 1 : 0);
    setField(value);
  };

  useEffect(() => {
    props.getHost(
      search,
      genderType,
      statusType,
      blockType,
      countryType,
      field,
      type,
      currentPage,
      size
    );
  }, [
    genderType,
    statusType,
    blockType,
    countryType,
    field,
    type,
    search,
    currentPage,
    size,
  ]);

  useEffect(() => {
    sessionStorage.setItem("rowParPageHostValue", rowsPerPage);
    sessionStorage.setItem("sizePageHostValue", size);
    sessionStorage.setItem("currentPageHostTable", currentPage);
    sessionStorage.setItem("pageHostTable", page);
    sessionStorage.setItem("typeHost", type);
    sessionStorage.setItem("filedTypeHost", field);
    sessionStorage.setItem("searchHost", search);
    sessionStorage.setItem("genderTypeHost", genderType);
    sessionStorage.setItem("blockTypeHost", blockType);
    sessionStorage.setItem("statusTypeHost", statusType);
    sessionStorage.setItem("countryTypeHost", countryType);
  }, [
    genderType,
    statusType,
    blockType,
    countryType,
    field,
    type,
    search,
    rowsPerPage,
    currentPage,
    page,
    size,
  ]);

  useEffect(() => {
    setPage(1);
    setRowsPerPage(10);
    setCurrentPage(1);
    setSize(10);
  }, [statusType, blockType, countryType, field, type, search]);

  useEffect(() => {
    setData(host);
  }, [host]);

  useEffect(() => {
    props.getHost(
      search,
      genderType,
      statusType,
      blockType,
      countryType,
      field,
      type,
      currentPage,
      size
    );
  }, [refresh]);

  const handleClick = (id) => {
    props.hostBlocked(id, id.isBlock === true ? false : true);
  };

  const handleSwitchOffline = (id) => {
    id.isOnline && props.offlineHost(id);
  };

  const handleEnableCallCut = (host) => {
    props.enableCallCutBYAdmin(host);
  };

  const allReset = () => {
    $("#search").val("");
    setStatusType("");
    setCountryType("");
    setBlockType("");
    setType("0");
    setPage(1);
    setRowsPerPage(10);
    setCurrentPage(1);
    setSize(10);
    setField("createdAt");
    setSearch("");
    sessionStorage.removeItem("rowParPageHostValue");
    sessionStorage.removeItem("sizePageHostValue");
    sessionStorage.removeItem("currentPageHostTable");
    sessionStorage.removeItem("pageHostTable");
    sessionStorage.removeItem("hostPageTypeHost");
    sessionStorage.removeItem("hostRowPerPageTypeHost");
    sessionStorage.removeItem("typeHost");
    sessionStorage.removeItem("filedTypeHost");
    sessionStorage.removeItem("searchHost");
    sessionStorage.removeItem("statusTypeHost");
    sessionStorage.removeItem("countryHost");
    sessionStorage.removeItem("blockTypeHost");
    props.getHost("", "", "", "", "", "createdAt", "0", 1, 10);
  };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleBusy = (data) => {
    data.isBusy && props.freeHost(data?._id);
  };

  const handelMessageDialogue = (data) => {
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { id: data?._id, type: "host" },
    });
  };

  const hostTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Unique Id", body: "uniqueId" },
    {
      Header: "Name",
      body: "name",
      class: "text-capitalize",
      sorting: { type: "server" },
    },

    {
      Header: "Avatar",

      body: "image",
      Cell: ({ row }) => (
        <img
          draggable={false}
          src={row.profileImage}
          className={`table-image`}
          height={60}
          width={60}
          alt=""
          onClick={() => handleProfile(row, "hostProfile")}
        />
      ),
    },
    {
      Header: "Agency Name",
      body: "agencyId",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.agencyId?.name}</span>
      ),
    },
    // { Header: "Email", body: "email" } AS agencyWise Host
    {
      Header: "Coin",
      body: "coin",
      Cell: ({ row }) => <span>{row.coin}</span>,
      sorting: { type: "server" },
    },
    { Header: "Call Charge", body: "callCharge", sorting: { type: "server" } },
    {
      Header: "Level",
      body: "level",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.level ? row.level.name : "Level 0"}
        </span>
      ),
      sorting: { type: "server" },
    },
    { Header: "App Version", body: "appVersion" },
    {
      Header: "Country",
      body: "country",

      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.country == "" ? "india" : row?.country}
        </span>
      ),
    },

    {
      Header: "Disable Host",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Enable Call Cut",
      body: "enableCallCut",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.enableCallCut}
          onClick={() => handleEnableCallCut(row)}
        />
      ),
    },
    {
      Header: "Approve Status",
      Cell: ({ row }) => (
        <span
          className={` p-2 fw-bold ${
            row?.isApproved
              ? "badge badge-success"
              : row?.isRejected
              ? "badge badge-danger fw-bold"
              : row?.verificationVideo
              ? "badge badge-warning fw-bold"
              : "badge badge-secondary fw-bold"
          } `}
        >
          {row?.isApproved
            ? "Approved"
            : row?.isRejected
            ? "Rejected"
            : row?.verificationVideo
            ? "Pending"
            : "Video not upload"}
        </span>
      ),
    },
    {
      Header: "App Restart",
      body: "app restart",
      Cell: ({ row }) => (
        <Button
          newClass={`badge badge-secondary mt-2`}
          btnName={`App Restart`}
          onClick={() => appRestart(row?._id)}
          style={{
            border: "1px solid rgb(225 18 18)",
            fontSize: "12px",
            backgroundColor: "#e97474",
            width: "90px",
            borderRadius: "5px",
          }}
        />
      ),
    },

    {
      Header: "Online host",
      body: "online",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={
            row?.isOnline
              ? "badge badge-success mt-2"
              : "badge badge-danger disabled"
          }
          btnName={row?.isOnline ? "Online" : "Offline"}
          onClick={() => handleSwitchOffline(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "80px" }}
        />
      ),
    },
    {
      Header: "Busy host",
      body: "busyHost",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={
            row?.isBusy
              ? "badge badge-success mt-2"
              : "badge badge-danger disabled"
          }
          btnName={row?.isBusy ? "Busy" : "Free"}
          onClick={() => {
            handleBusy(row);
          }}
          style={{ borderRadius: "5px", margin: "auto", width: "60px" }}
        />
      ),
    },
    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <Tooltip title="Action" placeholder="bottom">
          <div class="btn-group mt-2 mb-2 mx-2 ">
            <i
              class="fa-solid fa-ellipsis-vertical fs-4 p-3"
              data-bs-toggle="dropdown"
            ></i>

            <ul class="dropdown-menu my-1" role="menu">
              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  onClick={() =>
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { data: row, type: "hostEdit" },
                    })
                  }
                >
                  <i class="fa-solid fa-pencil pe-2  fs-6"></i>
                  <span> Edit</span>
                </a>
              </li>
              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  href={() => false}
                  onClick={() => handleProfile(row, "hostProfile")}
                >
                  <i class="fas fa-info-circle pe-2 fs-6"></i>
                  <span> Profile</span>
                </a>
              </li>
              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  href={() => false}
                  onClick={() => handleProfile(row, "hostHistory")}
                >
                  <i class="fas fa-history pe-2 fs-6"></i>
                  <span>History</span>
                </a>
              </li>

              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  href={() => false}
                  onClick={() => handelMessageDialogue(row)}
                >
                  <i class="fa-solid fa-envelope pe-2  fs-6"></i>
                  <span> Message</span>
                </a>
              </li>
            </ul>
          </div>
        </Tooltip>
      ),
    },
  ];

  const appRestart = (id) => {
    const socket = io(baseURL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.emit("appRestart", { hostId: id });
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className={`mainPlanTable position-relative `}>
        {dialogue && dialogueType === "hostHistory" && (
          <>
            <div className="mainHostHistory">
              <HostHistory />
            </div>
          </>
        )}
        {dialogue && dialogueType === "hostProfile" && (
          <>
            <div className="mainHostHistory">
              <HostInfoPage />
            </div>
          </>
        )}
        <div
          className="planTable px-3"
          style={{
            display: `${
              dialogueType === "hostProfile"
                ? "none"
                : dialogueType === "hostHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="primeHeader mt-1">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Host`} />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Host </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain ">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                    <Button
                      newClass={`themeFont  userBtn`}
                      btnColor={`btnBlackPrime`}
                      btnName={`New`}
                      btnIcon={`fa-regular fa-plus me-2 me-2`}
                      onClick={() => {
                        navigate("/admin/host/hostInsertPage");
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "80px",
                      }}
                    />
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-info btn-pill dropdown-toggle text-white border-0"
                        data-bs-toggle="dropdown"
                      >
                        {statusType ? (
                          <span class="caret">{statusType}</span>
                        ) : (
                          <span class="caret text-capitalize ">Status</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("online");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "online"
                              );
                            }}
                          >
                            Online
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("offline");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "offline"
                              );
                            }}
                          >
                            Offline
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("live");
                              localStorage.setItem("statusTypeHost", "live");
                            }}
                          >
                            isLive
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("busy");
                              sessionStorage.setItem("statusTypeHost", "busy");
                            }}
                          >
                            isBusy
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("pending");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "pending"
                              );
                            }}
                          >
                            Pending
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("rejected");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "rejected"
                              );
                            }}
                          >
                            Rejected
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("approved");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "approved"
                              );
                            }}
                          >
                            Approved
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("");
                              sessionStorage.setItem("statusTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-warning btn-pill dropdown-toggle text-capitalize text-white"
                        data-bs-toggle="dropdown"
                      >
                        {blockType ? (
                          <span class="caret text-capitalize">{blockType}</span>
                        ) : (
                          <span class="caret ">Blocked</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("block");
                              sessionStorage.setItem("blockTypeHost", "block");
                            }}
                          >
                            Block Host
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("unblock");
                              sessionStorage.setItem(
                                "blockTypeHost",
                                "unblock"
                              );
                            }}
                          >
                            Unblock Host
                          </a>
                        </li>
                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockType("");
                              sessionStorage.setItem("blockTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-success btn-pill dropdown-toggle text-white"
                        data-bs-toggle="dropdown"
                      >
                        {countryType ? (
                          <span class="caret">{countryType}</span>
                        ) : (
                          <span class="caret text-capitalize">Country</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        {allCountry?.map((res) => {
                          return (
                            <>
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className="dropdown-option-text text-capitalize"
                                  href={() => false}
                                  onClick={() => {
                                    setCountryType(res.name.toLowerCase());
                                    sessionStorage.setItem(
                                      "countryTypeHost",
                                      res.name.toLowerCase()
                                    );
                                  }}
                                >
                                  {res.name.toLowerCase()}
                                </a>
                              </li>
                            </>
                          );
                        })}

                        <li style={{ cursor: "pointer" }}>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setCountryType("");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-dark text-white"
                        onClick={allReset}
                      >
                        <span class="caret">Reset All</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      type={`server`}
                      data={host}
                      setData={setData}
                      column={hostTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      setSearchValue={setSearch}
                      searchValue={search}
                    />
                  </div>
                </div>
              </div>
              {dialogue && dialogueType === "hostEdit" && (
                <HostUpdateDialogue />
              )}

              {messageData && <HostMessageDialogue />}
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={hostTable}
                serverPerPage={rowsPerPage}
                serverPage={page}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <Pagination
              component="div"
              count={host?.length}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={totalHost}
              serverPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHost,
  freeHost,
  offlineHost,
  hostBlocked,
  enableCallCutBYAdmin,
})(Host);
