import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import Button from "../../extra/Button";
import {
  getAgency,
  deleteAgency,
  agencySwitch,
} from "../../store/agencyTable/agency.action";
import dayjs from "dayjs";
import { setToast } from "../../util/toast";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import AgencyDialogue from "./AgencyDialogue";
import male from "../../../assets/images/maleImage.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import NotificationDialogue from "../../Pages/NotificationDialogue";
import AgencySettlement from "./AgencySettlement";
import AgencyHistory from "./AgencyHistory";

const Agency = (props) => {
  const { agency } = useSelector((state) => state.agency);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [data, setData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);
  const [agnId, setAgnId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getAgency();
  }, []);
  useEffect(() => {
    props.getAgency();
  }, [refresh]);

  useEffect(() => {
    setData(agency);
  }, [agency]);
  // handle Code  Copy
  const copyCode = (code) => {
    var copyText = document.getElementById("myInput");

    navigator.clipboard.writeText(code);
    setToast("success", `Code Copy : ${code}`);
  };

  const navigate = useNavigate();

  const handleDisbale = (data) => {
    props.deleteAgency(data, data?.isDisable === true ? false : true);
  };

  const handleSwitch = (data, type) => {
    const available =
      type === "random" ? !data.forRandomCall : !data.forLiveStreaming;
    props.agencySwitch(data, type, available);
  };

  const handelSettlement = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const dispatch = useDispatch();
  const agencyTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      class: "text-capitalize",
      Cell: ({ row }) => (
        <span
          onClick={() => navigate("/admin/agency/agencyHost", { state: row })}
        >
          {row.name}
        </span>
      ),
    },
    {
      Header: "Code",
      body: "code",
      Cell: ({ row }) => (
        <span>
          <i
            class="fas fa-copy p-2 fs-6 copyCode"
            style={{ cursor: "pointer" }}
            onClick={() => copyCode(row?.code)}
          ></i>
          <span>{row?.code}</span>
        </span>
      ),
    },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
          draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Mobile number", body: "mobileNo" },
    {
      Header: "Create  Date",
      body: "createdAt",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Disable Agency",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isDisable}
          onClick={() => handleDisbale(row)}
        />
      ),
    },
    {
      Header: "Live Streaming",
      body: "forLiveStreaming",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.forLiveStreaming}
          onClick={() => handleSwitch(row, "live")}
        />
      ),
    },
    {
      Header: "Random Match ",
      body: "forRandomCall",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.forRandomCall}
          onClick={() => handleSwitch(row, "random")}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "Agency" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "Notification",
      body: "notification",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-secondary`}
            btnIcon={`fa-solid fa-bell fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "NotificationAgency" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "History",
      body: "history",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-success`}
          btnIcon={`fas fa-history px-2 text-white fs-6`}
          onClick={() => handelSettlement(row, "agencyHistory")}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "	Settlement",
      body: "	Settlement",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-primary`}
          btnIcon={`fas fa-folder px-2 text-white fs-6`}
          onClick={() => handelSettlement(row, "agencySettlement")}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable position-relative">
        {dialogue && dialogueType === "agencyHistory" && (
          <>
            <div className="mainHostHistory">
              <AgencyHistory />
            </div>
          </>
        )}
        {dialogue && dialogueType === "agencySettlement" && (
          <>
            <div className="mainHostHistory">
              <AgencySettlement />
            </div>
          </>
        )}
        <div
          className="planTable"
          style={{
            display: `${
              dialogueType === "agencySettlement"
                ? "none"
                : dialogueType === "agencyHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="primeHeader mt-1">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Agency`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 ">
                  <li class="breadcrumb-item1">
                    <p className="pb-0" to="/admin/dashboard">
                      Dashboard
                    </p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Agency </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6">
                    <Button
                      newClass={`themeFont  userBtn`}
                      btnColor={`btnBlackPrime`}
                      btnName={`New`}
                      btnIcon={`fa-regular fa-plus me-2 me-2`}
                      onClick={() => {
                        dispatch({
                          type: OPEN_DIALOGUE,
                          payload: { type: "Agency" },
                        });
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "80px",
                      }}
                    />
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      type={`client`}
                      data={agency}
                      setData={setData}
                      column={agencyTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
                {dialogue && dialogueType === "Agency" && <AgencyDialogue />}
                {dialogue && dialogueType === "NotificationAgency" && (
                  <NotificationDialogue />
                )}
              </div>
            </div>

            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={agencyTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>

            <Pagination
              component="div"
              count={data?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={data?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgency, deleteAgency, agencySwitch })(Agency);
