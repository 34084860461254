import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import moment from "moment";
import { useLocation } from "react-router-dom";

function HostAdminHistory(props) {
  const { hostHistory, totalData, totalCoin } = useSelector(
    (state) => state.host
  );

  const { state } = useLocation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("0");
  const [filed, setField] = useState("");

  useEffect(() => {
    props.getHostHistory(
      state?.id ? state?.id : props?.props,
      "admin",
      currentPage,
      size,
      state?.startDate ? state?.startDate : startDate,
      state?.endDate ? state?.endDate : endDate,
      filed, type
    );
  }, [currentPage, size, startDate, endDate ,filed, type]);

  useEffect(() => {
    setData(hostHistory);
  }, [hostHistory]);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span className="fw-semibold ,fs-6">{(page) * rowsPerPage + parseInt(index)+1}</span>
      ),
    },

    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "server" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-danger fs-6 fw-bold">{row.hCoin}</span>
        ),
    },

    {
      Header: "Date",
      body:"date",sorting:{type:"server"},
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="fw-semibold fs-6">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="fw-semibold fs-6">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        {" "}
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
      <div className="primeMain border-top">


          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
            onChildValue={handleChildValue}
          />
                 </div>
          <Pagination
            component="div"
            count={data?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalData}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
 
      </div>
    </>
  );
}

export default connect(null, { getHostHistory })(HostAdminHistory);
