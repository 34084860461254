import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  hostRequest,
  hostAccept,
} from "../../store/hostRequest/hostRequest.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import dayjs from "dayjs";
import HostRequestDialogue from "./Dialgue/HostRequestDialogue";
import Searching from "../../extra/Searching";
import $ from "jquery";
import male from "../../../assets/images/maleImage.png";

const HostRequest = (props) => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { hostRequest } = useSelector((state) => state.hostRequest);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    props.hostRequest();
  }, []);
  useEffect(() => {
    props.hostRequest();
  }, [refresh]);

  useEffect(() => {
    setData(hostRequest);
  }, [hostRequest]);

  const handleClick = (id) => {
    props.hostAccept(id);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const hostRequestTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Name", body: "name" },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    {
      Header: "Sub agent Name",
      body: "name",
      Cell: ({ row }) => (
        <span>{row?.subAgentId?.name ? row?.subAgentId?.name : "-"}</span>
      ),
    },
    { Header: "Country", body: "country" },
    {
      Header: "Created At",
      body: "created At",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },

    {
      Header: "Is Accepted",
      body: "enableCallCut",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isAccepted}
          onClick={() => handleClick(row._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "hostRequest" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const dispatch = useDispatch();

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Host Request`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Host Request</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-6 ">
                    <div>
                      <Button
                        newClass={`themeFont  userBtn text-white mx-3`}
                        btnColor={`btn-danger`}
                        btnIcon={`fa-solid fa-rotate-right me-2`}
                        btnName={`Refresh`}
                        onClick={() =>
                          setRefresh(refresh === true ? false : true)
                        }
                        style={{
                          borderRadius: "5px",
                          marginRight: "auto",
                          width: "95px",
                        }}
                      />
                      {dialogue && dialogueType === "hostRequest" && (
                        <HostRequestDialogue />
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <Searching
                      button={true}
                      type={`client`}
                      data={hostRequestTable}
                      setData={setData}
                      column={Table}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={hostRequestTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <Pagination
              component="div"
              count={hostRequest?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={hostRequest?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { hostRequest, hostAccept })(HostRequest);
