import React, { useEffect, useState } from "react";
import Table from "../../extra/Table";
import Searching from "../../extra/Searching";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  agencyRequestAccept,
  agencyRequest,
} from "../../store/agencyRequest/agencyRequest.action";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import AgencyRequestEditDialoge from "./AgencyRequestEditDialoge";
import dayjs from "dayjs";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Title from "../../extra/Title";
import Pagination from "../../extra/Pagination";
import male from "../../../assets/images/maleImage.png";
import $ from "jquery";

const AgencyRequest = (props) => {
  const { agencyRequest } = useSelector((state) => state.agencyRequest);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    props.agencyRequest();
  }, []);

  useEffect(() => {
    props.agencyRequest();
  }, [refresh]);

  useEffect(() => {
    setData(agencyRequest);
  }, [agencyRequest]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleClick = (id) => {
    props.agencyRequestAccept(id);
  };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  const agencyRequestTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Name", body: "name" },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Email", body: "email" },
    { Header: "Mobile No", body: "mobileNo" },

    {
      Header: "Created At",
      body: "created At",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },

    {
      Header: "Is Accepted",
      body: "enableCallCut",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isAccepted}
          onClick={() => handleClick(row._id)}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "agencyRequest" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="mainTable">
        <div className="planTable">
          <div className="primeHeader mt-1">
            <div className="row align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`Agency Request`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Host Request</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="coin-plan">
            <div className="coin-plan-btn">
              <div className="row ps-4 py-3">
                <div className="col-6 ">
                  <div>
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />
                    {dialogue && dialogueType === "agencyRequest" && (
                      <AgencyRequestEditDialoge />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <Searching
                    type={`client`}
                    button={true}
                    data={agencyRequestTable}
                    setData={setData}
                    column={Table}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    setSearchValue={setSearch}
                    searchValue={search}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="primeMain border-top">
            <Table
              data={data}
              mapData={agencyRequestTable}
              PerPage={rowsPerPage}
              Page={page}
              type={"client"}
            />
            <Pagination
              component="div"
              count={agencyRequest?.length}
              serverPage={page}
              type={"client"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={agencyRequest?.length}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { agencyRequest, agencyRequestAccept })(
  AgencyRequest
);
