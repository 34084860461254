import * as actionType from "./permission.type";

//Initial State
const initialState = {
  permission: [],
  permissionDialog: false,
  permissionDialogData: null,
};

export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Permission
    case actionType.GET_PERMISSION:
      return {
        ...state,
        permission: action.payload,
      };

    //Create Permission
    case actionType.CREATE_PERMISSION:
      const data = [...state.permission];
      data.unshift(action.payload);
      return {
        ...state,
        permission: data,
      };

    //Update Permission
    case actionType.UPDATE_PERMISSION:
      return {
        ...state,
        permission: state.permission.map((data) =>
          data._id === action.payload.id ? action.payload.permission : data
        ),
      };

    case actionType.ACTIVE_PERMISSION:
      return {
        ...state,
        permission: state.permission.map((perData) => {
          if (perData._id === action.payload.id)
            return action.payload.data;
          else return perData;
        }),
      };

    //Delete Permission
    case actionType.DELETE_PERMISSION:
      return {
        ...state,
        permission: state.permission.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    //Open Permission Dialog
    case actionType.OPEN_PERMISSION_DIALOG:
      return {
        ...state,
        permissionDialog: true,
        permissionDialogData: action.payload || null,
      };

    //Close Permission Dialog
    case actionType.CLOSE_PERMISSION_DIALOG:
      return {
        ...state,
        permissionDialog: false,
        permissionDialogData: null,
      };

    default:
      return state;
  }
};
