import React from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import $ from "jquery";

import { useMemo } from "react";

import useResponsiveFontSize from "./useResponsiveFontSize";
import { useState } from "react";

import { useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { key } from "../util/config";
import { setToast } from "../util/toast";


const VIPPlanStripPayment = (props) => {
  // const value = props.data[0] || [];
  console.log("props in vip plan stripe", props.data);
  const [userId, setUserId] = useState("");

  const stripe = useStripe();
const navigate =useNavigate()
  const elements = useElements();

  const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(() => ({
      style: {
        base: {
          width: "fit-content",
          borderRadius: "8px",
          background: "rgba(228, 228, 228, 0.3)",
          border: "2px solid transparent",
          fontSize: "17px",
          fontWeight: "600",
          color: "#000",
          transition: "all 0.25s",
          "::placeholder": {
            color: "#9c9797",
          },
        },
        invalid: {
          color: "#eb1c26",
        },
      },
    }));

    return options;
  };

  // console.log("planId :", props?.data?._id);

  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    stripePaymentMethodHandler(result);
  };

  const options = useOptions();
  const stripePaymentMethodHandler = async (result) => {
    // const paymentData = { token: token.id };
    if (result.error) {
      // Show error in payment form
      // alert("error", result.error.message);

      setToast("error", result.error.message);
      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();
    } else {
      let data = {
        planId: props.data?._id,
        currency: props.data?.rates?.code.toLowerCase(),
        userId: props.data?.userId,
        payment_method_id: result.paymentMethod.id,
      };

      axios.defaults.headers.common["key"] = key;
      axios
        .post(`coinPlan/purchase/stripe/`, data)
        // .then(function (result) {
        //   console.log("result data", result);
        //   // Handle server response
        //   result
        //     .json()
        .then(function (json) {
          console.log("json data", json);
          handleServerResponse(json);
        })
        .catch((error) => console.error(error));
      // });
    }
  };

  function handleServerResponse(response) {
    if (response.error) {
      // Show error from server on payment form
      // alert("error", response.error.message);
      setToast("error", response.error.message);
    } else if (response.data.requires_action) {
      // Use Stripe.js to handle required card action
      stripe
        .handleCardAction(response.data.payment_intent_client_secret)
        .then(handleStripeJsResult);
    } else if (!response.data.status) {
      // Show success message
      setToast("error", response.data.error);

      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();

      $("#insufficientModal").css("display", "none");
      // setTimeout(() => {
      //   history.push("/");
      // }, [2000]);
    } else {
      // Show success message
      setToast("success", "added to your wallet!");

      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();

      $("#insufficientModal").css("display", "none");
      // setTimeout(() => {
      //   history.push("/");
      // }, [2000]);
    }
  }

  $('input[type=number]').on('mousewheel', function(e) {
    $(e.target).blur();
  });
  function handleStripeJsResult(result) {
    if (result.error) {
      // Show error in payment form
      // alert("error", result.error.message);
      setToast("error", result.error.message);
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      let data = {
        planId: props.data?._id,
        currency: props.data?.rates?.code.toLowerCase(),
        userId: props.data?.userId,
        payment_intent_id: result.paymentIntent.id,
      };
      axios.defaults.headers.common["key"] = key;
      axios
        .post(`coinPlan/purchase/stripe`, data)
        // .then(function (result) {
        //   // Handle server response
        //   result
        //     .json()
        .then(function (json) {
          console.log("second time json", json);
          handleServerResponse(json);
        })
        .catch((error) => console.error(error));
      // });
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          {/* <label
            className="stripeLabel fw-bold  "
            style={{ fontSize: "20px", color: "#000" }}
          >
            User Id
          </label>
          <div className="col-md-12 my-2 p-0">
            <input
              type="text"
              placeholder="userId"
              class="StripeElement ms-0 fw-bold "
              style={{ width: "100%", fontSize: "17px" }}
              required
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            />
          </div> */}

          <label
            className="stripeLabel fw-bold "
            style={{ fontSize: "20px", color: "#000" }}
          >
            Card number
          </label>
          <CardNumberElement options={options} className="" />
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label
                className="stripeLabel mt-3 fw-bold "
                style={{ fontSize: "20px", color: "#000" }}
              >
                Expiration date
              </label>
              <CardExpiryElement options={options} className=" " />
            </div>

            <div className="col-sm-12 col-md-6">
              <label
                className="stripeLabel mt-3 fw-bold "
                style={{ fontSize: "20px", color: "#000" }}
              >
                CVC
              </label>
              <CardCvcElement options={options} className="" />
            </div>
          </div>

          <div className="d-flex justify-content-center my-4">
            <button
              className="btn btn-primary px-4 stripe_pay_button"
              type="submit"
              disabled={!elements || !stripe ? true : false}
            >
              Pay
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default VIPPlanStripPayment;
