import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import Input from "../../../extra/Input";
import { Typography } from "@material-ui/core";
import Button from "../../../extra/Button";
import $ from "jquery";
import male from "../../../../assets/images/noImage.png";

import { updateTask, createTask } from "../../../store/TaskTable/task.action";

const TaskDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [coin, setCoin] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");

  const dispatch = useDispatch();

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData.name);
      setCoin(dialogueData.coin);
      setDescription(dialogueData.description);
      setImagePath(dialogueData.image);
    }
  }, [dialogue]);

  useEffect(
    () => () => {
      setName("");

      setCoin(0);
      setDescription("");
      setImagePath("");
      setError({
        name: "",
        coin: "",
        description: "",
        imagePath: "",
        image: [],
      });
    },
    [dialogue]
  );

  const handleSubmit = () => {
    if (!coin || !name || !description) {
      let error = {};
      if (!name) error.name = "Name is Required !";
      if (coin < 0) error.coin = "Invalid Coin!";
      if (!coin) error.coin = "Coin is Required !";
      if (!description) error.description = "Description is Required !";
      if (image.length == 0) error.image = "Image is Required !";
      return setError({ ...error });
    } else {
      const coinValid = isNumeric(coin);
      if (!coinValid) {
        return setError({
          ...error,
          coin: "Invalid Coin!",
        });
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("coin", coin);
      formData.append("image", image);
      formData.append("description", description);
      if (dialogueData) {
        formData.append("taskId", dialogueData?._id);
      }

      if (dialogueData) {
        props.updateTask(formData, dialogueData._id);
      } else {
        props.createTask(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });
  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Task" : "Create Task"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Name`}
                  id={`name`}
                  placeholder={`Name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Coin`}
                  id={`coin`}
                  placeholder={`1000`}
                  type={`number`}
                  value={coin}
                  errorMessage={error.coin && error.coin}
                  onChange={(e) => {
                    setCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        coin: `Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        coin: "",
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12 my-2">
                <div>
                  <div className="form-group mb-2">
                    <p className="form-label fw-bold mt-1">Description</p>
                  </div>
                  <textarea
                    rows="3"
                    placeholder="Welcome Message"
                    style={{
                      cursor: "auto",
                    }}
                    className="form-control mb-2 pe-1"
                    value={description}
                    errorMessage={error.description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          description: "Description Is Required!",
                        });
                      } else {
                        return setError({
                          ...error,
                          description: "",
                        });
                      }
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createTask, updateTask })(TaskDialogue);
