import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import moment from "moment";
import $ from "jquery";
import male from "../../../../assets/images/maleImage.png";
import { useLocation, useNavigate } from "react-router-dom";

const HostCallHistory = (props) => {
  const { hostHistory, totalData, totalCoin } = useSelector(
    (state) => state.host
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("0");
  const [filed, setField] = useState("");

  const { state } = useLocation();

  useEffect(() => {
    props.getHostHistory(
      state?.id ? state?.id : props?.props?.historyId,
      "call",
      currentPage,
      size,
      state?.startDate ? state?.startDate : startDate,
      state?.endDate ? state?.endDate : endDate,
      filed,
      type
    );
  }, [currentPage, size, startDate, endDate, filed, type, props?.props?.data]);

  useEffect(() => {
    setData(hostHistory);
  }, [hostHistory]);

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  useEffect(() => {
    setData(hostHistory);
  }, [hostHistory]);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  const navigate = useNavigate();

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Details",
      body: "details",
      Cell: ({ row }) => {
        var str = row?.userName;
        if (str?.length > 15) str = str.substring(0, 15) + "...";

        return (
          <>
            <span className="fw-semibold ">
              {row?.callType === "MissedCall" && (
                <i class="fa fa-phone m-1 text-danger"> </i>
              )}
              {row?.callType === "Outgoing" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
              {row?.callType === "Incoming" && (
                <i class="fa fa-phone m-1 text-success"></i>
              )}
            </span>

            <span
              className="text-primary fw-bold fs-6"
              onClick={() =>
                navigate("/admin/user/profile", { state: row?.userId })
              }
              style={{ cursor: "pointer" }}
            >
              @{str}
            </span>

            <span className="me-2 fs-6 ">[{row?.callType}]</span>
          </>
        );
      },
    },
    {
      Header: "Type",
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold">
          {row?.callType === "MissedCall" && (
            <span className="text-danger">MissedCall</span>
          )}
          {row?.callType === "Outgoing" && (
            <span class="text-success"> Outgoing</span>
          )}
          {row?.callType === "Incoming" && (
            <span class="  text-success">Incoming</span>
          )}
        </span>
      ),
    },

    {
      Header: "Duration",
      Cell: ({ row }) => (
        <span className="fw-bold fs-6">
          {row.callConnect
            ? row.callEndTime
              ? moment
                  .utc(
                    moment.duration(row?.duration, "seconds").as("milliseconds")
                  )
                  .format("HH:mm:ss")
              : " - "
            : "00:00:00"}
        </span>
      ),
    },

    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "server" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.hCoin}</span>
        ),
    },
    { Header: "Description", body: "description", class: "fw-semibold fs-6" },
    {
      Header: "End By",
      body: "callEndReason",
      Cell: ({ row }) => (
        <span className="fw-semibold  fs-6">
          {row?.callEndReason == null
            ? "-"
            : row?.callEndReason}
        </span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      sorting: { type: "server" },
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };
  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory border-top">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
            onChildValue={handleChildValue}
          />
        </div>
        <Pagination
          component="div"
          count={hostHistory?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={totalData}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default connect(null, { getHostHistory })(HostCallHistory);
