import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./notification.type";

export const sendNotification = (formData) => (dispatch) => {
  
  axios
    .post(`notification`, formData)
    .then((res) => {
      console.log(res);

      dispatch({ type: ActionType.SEND_NOTIFICATION, payload: res.data });
      setToast("success", res.data.message);

      // setToast("error", res.data.message);
    })
    .catch((error) => setToast("error", error));
};

export const particularAgencySendNotification =
  (formData, id) => (dispatch) => {
    
    axios
      .post(`notification/particularAgencyNotification/${id}`, formData)
      .then((res) => {
        if (res.data.status) {
          setToast("success", "Notification sent successfully ");
          dispatch({
            type: ActionType.CLOSE_AGENCY_NOTIFICATION_DIALOG,
          });
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => setToast("toast", error));
  };

export const particularUserSendNotification = (fromData, id) => (dispatch) => {
  
  axios
    .post(`notification/particularUserNotification/${id}`, fromData)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Notification sent successfully ");
        dispatch({ type: ActionType.CLOSE_AGENCY_NOTIFICATION_DIALOG });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
