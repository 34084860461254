import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";
import Button from "../extra/Button";
import Input from "../extra/Input";
import { Typography } from "@material-ui/core";
import {
  sendNotification,
  particularUserSendNotification,
  particularAgencySendNotification,
} from "../store/notification/notification.action";
import { CLOSE_NOTIFICATION_DIALOG } from "../store/notification/notification.type";

const NotificationDialogue = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [title, setTitle] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState("");

  useEffect(
    () => () => {
      setTitle("");
      setImagePath("");
      setDescription("");
      setNotificationType("");
      setImage([]);
      setError({
        title: "",
        description: "",
        image: "",
        type: "",
      });
    },
    [dialogue]
  );

  const dispatch = useDispatch();

  const handleSend = () => {

    if (
      !title ||
      (dialogueType === "Notification" && !notificationType) ||
      notificationType === "SelectType" ||
      !description
    ) {
      let error = {};
      if (!title) error.title = "Title Is Required!";
      if (dialogueType === "Notification") {
        if (!notificationType || notificationType === "SelectType")
          error.notificationType = "NotificationType Is Required!";
      }
      if (!description) error.description = "Description Is Required!";

      return setError({ ...error });
    } else {
      ;
      const formData = new FormData();
      formData.append("title", title);
      if (dialogueType === "Notification") {
        formData.append("notificationType", notificationType);
      }
      formData.append("description", description);
      formData.append("image", image);
      dialogueType === "Notification" && props.sendNotification(formData);
      dialogueType === "NotificationAgency" &&
        props.particularAgencySendNotification(formData, dialogueData._id);

     ( dialogueType === "NotificationUser" ||
        dialogueType == "userProfile" &&
          props.particularUserSendNotification(formData, dialogueData._id));
      handleCloseDialogue();
    }
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const handleCloseDialogue = () => {
    console.log("dialogueType", dialogueType);
    if (dialogueType === "Notification") {
      dispatch({ type: CLOSE_DIALOGUE });
    } else if (dialogueType === "userProfile") {
      dispatch({ type: CLOSE_NOTIFICATION_DIALOG });
    } else {
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Notification</div>
            <div className="closeBtn boxCenter" onClick={handleCloseDialogue}>
              <i class="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`title`}
                  id={`Title`}
                  placeholder={`Title `}
                  type={`text`}
                  value={title}
                  errorMessage={error.title && error.title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        title: `Title Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        title: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Description`}
                  id={`Description`}
                  type={`text`}
                  placeholder={`description`}
                  value={description}
                  errorMessage={error.description && error.description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        description: `Description Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        description: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {dialogueType === "Notification" && (
                <div className="col-12">
                  <label class="float-left dialog__input__title mb-2">
                    Notification Type
                  </label>
                  <select
                    name="type"
                    class="form-control form-control-line"
                    id="type"
                    value={notificationType}
                    onChange={(e) => {
                      setNotificationType(e.target.value);

                      if (e.target.value === "SelectType") {
                        return setError({
                          ...error,
                          notificationType: "NotificationType Is Required!",
                        });
                      } else {
                        return setError({
                          ...error,
                          notificationType: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="SelectType">---Select Type---</option>

                    <option value="agency">Agency</option>
                    <option value="user">User</option>
                    <option value="host">Host</option>
                  </select>
                  {error.notificationType && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.notificationType}
                      </Typography>
                    </div>
                  )}
                </div>
              )}

              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <label className="form-label "> Image(optional)</label>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Send`}
                btnColor={`btn-info text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSend}
              />

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={handleCloseDialogue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  sendNotification,
  particularUserSendNotification,
  particularAgencySendNotification,
})(NotificationDialogue);
