import * as ActionType from "./banner.type";

const initialState = {
  banner: [],
  openDialog: false,
  dialogData: null,
};
export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_BANNER:
      return {
        ...state,
        banner: action.payload,
      };
    case ActionType.OPEN_BANNER_DIALOG:
      return {
        ...state,
        openDialog: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_BANNER_DIALOG:
      return {
        ...state,
        openDialog: false,
        dialogData: null,
      };
    case ActionType.CREATE_BANNER:
      const data = [...state.banner];
      data.unshift(action.payload);
      return {
        ...state,
        banner: data,
      };

    // update banner

    case ActionType.UPDATE_BANNER:
      return {
        ...state,
        banner: state.banner.map((data) =>
          data?._id === action.payload.id ? action.payload.data : data
        ),
      };

    // delete banner
    case ActionType.DELETE_BANNER:
      return {
        ...state,
        banner: state.banner.filter((data) => data._id !== action.payload),
      };
    // update banner

    case ActionType.WEB_PURCHASE_BANNER:
      return {
        ...state,
        banner: state.banner.map((data) =>
          data?._id === action.payload.id ? action.payload.data : data
        ),
      };

    default:
      return state;
  }
};
