import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCommission,
  deleteCommission,
} from "../../store/Commission/commission.action";
import Pagination from "../../extra/Pagination";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import CommissionDialogue from "./CommissionDialogue";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import dayjs from "dayjs";
import { warning } from "../../util/Alert";

const Commission = (props) => {
  const { commission } = useSelector((state) => state.commission);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");


    

  useEffect(() => {
    props.getCommission();
  }, []);

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCommission(id);
        }
      })
      .catch((err) => console.log(err));
  };
  // Active Plan
  const handleClick = (id) => {
    props.activeInactiveSwitch(id);
  };

  useEffect(() => {
    setData(commission);
  }, [commission]);
  const dispatch = useDispatch();


  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const commissionTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Coin", body: "coin", sorting: { type: "client" } },
    {
      Header: "Slab",
      sorting: { type: "client" },
      body: "rate",
      Cell: ({ row }) => <span>{row.rate}%</span>,
    },
    {
      Header: "Create  Date",
      sorting: { type: "client" },
      body: "createdAt",
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-pen-to-square`}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "Commission" },
            })
          }
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="row align-items-center mt-3">
                <div className="col-6 title-head title-head ">
                  <Title name={`Commission`} />
                </div>
                <div className="col-6 d-flex justify-content-end ">
                  <ol class="breadcrumb1">
                    <li class="breadcrumb-item1">
                      <p to="/admin/dashboard">Dashboard</p>
                    </li>
                    <li class="breadcrumb-item1 activeItem">Commission </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-6 ">
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn`}
                        btnColor={`btnBlackPrime`}
                        btnName={`New`}
                        btnIcon={`fa-regular fa-plus me-2`}
                        onClick={() => {
                          dispatch({
                            type: OPEN_DIALOGUE,
                            payload: { type: "Commission" },
                          });
                        }}
                        style={{
                          borderRadius: "5px",
                          marginRight: "auto",
                          width: "80px",
                        }}
                      />

                      {dialogue && dialogueType === "Commission" && (
                        <CommissionDialogue />
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <Searching
                      type={`client`}
                      data={commission}
                      setData={setData}
                      column={commissionTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch} />
                  </div>
                </div>
              </div>
              <div className="primeMain border-top">

              <Table
                data={data}
                mapData={commissionTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"} 
              />
              </div>
              <Pagination
                component="div"
                count={commission?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={commission?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCommission, deleteCommission })(Commission);
