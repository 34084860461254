import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SettingBox from "../../Table/setting/SettingBox";
import Input from "../../extra/Input";
import Title from "../../extra/Title";
import {
  getSetting,
  updateSetting,
  handleToggleSwitch,
} from "../../store/setting/setting.action";

const CoinSetting = (props) => {
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    props.getSetting();
  }, []);

  const [chargeForMatchMale, setChargeForMatchMale] = useState(0);
  const [chargeForMatchFemale, setChargeForMatchFemale] = useState(0);
  const [currency, setCurrency] = useState("");
  const [chargeForMessage, setChargeForMessage] = useState(0);
  const [minPrivateCallCharge, setMinPrivateCallCharge] = useState(0);
  const [coinPerDollar, setCoinPerDollar] = useState(0);
  const [giftTax, setGiftTax] = useState(0);
  const [callTax, setCallTax] = useState(0);
  const [error, setError] = useState({
    chargeForMessage: "",
    currency: "",
    chargeForMatchFemale: "",
    chargeForMatchMale: "",
    coinPerDollar: "",
    giftTax: "",
  });

  useEffect(() => {
    setCallTax(setting?.callTax);
    setGiftTax(setting?.giftTax);
    setChargeForMessage(setting?.chargeForMessage);
    setChargeForMatchMale(setting?.chargeForMatchMale);
    setChargeForMatchFemale(setting?.chargeForMatchFemale);
    setCurrency(setting?.currency);
    setCoinPerDollar(setting?.coinPerDollar);
    setMinPrivateCallCharge(setting?.minPrivateCallCharge);
  }, [setting]);

  const handleSubmit = () => {
    if (
      callTax < 0 ||
      giftTax < 0 ||
      !currency ||
      chargeForMessage < 0 ||
      !chargeForMatchMale ||
      !chargeForMatchFemale ||
      minPrivateCallCharge < 0 ||
      coinPerDollar < 0
    ) {
      let error = {};

      if (callTax < 0) error.callTax = "Invalid Call Tax ";
      // if (!giftTax) error.giftTax = "Gift Tex Required";
      if (giftTax < 0) error.giftTax = "Gift Tax Required";
      if (chargeForMessage < 0)
        error.chargeForMessage = "Charge For Message is Required!";
      if (!chargeForMatchMale)
        error.chargeForMatchMale = "charge For Match(Male) Is Required!";
      if (chargeForMatchMale < 0)
        error.chargeForMatchMale = "InValid Charge For Match!";
      if (!chargeForMatchFemale)
        error.chargeForMatchFemale = "Charge For Match(Female) Is Required !";
      if (chargeForMatchFemale < 0)
        error.chargeForMatchFemale = "InValid Charge For Match!";
      if (coinPerDollar < 0)
        error.coinPerDollar = "InValid Coin Per Dollar Call Charge!";
      return setError({
        ...error,
      });
    } else {
      const data = {
        chargeForMatchMale,
        chargeForMatchFemale,
        currency,
        chargeForMessage,
        minPrivateCallCharge,
        coinPerDollar,
        callTax,
        giftTax,
      };
      props.updateSetting(setting?._id, data);
    }
  };
  return (
    <>
      <div>
        <div className="mainSettingBar">
          <div className="settingBar ">
            <div className="settingHeader primeHeader">
              <div className="row align-items-center justify-content-center px-3">
                <div className="col-6">
                  <Title name={`Setting`} />
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <ol class="breadcrumb1 pb-0 mb-0">
                    <li class="breadcrumb-item1 ">
                      <p to="/admin/dashboard">Dashboard</p>
                    </li>
                    <li class="breadcrumb-item1 activeItem"> setting </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="settingMain">
              <div className="row">
                <div className="col-6">
                  <Title name={`Coin Setting`} />
                  {/*-------------- Box 1 --------------*/}
                  <SettingBox submit={handleSubmit} title={`CHARGES`}>
                    <Input
                      type={`number`}
                      label={`charge For Match(Male)`}
                      value={chargeForMatchMale}
                      newClass={`col-6`}
                      placeholder={`Enter You Live Api Time....`}
                      onChange={(e) => {
                        setChargeForMatchMale(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`charge For Match(Female)`}
                      value={chargeForMatchFemale}
                      newClass={`col-6`}
                      placeholder={`Enter You Live Api Time....`}
                      onChange={(e) => {
                        setChargeForMatchFemale(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Charge For Message`}
                      value={chargeForMessage}
                      newClass={`col-6`}
                      placeholder={`Enter You Live Api Time....`}
                      onChange={(e) => {
                        setChargeForMatchMale(e.target.value);
                      }}
                    />

                    <div className="col-6">
                      <label className="styleForTitle mb-2 text-dark">
                      Currency


                      </label>
                      <select
                        name="type"
                        className="form-control form-control-line"
                        id="type"
                        value={currency}
                        onChange={(e) => {
                          setCurrency(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              currency: "currency is Required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              currency: "",
                            });
                          }
                        }}
                      >
                    <option value="0"> $ </option>
                      <option value="1"> ₹ </option> 
                      </select>
                    
                    </div>
                    <Input
                      type={`number`}
                      label={`Min Private Call Charge`}
                      value={minPrivateCallCharge}
                      newClass={`col-6`}
                      placeholder={`Enter You Live Api Time....`}
                      onChange={(e) => {
                        setMinPrivateCallCharge(e.target.value);
                      }}
                    />
                  </SettingBox>
                </div>
                <div className="col-6">
                  <Title name={`Host Setting`} />
                  {/*-------------- Box 2 --------------*/}

                  <SettingBox submit={handleSubmit} title={`CHARGES`}>
                    <Input
                      type={`text`}
                      label={`Coin Rate (for cash out)`}
                      value="1 dollar"
                      newClass={`col-6`}
                      placeholder={`callTax`}
                    />
                    <Input
                      type={`number`}
                      label={`How Many Coin`}
                      value={coinPerDollar}
                      newClass={`col-6`}
                      placeholder={`Coin Per Dollar`}
                      onChange={(e) => {
                        setCoinPerDollar(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Call (Percentage)`}
                      value={callTax}
                      newClass={`col-6`}
                      placeholder={`callTax`}
                      onChange={(e) => {
                        setCallTax(e.target.value);
                      }}
                    />

                    <Input
                      type={`number`}
                      label={`Gift (Percentage)`}
                      value={giftTax}
                      newClass={`col-6`}
                      placeholder={`callTax`}
                      onChange={(e) => {
                        setGiftTax(e.target.value);
                      }}
                    />
                  </SettingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSetting, updateSetting, handleToggleSwitch })(
  CoinSetting
);
