import * as ActionType from "./redeem.type";

const initialState = {
  redeemAll: [],
  redeemPending: [],
  redeemSolved: [],
  dialog: false,
  dialogData: null,
};

export const redeemReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Redeem All
    case ActionType.GET_REDEEM_ALL:
      return {
        ...state,
        redeemAll: action.payload,
      };

    //Get Redeem Pending
    case ActionType.GET_REDEEM_PENDING:
      return {
        ...state,
        redeemPending: action.payload,
      };

    //Get Redeem Solved
    case ActionType.GET_REDEEM_SOLVED:
      return {
        ...state,
        redeemSolved: action.payload,
      };

    //Feedback Solved Switch
    case ActionType.REDEEM_SOLVED:
      return {
        ...state,
        redeemAll: state.redeemAll.map((data) => {
          return {
            ...data,
            agency: data.agency.map((agency) => {
              if (agency._id === action.payload) {
                return {
                  ...agency,
                  isPay: true,
                };
              }
              return agency;
            }),
          };
        }),
        
        redeemPending: state.redeemPending.filter(
          (data) => data?._id !== action.payload && data
        ),
      };
    case ActionType.OPEN_REDEEM_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_REDEEM_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case ActionType.REDEEM_UPDATE:
      return {
        ...state,
        redeemPending: state.redeemPending.map((updateRedeem) =>
          updateRedeem._id === action.payload.editRedeem._id
            ? action.payload.editRedeem
            : updateRedeem
        ),
      };
    default:
      return state;
  }
};
