// GET HOST
export const HOST_GET = "HOST_GET";

// get Unique Id
export const GET_UNIQUE = "GET_UNIQUE";

// Create Host header
export const HOST_CREATE = "HOST_CREATE";

// Check email
export const CHECK_EMAIL = "CHECK_EMAIL";

//  EDIT HOST
export const HOST_UPDATE = "HOST_UPDATE";

// OPEN_DIALOG
export const OPEN_DIALOG = "OPEN_DIALOG";

// CLOSE DIALOG
export const CLOSE_DIALOG = "CLOSE_DIALOG";

// GET_HOST PROFILE
export const GET_HOST_PROFILE = "GET_HOST_PROFILE";

// UPADTE_HOST_COIN
export const UPADTE_HOST_COIN = "UPADTE_HOST_COIN";
export const UPDATE_HOST_ALERT_MESSAGE = "UPDATE_HOST_ALERT_MESSAGE";

// get_history
export const GET_HOST_HISTORY = "GET_HOST_HISTORY";
export const GET_HOST_LIVE_HISTORY = "GET_HOST_LIVE_HISTORY";
export const GET_ALL_HOST_HISTORY = "GET_ALL_HOST_HISTORY";

// BLOCKED HOST

export const BLOCKED_HOST = "BLOCKED_HOST";
export const APPROVED_HOST = "APPROVED_HOST";

// ofline host swicth

export const OFFLINE_HOST = "OFFLINE_HOST";
export const FREE_HOST = "FREE_HOST";

// get country flag
export const COUNTRY_FLAG_NAME = "COUNTRY_FLAG_NAME";

export const VERIFICATION_VIDEO_OPEN_DIALOGUE =
  "VERIFICATION_VIDEO_OPEN_DIALOGUE";

export const VERIFICATION_VIDEO_CLOSE_DIALOGUE =
  "VERIFICATION_VIDEO_CLOSE_DIALOGUE";

// call enable switch

export const ENABLE_CALL_CUT_HOST = "ENABLE_CALL_CUT_HOST";

// host task history 
export const GET_HOST_TASK_HISTORY = "GET_HOST_TASK_HISTORY"



