//  get users
export const GET_USERS = "GET_USERS";

//  get Plan Purchase users
export const GET_PLAN_PURCHASE_USERS = "GET_PLAN_PURCHASE_USERS";

//  get single users
export const GET_SINGLE_USERS = "GET_SINGLE_USERS";

// USER BLOCK
export const USER_BLOCK = "USER_BLOCK";

// get user history
export const GET_USER_HISTORY = "GET_USER_HISTORY";

// Update User Coin
export const UPDATE_USER_COIN = "UPDATE_USER_COIN";

// blocked  user
export const BLOCKED_USER = "BLOCKED_USER";







