import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./redeem.type";

export const redeemAllList = () => (dispatch) => {
  axios
    .get(`getAgencyAllList`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: ActionType.GET_REDEEM_ALL,
        payload: res.data.redeem,
      });
    })
    .catch((error) => setToast("error", error.message));
};
export const redeemPending = () => (dispatch) => {
  axios
    .get(`pendingRedeem`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: ActionType.GET_REDEEM_PENDING,
        payload: res.data.redeem,
      });
    })
    .catch((error) => setToast("error", error.message));
};

export const redeemSolved = (type, startDate, endDate) => (dispatch) => {
  axios
    .get(`solvedRedeem?type=${type}&startDate=${startDate}&endDate=${endDate}`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: ActionType.GET_REDEEM_SOLVED,
        payload: res.data.redeem,
      });
    })
    .catch((error) => setToast("error", error.message));
};

export const redeemPay = (id) => (dispatch) => {

  axios
    .put(`agencyPayout/${id}`)
    .then((res) => {
      if (res.data.status === true) {

        dispatch({ type: ActionType.REDEEM_SOLVED, payload: id });
        setToast("success", "Pay");
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const noteRedeem = (data, id) => (dispatch) => {
  axios
    .patch(`updateRedeem/${id}`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: ActionType.REDEEM_UPDATE,
          payload: { editRedeem: res.data.redeem, id },
        });
        setToast("success", "Action Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
