import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '../../extra/Button';
import { Typography } from '@material-ui/core';
import Input from '../../extra/Input';
import { CLOSE_DIALOGUE } from '../../store/dialogue/dialogue.type';
import male from "../../../assets/images/maleImage.png";
import $ from "jquery";

import { agencyRequestUpdate } from "../../store/agencyRequest/agencyRequest.action";

const AgencyRequestEditDialoge = (props) => {

  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);


  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState(0);
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState("");


  
  useEffect(() => {
    setName(dialogueData?.name);
    setMobileNo(dialogueData?.mobileNo);
    setImagePath(dialogueData?.image);
  }, [dialogue]);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!name || !mobileNo || !imagePath) {
      let error = {};
      if (!name) error.name = "Name Is Required!";
      if (!mobileNo) error.mobileNo = "Mobile No Is Required!";
      if (!imagePath) error.image = "Image Is Required!";
      if (mobileNo < 0) error.mobileNo = "Invalid Mobile No!";
      return setError({ ...error });
    } else {
      const mobileNoValid = isNumeric(mobileNo);
      if (!mobileNoValid) {
        return setError({
          ...error,
          mobileNo: "Mobile Number must be ten digit!",
        });
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("mobileNo", mobileNo);
        formData.append("image", image);

        props.agencyRequestUpdate(dialogueData._id, formData);
        dispatch({ type: CLOSE_DIALOGUE });
      }
    }
  };

   // set default image

   $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

   // mobile number validation
   const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d{10}$/.test(val);
    return validNumber;
  };

  return (

    <>
  <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
            Update Agency Request
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Agency Name`}
                  id={`agencyName`}
                  placeholder={`Agency Name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Agency Mobile No`}
                  id={`mobileNo`}
                  placeholder={`96512-xxxxx`}
                  type={`number`}
                  value={mobileNo}
                  errorMessage={error.mobileNo && error.mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        mobileNo: `mobileNo Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        mobileNo: "",
                      });
                    }

                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
            

             
              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Agency Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
             
               
             
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
           

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null ,{agencyRequestUpdate})(AgencyRequestEditDialoge)