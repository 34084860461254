import React, { useState } from "react";

export default function useLocalStorageValue(key, defaultValue) {

  const [state, setState] = useState(() => {

    const storedValue = sessionStorage.getItem(key);
 
    return storedValue !== null ? (storedValue) : defaultValue;
  });

  const updateState = (value) => {

    sessionStorage.setItem(key, (value));
  
    setState(value);

  };
  return [state, updateState];
}
