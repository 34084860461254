import axios from "axios";

import * as ACctionType from "./webPayment.type";

// get paln list
export const getPlanList = () => (disapatch) => {
  axios
    .get("coinPlan/get?platFormType=2")
    .then((response) => {
      disapatch({
        type: ACctionType.GET_PLAN_LIST,
        payload: response.data.coinPlan,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
// get paln list
export const getPlan = (id) => (disapatch) => {

  axios
    .get(`coinPlan/byId?planId=${id}`)
    .then((response) => {
    
      disapatch({
        type: ACctionType.GET_PLAN,
        payload: response.data.coinPlan,
      });
    })
    .catch((error) => {
    
      console.log(error);
    });
};
