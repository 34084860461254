import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPermissionData ,permissionActive ,deletePermission } from "../../store/permission/permission.action";
import Table from "../../extra/Table";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import ManagementDialogue from "./ManagementDialogue";
import Searching from "../../extra/Searching";
import Pagination from "../../extra/Pagination";
import { warning } from "../../util/Alert";

const Management = (props) => {
  const { permission } = useSelector((state) => state.permission);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getPermissionData();
  }, []);

  useEffect(() => {
    setData(permission);
  }, [permission]);

  const handleClick = (data) => {
    props.permissionActive(data, data.isActive === true ? false : true);
  };

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deletePermission(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const dispatch = useDispatch();

  const managementTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{(page) * rowsPerPage + parseInt(index)+1}</span>,
    },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <img
        draggable={false}
          src={row.image}
          className={`table-image mx-auto d-block`}
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    { Header: "Name", body: "name" },
    { Header: "Email", body: "email" },
    { Header: "Password", body: "password" },
    {
      Header: "Active",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isActive} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => {
        return (
          <span >
            {row?.role?.map((data, index) => {
              return (
                <span key={index}>
                  {data === "1" && `${index ? ", " : ""} User`}
                  {data === "2" && `${index ? ", " : ""} Host`}
                  {data === "3" && `${index ? ", " : ""} Gift`}
                  {data === "4" && `${index ? ", " : ""} Feedback`}
                  {data === "5" && `${index ? ", " : ""} Report`}
                  {data === "6" && `${index ? ", " : ""} Redeem`}
                </span>
              );
            })}
          </span>
        );
      },
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <>
          <Button
            newClass={`themeFont boxCenter userBtn text-white`}
            btnColor={`bg-primary`}
            btnIcon={`fa-solid fa-pen-to-square fs-6`}
            onClick={() =>
              dispatch({
                type: OPEN_DIALOGUE,
                payload: { data: row, type: "Management" },
              })
            }
            style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
          />
        </>
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };
  return (
    <>
      <div className="mainPlanTable ">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`Management`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Management </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
          <div className="coin-plan">
            <div className="coin-plan-btn">
              <div className="row ps-4 py-3">
                <div className="col-6 ">
                  <div>
                    <Button
                      newClass={`themeFont boxCenter userBtn`}
                      btnColor={`btnBlackPrime`}
                      btnName={`New`}
                      btnIcon={`fa-regular fa-plus me-2 me-2`}
                      onClick={() => {
                        dispatch({
                          type: OPEN_DIALOGUE,
                          payload: { type: "Management" },
                        });
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "80px",
                      }}
                    />

                    {dialogue && dialogueType === "Management" && (
                      <ManagementDialogue />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <Searching
                    type={`client`}
                    data={permission}
                    setData={setData}
                    column={managementTable}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
              </div>
            </div>
            <div className="primeMain border-top">

              <Table
                data={data}
                mapData={managementTable}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
              </div>
              <Pagination
                component="div"
                count={permission?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={permission?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getPermissionData ,deletePermission ,permissionActive })(Management);
