import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import { Typography } from "@material-ui/core";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import $ from "jquery";
import Multiselect from "multiselect-react-dropdown";
import {
  createPermission,
  updatePermission,
} from "../../store/permission/permission.action";

const ManagementDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState([]);
  const [name, setName] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);

  const [error, setError] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    image: "",
  });

  const roleOption = [
    { name: "User", id: 1 },
    { name: "Host", id: 2 },
    { name: "Gift", id: 3 },
    { name: "Feedback", id: 4 },
    { name: "Report", id: 5 },
    { name: "Redeem", id: 6 },
  ];

  //set data in dialog
  //set data in dialog
  useEffect(() => {
    
    const roleData = dialogueData?.role?.map((data) => {
      return {
        name:
          (data == 1 && "User") ||
          (data == 2 && "Host") ||
          (data == 3 && "Gift") ||
          (data == 4 && "Feedback") ||
          (data == 5 && "Report") ||
          (data == 6 && "Redeem"),
        id: parseInt(data),
      };
    });

    if (dialogueData) {
      setName(dialogueData.name);
      setEmail(dialogueData.email);
      setPassword(dialogueData.password);
      setImagePath(dialogueData.image);
      setRole(dialogueData?.role);
      setSelectedValue(roleData);
      setMongoId(dialogueData?._id);
    }
  }, [dialogue]);

  //Empty Data After Insert
  useEffect(
    () => () => {
      setName("");
      setImagePath("");
      setEmail("");
      setPassword("");
      setRole([]);
      setMongoId("");
      setSelectedValue("");
      setImage([]);
      setError({
        name: "",
        email: "",
        role: "",
        password: "",
        image: "",
      });
    },
    [dialogue]
  );
  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  //onselect function of selecting multiple values
  function onSelect(selectedList, selectedItem) {
    role.push(selectedItem.id);
  }

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setRole(selectedList.map((data) => data.id));
  }

  const dispatch = useDispatch();
  //Handle Submit
  const handleSubmit = () => {
    if (!name || !email || !password || role.length === 0 || !imagePath) {
      let error = {};
      if (!name) error.name = "Name Is Required !";

      if (!email) error.email = "Email Is Required !";

      if (!password) error.password = "Password Is Required !";

      if (!imagePath) error.image = "Image is required !";

      if (role.length === 0) error.role = "Role is Required !";

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("name", name);
      formData.append("role", role);

      if (mongoId) {
        props.updatePermission(formData, mongoId);
      } else {
        props.createPermission(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });


    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Management" : "Create Management"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Name`}
                  id={`name`}
                  placeholder={`Name`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Email`}
                  id={`email`}
                  placeholder={`demo@gmail.com`}
                  type={`email`}
                  value={email}
                  errorMessage={error.email && error.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        email: `Email Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        email: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold ">Password</p>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group" id="Password-toggle">
                      <a
                        href={() => false}
                        class="input-group-text"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          id="showPassword"
                          class="fa fa-eye"
                          aria-hidden="true"
                          onClick={showPassword}
                        ></i>
                      </a>
                      <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        className="form-control"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (!e.target.value)
                            return setError({
                              ...error,
                              password: "password Is Required !",
                            });
                          else {
                            return setError({
                              ...error,
                              password: "",
                            });
                          }
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    {error.password && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.password}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 my-2">
                <label class="float-left dialog__input__title mb-2">
                  Select Role
                </label>

                <Multiselect
                  options={roleOption}
                  selectedValues={selectedValue} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
                {error.role && (
                  <div className="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.role}
                    </Typography>
                  </div>
                )}
              </div>

              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { updatePermission, createPermission })(
  ManagementDialogue
);
