import axios from "axios";
import { setToast } from "../../util/toast";
import * as CoinSellerType from "./type";

// GET coinSeller
export const getCoinSeller = () => (dispatch) => {

  axios
    .get(`agent?type=admin`)
    .then((res) => {

      dispatch({
        type: CoinSellerType.GET_COINSELLER,
        payload: res.data.coinSeller,
      });
    })
    .catch((error) => console.log(error));
};

// CREATE coinSeller
export const addCoinSeller = (formData) => (dispatch) => {
  
  axios
    .post("agent", formData)
    .then((res) => {
      
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_COINSELLER,
          payload: res.data.coinSeller,
        });
        setToast("success", "coinSeller Add Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// UPDATE coinSeller
export const editCoinSeller = (id, fromData) => (dispatch) => {
  axios
    .patch(`agent/${id}`, fromData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.EDIT_COINSELLER,
          payload: { coinSeller: res.data.coinSeller, id },
        });
        setToast("success", "coinSeller Update Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// DELETE AGANCY
export const deleteCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`agent/disable/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.DELETE_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};

// Show coinSeller
export const showCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`agent/show/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.SHOW_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};

// add money

// UPDATE coinSeller
export const AddMoneyByAdmin = (data, id) => (dispatch) => {

  axios
    .post(`agent/adminToCoinSeller/${id}`, data)
    .then((res) => {
      console.log(res.data.coinSeller);
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_MONEY_BY_ADMIN,
          payload: { coinSeller: res.data.coinSeller, id },
        });
        setToast("success", "Add coin Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// GET coinSeller history
export const getCoinSellerHistory = (id) => (dispatch) => {
  axios
    .get(`coinSellerHistory/coinSeller/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.GET_COINSELLER_HISTORY,
        payload: res.data.history,
      });
    })
    .catch((error) => console.log(error));
};
