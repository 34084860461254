import * as ACTION_TYPE from "./coinPlan.type";

const initialState = {
  coinPlan: [],
  dialog: false,
  dialogData: null,
  purchasedHistory: [],
};

export const coinPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_COIN_PLAN:
      return {
        ...state,
        coinPlan: action.payload,
      };

    //Create Coin Plan
    case ACTION_TYPE.CREATE_COIN_PLAN:
      const data = [...state.coinPlan];
      data.unshift(action.payload);
      return {
        ...state,
        coinPlan: data,
      };

    //Update Coin Plan
    case ACTION_TYPE.UPDATE_COIN_PLAN:
      return {
        ...state,
        coinPlan: state.coinPlan.map((data) =>
          data?._id === action.payload.id ? action.payload.data : data
        ),
      };

    //Delete Coin Plan
    case ACTION_TYPE.DELETE_COIN_PLAN:
      return {
        ...state,
        coinPlan: state.coinPlan.filter((data) => data._id !== action.payload),
      };

    //Active Inactive Plan
    case ACTION_TYPE.ACTIVE_INACTIVE:
      return {
        ...state,
        coinPlan: state.coinPlan.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };

      case ACTION_TYPE.POPULAR_SWITCH:
        return {
          ...state,
          coinPlan: state.coinPlan.map((data) =>
            data._id === action.payload.id ? action.payload.data : data
          ),
        };

    // open dialog
    case ACTION_TYPE.OPEN_COIN_PLAN_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    // close dialog
    case ACTION_TYPE.CLOSE_COIN_PLAN_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    // purchased History Plan

    case ACTION_TYPE.PURCHASED_HISTORY:
      return {
        ...state,
        purchasedHistory: action.payload,
      };

    default:
      return state;
  }
};
