// GET HOST_REQUEST

export const GET_HOST_REQUEST = "GET_HOST_REQUEST";
// ACCEPET HOST_REQUEST
export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";

// OPEN_DIALOG
export const OPEN_DIALOG = "OPEN_DIALOG";

// CLOSE DIALOG
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//  EDIT HOST
export const HOST_UPDATE = "HOST_UPDATE";

// get subAgent 

export const GET_SUBAGENT ="GET-SUBAGENT"