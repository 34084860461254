import * as actionType from "./hostRequest.type";

const initialState = {
  hostRequest: [],
  dialog: false,
  dialogData: null,
  suAgent: [],
};
export const hostRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_HOST_REQUEST:
      return {
        ...state,
        hostRequest: action.payload,
      };

    case actionType.ACCEPT_HOST_REQUEST:
      return {
        ...state,
        hostRequest: state.hostRequest.filter(
          (hostAccept) => hostAccept._id !== action.payload && hostAccept
        ),
      };
    case actionType.HOST_UPDATE:
      return {
        ...state,
        hostRequest: state.hostRequest.map((updateHost) =>
          updateHost._id === action.payload.editHost._id
            ? action.payload.editHost
            : updateHost
        ),
      };
    case actionType.OPEN_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case actionType.CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case actionType.GET_SUBAGENT:
      return {
        ...state,
        suAgent: action.payload,
      };
    default:
      return state;
  }
};
