import * as ActionType from "./hostLevel.type";

const initialState = {
  hostLevel: [],
  dialog: false,
  dialogData: null,
};

export const hsotLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_HOST_LEVEL:
      return {
        ...state,
        hostLevel: action.payload,
      };
    case ActionType.OPEN_HOST_LEVEL_DIALOGUE:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_HOST_LEVEL_DIALOGUE:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    // crate Level
    case ActionType.CREATE_HOST_LEVEL:
      let data = [...state.hostLevel];
      data.unshift(action.payload);
      return {
        ...state,
        hostLevel: data,
      };
    // update Level
    case ActionType.UPDATE_HOST_LEVEL:
      console.log(action.payload);
      return {
        ...state,
        hostLevel: state.hostLevel.map((data) =>
          data._id === action.payload.id ? action.payload.edit : data
        ),
      };

    // delete Level
    case ActionType.DELETE_HOST_LEVEL:
      return {
        ...state,
        hostLevel: state.hostLevel.filter(
          (data) => data._id !== action.payload && data
        ),
      };
    default:
      return state;
  }
};
