import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAgencyHostSettlement } from "../../store/settlement/settlement.action";
import Analytics from "../../extra/Analytics";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Searching from "../../extra/Searching";

const AgencySettlement = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const { state } = useLocation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dayType, setDayType] = useState("WEEK");
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [type, setType] = useState("0");
  const [filed, setField] = useState("");

  const { agencyHostSettlement, totalAgentPayable } = useSelector(
    (state) => state.settlement
  );

  useEffect(() => {
    if (state?.type === "custom") {
      props.getAgencyHostSettlement(
        state?.id,
        state?.type,
        state?.startDate,
        state?.endDate,
        filed,
        type
      );
      setStartDate(state?.startDate);
      setEndDate(state?.endDate);
    } else {
      props.getAgencyHostSettlement(
        state?._id ? state?.id : dialogueData?._id,
        dayType,
        startDate,
        endDate,
        filed,
        type
      );
    }
  }, []);

  useEffect(() => {
    if (startDate === "") {
      props.getAgencyHostSettlement(
        state?._id ? state?.id : dialogueData?._id,
        "WEEK",
        startDate,
        endDate,
        filed,
        type
      );
    } else {
      props.getAgencyHostSettlement(
        state?._id ? state?.id : dialogueData?._id,
        "custom",
        startDate,
        endDate,
        filed,
        type
      );
    }
  }, [filed, type, startDate, endDate]);

  useEffect(() => {
    setData(agencyHostSettlement);
  }, [agencyHostSettlement]);

  useEffect(() => {
    !state && window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handelPreviousPage = () => {
    localStorage.removeItem("dialogueData");
    state ? navigate(-1) : dispatch({ type: CLOSE_DIALOGUE });
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const showHostSettlement = (data) => {
    const state = {
      id: data._id,
      name: data.name,
      startDate: startDate,
      endDate: endDate,
    };
    navigate("/admin/host/history", {
      state: state,
    });
  };

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };

  const agencySettlement = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "Host Id", body: "uniqueId", class: "fw-bold fs-6" },
    { Header: "Name", body: "name", class: "fw-bold fs-6" },
    {
      Header: "Host Coin",
      body: "hostCoin",
      sorting: { type: "server" },

      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-warning">{row?.hostCoin}</span>
      ),
    },
    {
      Header: "Revenue",
      body: "settlementCoin",
      sorting: { type: "server" },

      Cell: ({ row }) => (
        <span
          className="fs-6 fw-bold text-danger"
          style={{ cursor: "pointer" }}
          onClick={() => showHostSettlement(row)}
        >
          {!row?.settlementCoin ? 0 : row?.settlementCoin}
        </span>
      ),
    },
    {
      Header: "Host Salary",
      body: "hostBasicSalary",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-info">
          {!row?.hostBasicSalary ? 0 : row?.hostBasicSalary}
        </span>
      ),
    },
    {
      Header: "Total",
      body: "total",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fs-6 fw-bold text-dark">
          {!row?.total ? 0 : row?.total}
        </span>
      ),
    },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="PrimeHeader">
            <div className="primeHeader">
              <div className="row align-items-center ">
                <div className="col-6 title-head title-head ">
                  <Title name={"Settlement"} />
                </div>
                <div className="col-6 d-flex justify-content-end ">
                  <div className="profileBtn d-flex align-items-center">
                    <Button
                      btnColor={`btn-primary`}
                      newClass={`themeFont boxCent text-white`}
                      onClick={handelPreviousPage}
                      style={{
                        borderRadius: "5px",
                        height: "36px",
                        width: "50px",
                      }}
                      btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan ">
              <div className="coin-plan-btn">
                <div className="row m-3 border-bottom ">
                  <div className="col-6 d-flex align-items-center">
                    <div>
                      <Analytics
                        analyticsStartDate={start}
                        analyticsStartEnd={end}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end align-items-center fs-5 fw-bold text-primary">
                    Total Revenue : {totalAgentPayable}
                  </div>
                </div>
                <div className="row px-3 pt-2">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                    <div className="text-primary d-flex align-items-center">
                      <Title name={"Host Settlement"} />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6">
                    <Searching
                      type={`client`}
                      data={agencyHostSettlement}
                      setData={setData}
                      column={agencySettlement}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>

                <div className="userMain mt-3 ">
                  <div className="primeMainHistory border-top">
                    <Table
                      data={data}
                      mapData={agencySettlement}
                      PerPage={rowsPerPage}
                      Page={page}
                      type={"client"}
                      onChildValue={handleChildValue}
                    />
                  </div>
                  <Pagination
                    component="div"
                    count={agencyHostSettlement?.length}
                    serverPage={page}
                    type={"client"}
                    onPageChange={handleChangePage}
                    serverPerPage={rowsPerPage}
                    totalData={agencyHostSettlement?.length}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyHostSettlement })(AgencySettlement);
