import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import { Typography } from "@material-ui/core";
import Input from "../../extra/Input";
import $ from "jquery";
import { editCoinSeller ,addCoinSeller } from "../../store/coinSeller/action"

const CoinSellerCreateDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    mobileNo: "",
    countryCode: "",
    image: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setPassword(dialogueData?.password);
      setEmail(dialogueData?.email);
      setImagePath(dialogueData?.image);
      setMobileNo(dialogueData?.mobileNo);
      setCountryCode(dialogueData?.countryCode);
    }
  }, [dialogueData]);

  const handleSubmit = () => {
    if (
      !name ||
      !email ||
      !password ||
      !imagePath ||
      !mobileNo ||
      !countryCode
    ) {
      const error = {};
      if (!name) error.name = "Name Is Required !";
      if (!password) error.password = "password Is Required !";
      if (!email) error.email = "email Is Required !";
      if (!mobileNo) error.image = "Mobile number is required !";
      if (!countryCode) error.image = "countryCode is required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("image", image);
      formData.append("mobileNo", mobileNo);
      formData.append("countryCode", countryCode);

      if (dialogueData) {
        props.editCoinSeller(dialogueData._id, formData);
      } else {
        props.addCoinSeller(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  // upload image
  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Coin Seller" : "Create Coin Seller"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={` Name`}
                  id={`name`}
                  placeholder={`Coin Seller`}
                  type={`text`}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={` Email`}
                  id={`email`}
                  placeholder={`email`}
                  type={`email`}
                  value={email}
                  errorMessage={error.email && error.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        email: `Email Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        email: "",
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-2 my-2">
                <Input
                  label={`Code`}
                  id={`Code`}
                  type={`number`}
                  placeholder={`+91`}
                  value={countryCode}
                  readOnly={dialogueData && `readOnly`}
                  errorMessage={error.countryCode && error.countryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        countryCode: `Code Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        countryCode: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-10 my-2">
                <Input
                  label={`Mobile No`}
                  id={`mobileNo`}
                  placeholder={`96512-xxxxx`}
                  type={`number`}
                  value={mobileNo}
                  errorMessage={error.mobileNo && error.mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        mobileNo: `mobileNo Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        mobileNo: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <div className="form-group mb-0">
                  <p className="form-label ">Password</p>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group" id="Password-toggle">
                    <a
                      href={() => false}
                      class="input-group-text"
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        id="showPassword"
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={showPassword}
                      ></i>
                    </a>
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      className="form-control"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (!e.target.value)
                          return setError({
                            ...error,
                            password: "password Is Required !",
                          });
                        else {
                          return setError({
                            ...error,
                            password: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  {error.password && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.password}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label  "> Agency Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null ,{addCoinSeller ,editCoinSeller})(CoinSellerCreateDialogue);
