import Login from "./Component/Pages/Login.js";

import Admin from "./Component/Pages/Admin";
import PrivateRoute from "./Component/util/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOGIN_ADMIN, LOGOUT_ADMIN } from "./Component/store/admin/admin.type";
import { setToken } from "./Component/util/setAuth";

import { IdleTimeoutManager } from "idle-timer-manager";
import PlanList from "./Component/Pages/PlanList.js";
import WebPayment from "./Component/Pages/WebPayment.js";
import Error404 from "./Component/Pages/Error404.js";

function App() {
  const dispatch = useDispatch();
  const key = sessionStorage.getItem("key");
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: LOGIN_ADMIN, payload: token });
  }, [setToken, key]);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: LOGOUT_ADMIN });
        return (window.location.href = "/login");
      },
    });

    return () => {
      manager.clear();
    };
  }, []);

  const navigate = useNavigate();

  const isAuth = true;

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/planList/:userId/:currency/:symbol"
          element={<PlanList />}
        />
        <Route
          exact
          path="/webPayment/:planId/:userId"
          element={<WebPayment />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Error404 />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
