import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../extra/Title";

import HostLiveHistory from "./History/HostLiveHistory";
import HostTaskHistory from "./History/HostTaskHistory";
import HostGiftHistory from "./History/HostGiftHistory";
import HostAdminHistory from "./History/HostAdminHistory";

import HostCallHistory from "./History/HostCallHistory";
import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../store/dialogue/dialogue.type";

const HostHistory = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [type, setType] = useState("Call");
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);

  const handelPreviousPage = () => {
    localStorage.removeItem("dialogueData");
    if (state) {
      navigate(-1);
      dispatch({ type: CLOSE_DIALOGUE });
    } else {
      const hostProfile = localStorage.getItem("hostProfile");
      const profile = JSON.parse(hostProfile);
      profile
        ? dispatch({
            type: OPEN_DIALOGUE,
            payload: {
              dialogue: true,
              type: "hostProfile",
              data: profile.dialogueData,
            },
          })
        : state
        ? navigate(-1)
        : dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <div className={`mainPlanTable  hostHistory ${state && "mt-5"}`}>
        <div className="row ">
          <div className="col-12"></div>
        </div>
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title
                  name={state ? state?.name : dialogueData?.name + `'s History`}
                />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <Button
                  btnColor={`btn-primary`}
                  newClass={`themeFont boxCent text-white`}
                  onClick={handelPreviousPage}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "50px",
                  }}
                  btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                />
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan overflow-hidden">
              <div className="coin-plan-button">
                <div className="row p-3 d-flex align-items-center border-bottom">
                  <div className="col-sm-12 col-md-6 ">
                    <Title className="mt-0" name={type + " History"} />
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-danger`}
                        btnIcon={`fa-solid fa-rotate-right pe-2`}
                        btnName={`Refresh`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "95px",
                        }}
                        onClick={handleRefresh}
                      />
                    </div>
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-primary`}
                        btnName={`Call`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Call")}
                      />
                    </div>
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-success`}
                        btnName={`Live`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Live")}
                      />
                    </div>
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-secondary`}
                        btnName={`Task`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Task")}
                      />
                    </div>
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-warning`}
                        btnName={`Gift`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Gift")}
                      />
                    </div>

                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-danger`}
                        btnName={`Admin`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "70px",
                        }}
                        onClick={() => setType("Admin")}
                      />
                    </div>
                  </div>
                </div>

                {type === "Live" ? (
                  <HostLiveHistory
                  props={ { historyId: state ? state?.id : dialogueData?._id, data: refresh }}

                  />
                ) : type === "Gift" ? (
                  <HostGiftHistory
                  props={{ historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "Admin" ? (
                  <HostAdminHistory
                  props={{ historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : type === "Task" ? (
                  <HostTaskHistory
                  props={{ historyId: state ? state?.id : dialogueData?._id, data: refresh }}
                  />
                ) : (
                  type === "Call" && (
                    <HostCallHistory
                    props={{ historyId: state ? state?.id : dialogueData?._id, data: refresh }}

                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostHistory;
