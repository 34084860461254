import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Select from "react-select";

import male from "../../../../assets/images/maleImage.png";

import { getSubagent } from "../../../store/hostRequest/hostRequest.action";
import { getAgency } from "../../../store/agencyTable/agency.action";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import { Typography } from "@material-ui/core";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { updateRequestHost } from "../../../store/hostRequest/hostRequest.action";

const HostRequestDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);
  const { suAgent } = useSelector((state) => state.hostRequest);
  const { allCountry } = useSelector((state) => state.admin);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [bio, setBio] = useState("");
  const [age, setAge] = useState(0);
  const [email, setEmail] = useState("");
  const [oldAgency, setOldAgency] = useState("");
  const [agencys, setAgencys] = useState([]);
  const [gender, setGender] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [country, setCountry] = useState({
    value: "",
    label: "",
  });

  const [subagent, setSubagent] = useState({
    value: "",
    label: "",
  });

  const [error, setError] = useState({
    name: "",
    password: "",
    bio: "",
    age: "",
    image: "",
    gender: "",
    email: "",
    oldAgency: "",
    country: "",
  });

  useEffect(() => {
    props.getAgency();
    props.getSubagent();
  }, []);

  useEffect(() => {
    setAgencys(suAgent);
  }, [suAgent]);

  const options = allCountry.map((countryData) => {
    return {
      value: countryData.name,
      label: countryData.name,
    };
  });

  const optionsSubAgent = suAgent.map((subAgentData) => {
    return {
      value: subAgentData._id,
      label: subAgentData.name,
    };
  });

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setPassword(dialogueData?.password);
      setBio(dialogueData?.bio);
      setGender("female");
      setImagePath(dialogueData?.image);
      setAge(dialogueData?.age);
      setEmail(dialogueData?.email);
      setOldAgency(dialogueData?.agencyId);
      setCountry(dialogueData?.country);
      setCountry({
        value: dialogueData?.country,
        label: dialogueData?.country,
      });
      setSubagent({
        value: dialogueData?.subAgentId?._id,
        label: dialogueData?.subAgentId?.name,
      });
    }
  }, [dialogue]);

  const dispatch = useDispatch();

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    if (selectedOption?.value) {
      setCountry(selectedOption?.value);
    }

    if (
      !name ||
      !gender ||
      !bio ||
      !age ||
      age <= 18 ||
      !subagent?.value

      // !country
    ) {
      let error = {};
      if (!name) error.name = "Name Is Required !";

      if (!oldAgency) error.oldAgency = "SuAgent Is Required !";
      if (!gender) error.gender = "Gender Is Required !";
      if (age <= 18) error.age = "Invalid Age!";
      if (!bio) error.bio = "Bio Is Required !";
      if (!age) error.age = "Age Is Required !";
      if (!subagent?.value) error.subagent = "Sub Agent Is Required ";
      if (!country) error.country = "Country Is Required !";

      return setError({ ...error });
    } else {
      const ageValid = isNumeric(age);
      if (!ageValid) {
        return setError({
          ...error,
          age: "Invalid Age!",
        });
      }
      const formData = new FormData();

      const countries = country?.value;
      const subAgentName = subagent?.value;
      formData.append("name", name);
      formData.append("password", password);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("age", age);
      formData.append("image", image);
      formData.append("email", email);
      formData.append("subAgentId", subAgentName);
      formData.append("country", countries);

      props.updateRequestHost(formData, dialogueData?._id);
      dispatch({ type: CLOSE_DIALOGUE });
    }

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    };
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };
  $("input[type=number]").on("mousewheel", function (e) {
    $(e.target).blur();
  });

  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Host Request Edit </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>

          <div className="dialogueMain">
            <div className="col-12 col-sm-12 col-md-12 my-2 text-start">
              <Input
                label={`Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`Bio `}
                  id={`bio`}
                  type={`text`}
                  placeholder={`bio`}
                  value={bio}
                  errorMessage={error.bio && error.bio}
                  onChange={(e) => {
                    setBio(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        bio: `Extra Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        bio: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Email`}
                  id={`email`}
                  type={`email`}
                  value={email}
                  errorMessage={error.email && error.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        email: `Email Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        email: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`age`}
                  id={`age`}
                  type={`number`}
                  value={age}
                  placeholder={`18`}
                  errorMessage={error.age && error.age}
                  onChange={(e) => {
                    setAge(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        tag: `Tag Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        tag: "",
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold mt-3"> SuAgent</p>
                  </div>

                  <Select
                    value={subagent}
                    defaultValue={subagent}
                    onChange={setSubagent}
                    options={optionsSubAgent}
                  />
                </div>
                {error.subagent && (
                  <div class="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.subagent}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="col-6">
                <div>
                  <div>
                    <div className="form-group mb-0">
                      <p className="form-label fw-bold mt-3"> Country</p>
                    </div>

                    <Select
                      value={country}
                      defaultValue={country}
                      onChange={setCountry}
                      options={options}
                    />
                  </div>
                  {!selectedOption?.value && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.country}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Host Image</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => handleImage(e)}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Update`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgency, getSubagent, updateRequestHost })(
  HostRequestDialogue
);
