import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getHostHistory } from "../../../store/host/host.action";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import moment from "moment";
import { getHostLiveHistory } from "../../../store/host/host.action";
import $ from "jquery";
import male from "../../../../assets/images/maleImage.png";
import { useLocation, useNavigate } from "react-router-dom";

const HostLiveHistory = (props) => {
  const { hostLiveHistory, totalLiveCoin, totalLiveDuration, totalData } =
    useSelector((state) => state.host);



  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

const {state } =useLocation()

  useEffect(() => {
    props.getHostLiveHistory(
      state?.id ? state.id : props?.props?.historyId,
      "live",
      currentPage,
      size,
      state?.startDate ? state?.startDate : startDate,
      state?.endDate ? state?.endDate : endDate
    );
  }, [currentPage, size, startDate, endDate , props?.props?.data]);

  useEffect(() => {
    setData(hostLiveHistory);
  }, [hostLiveHistory]);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };


  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });


  const navigate =useNavigate()

  let date;

  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>,
    },
    {
      Header: "Duration",
      body: "duration",
      Cell: ({ row }) => {
        return (
          <span className="fw-semibold fs-6">{moment.utc(row.duration * 1000).format("HH:mm:ss")}</span>
        );
      },
    },
    {
      Header: "Coin",
      body: "hCoin",
      sorting: { type: "server" },
      Cell: ({ row }) =>
        row.hCoin > 0 ? (
          <span className="text-success fs-6 fw-semibold">+{row.hCoin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.hCoin}</span>
        ),
    },

    {
      Header: "Date",
      body:"date",
      sorting:{ type: "server"},
      Cell: ({ row }) => {
        date = row?.endTime?.split(",") || [];
        return <span className="fs-6 fw-semibold">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.endTime?.split(",") || [];
        return <span className="fs-6 fw-semibold">{date[1]}</span>;
      },
    },
  ];
  return (
    <>
      <div className="row d-flex align-items-center  py-3 px-4">
        {" "}
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-2 d-flex justify-content-end">
              {" "}
              <Title name={`Total Coin : ` + totalLiveCoin} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-2 d-flex justify-content-end">
              <Title
                name={
                  `Total Duration : ` +  moment.utc(totalLiveDuration * 1000).format("HH:mm:ss")
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="userMain">
      <div className="primeMainHistory border-top">

          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
             </div>
          <Pagination
            component="div"
            count={data?.length}
            type={"server"}
            onPageChange={handleChangePage}
            serverPerPage={rowsPerPage}
            totalData={totalData}
            serverPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
     
      </div>
    </>
  );
};

export default connect(null, { getHostLiveHistory })(HostLiveHistory);
