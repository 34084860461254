import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import dayjs from "dayjs";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { baseURL } from "../../util/config";
import { warning } from "../../util/Alert";
import Searching from "../../extra/Searching";
import Analytics from "../../extra/Analytics";
import {
  feedBack,
  feedBackSolved,
  deleteFeedBack,
} from "../../store/feedBack/feedBack.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import FeedbackDetailsDialog from "./FeedbackDetailsDialog";
import $ from "jquery";
import male from "../../../assets/images/maleImage.png";

const FeedBackPending = (props) => {
  const { feedBack } = useSelector((state) => state.feedBack);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("user");
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    props.feedBack("pending", "ALL", "ALL", "user");
  }, []);
  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    props.feedBack(
      "pending",
      startDate === "" ? "ALL" : startDate,
      endDate === "" ? "ALL" : endDate,
      type
    );
  }, [type, startDate, endDate]);

  useEffect(() => {
    setData(feedBack);
  }, [feedBack]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFeedBack(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };
  // Active Plan
  const handleClick = (id) => {
    props.feedBackSolved(id);
  };

  const FeedBackTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span className="text-dark fs-6">
          {page * rowsPerPage + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: type,
      body: type,
      Cell: ({ row }) => <span>{row.userId.name}</span>,
    },
    {
      Header: "Image",

      Cell: ({ row }) => (
        <img
        draggable={false}
          src={baseURL + row.screenshot}
          className="table-image mx-auto d-block"
          height={60}
          width={60}
          alt=""
        />
      ),
    },
    {
      Header: "Contact",
      body: "contact",
    },
    {
      Header: "FeedBack Type",
      body: "type",
      Cell: ({ row }) => {
        return (
          <span>
            {(row?.type === 1 && "Recharge") ||
              (row?.type === 2 && "Gifting") ||
              (row?.type === 3 && "Stream") ||
              (row?.type === 4 && "Events") ||
              (row?.type === 5 && "Suggestions") ||
              (row?.type === 6 && "Others")}
          </span>
        );
      },
    },
    {
      Header: "Solved FeedBack",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row.isSolved}
          onClick={() => handleClick(row._id)}
        />
      ),
    },
    {
      Header: "Create  Date",
      body: "createdAt",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <span> {dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "View Details",
      body: "details",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-info`}
          btnIcon={`fa fa-eye`}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "FeedBackDetails" },
            })
          }
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn text-white`}
          btnColor={`bg-danger`}
          btnIcon={`fa-solid fa-trash`}
          onClick={() => handleDelete(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mainPlanTable">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <Title name={`FeedBack Pending`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Feedback </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-6 ">
                    <Title name={type + ` FeedBack Pending`} />
                  </div>
                  <div className="col-6 ">
                    <Searching
                      button={true}
                      type={`client`}
                      data={feedBack}
                      setData={setData}
                      column={FeedBackTable}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                </div>
                <div className="row ps-4 py-3">
                  <div className="col-6 ">
                    <Analytics
                      analyticsStartDate={start}
                      analyticsStartEnd={end}
                    />
                  </div>
                  <div className="col-6 ">
                    <div class="d-flex justify-content-end me-2">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white me-3`}
                        btnColor={`bg-success`}
                        btnName={`User FeedBack`}
                        onClick={() => setType("user")}
                        style={{
                          borderRadius: "5px",

                          width: "120px",
                        }}
                      />
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-primary me-3`}
                        btnName={`Host FeedBack`}
                        onClick={() => setType("host")}
                        style={{
                          borderRadius: "5px",

                          width: "150px",
                        }}
                      />
                    </div>
                  </div>
                  {dialogue && dialogueType === "FeedBackDetails" && (
                    <FeedbackDetailsDialog />
                  )}
                </div>
              </div>
              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={FeedBackTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"client"}
                />
              </div>
              <Pagination
                component="div"
                count={feedBack?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={feedBack?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { feedBack, feedBackSolved, deleteFeedBack })(
  FeedBackPending
);
