//Open Notification Dialog
export const OPEN_NOTIFICATION_DIALOG = "OPEN_NOTIFICATION_DIALOG";

//Close Notification Dialog
export const CLOSE_NOTIFICATION_DIALOG = "CLOSE_NOTIFICATION_DIALOG";

// send Notification

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";


export const SEND_USER_NOTIFICATION = "SEND_USER_NOTIFICATION";


//Open Agency Notification Dialog
export const OPEN_AGENCY_NOTIFICATION_DIALOG =
  "OPEN_AGENCY_NOTIFICATION_DIALOG";

//Close Agency Notification Dialog
export const CLOSE_AGENCY_NOTIFICATION_DIALOG =
  "CLOSE_AGENCY_NOTIFICATION_DIALOG";

//Open user Notification Dialog
export const OPEN_USER_NOTIFICATION_DIALOG = "OPEN_USER_NOTIFICATION_DIALOG";

//Close user Notification Dialog
export const CLOSE_USER_NOTIFICATION_DIALOG = "CLOSE_USER_NOTIFICATION_DIALOG";
