import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { Typography } from "@material-ui/core";
import Button from "../../extra/Button";
import $ from "jquery";
import { getAgencyList } from "../../store/agencyTable/agency.action";
import { updateHost } from "../../store/host/host.action";
import Select from "react-select";
import ReactDropzone from "react-dropzone";
import noImage from "../../../assets/images/download.png";

const HostUpdateDialogue = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [bio, setBio] = useState("");
  const [callCharge, setCallCharge] = useState(0);
  const [uniqueId, setUniqueId] = useState(0);
  const [gender, setGender] = useState("Female");
  const [image, setImage] = useState([]);
  const [profileImage, setProfileImage] = useState([]);
  const [profileImagePath, setProfileImagePath] = useState("");
  const [country, setCountry] = useState({
    value: "",
    label: "",
  });
  const [countries, setCountries] = useState([]);
  const [age, setAge] = useState(0);
  const [targetEarnCoin, setTargetEarnCoin] = useState(0);
  const [agencys, setAgencys] = useState([]);
  const [oldAgency, setOldAgency] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const [error, setError] = useState({
    name: "",
    password: "",
    bio: "",
    age: "",
    profileImage: "",
    gender: "",
    country: "",
    callCharge: "",
    uniqueId: "",
    oldAgency: "",
    targetEarnCoin: "",
  });

  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);
  const { agency } = useSelector((state) => state.agency);

  const { allCountry } = useSelector((state) => state.admin);

  useEffect(() => {
    props.getAgencyList();
  }, []);

  useEffect(() => {
    setCountries(allCountry);
  }, [allCountry]);

  const options = allCountry.map((countryData) => {
    return {
      value: countryData.name,
      label: countryData.name,
    };
  });

  // useEffect(() => {
  //   window.onbeforeunload = dispatch({ type: CLOSE_DIALOGUE });
  // }, []);
  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setPassword(dialogueData?.password);
      setBio(dialogueData?.bio);
      setGender("female");
      setProfileImagePath(dialogueData?.profileImage);
      setImage(dialogueData?.image);
      setCountry({
        value: dialogueData?.country,
        label: dialogueData?.country,
      });
      setAge(dialogueData?.age);
      setCallCharge(dialogueData.callCharge);
      setUniqueId(dialogueData?.uniqueId);
      setOldAgency(dialogueData?.agencyId?._id);
      setTargetEarnCoin(dialogueData?.targetEarnCoin);
    }
  }, [dialogue]);

  // useEffect(
  //   () => () => {
  //     setName("");
  //     setPassword("");
  //     setBio("");
  //     setCountry("");
  //     setAge("");
  //     setGender("");
  //     setProfileImagePath("");
  //     setImage("");
  //     setCallCharge("");
  //     setUniqueId("");
  //     setProfileImage([]);
  //     setTargetEarnCoin(0);
  //     setError({
  //       name: "",
  //       bio: "",
  //       country: "",
  //       age: "",
  //       profileImage: "",
  //       password: "",
  //       callCharge: "",
  //       uniqueId: "",
  //       targetEarnCoin: "",
  //     });
  //   },
  //   [dialogue]
  // );

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  const handleSubmit = () => {
    if (selectedOption?.value) {
      setCountry(selectedOption?.value);
    }
    if (
      !name ||
      !password ||
      !country ||
      country === "select Country" ||
      !gender ||
      !bio ||
      !age ||
      age <= 17 ||
      callCharge < 0 ||
      oldAgency === "Select Agency" ||
      oldAgency === "" ||
      targetEarnCoin < 0 ||
      !profileImagePath
    ) {
      let error = {};

      if (!name) error.name = "Name Is Required !";
      if (callCharge < 0) error.callCharge = "Call Charge Is Required !";
      if (!password) error.password = "password Is Required !";
      if (!country || country === "select Country")
        error.country = "Country Is Required !";
      if (!gender) error.gender = "Gender Is Required !";
      if (!bio) error.bio = "Bio Is Required !";
      if (age <= 17) error.age = "Age not under 18 allowed !!";
      if (!age) error.age = "Age Is Required !";
      if (oldAgency === "Select Agency" || oldAgency === "")
        error.oldAgency = "Agency Is Required !";
      if (targetEarnCoin < 0)
        error.targetEarnCoin = "Invalid Target Earn Coin ";
      if (profileImage.length === 0)
        error.profileImage = "Profile Image is Required !";
      return setError({ ...error });
    } else {
      const ageValid = isNumeric(age);
      if (!ageValid) {
        return setError({
          ...error,
          age: "Invalid Age!",
        });
      }
      const formData = new FormData();

      const countries = country?.value?.toUpperCase();

      formData.append("name", name.trim());
      formData.append("password", password);
      formData.append("country", countries);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("age", age);
      formData.append("profileImage", profileImage);
      for (let i = 0; i < image.length; i++) {
        formData.append("image", image[i]);
      }
      formData.append("callCharge", callCharge);
      formData.append("hostId", dialogueData?._id);
      formData.append("agencyId", oldAgency);
      formData.append("targetEarnCoin", targetEarnCoin);
      props.updateHost(formData, dialogueData?._id);
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImage(image.concat(files));
  };

  const removeImage = (file) => {
    const newFiles = [...image];
    image.splice(newFiles.indexOf(file), 1);
    if (file.preview) {
      const images = image.filter((ele) => {
        return ele.preview !== file.preview;
      });
      setImage(images);
    } else {
      const newFiles = [...image];
      image.splice(newFiles.indexOf(file), 1);
      setImage(newFiles);
    }
  };

  const handleProfileImage = (e) => {
    setProfileImage(e.target.files[0]);
    setProfileImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Host Edit </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="col-12 col-sm-12 col-md-12 my-2 text-start">
              <Input
                label={`Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Bio `}
                  id={`bio`}
                  type={`text`}
                  placeholder={`bio`}
                  value={bio}
                  errorMessage={error.bio && error.bio}
                  onChange={(e) => {
                    setBio(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        bio: `Extra Coin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        bio: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Agency</p>
                  </div>
                  <select
                    className="form-control"
                    value={oldAgency}
                    onChange={(e) => {
                      setOldAgency(e.target.value);
                      if (e.target.value === "Select Agency") {
                        return setError({
                          ...error,
                          oldAgency: "Agency Is Required !",
                        });
                      } else {
                        return setError({
                          ...error,
                          oldAgency: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="Select Agency">
                      ---- Select Agency ----
                    </option>
                    {agency.map((res) => {
                      return <option value={res._id}>{res.name}</option>;
                    })}
                  </select>

                  {error.oldAgency && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.oldAgency}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Target Earn Coin`}
                  id={`targetEarnCoin`}
                  type={`text`}
                  value={targetEarnCoin}
                  errorMessage={error.targetEarnCoin && error.targetEarnCoin}
                  onChange={(e) => {
                    setTargetEarnCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        targetEarnCoin: `target EarnCoin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        targetEarnCoin: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`age`}
                  id={`age`}
                  type={`number`}
                  value={age}
                  placeholder={`18`}
                  errorMessage={error.age && error.age}
                  onChange={(e) => {
                    setAge(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        tag: `Tag Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        tag: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Call Charge`}
                  id={`callCharge`}
                  type={`number`}
                  value={callCharge}
                  placeholder={`Enter Call Charge`}
                  errorMessage={error.callCharge && error.callCharge}
                  onChange={(e) => {
                    setCallCharge(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        callCharge: `Call Charge Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        callCharge: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  label={`Unique Id`}
                  id={`UniqueId`}
                  type={`number`}
                  errorMessage={error.uniqueId && error.uniqueId}
                  value={uniqueId}
                  readonly={`readonly`}
                />
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold ">Password</p>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group" id="Password-toggle">
                      <a
                        href={() => false}
                        class="input-group-text"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          id="showPassword"
                          class="fa fa-eye"
                          aria-hidden="true"
                          onClick={showPassword}
                        ></i>
                      </a>
                      <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        className="form-control"
                        autoComplete="new-password"
                        errorMessage={error.password && error.password}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (!e.target.value)
                            return setError({
                              ...error,
                              password: "password Is Required !",
                            });
                          else {
                            return setError({
                              ...error,
                              password: "",
                            });
                          }
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    {error.password && (
                      <div class="pl-1 text-left">
                        <Typography
                          variant="caption"
                          color="error"
                          style={{ fontFamily: "Circular-Loom" }}
                        >
                          {error.password}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Country</p>
                  </div>
                  <Select
                    defaultValue={country}
                    value={country}
                    onChange={setCountry}
                    options={options}
                  />
                  {!selectedOption?.value && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.country}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row text-start">
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Host Image</p>
                  </div>
                  <input
                    draggable={false} 
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => handleProfileImage(e)}
                  />
                  {profileImagePath && (
                    <img
                      src={profileImagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.profileImage && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.profileImage}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2">
                <Input
                  type={`text`}
                  label={`Gender`}
                  value={gender}
                  readonly={`readonly`}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 my-2">
              <div>
                <div className="form-group mb-0">
                  <p className="form-label fw-bold mt-3 text-start">
                    Host Album
                  </p>
                </div>

                <div className="hostAlbum d-flex align-items-center">
                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                  accept="image/png, image/jpeg ,image/jpg "
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="me-4">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                  <div className="hostImagePre d-flex ms-5">
                    <div className="row">
                      {image?.length > 0 &&
                        image?.map((file, index) => {
                          return file?.type?.split("image")[0] === "" ? (
                            <>
                              <div className="col-6 col-md-4">
                                <img
                                  height="60px"
                                  width="60px"
                                  alt="app"
                                  src={file.preview}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    marginTop: 10,
                                    float: "left",
                                    objectFit: "contain",
                                    marginRight: 15,
                                  }}
                                  draggable="false"
                                />
                                <div
                                  class="img-container"
                                  style={{
                                    display: "inline",
                                    position: "relative",
                                    float: "left",
                                  }}
                                >
                                  <i
                                    class="fas fa-times-circle text-danger"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeImage(file)}
                                  ></i>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-12 col-sm-12 col-md-6 my-2">
                                <img
                                  height="60px"
                                  width="60px"
                                  alt="app"
                                  src={file}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    marginTop: 10,
                                    float: "left",
                                    objectFit: "contain",
                                    marginRight: 15,
                                  }}
                                  draggable="false"
                                />
                                <div
                                  class="img-container"
                                  style={{
                                    display: "inline",
                                    position: "relative",
                                    float: "left",
                                  }}
                                >
                                  <i
                                    class="fas fa-times-circle text-danger"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeImage(file)}
                                  ></i>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {error.image && (
                  <div class="pl-1 text-left">
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontFamily: "Circular-Loom" }}
                    >
                      {error.image}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Update`}
                btnColor={`btnBlackPrime`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyList, updateHost })(HostUpdateDialogue);
