// GET COINSELLER
export const GET_COINSELLER = "GET_COINSELLER";

// ADD COINSELLER
export const ADD_COINSELLER = "ADD_COINSELLER";

// EDIT COINSELLER
export const EDIT_COINSELLER = "EDIT_COINSELLER";

// DELETE COINSELLER
export const DELETE_COINSELLER = "DELETE_COINSELLER";

// OPEN DIALOG

export const OPEN_DIALOGUE = "OPEN_DIALOGUE";

// CLOSE DIALOG
export const CLOSE_DIALOGUE = "CLOSE_DIALOGUE";

// ADD_MONEY_OPEN_DIALOGUE

export const ADD_MONEY_OPEN_DIALOGUE = "ADD_MONEY_OPEN_DIALOGUE";

// ADD_MONEY_CLOSE_DIALOGUE
export const ADD_MONEY_CLOSE_DIALOGUE = " ADD_MONEY_CLOSE_DIALOGUE";

// add money
export const ADD_MONEY_BY_ADMIN = "ADD_MONEY_BY_ADMIN";

// Show COINSELLER
export const SHOW_COINSELLER = "SHOW_COINSELLER";

// add money
export const GET_COINSELLER_HISTORY = "GET_COINSELLER_HISTORY";
