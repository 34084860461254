import * as ActionType from "./type";
const initialState = {
  coinSeller: [],
  coinSellerHistory: [],
  dialogOpen: false,
  dialogData: null,

  monyDialogOpen: false,
  moneyDialogData: null,
};

export const coinSellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_COINSELLER:
      return {
        ...state,
        coinSeller: action.payload,
      };
    case ActionType.OPEN_DIALOGUE:
      return {
        ...state,
        dialogOpen: true,
        dialogData: action.payload || null,
      };
    case ActionType.CLOSE_DIALOGUE:
      return {
        ...state,
        dialogOpen: false,
        dialogData: null,
      };
    case ActionType.ADD_COINSELLER:
      let data = [...state.coinSeller];
      data?.unshift(action.payload);

      return {
        ...state,
        coinSeller: data,
      };

    case ActionType.EDIT_COINSELLER:
      return {
        ...state,
        coinSeller: state.coinSeller.map((coinSellerId) =>
          coinSellerId._id === action.payload.id
            ? action.payload.coinSeller
            : coinSellerId
        ),
      };

    case ActionType.ADD_MONEY_BY_ADMIN:
      return {
        ...state,
        coinSeller: state.coinSeller.map((coinSellerId) =>
          coinSellerId?._id == action.payload.id
            ? action.payload.coinSeller
            : coinSellerId
        ),
      };

    case ActionType.DELETE_COINSELLER:
      return {
        ...state,
        coinSeller: state.coinSeller.map((coinSellerData) => {
          if (coinSellerData._id === action.payload.id)
            return action.payload.data;
          else return coinSellerData;
        }),
      };

    case ActionType.ADD_MONEY_OPEN_DIALOGUE:
      return {
        ...state,
        monyDialogOpen: true,
        moneyDialogData: action.payload || null,
      };
    case ActionType.ADD_MONEY_CLOSE_DIALOGUE:
      return {
        ...state,
        monyDialogOpen: false,
        moneyDialogData: null,
      };
    case ActionType.GET_COINSELLER_HISTORY:
      return {
        ...state,
        coinSellerHistory: action.payload,
      };

    case ActionType.SHOW_COINSELLER:
      return {
        ...state,
        coinSeller: state.coinSeller.map((coinSellerData) => {
          if (coinSellerData._id === action.payload.id)
            return action.payload.data;
          else return coinSellerData;
        }),
      };

    default:
      return state;
  }
};
