import { Avatar, Button } from "@material-ui/core";
import profile from "../../assets/images/g-7.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CLOSE_DIALOGUE, OPEN_DIALOGUE } from "../store/dialogue/dialogue.type";
import NotificationDialogue from "./NotificationDialogue";
import MessageDialogue from "./MessageDialogue";
import male from "../../assets/images/maleImage.png";
import $ from "jquery";
import { ADMIN_SEND_MESSAGE_DIALOGUE_OPEN } from "../store/message/message.type";

const Navbar = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { admin } = useSelector((state) => state.admin);

  const { messageDialogue } = useSelector((state) => state.messageData);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handelMessageDialogue = () => {
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { type: "MessageAdminAll" },
    });
  };

  const handleAllHistory = () => {
    dispatch({type :CLOSE_DIALOGUE })
    window.localStorage.clear();
    navigate("/admin/allHostHistory");
  };
  return (
    <>
      <div className="mainNavbar webNav fixed-top">
        <div className="navBar bg-white boxBetween pe-0 ps-4 app-sidebar__toggle">
          <div className="navToggle">
            <i class="fa-solid fa-bars"></i>
          </div>
          <div className="navIcons  boxBetween">
            <div onClick={() => handelMessageDialogue()}>
              <i class="fa fa-envelope "></i>
            </div>
            <div
              onClick={() =>
                dispatch({
                  type: OPEN_DIALOGUE,
                  payload: { type: "Notification" },
                })
              }
            >
              <i class="fa-solid fa-bell"></i>
            </div>
            <div>
              <i
                class="fas fa-history text-grey"
                onClick={() => handleAllHistory()}
              ></i>
            </div>
            <div>
              <Link to="/admin/profile">
                <Button>
                  <Avatar alt="Profile" src={admin?.image} />
                </Button>
              </Link>
            </div>

            {dialogue && dialogueType === "Notification" && (
              <NotificationDialogue />
            )}
            {messageDialogue && <MessageDialogue />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
