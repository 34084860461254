import { Link, useNavigate } from "react-router-dom";

import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUser, userBlocked } from "../../store/users/user.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import $ from "jquery";
import male from "../../../assets/images/maleImage.png";
import { Tooltip } from "@material-ui/core";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import NotificationDialogue from "../../Pages/NotificationDialogue";
import MessageDialogue from "../../Pages/MessageDialogue";
import UserHistory from "./UserHistory/UserHistory";
import UserDetails from "./UserDetails";
import { ADMIN_SEND_MESSAGE_DIALOGUE_OPEN } from "../../store/message/message.type";

const User = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [data, setData] = useState([]);

  const [size, setSize] = useState(() => {
    const sizePageValue = sessionStorage.getItem("userSizeValue");
    return sizePageValue !== null ? sizePageValue : 10;
  });

  const [page, setPage] = useState(() => {
    const userPageValue = sessionStorage.getItem("userPage");
    return userPageValue !== null ? userPageValue : 1;
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const userCurrentPageValue = sessionStorage.getItem("userCurrentPge");
    return userCurrentPageValue !== null ? userCurrentPageValue : 1;
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const userRowPerPageValue = sessionStorage.getItem("userRowPerPage");
    return userRowPerPageValue !== null ? userRowPerPageValue : 10;
  });
  const [search, setSearch] = useState(() => {
    const searchStoredValue = sessionStorage.getItem("search");
    return searchStoredValue !== null ? searchStoredValue : "";
  });
  const [genderType, setGenderType] = useState(() => {
    const genderStoredValue = sessionStorage.getItem("gender");
    return genderStoredValue !== null ? genderStoredValue : "";
  });
  const [statusType, setStatusType] = useState(() => {
    const statusStoredValue = sessionStorage.getItem("statusType");
    return statusStoredValue !== null ? statusStoredValue : "";
  });
  const [blockedType, setBlockedType] = useState(() => {
    const blockStoredValue = sessionStorage.getItem("blockedType");
    return blockStoredValue !== null ? blockStoredValue : "";
  });
  const [countryType, setCountryType] = useState(() => {
    const countryStoredValue = sessionStorage.getItem("countryType");
    return countryStoredValue !== null ? countryStoredValue : "";
  });
  const [planType, setPlanType] = useState(() => {
    const planStoredValue = sessionStorage.getItem("planType");
    return planStoredValue !== null ? planStoredValue : "";
  });
  const [type, setType] = useState(() => {
    const typeStoredValue = sessionStorage.getItem("typeHost");
    return typeStoredValue !== null ? typeStoredValue : "0";
  });
  const [field, setField] = useState(() => {
    const fieldStoredValue = sessionStorage.getItem("filedTypeHost");
    return fieldStoredValue !== null ? fieldStoredValue : "createdAt";
  });
  const [refresh, setRefresh] = useState(false);

  const { allCountry } = useSelector((state) => state.admin);
  const { user, total } = useSelector((state) => state.user);

  const { messageDialogue } = useSelector((state) => state.messageData);

  useEffect(() => {
    props.getUser(
      search,
      currentPage,
      size,
      genderType,
      statusType,
      planType,
      blockedType,
      countryType,
      field,
      type
    );
  }, [
    search,
    currentPage,
    size,
    genderType,
    statusType,
    planType,
    blockedType,
    countryType,
    field,
    type,
  ]);

  useEffect(() => {
    sessionStorage.setItem("userSizeValue", size);
    sessionStorage.setItem("userPage", page);
    sessionStorage.setItem("userCurrentPge", currentPage);
    sessionStorage.setItem("userRowPerPage", rowsPerPage);
    sessionStorage.setItem("search", search);
    sessionStorage.setItem("gender", genderType);
    sessionStorage.setItem("statusType", statusType);
    sessionStorage.setItem("blockedType", blockedType);
    sessionStorage.setItem("countryType", countryType);
    sessionStorage.setItem("planType", planType);
    sessionStorage.setItem("typeHost", type);
    sessionStorage.setItem("filedTypeHost", field);
  }, [
    search,
    type,
    field,
    genderType,
    statusType,
    blockedType,
    countryType,
    size,
    page,
    currentPage,
    rowsPerPage,
    planType,
  ]);

  useEffect(() => {
    props.getUser(
      search,
      currentPage,
      size,
      genderType,
      statusType,
      planType,
      blockedType,
      countryType,
      field,
      type
    );
  }, [refresh]);

  useEffect(() => {
    setPage(1);
    setRowsPerPage(10);
    setCurrentPage(1);
    setSize(10);
  }, [statusType, blockedType, countryType, field, type, genderType, planType]);

  useEffect(() => {
    setData(user);
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  const allReset = () => {
    setGenderType("");
    setSearch("");
    setStatusType("");
    setPlanType("");
    setCountryType("");
    setBlockedType("");
    sessionStorage.removeItem("search");
    sessionStorage.removeItem("gender");
    sessionStorage.removeItem("statusType");
    sessionStorage.removeItem("blockedType");
    sessionStorage.removeItem("countryType");
    sessionStorage.removeItem("planType");
    sessionStorage.removeItem("typeHost");
    sessionStorage.removeItem("filedTypeHost");
    props.getUser("", 1, 10, "", "", "", "", "", "createdAt", "0");
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handelMessageDialogue = (data) => {
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { id: data?._id, type: "user" },
    });
  };

  let date;

  const handleClick = (row) => {
    props.userBlocked(row, row.isBlock === true ? false : true);
  };
  const dispatch = useDispatch();

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => (
        <img
          draggable={false}
          src={row.image}
          className={`table-image d-block mx-auto`}
          height={60}
          width={60}
          alt=""
          onClick={() => handleProfile(row, "userProfile")}
        />
      ),
    },
    {
      Header: "Name",
      body: "name",
      sorting: { type: "server" },
      Cell: ({ row }) => {
        var str = row?.name;
        if (str?.length > 15) str = str.substring(0, 15) + "...";
        return <span>{str}</span>;
      },
    },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <span>{row.email ? row.email : "qwee@gmail.com"}</span>
      ),
    },
    { Header: "Unique Id", body: "uniqueId" },
    { Header: "Gender", body: "gender" },
    { Header: "Country", body: "country" },
    { Header: "Coin", body: "coin", sorting: { type: "server" } },
    {
      Header: "Block",
      body: "isBlock",
      Cell: ({ row }) => (
        <ToggleSwitch value={row.isBlock} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Created Date",
      body: "createdAt",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },

    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <Tooltip title="Action" placeholder="bottom">
          <div class="btn-group mt-2 mb-2 mx-2 ">
            <i
              class="fa-solid fa-ellipsis-vertical fs-4 p-3"
              data-bs-toggle="dropdown"
            ></i>

            <ul class="dropdown-menu my-1" role="menu">
              <li style={{ cursor: "pointer" }}>
                <a onClick={() => handleProfile(row, "userProfile")}>
                  <i class="fas fa-info-circle pe-2 fs-6"></i>
                  <span> Profile</span>
                </a>
              </li>
              <li className="text-muted " style={{ cursor: "pointer" }}>
                <a
                  href={() => false}
                  onClick={() => handleProfile(row, "userHistory")}
                >
                  <i class="fas fa-history pe-2 fs-6"></i>
                  <span> History</span>
                </a>
              </li>
              <li className="text-muted" style={{ cursor: "pointer" }}>
                <a
                  href={() => false}
                  onClick={() => handelMessageDialogue(row)}
                >
                  <i class="fa-solid fa-envelope pe-2  fs-6"></i>
                  <span> Message</span>
                </a>
              </li>
              <li className="text-muted cursor-pointer">
                <a
                  href={() => false}
                  onClick={() =>
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: {
                        type: "NotificationUser",
                        data: row,
                      },
                    })
                  }
                >
                  <i class="fa-solid fa-bell fs-6"></i>
                  <span> Notification</span>
                </a>
              </li>
            </ul>
          </div>
        </Tooltip>
      ),
    },

    // add more columns as needed
  ];

  const handleChildValue = (value) => {
    setType(type === 0 ? 1 : 0);
    setField(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable position-relative">
        {dialogue && dialogueType === "userHistory" && (
          <>
            <div className="mainHostHistory">
              <UserHistory />
            </div>
          </>
        )}
        {dialogue && dialogueType === "userProfile" && (
          <>
            <div className="mainHostHistory">
              <UserDetails />
            </div>
          </>
        )}
        <div
          className="planTable px-3"
          style={{
            display: `${
              dialogueType === "userProfile"
                ? "none"
                : dialogueType === "userHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="primeHeader mt-1">
            <div className="row  align-items-center mt-3">
              <div className="col-6 title-head title-head ">
                <Title name={`User`} />
              </div>
              <div className="col-6 d-flex justify-content-end ">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">User </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan">
              <div className="coin-plan-btn">
                <div className="row ps-4 py-3">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-7">
                    <Button
                      newClass={`themeFont  userBtn text-white mx-3`}
                      btnColor={`btn-danger`}
                      btnIcon={`fa-solid fa-rotate-right me-2`}
                      btnName={`Refresh`}
                      onClick={() =>
                        setRefresh(refresh === true ? false : true)
                      }
                      style={{
                        borderRadius: "5px",
                        marginRight: "auto",
                        width: "95px",
                      }}
                    />
                    <div class="btn-group mt-2 mb-2 mx-2 ">
                      <button
                        type="button"
                        class="btn btn-primary btn-pill dropdown-toggle  "
                        data-bs-toggle="dropdown"
                        onClick={() => setGenderType(genderType)}
                      >
                        {genderType == null || !genderType ? (
                          <span class="caret">Gender</span>
                        ) : (
                          <span class="caret text-capitalize">
                            {genderType}
                          </span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setGenderType("male")}
                          >
                            Male
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setGenderType("female")}
                          >
                            Female
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setGenderType("")}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-success btn-pill dropdown-toggle text-white border-0"
                        data-bs-toggle="dropdown"
                      >
                        {statusType ? (
                          <span class="caret">{statusType}</span>
                        ) : (
                          <span class="caret text-capitalize ">Status</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("online");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "online"
                              );
                            }}
                          >
                            Online
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("offline");
                              sessionStorage.setItem(
                                "statusTypeHost",
                                "offline"
                              );
                            }}
                          >
                            Offline
                          </a>
                        </li>

                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setStatusType("");
                              sessionStorage.setItem("statusTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-info btn-pill dropdown-toggle border-0 text-white"
                        data-bs-toggle="dropdown"
                      >
                        {planType == null || !planType ? (
                          <span class="caret">Purchase</span>
                        ) : (
                          <span class="caret text-capitalize">{planType}</span>
                        )}
                      </button>
                      <ul
                        class="dropdown-menu my-1"
                        role="menu"
                        style={{ maxHeight: "400px" }}
                      >
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setPlanType("withPurchase")}
                          >
                            With Purchase
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setPlanType("withOutPurchase")}
                          >
                            Without Purchase
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => setPlanType("")}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-warning btn-pill dropdown-toggle text-capitalize text-white"
                        data-bs-toggle="dropdown"
                      >
                        {blockedType ? (
                          <span class="caret text-capitalize">
                            {blockedType}
                          </span>
                        ) : (
                          <span class="caret ">Blocked</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockedType("block");
                              sessionStorage.setItem("blockTypeHost", "block");
                            }}
                          >
                            Block Host
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockedType("unblock");
                              sessionStorage.setItem(
                                "blockTypeHost",
                                "unblock"
                              );
                            }}
                          >
                            Unblock Host
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setBlockedType("");
                              sessionStorage.setItem("blockTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-success btn-pill dropdown-toggle text-white"
                        data-bs-toggle="dropdown"
                      >
                        {countryType ? (
                          <span class="caret">{countryType}</span>
                        ) : (
                          <span class="caret text-capitalize">Country</span>
                        )}
                      </button>
                      <ul class="dropdown-menu my-1" role="menu">
                        {allCountry?.map((res) => {
                          return (
                            <>
                              <li>
                                <a
                                  className="dropdown-option-text text-capitalize"
                                  href={() => false}
                                  onClick={() => {
                                    setCountryType(res.name.toLowerCase());
                                    sessionStorage.setItem(
                                      "countryTypeHost",
                                      res.name.toLowerCase()
                                    );
                                  }}
                                >
                                  {res.name.toLowerCase()}
                                </a>
                              </li>
                            </>
                          );
                        })}

                        <li>
                          <a
                            className="dropdown-option-text"
                            href={() => false}
                            onClick={() => {
                              setCountryType("");
                              sessionStorage.setItem("countryTypeHost", "");
                            }}
                          >
                            Reset
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-group mt-2 mb-2 mx-2">
                      <button
                        type="button"
                        class="btn btn-dark text-white"
                        onClick={allReset}
                      >
                        <span class="caret">Reset All</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-5">
                    <Searching
                      type={"server"}
                      data={user}
                      setData={setData}
                      onFilterData={handleFilterData}
                      serverSearching={handleFilterData}
                      button={true}
                      searchValue={search}
                      setSearchValue={setSearch}
                    />
                  </div>
                  {dialogue && dialogueType === "NotificationUser" && (
                    <NotificationDialogue />
                  )}
                </div>
              </div>
            </div>
            <div className="primeMain border-top">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={page}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <Pagination
              component="div"
              count={user?.length}
              type={"server"}
              onPageChange={handleChangePage}
              serverPerPage={rowsPerPage}
              totalData={total}
              serverPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUser, userBlocked })(User);
