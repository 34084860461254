// login
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

// admin profile
export const GET_PROFILE = "GET_PROFILE";

//  admin profile update
export const UPDATE_PROFILE = "UPDATE_PROFILE";

// Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// Update Image Profile
export const UPDATE_IMAGE_PROFILE = "UPDATE_IMAGE_PROFILE";

// send Email
export const SEND_EMAIL = "SEND_EMAIL";

// country list
export const ALL_COUNTRY ="ALL_COUNTRY" 
