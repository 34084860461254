import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../util/config";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import { Typography } from "@material-ui/core";
import IOSSwitch from "@material-ui/core/Switch";
import Input from "../../extra/Input";
import { createBanner, updateBanner } from "../../store/banner/banner.action";
import $ from "jquery";
import noImage from "../../../assets/images/noImage.png";

const BannerDialogue = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [url, setUrl] = useState("");
  const [isPurchase, setPurchase] = useState(false);
  const [number, setNumber] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    url: "",
  });

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      setUrl(dialogueData?.url);
      setImagePath(baseURL + dialogueData?.image);
      setPurchase(dialogueData?.isPurchase);
      setNumber(dialogueData?.number);
    }
  }, [dialogue]);

  useEffect(
    () => () => {
      setUrl("");
      setImagePath("");
      setImage([]);
      setNumber("");
      setPurchase(false);
      setError({
        url: "",
        imagePath: "",
        number: "",
      });
    },
    [dialogue]
  );
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  const handleSubmit = () => {
    if (!url || !imagePath) {
      let error = {};
      if (!url) error.url = "Url Is Required !";
      if (!imagePath) error.image = "Image is required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("url", url);
      formData.append("number", number);
      formData.append("isPurchase", isPurchase);
      formData.append("image", image);

      if (dialogueData) {
        props.updateBanner(formData, dialogueData._id);
      } else {
        props.createBanner(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <>
      <div className="mainDialogue fade-in">
        <div className="Dialogue">
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">
              {dialogueData ? "Edit Banner" : "Create banner"}
            </div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 my-2">
                <Input
                  label={`URL`}
                  id={`url`}
                  placeholder={baseURL + "planLIst/"}
                  type={`text`}
                  value={url}
                  errorMessage={error.url && error.url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        url: `Url Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        url: "",
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12  my-2">
                <div>
                  <div className="form-group mb-0">
                    <p className="form-label fw-bold "> Banner</p>
                  </div>
                  <input
                    type="file"
                  accept="image/png, image/jpeg ,image/jpg "
                    className="form-control "
                    autocomplete="off"
                    onChange={(e) => {
                      handleImage(e);
                      if (!e.target.files[0]) {
                        return setError({
                          ...error,
                          image: `Image Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          image: "",
                        });
                      }
                    }}
                  />
                  {imagePath && (
                    <img
                      src={imagePath}
                      alt="hostImage"
                      draggable="false"
                      className="p-3 "
                      width={100}
                    />
                  )}
                  {error.image && (
                    <div class="pl-1 text-left">
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontFamily: "Circular-Loom" }}
                      >
                        {error.image}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              {!dialogueData?.isWebPurchase && (
                <>
                  <div className="row">
                    <div className="col-6 mt-2">
                      <label class="float-left dialog__input__title">
                        Purchase Banner
                      </label>
                      <div>
                        <IOSSwitch
                          value={isPurchase}
                          checked={isPurchase}
                          color="primary"
                          onChange={(e) => {
                            setPurchase(isPurchase === true ? false : true);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                isPurchase: "Purchase Is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                isPurchase: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {isPurchase && (
                      <div className="col-6 mt-2">
                        <Input
                          label={`Mobile No`}
                          id={`mobileNo`}
                          placeholder={`Whatsapp Number`}
                          type={`number`}
                          value={number}
                          errorMessage={error.number && error.number}
                          onChange={(e) => {
                            setNumber(
                              (e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10))
                            );
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                number: "number Is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                number: "",
                              });
                            }
                          }}
                          onKeyPress={handleKeyPress}
                        />

                        {error.number && (
                          <div class="pl-1 text-left">
                            <Typography
                              variant="caption"
                              color="error"
                              style={{ fontFamily: "Circular-Loom" }}
                            >
                              {error.number}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              {dialogueData?.data ? (
                <Button
                  btnName={`Update`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
              )}

              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { createBanner, updateBanner })(BannerDialogue);
