import {
  GET_GIFT,
  CREATE_NEW_GIFT,
  OPEN_GIFT_DIALOG,
  CLOSE_GIFT_DIALOG,
  EDIT_GIFT,
  DELETE_GIFT,
} from "./types";

const initialState = {
  gift: [],
  dialog: false,
  dialogData: null,
};

export const giftReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GIFT:
      return {
        ...state,
        gift: action.payload,
      };
    case CREATE_NEW_GIFT:
      const data = [...action.payload, ...state.gift];
      return {
        ...state,
        gift: data,
      };

      case EDIT_GIFT:
        
        return {
          ...state,
        gift: state.gift?.map((gift) => {
          return {
            ...gift,
            gift: gift.gift?.map((g) =>
              g._id === action.payload.id ? action.payload.data : g
            ),
          };
        }),
      };

    case DELETE_GIFT:
      return {
        ...state,
        gift: state.gift.map((gift) => {
          return {
            ...gift,
            gift: gift.gift.filter((g) => g._id !== action.payload),
          };
        }),
      };
    case OPEN_GIFT_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_GIFT_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};
