import * as ActionType from "./user.type";
import axios from "axios";
import { baseURL, key } from "../../util/config";
import { setToast } from "../../util/toast";

export const getUser =
  (
    searchUser,
    page,
    size,
    genderType,
    statusType,
    planType,
    blockedType,
    countryType,
    field,
    type
  ) =>
  (dispatch) => {
    ;
    axios
      .get(
        `${baseURL}user/userList?search=${searchUser}&start=${page}&limit=${size}&genderType=${genderType}&statusType=${statusType}&purchaseType=${planType}&blockedType=${blockedType}&country=${countryType}&field=${field}&sortType=${type}`
      )

      .then((res) => {
        ;
        console.log(res.data);
        dispatch({ type: ActionType.GET_USERS, payload: res.data });
      })
      .catch((error) => console.log(error));
  };

export const getMostPlanPurchaseUser =
  (searchUser, page, size) => (dispatch) => {
    const request = {
      method: "GET",
      headers: { "Content-Type": "application/json", key: key },
    };
    fetch(
      `${baseURL}user/richUser?search=${searchUser}&start=${page}&limit=${size}`,
      request
    )
      .then((response) => response.json())
      .then((res) => {
        dispatch({ type: ActionType.GET_PLAN_PURCHASE_USERS, payload: res });
      })
      .catch((error) => console.log(error));
  };

// user Profile
export const singleGetUser = (userID) => (dispatch) => {
  axios
    .get(`user/profile?userId=${userID}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.GET_SINGLE_USERS, payload: res.data.user });
        // console.log("User", res.data.user);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

// UPDATE USER COIN
export const upadteUserCoin = (val, ids) => (dispatch) => {
  axios
    .post(`user/addLessCoin`, { userId: ids, coin: val })
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_USER_COIN,
          payload: { editUserCoin: res.data.user },
        });
        console.log("res is Upadte", res.data.user);
        setToast("success", "User Coin Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.error(error));
  console.log(ids, val);
};

//Get User History
export const getUserHistory =
  (userId, type, start, limit, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `historyForUser?userId=${userId}&type=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: ActionType.GET_USER_HISTORY,
          payload: {
            history: res.data.history,
            totalCallCharge: res.data.totalCallCharge,
            totalData: res.data.total,
            totalCoin: res.data.totalCallCharge,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

// userBlocked

export const userBlocked = (users, block) => (dispatch) => {
  axios
    .put(`user/block/${users._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.BLOCKED_USER,
          payload: { data: res.data.user, id: users._id },
        });
        setToast(
          "success",
          `${users.name} Is ${
            block === true ? "Blocked" : "Unblocked"
          } Successfully!`
        );
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
