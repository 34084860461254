import dayjs from "dayjs";
import React, { createContext, useContext } from "react";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

const Analytics = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //Apply button function for analytic
  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    props.analyticsStartDate(start);
    props.analyticsStartEnd(end);
  };

  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker?.element.val("");
    setStartDate("");
    setEndDate("");
    props.analyticsStartDate("");
    props.analyticsStartEnd("");
  };
  return (
    <>
      <div className="d-flex m-2 align-items-baseline">
        <DateRangePicker
          initialSettings={{
            autoUpdateInput: false,
            locale: {
              cancelLabel: "Clear",
            },
            maxDate: new Date(),
            buttonClasses: ["btn btn-dark"],
          }}
          onApply={handleApply}
          onCancel={handleCancel}
        >
          <input
            type="text"
            readOnly
            class="daterange form-control float-left  mr-4 "
            value="Select Date"
            style={{
              width: 120,
              fontWeight: 500,
              cursor: "pointer",
            }}
          />
        </DateRangePicker>
        {startDate === "" || endDate === "" ? (
          <div className="ml-3 mt-2  fs-5  fw-bold"></div>
        ) : (
          <div
            className="dateShow mt-2 ms-3 fw-bold"
            style={{ fontSize: "15px" }}
          >
            <span className="mr-2 start-date">{startDate}</span>
            <span className="mr-2 to-date"> To </span>
            <span className="end-date">{endDate}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Analytics;
