import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { redeemSolved } from "../../store/redeem/redeem.action";
import dayjs from "dayjs";
import IOSSwitch from "@material-ui/core/Switch";
import { TablePagination } from "@material-ui/core";

import $ from "jquery";

import { baseURL } from "../../util/config";
// import { OPEN_FEEDBACK_DIALOG } from "../../store/feedBack/feedBack.type";
// import FeedbackDetailsDialog from "../Dialog/FeedbackDetailsDialog";

//datepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { warning } from "../../util/Alert";
import Pagination from "../../extra/Pagination";
import Title from "../../extra/Title";

const RedeemSolved = (props) => {
  const { redeemSolved } = useSelector((state) => state.redeem);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPage, setDataPage] = useState(0);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [sorting, setSorting] = useState({
    settlementCoin: false,
    agencyCommission: false,
    penaltyOrBonus: false,
    commissionRate: false,
    hostSalary: false,
    total: false,
    dollar: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    props.redeemSolved("", startDate, endDate);
  }, []);

  useEffect(() => {
    setData(redeemSolved);
  }, [redeemSolved]);

  // const handleFeedBack = (id) => {
  //   props.feedBackSolved(id);
  // };

  useEffect(() => {
    setPage(0);
  }, [dataPage]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    setSorting((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    let arraySort;
    if (!sorting[field]) {
      arraySort = data.sort((a, b) => a[field] - b[field]);
    } else {
      arraySort = data.sort((a, b) => b[field] - a[field]);
    }
    setData([...arraySort]);
  };
  //handle Search Function
  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const data = redeemSolved.filter((data) => {
        return (
          data?.agencyName?.toUpperCase()?.indexOf(value) > -1 ||
          data?.uniqueId?.toString()?.indexOf(value) > -1 ||
          data?.settlementCoin?.toString()?.indexOf(value) > -1 ||
          data?.hostSalary?.toString()?.indexOf(value) > -1 ||
          data?.total?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(redeemSolved);
    }
  };

  // //Handle Open Feedback Dialog
  // const openFeedbackDialog = (data) => {
  //   dispatch({ type: OPEN_FEEDBACK_DIALOG, payload: data });
  // };

  // //Apply button function for analytic
  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");

    const end = dayjs(picker.endDate).format("YYYY-MM-DD");

    setStartDate(start);
    setEndDate(end);
    props.redeemSolved("custom", start, end);
  };

  // //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker.element.val("");
    setStartDate("ALL");
    setEndDate("ALL");
    props.redeemSolved("", startDate, endDate);
  };

  //Handle Redirect User Settlement Page
  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
    };
    navigate("/admin/agency/agencySettlement", { state: state });
  };

  return (
    <>
      <div className=" primeHeader">
        <div className="row ">
          <div className="col-6 title-head title-head ">
            <Title name={`Redeem Solved`} />
          </div>
          <div className="col-6 d-flex justify-content-end mt-3">
            <ol class="breadcrumb1 pb-0 mb-0">
              <li class="breadcrumb-item1">
                <p to="/admin/dashboard">Dashboard</p>
              </li>
              <li class="breadcrumb-item1 activeItem">Redeem Solved</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="d-flex my-3 "></div>
      <div className="card border-0 m-3">
        <div className="card-body p-0">
          <div className="row m-5">
            <div className="col-8 d-flex align-items-center">
              <div>
                <DateRangePicker
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                      cancelLabel: "Clear",
                    },
                    maxDate: new Date(),

                    buttonClasses: ["btn btn-dark"],
                  }}
                  onApply={handleApply}
                  onCancel={handleCancel}
                >
                  <input
                    readOnly
                    type="text"
                    class="daterange form-control float-left bg-primary text-white"
                    value="Select Date"
                    style={{ width: 120, fontWeight: 700 }}
                  />
                </DateRangePicker>
              </div>

              {startDate === "ALL" || endDate === "ALL" ? (
                ""
              ) : (
                <div className="dateShow ms-3 fs-5 text-primary fw-bold">
                  <span className="me-3">{startDate}</span>
                  <span className="me-3">To</span>
                  <span>{endDate}</span>
                </div>
              )}
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 ">
              <form action="">
                <div className="input-group  border rounded-pill">
                  <input
                    type="search"
                    placeholder="Searching for..."
                    aria-describedby="button-addon4"
                    className="form-control bg-none border-0 rounded-pill searchBar"
                    onChange={handleSearch}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="table-responsive">
            <table
              className="table table-hover card-table table-vcenter text-nowrap settlementTable"
              style={{ backgroundColor: "#fbfafa" }}
            >
              <thead>
                <tr className="text-center">
                  <th className="fw-bold border-top">ID</th>
                  <th className="fw-bold border-top">Agency Code</th>
                  <th className="fw-bold border-top">Agency Name</th>
                  <th className="fw-bold border-top">Start Date</th>
                  <th className="fw-bold border-top">End Date</th>
                  <th className="fw-bold border-top bg-dark3 border-top">
                    Revenue
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("settlementCoin")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark4 border-top">
                    Agency Commission
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("commissionRate")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark2 border-top">
                    Total
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("total")}
                    ></i>
                  </th>
                  <th className="fw-bold bg-dark2 border-top">
                    Total Amount
                    <i
                      className="fa-solid fa-arrow-right-arrow-left deg90 ms-1"
                      onClick={() => handleSort("dollar")}
                    ></i>
                  </th>
                  <th className="fw-bold border-top">Pay</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((redeemData, index) => {
                      return (
                        <>
                          <tr className="text-center">
                            <td className="border-bottom">{page * rowsPerPage + parseInt(index) + 1}</td>
                            <td className="border-bottom">{redeemData?.agencyCode.code} </td>
                            <td  className="border-bottom">{redeemData?.agencyName} </td>
                            <td className="fw-bold  border-bottom">{redeemData.startDate}</td>
                            <td className="fw-bold border-bottom">{redeemData.endDate}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold cursor-pointer bg-dark3 border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {parseInt(redeemData?.settlementCoin) +
                                parseInt(redeemData?.hostSalary)}
                            </td>
                            <td className="fw-bold bg-dark4 border-bottom">
                              {redeemData.agencyCommission}{" "}
                              <span className="text-danger">
                                {redeemData?.commissionRate
                                  ? redeemData?.commissionRate !== 0 &&
                                    `(${redeemData?.commissionRate}%)`
                                  : ""}
                              </span>
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-danger cursor-pointer bg-dark2 border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {redeemData?.total}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="fw-bold text-info cursor-pointer bg-dark2 border-bottom"
                              onClick={() =>
                                handleLoadSettlementPage(redeemData)
                              }
                            >
                              {redeemData?.dollar}$
                            </td>
                            <td className="border-bottom">
                              <i class="fa-solid fa-circle-check fs-5 text-success"></i>
                            </td>
                          </tr>
                        </>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan="11" className="text-center">
                      No Data Found !
                    </td>
                  </tr>
                )}

                <td colSpan="10">
                  <Pagination
                    component="div"
                    count={redeemSolved?.length}
                    serverPage={page}
                    type={"client"}
                    onPageChange={handleChangePage}
                    serverPerPage={rowsPerPage}
                    totalData={redeemSolved?.length}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <FeedbackDetailsDialog /> */}
    </>
  );
};

export default connect(null, { redeemSolved })(RedeemSolved);
