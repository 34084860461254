import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Title from "../../extra/Title";
import SettingBox from "../../Table/setting/SettingBox";
import Input from "../../extra/Input";
import { Typography } from "@material-ui/core";
import {
  getSetting,
  updateSetting,
  handleToggleSwitch,
} from "../../store/setting/setting.action";

const AppSetting = (props) => {
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    props.getSetting();
  }, []);
  // box 1
  const [referralBonus, setReferralBonus] = useState(0);
  const [loginBonus, setLoginBonus] = useState(0);
  const [maxLoginBonus, setMaxLoginBonus] = useState(0);
  const [hostSalary, setHostSalary] = useState("");
  const [hostWithdrawalLimit, setHostWithdrawalLimit] = useState("");

  const [durationOfFreeCall, setDurationOfFreeCall] = useState(0);
  const [key, setKey] = useState("");
  const [certificates, setCertificates] = useState("");
  const [redirectMessage, setRedirectMessage] = useState("");
  const [redirectAppUrl, setRedirectAppUrl] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [appActiveSwitch, setAppActiveSwitch] = useState(false);
  const [networkPopupEnable, setNetworkPopupEnable] = useState(false);
  const [genderSelection, setGenderSelection] = useState(true);
  const [allHostOnlineEnable, setAllHostOnlineEnable] = useState(true);
  const [onlineButtonShow, setOnlineButtonShow] = useState(true);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [termAndCondition, setTermAndCondition] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [country, setCountry] = useState("");
  const [addCountry, setAddCountry] = useState([]);
  const [videoCall, setVideoCall] = useState(0);
  const [minimumLiveTimeForHost, setMinimumLiveTimeForHost] = useState("");
  const [popupString, setPopupString] = useState("");
  const [googleAPIKey, setGoogleAPIKey] = useState("");
  const [letestVersonCode, setletestVersonCode] = useState(0);
  const [letestVersonCodeHost, setLetestVersonCodeHost] = useState(0);
  const [notificationType, setNotificationType] = useState(1);
  const [commonText, setCommonText] = useState(1);
  const [topUpText, setTopUpText] = useState("");
  const [videoDelaySecond, setVideoDelaySecond] = useState("");
  const [restartAppEnable, setRestartAppEnable] = useState(false);

  const [error, setError] = useState({
    referralBonus: "",
    loginBonus: "",
    maxLoginBonus: "",
    chargeForMatch: "",
    videoCall: "",
    currency: "",
    googlePlayKey: "",
    googlePlayEmail: "",
    stripePublishableKey: "",
    stripeSecretKey: "",
    privacyPolicyLink: "",
    privacyPolicyText: "",
    welcomeMessage: "",
    redirectAppUrl: "",
    redirectMessage: "",
    durationOfFreeCall: "",
    chargeForMessage: "",
    hostSalary: "",
    hostWithdrawalLimit: "",
    minimumLiveTimeForHost: "",
    termAndCondition: "",
    letestVersonCode: "",
    letestVersonCodeHost: "",
    commonText: "",
    topUpText: "",
    videoDelaySecond: "",
  });

  useEffect(() => {
    setVideoCall(setting?.numberOfFreeVideoCall);
    setRedirectMessage(setting?.redirectMessage);
    setReferralBonus(setting?.referralBonus);
    setLoginBonus(setting?.loginBonus);
    setMaxLoginBonus(setting?.maxLoginBonus);
    setKey(setting?.agoraKey);
    setCertificates(setting?.agoraCertificate);
    setWelcomeMessage(setting?.welcomeMessage);
    setRedirectAppUrl(setting?.redirectAppUrl);
    setAppActiveSwitch(setting?.isAppActive);
    setPrivacyPolicyLink(setting?.privacyPolicyLink);
    setPrivacyPolicyText(setting?.privacyPolicyText);
    setHostSalary(setting?.hostSalary);
    setHostWithdrawalLimit(setting?.hostWithdrawalLimit);
    setDurationOfFreeCall(setting?.durationOfFreeCall);
    setAddCountry(setting?.location);
    setMinimumLiveTimeForHost(setting?.minimumLiveTimeForHost);
    setTermAndCondition(setting?.termAndCondition);
    setPopupString(setting?.popupString);
    setletestVersonCode(setting?.letestVersonCode);
    setLetestVersonCodeHost(setting?.letestVersonCodeHost);
    setGenderSelection(setting?.genderSelectionForRandomMatch);
    setAllHostOnlineEnable(setting?.allHostOnlineEnable);
    setOnlineButtonShow(setting?.onlineButtonShow);
    setGoogleAPIKey(setting?.googleAPIKey);
    setNotificationType(setting?.notificationType);
    setCommonText(setting?.commonText);
    setTopUpText(setting?.topUpText);
    setVideoDelaySecond(setting?.videoDelaySecond);
    setNetworkPopupEnable(setting?.networkPopupEnable);
    setRestartAppEnable(setting?.restartAppEnable);
  }, [setting]);

  const handleSubmit = () => {
    if (
      !referralBonus ||
      loginBonus < 0 ||
      maxLoginBonus < 0 ||
      !key ||
      !certificates ||
      !welcomeMessage ||
      !privacyPolicyLink ||
      !privacyPolicyText ||
      !termAndCondition ||
      minimumLiveTimeForHost < 0 ||
      letestVersonCode < 0 ||
      letestVersonCodeHost < 0 ||
      videoDelaySecond < 0
    ) {
      let error = {};

      if (!welcomeMessage)
        error.welcomeMessage = "Welcome Message Is Required!";
      if (!referralBonus)
        error.referralBonus = "Referral Bonus Number is required!";
      if (loginBonus) error.loginBonus = "Min login Bonus  Is Required !";
      if (maxLoginBonus) error.maxLoginBonus = "Max login Bonus  Is Required !";
      if (!certificates) error.certificates = "Agora Certificate Is Required !";
      if (!termAndCondition) error.termAndCondition = "Condition Is Required !";
      if (!key) error.key = "Agora key Is Required !";
      if (!privacyPolicyLink) error.privacyPolicyLink = "Link is required!";
      if (!privacyPolicyText) error.privacyPolicyText = "Text is required!";
      if (minimumLiveTimeForHost < 0)
        error.minimumLiveTimeForHost = "Invalid Minimum Live Time !";
      if (letestVersonCodeHost < 0)
        error.letestVersonCodeHost = "Invalid Latest Version Code !";
      if (letestVersonCode < 0)
        error.letestVersonCode = "Invalid Latest Version Code !";
      if (videoDelaySecond < 0)
        error.videoDelaySecond = "Invalid Video Delay Time!";

      return setError({
        ...error,
      });
    } else {
      const data = {
        redirectMessage: redirectMessage.trim(),
        referralBonus,
        loginBonus,
        maxLoginBonus,
        agoraKey: key,
        numberOfFreeVideoCall: videoCall,
        agoraCertificate: certificates,
        welcomeMessage: welcomeMessage.trim(),
        redirectAppUrl: redirectAppUrl.trim(),
        durationOfFreeCall,
        hostWithdrawalLimit,
        hostSalary,
        privacyPolicyLink,
        privacyPolicyText,
        location: addCountry,
        minimumLiveTimeForHost,
        termAndCondition,
        popupString,
        letestVersonCode,
        letestVersonCodeHost,
        googleAPIKey,
        notificationType,
        commonText,
        topUpText,
        videoDelaySecond,
      };
      props.updateSetting(setting?._id, data);
    }
  };

  const handleClick = (type) => {
    props.handleToggleSwitch(setting?._id, type);
  };

  const addCountryList = (e) => {
    e.preventDefault();
    setAddCountry((old) => {
      return [...old, country];
    });
    setCountry("");
  };

  const onRemove = (id) => {
    setAddCountry((old) => {
      return old.filter((arry, index) => {
        return index !== id;
      });
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };
  return (
    <>
      {/* row 1 */}
      <div>
        <div className="mainSettingBar">
          <div className="settingBar ">
            <div className="settingHeader primeHeader">
              <div className="row align-items-center justify-content-center px-3">
                <div className="col-6">
                  <Title name={`Setting`} />
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <ol class="breadcrumb1">
                    <li class="breadcrumb-item1 pb-0 mb-0">
                      <p to="/admin/dashboard">Dashboard</p>
                    </li>
                    <li class="breadcrumb-item1 activeItem"> setting </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="settingMain">
              <div className="row">
                <div className="col-6">
                  <Title name={`App Setting`} />
                  {/*-------------- Box 1 --------------*/}
                  <SettingBox
                    submit={handleSubmit}
                    title={`Is App Active`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: appActiveSwitch,
                      handleClick: () => {
                        handleClick("app");
                      },
                    }}
                  >
                    <Input
                      type={`text`}
                      label={`Privacy Policy Link`}
                      value={privacyPolicyLink}
                      errorMessage={error.privacyPolicyLink}
                      newClass={`col-6`}
                      placeholder={`Enter You Privacy Policy Link....`}
                      onChange={(e) => {
                        setPrivacyPolicyLink(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            privacyPolicyLink: "Link is required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            privacyPolicyLink: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />

                    <Input
                      type={`text`}
                      errorMessage={error.termAndCondition}
                      label={`Privacy Policy Text`}
                      value={termAndCondition}
                      newClass={`col-6`}
                      placeholder={`Privacy Policy Text....`}
                      onChange={(e) => {
                        setTermAndCondition(e.target.value);
                        
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={`Latest Version Code User`}
                      errorMessage={error.letestVersonCode}
                      value={letestVersonCode}
                      newClass={`col-6`}
                      placeholder={`Enter Latest Version Code User`}
                      onChange={(e) => {
                        setletestVersonCode(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />

                    <Input
                      type={`text`}
                      label={`Latest Version Code Host`}
                      value={letestVersonCodeHost}
                      errorMessage={error.letestVersonCodeHost}
                      newClass={`col-6`}
                      placeholder={`Enter Latest Version Code Host`}
                      onChange={(e) => {
                        setLetestVersonCodeHost(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            letestVersonCodeHost:
                              "Invalid Latest Version Code !!",
                          });
                        } else {
                          return setError({
                            ...error,
                            letestVersonCodeHost: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`text`}
                      label={`Term And Condition `}
                      value={termAndCondition}
                      errorMessage={error.termAndCondition}
                      newClass={`col-6`}
                      placeholder={`Enter You Term And Condition ....`}
                      onChange={(e) => {
                        setTermAndCondition(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`text`}
                      label={`Top Up Text`}
                      errorMessage={error.topUpText}
                      value={topUpText}
                      newClass={`col-6`}
                      placeholder={`Enter Top Up Text ...`}
                      onChange={(e) => {
                        setTopUpText(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            topUpText: "Top Up Text  Is required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            topUpText: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}

                    />
                  </SettingBox>
                </div>
                <div className="col-6">
                  <Title name={`Zigo Cloud Setting`} />
                  {/*-------------- Box 2 --------------*/}

                  <SettingBox
                    submit={handleSubmit}
                    title={`NETWORK POPUP ENABLE`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: networkPopupEnable,
                      handleClick: () => {
                        handleClick("network");
                      },
                    }}
                  >
                    <Input
                      type={`text`}
                      label={`App Key`}
                      value={key}
                      errorMessage={error.key}
                      newClass={`col-12`}
                      placeholder={`Enter App Key....`}
                      onChange={(e) => {
                        setKey(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            key: "Agora key Is Required !",
                          });
                        } else {
                          return setError({
                            ...error,
                            key: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`text`}
                      label={`App Sign In`}
                      errorMessage={error.certificates}
                      value={certificates}
                      newClass={`col-12`}
                      placeholder={`Enter App Sign In....`}
                      onChange={(e) => {
                        setCertificates(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            certificates: "Agora Certificate required!",
                          });
                        } else {
                          return setError({
                            ...error,
                            certificates: "",
                          });
                        }

                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={`video Delay Time (in Second)`}
                      value={videoDelaySecond}
                      newClass={`col-12`}
                      placeholder={`Enter video Delay Time....`}
                      onChange={(e) => {
                        setVideoDelaySecond(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                  </SettingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* row 2 */}




      <div>
        <div className="mainSettingBar">
          <div className="settingBar ">
            <div className="settingMain">
              <div className="row">
                <div className="col-6">
                  <Title name={`other Setting`} />
                  {/*-------------- Box 1 --------------*/}
                  <SettingBox
                    submit={handleSubmit}
                    title={`GENDER SELECTION FOR RANDOM MATCH`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: genderSelection,
                      handleClick: () => {
                        handleClick("genderSelection");
                      },
                    }}
                  >
                    <Input
                      type={`number`}
                      label={`Referral Bonus`}
                      value={referralBonus}
                      newClass={`col-6`}
                      placeholder={`Enter You Privacy Policy Link....`}
                      onChange={(e) => {
                        setReferralBonus(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={` Duration Of Free Call`}
                      value={durationOfFreeCall}
                      newClass={`col-6`}
                      placeholder={`Privacy Policy Text....`}
                      onChange={(e) => {
                        setDurationOfFreeCall(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Min Login Bonus`}
                      value={loginBonus}
                      newClass={`col-6`}
                      placeholder={`Enter Latest Version Code User`}
                      onChange={(e) => {
                        setLoginBonus(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />

                    <Input
                      type={`number`}
                      label={` Max Login Bonus`}
                      value={maxLoginBonus}
                      newClass={`col-6`}
                      placeholder={`Enter Latest Version Code Host`}
                      onChange={(e) => {
                        setMaxLoginBonus(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={`Number Of Free VideoCall`}
                      value={videoCall}
                      newClass={`col-6`}
                      placeholder={`Enter Number Of Free VideoCall ....`}
                      onChange={(e) => {
                        setVideoCall(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                  </SettingBox>
                </div>
                <div className="col-6">
                  <Title name={`Host Salary Setting`} />
                  {/*-------------- Box 2 --------------*/}

                  <SettingBox
                    submit={handleSubmit}
                    title={`SHOW ONLINE HOST`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: onlineButtonShow,
                      handleClick: () => {
                        handleClick("onlineShow");
                      },
                    }}
                  >
                    <Input
                      type={`number`}
                      label={`Host Basic Salary`}
                      value={hostSalary}
                      newClass={`col-6`}
                      placeholder={`Enter Host Basic Salary....`}
                      onChange={(e) => {
                        setHostSalary(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={`Host Withdrawal Limit`}
                      value={hostWithdrawalLimit}
                      newClass={`col-6`}
                      placeholder={`Enter App Sign In....`}
                      onChange={(e) => {
                        setHostWithdrawalLimit(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`number`}
                      label={`minimum Live Time(Hour)`}
                      value={minimumLiveTimeForHost}
                      newClass={`col-12`}
                      placeholder={`Enter video Delay Time....`}
                      onChange={(e) => {
                        setMinimumLiveTimeForHost(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                  </SettingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* row */}

      <div>
        <div className="mainSettingBar">
          <div className="settingBar ">
            <div className="settingMain">
              <div className="row">
                <div className="col-6">
                  <Title name={`other Setting`} />
                  {/*-------------- Box 1 --------------*/}

                  <SettingBox
                    submit={handleSubmit}
                    title={`RESET APP`}
                    toggleSwitch={{
                      switchName: "",
                      switchValue: restartAppEnable,
                      handleClick: () => {
                        handleClick("restart");
                      },
                    }}
                  >
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <div className="form-group mb-2">
                            <p className="form-label fw-bold mt-1">
                              Welcome Message
                            </p>
                          </div>
                          <textarea
                            rows="3"
                            placeholder="Welcome Message"
                            style={{
                              cursor: "auto",
                            }}
                            className="form-control mb-2 pe-1"
                            value={welcomeMessage}
                            errorMessage={error.welcomeMessage}
                            onChange={(e) => {
                              setWelcomeMessage(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  welcomeMessage:
                                    "Welcome Message Is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  welcomeMessage: "",
                                });
                              }
                            }}
                      onKeyPress={handleKeyPress}

                          ></textarea>
                          {error.welcomeMessage && (
                            <div class="pl-1 text-left">
                              <Typography
                                variant="caption"
                                color="error"
                                style={{
                                  fontFamily: "Circular-Loom",
                                }}
                              >
                                {error.welcomeMessage}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <div className="form-group mb-2">
                            <p className="form-label fw-bold mt-1">
                              Version Update Popup
                            </p>
                          </div>
                          <textarea
                            rows="3"
                            placeholder="Version Update Popup"
                            style={{
                              cursor: "auto",
                            }}
                            className="form-control mb-2 pe-1"
                            value={popupString}
                            onChange={(e) => {
                              setPopupString(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <Input
                      type={`text`}
                      label={`Redirect App Url`}
                      value={redirectAppUrl}
                      newClass={`col-6`}
                      placeholder={`Enter Redirect App Url....`}
                      onChange={(e) => {
                        setRedirectAppUrl(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />

                    <Input
                      type={`text`}
                      label={`Redirect Message`}
                      value={redirectMessage}
                      newClass={`col-6`}
                      placeholder={`Enter Redirect Message....`}
                      onChange={(e) => {
                        setRedirectMessage(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`text`}
                      label={`Google API Key (Chat)`}
                      value={googleAPIKey}
                      newClass={`col-6`}
                      placeholder={`Google API Key (Chat)....`}
                      onChange={(e) => {
                        setGoogleAPIKey(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                    <Input
                      type={`text`}
                      label={`Common Text`}
                      value={commonText}
                      newClass={`col-6`}
                      placeholder={`Common Text`}
                      onChange={(e) => {
                        setCommonText(e.target.value);
                      }}
                      onKeyPress={handleKeyPress}

                    />
                  </SettingBox>
                </div>
                <div className="col-6">
                  <Title name={`Location`} />
                  {/*-------------- Box 2 --------------*/}
                  <SettingBox submit={handleSubmit}>
                    <div>
                      <div className="form-group mb-2">
                        <p className="form-label fw-bold mt-1">Country</p>
                      </div>
                      <div className="mb-2 pe-1">
                        <div className="displayCountry form-control border p-3">
                          {addCountry?.map((item, id) => {
                            return (
                              <>
                                <span className="">
                                  {item}
                                  <i
                                    class="fa-solid fa-circle-xmark ms-2"
                                    onClick={() => {
                                      onRemove(id);
                                    }}
                                  ></i>
                                </span>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 w-100">
                      <div>
                        <div className="form-group mb-2">
                          <p className="form-label fw-bold mt-1">Location</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type={`text`}
                            label={`country`}
                            value={country}
                            newClass={country ? "col-11" : "col-12"}
                            placeholder={`Enter Country`}
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                      onKeyPress={handleKeyPress}

                          />
                          {country !== "" && (
                            <div
                              className=" px-3 py-4 text-white mx-2 d-flex align-items-center justify-content-center mt-3"
                              style={{
                                height: "38px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "#664dc9",
                              }}
                              onClick={addCountryList}
                            >
                              <span>ADD</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <label className="styleForTitle mb-2 text-dark">
                        notification Type
                      </label>
                      <select
                        name="type"
                        className="form-control form-control-line"
                        id="type"
                        value={notificationType}
                        onChange={(e) => {
                          setNotificationType(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              notificationType: "Discount Type is Required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              notificationType: "",
                            });
                          }
                        }}
                      onKeyPress={handleKeyPress}

                      >
                        <option value={1}> Normal </option>
                        <option value={2}> Custom</option>
                        <option value={3}> Advanced</option>
                      </select>
                      {error.notificationType && (
                        <div className="pl-1 text-left">
                          <p className="errorMessage">
                            {error.notificationType}
                          </p>
                        </div>
                      )}
                    </div>
                  </SettingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getSetting, updateSetting, handleToggleSwitch })(
  AppSetting
);
