import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import SettingBox from "../Table/setting/SettingBox";
import { baseURL } from "../util/config";
import Input from "../extra/Input";
import { connect, useSelector } from "react-redux";
import {
  getProfile,
  updateProfile,
  ChangePassword,
  UpdateImage,
  allCountrys,
} from "../store/admin/admin.action";
import Button from "../extra/Button";
import { setToast } from "../util/toast";
import male from "../../assets/images/maleImage.png";
import $ from "jquery";

const Profile = (props) => {
  const { admin } = useSelector((state) => state.admin);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [invitation, setInvitation] = useState(
    baseURL + "storage/adminInvitation/agency.html"
  );

  useEffect(() => {
    props.allCountrys();
    props.getProfile();
  }, []);

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  useEffect(() => {
    setName(admin.name);
    setEmail(admin.email);
  }, [admin]);
  const handleEditProfile = () => {
    if (email === "" || name === "") {
      let error = {};
      if (email === "") error.email = "Email Is Required !";
      if (name === "") error.name = "Name Is Required !";
      return setError({ ...error });
    }
    let edit = {
      name,
      email,
    };
    props.updateProfile(edit);
  };

  const handleChangePassword = () => {
    if (
      oldPassword === "" ||
      newPassword === "" ||
      confirmPassword === "" ||
      newPassword !== confirmPassword
    ) {
      let error = {};
      if (oldPassword === "") error.oldPassword = "OldPassword Is Required !";
      if (newPassword === "") error.newPassword = "NewPassword Is Required !";
      if (confirmPassword === "")
        error.confirmPassword = "ConfirmPassword Is Required !";
      if (newPassword !== confirmPassword)
        error.confirmPassword =
          "New Password and Confirm Password doesn't match";
      return setError({ ...error });
    } else {
      let password = {
        oldPass: oldPassword,
        newPass: newPassword,
        confirmPass: confirmPassword,
      };
      props.ChangePassword(password);
    }
  };

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleImage = () => {
    if (image.length === 0) {
      setToast("info", "Please S  elect Image");
    } else {
      const formData = new FormData();
      formData.append("image", image);
      props.UpdateImage(formData);
    }
  };

  const copyCode = (code) => {
    var copyText = document.getElementById("myInput");

    navigator.clipboard.writeText(code);
    setToast("success", "Link Copy Successfully");
  };
  return (
    <>
      <div className="mainSettingBar">
        <div className="settingBar">
          <div className=" settingHeader primeHeader mt-2">
            <div className="row ">
              <div className="col-6">
                <Title name={`Profile`} />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <ol class="breadcrumb1 pb-1 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem">Profile </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="settingMain pt-0">
            <div className="row">
              <div className="col-4">
                {/*-------------- Box 1 --------------*/}
                <div className="card border-0" style={{ margin: "0px 18px" }}>
                  <div className="card-body">
                    <div className="userImgae d-flex boxCenter position-relative">
                      <input
                        id="file-input"
                        type="file"
                      accept="image/png, image/jpeg ,image/jpg "
                        className="d-none"
                        onChange={(e) => handleUploadImage(e)}
                      />
                      <img
                        className="p-2"
                        style={{
                          border: "2px solid #eeeef3",
                          width: "150px",
                          height: "150px",
                          borderRadius: "50%",
                        }}
                        src={imagePath ? imagePath : admin?.image}
                        alt=""
                      />
                      <div
                        className="position-absolute"
                        style={{ bottom: "-3%", right: "46%" }}
                      >
                        <div
                          style={{
                            background: "#ffff",
                            borderRadius: "50px",
                            height: "33px",
                            border: "1px solid #000",
                          }}
                        >
                          <label for="file-input">
                            <i
                              class="fa-solid fa-camera d-flex justify-content-center  rounded-circle  p-2 cursorPointer"
                              style={{
                                fontSize: "15px",
                                color: "#000",
                                cursor: "pointer",
                              }}
                            ></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <h2 className="fw-semibold boxCenter mt-2">{admin.name}</h2>

                    <div className="mt-1 boxCenter">
                      <Button
                        newClass={`whiteFont`}
                        btnColor={`btnBlackPrime`}
                        btnName={`Upload Image `}
                        onClick={handleImage}
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                {/*-------------- Box 2 --------------*/}
                <SettingBox submit={handleEditProfile}>
                  <Input
                    label={`Name`}
                    id={`ProfileName`}
                    type={`text`}
                    value={name}
                    errorMessage={error.name && error.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: `name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          name: "",
                        });
                      }
                    }}
                  />
                  <Input
                    label={`Email`}
                    id={`loginEmail`}
                    type={`email`}
                    value={email}
                    errorMessage={error.email && error.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `Email Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                  />
                </SettingBox>
              </div>
            </div>
          </div>

          <div className="settingMain ">
            <div className="row">
              <div className="col-12">
                <SettingBox submit={handleChangePassword}>
                  <Input
                    label={`Old Password`}
                    id={`loginPassword`}
                    type={`password`}
                    value={oldPassword}
                    className={`form-control`}
                    errorMessage={error.oldPassword && error.oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          oldPassword: `oldPassword Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          oldPassword: "",
                        });
                      }
                    }}
                  />

                  <Input
                    label={`New Password`}
                    id={`loginPassword`}
                    type={`password`}
                    value={newPassword}
                    className={`form-control`}
                    errorMessage={error.newPassword && error.newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          newPassword: `newPassword Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          newPassword: "",
                        });
                      }
                    }}
                  />

                  <Input
                    label={`Confirm Password`}
                    id={`loginPassword`}
                    type={`password`}
                    value={confirmPassword}
                    className={`form-control`}
                    errorMessage={
                      error.confirmPassword && error.confirmPassword
                    }
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          confirmPassword: `Confirm Password Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          confirmPassword: "",
                        });
                      }
                    }}
                  />
                </SettingBox>
              </div>
            </div>
          </div>

          <div className="settingMain card border-0">
            <div className="main-profile-body">
              <div class="form-group m-3">
                <p class="form-label fw-bold mt-3">Invitation Link</p>
              </div>
              <div className="card-body pt-0">
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-primary d-block form-control me-2"
                      style={{ width: "40px", cursor: "pointer" }}
                      id="myInput"
                      onClick={() => copyCode(invitation)}
                    >
                      <i
                        className="fas fa-copy me-4 text-white"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div id="copyText" className="form-control">
                      <span>{invitation}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getProfile,
  updateProfile,
  ChangePassword,
  UpdateImage,
  allCountrys,
})(Profile);
