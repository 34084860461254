import * as ActionType from "./agencyRequest.type";
import axios from "axios";
import { setToast } from "../../util/toast";

// agency Request get
export const agencyRequest = () => (dispatch) => {
  axios
    .get("agencyRequest")
    .then((res) => {
      
      dispatch({
        type: ActionType.AGENCY_REQUEST_GET,
        payload: res.data.request,
      });
    })
    .catch((error) => console.log(error));
};

// REQUEST ACCEPT get

export const agencyRequestAccept = (id) => (dispatch) => {
  axios
    .put(`agencyRequest/${id}`)
    .then((res) => {
      ;
      if (res.data.status) {
        dispatch({ type: ActionType.AGENCY_REQUEST_ACCEPT, payload: id });
        setToast("success", res.data.message);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// agency request update
export const agencyRequestUpdate = (id, formData) => (dispatch) => {
  axios
    .patch(`agencyRequest/${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_AGENCY_REQUEST,
          payload: { updateRequest: res.data.request, id },
        });
        setToast("success", "Agency Update Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};
