import { useEffect, useState } from "react";
import Button from "../extra/Button";
import Input from "../extra/Input";
import Logo from "../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { loginAdmin ,allCountrys} from "../store/admin/admin.action";
import { connect, useSelector } from "react-redux";

const Login = (props) => {
  let navigate = useNavigate();

  const isAuth = useSelector((state) => state.admin.isAuth);

  useEffect(() => {
    isAuth && navigate("/admin");
  }, [isAuth]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    if (!email || !password) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "password is required !";
      return setError({ ...error });
    } else {
      let login = {
        email,
        password,
      };

      props.loginAdmin(login);
      props.allCountrys()
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      
            <div
        class="account-pages d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#f8f8fc" }}
      >
        <div class="container">
          <div class="row justify-content-center pt-5">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-5 ">
              <div class="card overflow-hidden mb-3 border-0 loginBox-shadow" >
                <div class="bg-soft" style={{ backgroundColor: "#664dc9" }}>
                  <div class="row " style={{ height: "150px" }}>
                    <div class="col-12 pb-5">
                      <div class="text-white p-4 text-center ">
                        <h3 class="text-white ">Welcome To Qwee !</h3>
                        <h5>Sign in to continue.</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body pt-0 position-relative">


                  <div class="email_filed mt-5">
                    <div class="mb-3">
                      <Input
                        label={`Email`}
                        id={`loginEmail`}
                        type={`email`}
                        value={email}
                        errorMessage={error.email && error.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              email: `Email Is Required`,
                            });
                          } else {
                            return setError({
                              ...error,
                              email: "",
                            });
                          }
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    </div>

                    <Input
                      label={`Password`}
                      id={`loginPassword`}
                      type={`password`}
                      value={password}
                      className={`form-control`}
                      errorMessage={error.password && error.password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            password: `Password Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            password: "",
                          });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                    />

                    <div class="mt-5 d-grid">
                      {/* <button
                          type="button"
                          class="btn  waves-effect waves-light text-white py-3"
                          style={{ backgroundColor: "#664dc9" }}
                          onClick={handleLogin}
                        >
                          Log In
                        </button> */}

                      <Button
                      
                        newClass={`themeFont`}
                        btnColor={`btnBlackPrime`}
                        btnName={`Log In`}
                        onClick={handleSubmit}
                        style={{ borderRadius: "7px", width: "100%" }}
                      />
                    </div>

                    <div class="mt-5 text-center" style={{ color: "#98a6be" }}>
                      {/* <Link to="/forgotPassword" class="text-muted"> */}
                      <i class="fa fa-lock me-1"></i> Forgot your password?
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { loginAdmin ,allCountrys})(Login);
