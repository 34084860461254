import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  singleGetUser,
  upadteUserCoin,
  userBlocked,
} from "../../store/users/user.action";
import IOSSwitch from "@material-ui/core/Switch";

import EdiText from "react-editext";
// import { OPEN_AGENCY_NOTIFICATION_DIALOG } from "../../store/notification/notification.type";
// import AgencyNotification from "./AgencyNotification";
import { Tooltip } from "@material-ui/core";
import { baseURL } from "../../util/config";
import $ from "jquery";
import { ADMIN_SEND_MESSAGE_DIALOGUE_OPEN } from "../../store/message/message.type";
import { setToast } from "../../util/toast";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import NotificationDialogue from "../../Pages/NotificationDialogue";
import MessageDialogue from "../../Pages/MessageDialogue";
import { OPEN_NOTIFICATION_DIALOG } from "../../store/notification/notification.type";

const UserDetails = (props) => {
  const { dialogueData, dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );
  const { messageDialogue } = useSelector((state) => state.messageData);

  const { notificationDialog } = useSelector((state) => state.notification);

  const [data, setData] = useState({});
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    props.singleGetUser(state ? state : dialogueData?._id);
  }, []);

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    setData(userDetails);
  }, [userDetails]);

  const handleSave = (val) => {
    if (val < 0 || val?.length > 9) {
      setToast("error", "Invalid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
      } else {
        props.upadteUserCoin(val, data._id);
      }
    }
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  $("input[type=number]").on("mousewheel", function (e) {
    $(e.target).blur();
  });
  // open Image New Teb
  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  const handleClick = (userDetails) => {
    props.userBlocked(userDetails, userDetails.isBlock === true ? false : true);
  };

  const goPrevious = () => {
    dialogueData ? dispatch({ type: CLOSE_DIALOGUE }) : navigate(-1);
    window.localStorage.clear();
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", `${baseURL}` + "storage/male.png");
    });
  });

  const handleAdminMessage = (id) => {
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { type: "user", id },
    });
  };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    type === "userHistory" &&
      localStorage.setItem("userProfile", JSON.stringify(dialogueData_));

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleHistory = () => {
    let stateData = { id: state, name: data?.name };

    navigate("/admin/user/history", { state: stateData });
  };

  const handelMessageDialogue = (data) => {
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { id: data?._id, type: "user" },
    });
  };

  var str = data?.name;
  if (str?.length > 15) str = str.substring(0, 15) + "...";
  return (
    <>
      <div className="mt-5 d-flex align-items-center justify-content-between">
        <h4 className="page-title mb-0 text-primary text-capitalize m-2">
          {str}'s Details
        </h4>
        <div className="ProfileBtn d-flex m-2 ">
          <div>
            <Tooltip title="Message" placeholder="bottom">
              <button
                className="btn btn-secondary btn-icon border-0"
                onClick={() => handelMessageDialogue(data)}
              >
                <i
                  class="fas fa-envelope px-2 text-center"
                  style={{ fontSize: "20px", padding: "5px" }}
                ></i>
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Notification" placeholder="bottom">
              <button
                className="btn btn-primary btn-icon ms-3 px-2 border-0"
                onClick={() =>
                  dispatch({
                    type: OPEN_NOTIFICATION_DIALOG,
                    payload: {
                      data,
                    },
                  })
                }
                style={{ width: "58px" }}
              >
                <i
                  class="fas fa-bell px-2 text-white"
                  style={{ fontSize: "20px", padding: "5px" }}
                ></i>
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="History" placeholder="bottom">
              <button
                className="btn btn-info btn-icon mx-3  px-2 border-0"
                onClick={() =>
                  state
                    ? handleHistory()
                    : handleProfile(dialogueData, "userHistory")
                }
                style={{ width: "58px" }}
              >
                <i
                  style={{ fontSize: "20px", padding: "5px" }}
                  class="fas fa-history text-white"
                ></i>
              </button>
            </Tooltip>
          </div>
          {/* <Link to="/admin/user" className="me-3"> */}
          <button
            className="btn btn-primary btn-icon px-2 border-0"
            onClick={goPrevious}
            style={{ width: "58px" }}
          >
            <i
              class="fa-solid fa-angles-left text-white fs-6 "
              style={{ fontSize: "20px", padding: "5px" }}
            ></i>
          </button>
          {/* </Link> */}
        </div>
      </div>

      <div className="mainCard mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="imageCard bg-white p-4 shadow-sm">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5">
                  <div className="profile p-3 pt-4">
                    <div className="profileImage m-auto overflow-hidden rounded-circle border-primary border d-flex justify-content-center align-items-center">
                      <img
                        className="w-100"
                        src={data?.image}
                        alt="Profile"
                        draggable="false"
                        onClick={() => handleOpenUrl(data?.image)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-7 borderCT">
                  <table className="uTable w-100">
                    <tr>
                      <td width="90px">Name</td>
                      <td width="50px">:</td>
                      <td>{str}</td>
                    </tr>
                    <tr>
                      <td>Bio </td>
                      <td>:</td>
                      <td>{data?.bio}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>:</td>
                      <td>{data?.email}</td>
                    </tr>
                    <tr>
                      <td>DOB</td>
                      <td>:</td>
                      <td>{data?.dob ? data?.dob : "-"}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>:</td>
                      <td>{data?.gender ? data?.gender : "-"}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="allDetails bg-white p-4 shadow-sm mt-4">
                  <table className="uTable w-100">
                    <tr>
                      <td>Status</td>
                      <td>:</td>
                      <td>
                        {data?.isOnline === true ? (
                          <div
                            className="badge badge-success p-2"
                            style={{  maxWidth: "121px",
                            fontSize: "14px",
                            fontWeight: "500",
                            padding: "10px",
                            width: "100%", }}
                          >
                            Online
                          </div>
                        ) : (
                          <div
                            className="badge badge-danger disabled p-2"
                            style={{  maxWidth: "121px",
                            fontSize: "14px",
                            fontWeight: "500",
                            padding: "10px",
                            width: "100%", }}
                          >
                            Offline
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td width="110px">Coin</td>
                      <td width="50px">:</td>
                      <td>
                        <EdiText
                          type="number"
                          min="0"
                          value={data?.coin}
                          onSave={handleSave}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Age</td>
                      <td>:</td>
                      <td>{data?.age}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.</td>
                      <td>:</td>
                      <td>
                        {data?.mobileNumber === null ? "-" : data.mobileNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>:</td>
                      <td>{data?.country}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="allDetails bg-white p-4 shadow-sm mt-4">
                  <table className="uTable w-100">
                    <tr>
                      <td>Block</td>
                      <td>:</td>
                      <td className="mx-auto" style={{ display: "table-cell" }}>
                        <IOSSwitch
                          checked={data.isBlock}
                          color="primary"
                          onClick={() => handleClick(data)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="110px">PlatformType</td>
                      <td width="50px">:</td>
                      <td>{data?.platformType === 0 ? "Android" : "IOS"}</td>
                    </tr>
                    <tr>
                      <td>LoginType</td>
                      <td>:</td>
                      <td>
                        {data?.loginType === 0
                          ? "Google"
                          : data.loginType === 1
                          ? "Facebook"
                          : data.loginType === 2
                          ? "Mobile No"
                          : "Quick Login"}
                      </td>
                    </tr>
                    <tr>
                      <td>PlanLevel</td>
                      <td>:</td>
                      <td>{data?.planLevel}</td>
                    </tr>
                    <tr>
                      <td>Last Login</td>
                      <td>:</td>
                      <td>{data?.lastLogin}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {notificationDialog && <NotificationDialogue />}
      {/* {messageDialogue && <MessageDialogue />} */}
    </>
  );
};

export default connect(null, { singleGetUser, upadteUserCoin, userBlocked })(
  UserDetails
);
