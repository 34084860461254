import axios from "axios";
import * as actionType from "./hostRequest.type";

import { setToast } from "../../util/toast";

export const hostRequest = () => (dispatch) => {
  axios
    .get("request")
    .then((res) => {
      dispatch({
        type: actionType.GET_HOST_REQUEST,
        payload: res.data.request,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const hostAccept = (id) => (dispatch) => {
  axios
    .put(`request/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType.ACCEPT_HOST_REQUEST, payload: id });
        setToast("success", "Host Request Accept!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

// UPDATE HOST
export const updateRequestHost = (formData, id) => (dispatch) => {
  axios
    .patch(`request/${id}`, formData)
    .then((res) => {
      console.log(res.data.request);
      if (res.data.status) {
        dispatch({
          type: actionType.HOST_UPDATE,
          payload: { editHost: res.data.request, id },
        });
        setToast("success", "Host Updated Successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error));
};

// get subAgent

export const getSubagent = () => (dispatch) => {
  axios
    .get("subAgent")
    .then((res) => {
      dispatch({ type: actionType.GET_SUBAGENT, payload: res.data.subAgent });
    })
    .catch((error) => {
      console.log(error);
    });
};
