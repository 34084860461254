import axios from "axios";
import { setToast } from "../../util/toast";
import {
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  GET_PERMISSION,
  UPDATE_PERMISSION,
  ACTIVE_PERMISSION,
} from "./permission.type";

//GEt Permission
export const getPermissionData = () => (dispatch) => {
  axios
    .get(`permission`)
    .then((res) => {
      dispatch({ type: GET_PERMISSION, payload: res.data.permission });
    })
    .catch((error) => console.log(error));
};

//Create Permission
export const createPermission = (data) => (dispatch) => {
  axios
    .post(`permission`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_PERMISSION, payload: res.data.permission });
        setToast("success", "Permission created successfully !");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Update Permission
export const updatePermission = (data, id) => (dispatch) => {
  axios
    .patch(`permission/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_PERMISSION,
          payload: { permission: res.data.permission, id },
        });
        setToast("success", "Permission updated successfully !");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

//Delete Permission
export const deletePermission = (id) => (dispatch) => {
  axios
    .delete(`permission/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_PERMISSION, payload: id });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};


export const permissionActive = (permission,active) => (dispatch) => {
  axios
    .put(`permission/${permission._id}`)
    .then((res) => {
      if (res.data.status) {
        setToast("success", `${permission.name} is ${active === true ? "Active" : "Deactive"}`);
        dispatch({
          type: ACTIVE_PERMISSION,
          payload: { data: res.data.permission ,  id: permission._id},
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};