/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_GIFT_DIALOG } from "../../../store/gift/types";

//action
import { editGift } from "../../../store/gift/action";
import { getCategory } from "../../../store/giftCategory/action";
import { baseURL } from "../../../util/config";

const GiftDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.gift);

  const GiftClick = localStorage.getItem("GiftClick");

  const [mongoId, setMongoId] = useState("");
  const [coin, setCoin] = useState("");
  const [category, setCategory] = useState("");
  const [platFormType, setPlatFormType] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const categoryDetail = JSON.parse(localStorage.getItem("Category"));

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getCategory(); // eslint-disable-next-line
  }, []);

  const categories = useSelector((state) => state.giftCategory.giftCategory);

  const [errors, setError] = useState({
    image: "",
    coin: "",
    category: "",
    platFormType: "",
  });

  useEffect(() => {
    window.onbeforeunload = closePopup();
  } ,[])

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setCoin(dialogData.coin);
      setPlatFormType(dialogData.platFormType);
      setCategory(
        GiftClick === null ? dialogData.category._id : dialogData.category
      );
      setImagePath(baseURL + dialogData.image);
    }
  }, [dialogData, GiftClick]);

  useEffect(
    () => () => {
      setError({
        image: "",
        coin: "",
        category: "",
      });
      setCategory("");
      setCoin("");
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  console.log("platFormType", platFormType);
  const closePopup = () => {
    dispatch({ type: CLOSE_GIFT_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      (!imageData && !imagePath) ||
      !coin ||
      platFormType === "Select PlatformType" ||
      (GiftClick !== null && (!category || category === "Select Category"))
    ) {
      let errors = {};
      if (!coin) errors.coin = "Coin is Required!";
      if (platFormType === "Select PlatformType")
        errors.platFormType = "Please select a Platform Type!";
      if (!imageData && !imagePath) errors.image = "Please select an Image!";

      if (GiftClick !== null && (category === "Select Category" || !category)) {
        errors.category = "Please select a Category!";
      }
      setError({ ...errors });
    } else {
      const coinValid = isNumeric(coin);
      if (!coinValid) {
        return setError({ ...errors, coin: "Invalid Coin!!" });
      }
      const formData = new FormData();

      formData.append("image", imageData);
      formData.append("coin", coin);
      GiftClick !== null && formData.append("category", category);
      formData.append("platFormType", platFormType);
      props.editGift(formData, mongoId);
    }
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        fullWidth
        maxWidth="xs"
        style={{
          zIndex: 9999999,
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-primary fw-bold h4"> Gift </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#664dc9",
          }}
        >
          <Tooltip title="Close" placement="right">
            <Cancel className="cancelButton" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-md-12 my-2">
                    <label className="mb-2 dialog__input__title">Coin</label>
                    <input
                      type="number"
                      className="form-control pe-1"
                      required=""
                      placeholder="20"
                      value={coin}
                      onChange={(e) => {
                        setCoin(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            coin: "Coin is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            coin: "",
                          });
                        }
                      }}
                    />
                    {errors.coin && (
                      <div className="ml-2 mt-1">
                        {errors.coin && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.coin}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 my-2">
                    <label className="dialog__input__title mb-2">
                      Category
                    </label>
                    {GiftClick === null ? (
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Category Name"
                        disabled
                        value={categoryDetail?.name}
                      />
                    ) : (
                      <>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={category}
                          onChange={(e) => {
                            setCategory(e.target.value);
                            if (e.target.value === "Select Category") {
                              return setError({
                                ...errors,
                                category: "Please select a Category!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                category: "",
                              });
                            }
                          }}
                        >
                          <option value="Select Category" selected>
                            Select Category
                          </option>
                          {categories.map((category) => {
                            return (
                              <option value={category._id}>
                                {category.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.category && (
                          <div className="ml-2 mt-1">
                            {errors.category && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="earning"
                      className="dialog__input__title mb-2"
                    >
                      Gift package
                    </label>
                    <select
                      name="type"
                      class="form-control form-control-line"
                      id="type"
                      value={platFormType}
                      onChange={(e) => {
                        setPlatFormType(parseInt(e.target.value));

                        if (e.target.value === "Select PlatformType") {
                          return setError({
                            ...errors,
                            platFormType: "Please select a Platform Type!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            platFormType: "",
                          });
                        }
                      }}
                    >
                      <option value="Select PlatformType">
                        Select platForm Type
                      </option>

                      <option value="0">Android</option>
                      <option value="1">IOS</option>
                    </select>
                    {errors.platFormType && (
                      <div className="ml-2 mt-1">
                        {errors.platFormType && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.platFormType}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 my-2">
                    <label className="mb-2 dialog__input__title">
                      Select Image or GIF
                    </label>
                    <input
                      type="file"
                      className="form-control"
                    accept="image/png, image/jpeg ,image/jpg "
                      required=""
                      onChange={HandleInputImage}
                    />
                    {errors.image && (
                      <div className="ml-2 mt-1">
                        {errors.image && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {imagePath && (
                      <>
                        <img
                          height="70px"
                          width="70px"
                          alt="app"
                          src={imagePath}
                          draggable="false"
                          style={{
                            marginTop: 10,
                            float: "left",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    className="btn  btn-success  mx-2"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger  "
                    onClick={closePopup}
                  >
                    Close
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editGift, getCategory })(GiftDialog);
