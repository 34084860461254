import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import DateRangePicker from "react-bootstrap-daterangepicker";
import dayjs from "dayjs";
import { connect, useDispatch, useSelector } from "react-redux";
import { getEntireSettlement } from "../../store/settlement/settlement.action";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import { useNavigate } from "react-router-dom";
import Searching from "../../extra/Searching";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import HostInfoPage from "../Host/History/HostInfoPage";
import { Tooltip } from "@material-ui/core";

const HostTransaction = (props) => {
  const { settlement, totalData, total } = useSelector(
    (state) => state.settlement
  );

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [type, setType] = useState("host");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [field, setField] = useState("callCoin");
  const [sorting, setSorting] = useState("0");

  useEffect(() => {
    props.getEntireSettlement(
      "ALL",
      startDate,
      endDate,
      currentPage,
      size,
      field,
      sorting
    );
  }, []);

  useEffect(() => {
    props.getEntireSettlement(
      "ALL",
      startDate,
      endDate,
      currentPage,
      size,
      field,
      sorting
    );
  }, [currentPage, size, field, sorting]);

  useEffect(() => {
    setData(settlement);
  }, [settlement]);

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");

    setStartDate(start);
    setEndDate(end);
    props.getEntireSettlement("custom", start, end, currentPage, size);
  };

  const navigate = useNavigate();
  //Cancel button function for analytic
  const handleCancel = (event, picker) => {
    picker?.element.val("");
    setStartDate("");
    setEndDate("");
    props.getEntireSettlement("ALL", "", "", currentPage, size);
  };

  const handleChildValue = (value) => {
    setSorting(sorting == 0 ? 1 : 0);
    setField(value);
  };

  const dispatch = useDispatch();
  const handleProfile = (row, type) => {
    console.log("row", row);
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const hostTransactionTable = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Host Name",
      body: "name",
      class: "fs-6 fw-semibold",
      Cell: ({ row }) => (
        <Tooltip title={`${row.name} Profile`} placement="bottom">
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate("/admin/host/profile", { state: row.hostId })
            }
          >
            {row?.name}
          </span>
        </Tooltip>
      ),
    },
    { Header: "Country", body: "country", class: "fs-6 fw-semibold" },
    { Header: "Host Id", body: "uniqueId", class: "fs-6 fw-semibold" },
    {
      Header: "Host Salary",
      body: "hostSalary",
      class: "fs-6 fw-semibold text-dark",
    },

    {
      Header: "Gift Coin",
      body: "giftCoin",
      class: "fs-6 fw-semibold text-primary",

      sorting: { type: "server" },
    },
    {
      Header: "Task Coin",
      body: "hostTaskCoin",
      class: "fs-6 fw-semibold text-info",
      sorting: { type: "server" },
    },

    {
      Header: "Call Coin",
      body: "callCoin",
      class: "fs-6 fw-semibold text-warning",
      sorting: { type: "server" },
    },
    {
      Header: "Admin Coin",
      body: "adminCoin",
      class: "fs-6 fw-semibold text-danger",
      sorting: { type: "server" },
    },

    {
      Header: "Revenue",
      body: "Revenue",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="fw-semibold fs-6 text-info">{row.settlementCoin}</span>
      ),
    },

    {
      Header: "Total",
      body: "totalPayable",
      class: "fw-bold fs-6 text-dark",
      sorting: { type: "client" },
    },
    // {
    //   Header: "Start Date",
    //   body: "startDate",
    //   Cell: ({ row }) => (
    //     <span> {dayjs(row?.startDate).format("DD/MM/YYYY")}</span>
    //   ),
    // },
    // {
    //   Header: "End Date",
    //   body: "endDate",
    //   Cell: ({ row }) => (
    //     <span> {dayjs(row?.endDate).format("DD/MM/YYYY")}</span>
    //   ),
    // },
  ];
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainPlanTable  position-relative">
        {dialogue && dialogueType === "hostProfile" && (
          <>
            <div className="mainHostHistory">
              <HostInfoPage />
            </div>
          </>
        )}
        <div className="planTable">
          <div className=" primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head mt-3">
                <Title name={`Host Transaction`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <ol class="breadcrumb1 pb-0 mb-0">
                  <li class="breadcrumb-item1">
                    <p to="/admin/dashboard">Dashboard</p>
                  </li>
                  <li class="breadcrumb-item1 activeItem text-capitalize">
                    Host Transaction
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="coin-plan">
            <div className="planMain">
              <div className="row d-flex align-items-center px-2">
                <div className="col-6">
                  <div className="coin-plan-btn d-flex align-items-center">
                    <div>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            cancelLabel: "Clear",
                          },
                          maxDate: new Date(),

                          buttonClasses: ["btn btn-dark"],
                        }}
                        onApply={handleApply}
                        onCancel={handleCancel}
                      >
                        <input
                          readOnly
                          type="text"
                          class="daterange form-control float-left bg-primary text-white"
                          value="Select Date"
                          style={{
                            width: 120,
                            fontWeight: 700,
                            cursor: "pointer",
                          }}
                        />
                      </DateRangePicker>
                    </div>

                    {startDate === "" || endDate === "" ? (
                      <div className="ms-3 fs-5 text-primary fw-bold mb-2">
                        All Settlement
                      </div>
                    ) : (
                      <div className="dateShow ms-3 fs-5 text-primary fw-bold mb-2">
                        <span className="me-3">{startDate}</span>
                        <span className="me-3">To</span>
                        <span>{endDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end mb-2">
                  <Title name={`Total Revenue : ` + total} />
                </div>
              </div>
              <div className="row d-flex align-items-center px-2">
                <div className="col-6">
                  <div className="coin-plan-btn d-flex align-items-center"></div>
                </div>
                <div className="col-6 d-flex justify-content-end mb-2">
                  <Searching
                    type={`client`}
                    data={settlement}
                    setData={setData}
                    column={hostTransactionTable}
                    onFilterData={handleFilterData}
                    serverSearching={handleFilterData}
                    button={true}
                    searchValue={search}
                    setSearchValue={setSearch}
                  />
                </div>
              </div>
              <div className="primeMain border-top">
                <Table
                  data={data}
                  mapData={hostTransactionTable}
                  PerPage={rowsPerPage}
                  Page={page}
                  type={"server"}
                  onChildValue={handleChildValue}
                />
              </div>
              <Pagination
                component="div"
                count={settlement?.length}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={totalData}
                serverPage={currentPage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getEntireSettlement })(HostTransaction);
