import React, { useEffect, useState } from "react";
import Title from "../../../extra/Title";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../extra/Button";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getHostProfile,
  hostApproved,
  upadteHostCoin,
  updateAlertMessage,
  hostBlocked,
} from "../../../store/host/host.action";
import { baseURL } from "../../../util/config";
import EdiText from "react-editext";
import { setToast } from "../../../util/toast";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import ShowImage from "../ShowImage";
import $ from "jquery";
import noImage from "../../../../assets/images/noImage.png";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,

} from "../../../store/dialogue/dialogue.type";
import HostMessageDialogue from "../HostMessageDialogue";
import RejectionVideoDialogue from "../RejectionVideoDialogue";
import { VERIFICATION_VIDEO_OPEN_DIALOGUE } from "../../../store/host/host.type";

import { offlineHost } from "../../../store/host/host.action";
import { ADMIN_SEND_MESSAGE_DIALOGUE_OPEN } from "../../../store/message/message.type";

const HostInfoPage = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { state } = useLocation();

  const { verificationDialogue, verificationDialogueData } = useSelector(
    (state) => state.host
  );

  const { messageData } = useSelector((state) => state.messageData);

  const { hostProfile } = useSelector((state) => state.host);
  const [data, setData] = useState("");

  useEffect(() => {
    props.getHostProfile(state ? state : dialogueData?._id);
  }, []);

  useEffect(() => {
    setData(hostProfile);
  }, [hostProfile]);

  const navigate = useNavigate();

  const handelPreviousPage = () => {
    dialogueData ? dispatch({ type: CLOSE_DIALOGUE }) : navigate(-1);
    window.localStorage.clear();
  };

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);

  const handleOpenUrl = () => {};
  
  const handleClick = (data) => {
    props.hostBlocked(data, data?.isBlock === true ? false : true);
  };

  const dispatch = useDispatch();
  const handleApprovedClick = (data, type) => {
    
    props.hostApproved(data, type);
  };

  const handleVideo = () => {
    if(data?.verificationVideo !== null){
      dispatch({ type: VERIFICATION_VIDEO_OPEN_DIALOGUE, payload: data });
    } 
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImage);
    });
  });

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };
  
  const handleSave = (val) => {

    if (val < 0 || val?.length > 9) {
      setToast("error", "Invalid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
      } else {
        props.upadteHostCoin(val, data._id);
      }
    }
  };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    type === "hostHistory" &&
      localStorage.setItem("hostProfile", JSON.stringify(dialogueData_));

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const handleAlertSave = (val) => {
    props.updateAlertMessage(val, data._id);
  };

  const handleHistory = () => {
    let stateData = { id: state, name: data?.name };
    navigate("/admin/host/history", { state: stateData });
  };

  var str = data?.name;
  if (str?.length > 15) str = str.substring(0, 15) + "...";

  // const handleSwitchOffline = (id) => {
  //   id.isOnline && props.offlineHost(id);
  // };

  const handelMessageDialogue = (data) => {
    console.log("data", data);
    dispatch({
      type: ADMIN_SEND_MESSAGE_DIALOGUE_OPEN,
      payload: { id: data?._id, type: "host" },
    });
  };

  return (
    <>
      <div className="mainPlanTable hostHistory">
        <div className="planTable">
          <div className="primeHeader">
            <div className="row">
              <div className="col-6 title-head title-head ">
                <Title name={str + `'s History`} />
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <div className="profileBtn d-flex align-items-center">
                  <Tooltip title="message" placeholder="bottom">
                    <Button
                      btnColor={`btn-info`}
                      newClass={`themeFont boxCent text-white`}
                      style={{
                        borderRadius: "5px",
                        height: "36px",
                        width: "50px",
                      }}
                      onClick={() => handelMessageDialogue(data,"hostMessage")}
                      btnIcon={`fas fa-envelope  fs-5`}
                    />
                  </Tooltip>

                  <Tooltip title="history" placeholder="bottom">
                    <Button
                      btnColor={`btn-success`}
                      newClass={`themeFont boxCent text-white mx-4`}
                      onClick={() =>
                        state
                          ? handleHistory()
                          : handleProfile(dialogueData, "hostHistory")
                      }
                      style={{
                        borderRadius: "5px",
                        height: "36px",
                        width: "50px",
                      }}
                      btnIcon={`fas fa-history  fs-6`}
                    />
                  </Tooltip>

                  <Button
                    btnColor={`btn-primary`}
                    newClass={`themeFont boxCent text-white`}
                    onClick={handelPreviousPage}
                    style={{
                      borderRadius: "5px",
                      height: "36px",
                      width: "50px",
                    }}
                    btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mainCard  p-3">
            <div className="row">
              <div className="col-lg-12">
                <div className="imageCard bg-white p-4 shadow-sm">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                      <div
                        className="profile p-3 pt-4"
                        style={{ marginTop: "30px" }}
                      >
                        <div className="profileImage m-auto overflow-hidden rounded-circle border-primary border d-flex justify-content-center align-items-center">
                          <img
                            src={
                              data?.profileImage === null
                                ? `${baseURL}` + "/storage/male.png"
                                : data?.profileImage
                            }
                            alt="hostImage"
                            draggable="false"
                            className="w-100"
                            onClick={() => handleOpenUrl(data?.profileImage)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 borderCT border-end">
                      <table className="uTable w-100">
                        <tr>
                          <td width="90px">Name</td>
                          <td width="50px">:</td>
                          <td className="text-capitalize">{str}</td>
                        </tr>
                        <tr>
                          <td>Unique Id</td>
                          <td>:</td>
                          <td>{data?.uniqueId}</td>
                        </tr>
                        <tr>
                          <td>Bio</td>
                          <td>:</td>
                          <td>{data?.bio}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>:</td>
                          <td>{data?.email}</td>
                        </tr>
                        <tr>
                          <td>DOB</td>
                          <td>:</td>
                          <td>{data?.dob ? data?.dob : "-"}</td>
                        </tr>

                        <tr>
                          <td>PlanLevel</td>
                          <td>:</td>
                          <td>{data?.level?.name}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 d-grid align-items-center">
                      <div className="profile  p-3 pt-4">
                        <div
                          className=" m-auto overflow-hidden  border-primary border d-flex justify-content-center align-items-center"
                          style={{ width: "310px", height: "175px" }}
                        >
                          {data?.verificationVideo ? (
                            <video
                              src={data?.verificationVideo}
                              alt="video"
                              draggable="false"
                              height={150}
                              width={190}
                              style={{ objectFit: "cover" }}
                              controls
                              onClick={() =>
                                handleOpenUrl(data?.verificationVideo)
                              }
                            />
                          ) : (
                            <div
                              style={{ height: 190, width: 293 }}
                              className="text-center d-flex justify-content-center"
                            >
                              <h5 className="m-auto text-danger fw-bold">
                                Host Does not upload the verification video!!
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>

                      <table className="uTable w-100">
                        <tr className="row ">
                          <div className="col-12 text-center ms-4">
                            <FormControl className="mb-0">
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                // onChange={(e) => {
                                //   handleApprovedClick(data, e.target.value);
                                //   console.log(
                                //     "event in redio button ",
                                //     e.target.value
                                //   );
                                // }}

                                // value={gender.toLowerCase()}
                              >
                                <FormControlLabel
                                  value="Approved"
                                  checked={data?.isApproved ? true : false}
                                  control={<Radio />}
                                  disabled={
                                    data?.verificationVideo ? false : true
                                  }
                                  onChange={() => {
                                    handleApprovedClick(data, "Approved");
                                  }}
                                  label="Approved"
                                />
                                <FormControlLabel
                                  value="Rejected"
                                  onClick={handleVideo}
                                  checked={data?.isRejected ? true : false}
                                  disabled={
                                    data?.verificationVideo ? false : true
                                  }
                                  control={<Radio />}
                                  label="Rejected"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          {/* <td className="col-5 text-center">isApproved</td>
                      <td className="col-2">:</td>
                      <td className="col-5">
                        
                        <IOSSwitch
                          checked={data?.isApproved === true ? true : false}
                          color="primary"
                          onClick={() => handleApprovedClick(data)}
                        /> */}
                          {/* </td> */}
                        </tr>
                      </table>
                      {/* </div> */}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                      style={{ maxHeight: "295px", overflowY: "auto" }}
                    >
                      <div className="row m-4">
                        {hostProfile?.verificationImages?.length > 0
                          ? hostProfile?.verificationImages?.map((image) => {
                              return (
                                <>
                                  <div className="col-4 pb-5">
                                    <img
                                      src={image}
                                      alt=""
                                      draggable="false"
                                      style={{
                                        width: "75px",
                                        height: "75px ",
                                        objectFit: "cover",
                                        display: "block",
                                      }}
                                      className="mx-auto myImage"
                                    />
                                  </div>
                                </>
                              );
                            })
                          : [1, 2, 3, 4, 5, 6].map((image) => {
                              return (
                                <>
                                  <div className="col-4 pb-5 ">
                                    <img
                                      src={image}
                                      alt=""
                                      draggable="false"
                                      style={{
                                        width: "75px",
                                        height: "75px ",
                                        objectFit: "cover",
                                        display: "block",
                                      }}
                                      className="mx-auto myImage"
                                    />
                                  </div>
                                </>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="allDetails bg-white p-4 shadow-sm mt-4">
                      <table className="uTable w-100">
                        <tr>
                          <td>Status</td>
                          <td>:</td>
                          <td>
                            {data?.isOnline === true ? (
                              <div
                                className={"badge badge-success p-2"}
                                style={{
                                  maxWidth: "121px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  padding: "10px",
                                  width: "100%",
                                }}
                                // onClick={handleSwitchOffline(data)}
                              >
                                Online
                              </div>
                            ) : (
                              <div
                                className="badge badge-danger disabled p-2"
                                style={{
                                  maxWidth: "121px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  padding: "10px",
                                  width: "100%",
                                }}
                              >
                                Offline
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td width="110px">Coin</td>
                          <td width="50px">:</td>
                          <td>
                            <EdiText
                              type="number"
                              value={data?.coin}
                              onSave={handleSave}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="110px">Alert Message</td>
                          <td width="50px">:</td>
                          <td>
                            <EdiText
                              type="text"
                              value={data?.alertMessage}
                              onSave={handleAlertSave}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Mobile No.</td>
                          <td>:</td>
                          <td>
                            {data?.mobileNumber === null
                              ? "-"
                              : data?.mobileNumber}
                          </td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          <td>:</td>
                          <td>{data?.country}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="allDetails bg-white px-4 py-3 shadow-sm mt-4">
                      <table className="uTable w-100">
                        <tr>
                          <td className="py-0">Block</td>
                          <td className="py-0">:</td>
                          <td className="py-0">
                            <ToggleSwitch
                              value={data?.isBlock == true ? true : false}
                              onClick={() => handleClick(data)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Call Charge</td>
                          <td>:</td>
                          <td>{data?.callCharge}</td>
                        </tr>
                        <tr>
                          <td width="110px">PlatformType</td>
                          <td width="50px">:</td>
                          <td>
                            {data?.platformType === 0 ? "Android" : "IOS"}
                          </td>
                        </tr>
                        <tr>
                          <td>LoginType</td>
                          <td>:</td>
                          <td>
                            {data?.loginType === 0
                              ? "Google"
                              : data?.loginType === 1
                              ? "Facebook"
                              : data?.loginType === 2
                              ? "Mobile No"
                              : "Quick Login"}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Login</td>
                          <td>:</td>
                          <td>{data?.lastLogin}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="allDetails bg-white p-4 shadow-sm mt-4">
                  <div className="pb-4 border-bottom">
                    <h4 className="page-title mb-0 text-primary text-capitalize">
                      Host Image
                    </h4>
                  </div>

                  <div
                    className="profile p-3 pt-4"
                    style={{ maxHeight: "310px", overflow: "auto" }}
                  >
                    <div className="row">
                      {data?.image?.map((profile) => {
                        return (
                          <>
                            <div className="col-3 my-4">
                              <img
                                src={profile}
                                alt="hostProfile"
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                                draggable="false"
                                onClick={() => handleOpenUrl(profile)}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {messageData && <HostMessageDialogue />}

            {verificationDialogue && <RejectionVideoDialogue />}
          </div>
        </div>
      </div>
      {/* <ShowImage /> */}
    </>
  );
};

export default connect(null, {
  getHostProfile,
  hostApproved,
  upadteHostCoin,
  updateAlertMessage,
  hostBlocked,
  offlineHost,
})(HostInfoPage);
