import React, { useState } from "react";
import Analytics from "../../../extra/Analytics";
import Title from "../../../extra/Title";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getHostTaskHistory } from "../../../store/host/host.action";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import moment from "moment";
import { useLoaderData, useLocation } from "react-router-dom";

const HostTaskHistory = (props) => {
  const { history, totalData, totalTaskCoin } = useSelector(
    (state) => state.host
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const { state } = useLocation();


  

  useEffect(() => {
    props.getHostTaskHistory(
      state?.id ? state?.id : props?.props?.historyId,
      currentPage,
      size,
      state?.startDate ? state?.startDate : startDate,
      state?.endDate ? state?.endDate : endDate
    );
  }, [currentPage, size, startDate, endDate]);

  useEffect(() => {
    setData(history);
  }, [history]);

  const start = (start) => {
    setStartDate(start);
  };

  const end = (endDate) => {
    setEndDate(endDate);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event), 10);
    setRowsPerPage(parseInt(event), 10);
    setCurrentPage(1);
  };

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  let date;
  const mapData = [
    {
      Header: "No",
      width: "20px",

      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Avatar",
      body: "image",
      Cell: ({ row }) => {
        <img
          src={row.image}
          className={`table-image`}
          height={60}
          width={60}
          alt=""
        />;
      },
    },
    { Header: "Description", body: "description" },
    {
      Header: "Coin",
      Cell: ({ row }) =>
        row.coin > 0 ? (
          <span className="text-success fs-6 fw-bold">+{row.coin}</span>
        ) : (
          <span className="text-dark fs-6 fw-semibold">{row.coin}</span>
        ),
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[0]}</span>;
      },
    },
    {
      Header: "Time",
      Cell: ({ row }) => {
        date = row?.date?.split(",");
        return <span className="text-dark fs-6 fw-semibold">{date[1]}</span>;
      },
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="row d-flex align-items-center border-bottom py-3 px-4">
        {" "}
        <div className="col-sm-12 col-md-6 ps-0">
          <Analytics analyticsStartDate={start} analyticsStartEnd={end} />
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-end">
          <Title name={`Total Coin : ` + totalTaskCoin} />
        </div>
      </div>

      <div className="userMain">
        <div className="primeMainHistory border-top mt-5">
          <Table
            data={data}
            mapData={mapData}
            serverPerPage={rowsPerPage}
            serverPage={page}
            type={"server"}
          />
        </div>
        <Pagination
          component="div"
          count={history?.length}
          type={"server"}
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={totalData}
          serverPage={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default connect(null, { getHostTaskHistory })(HostTaskHistory);
